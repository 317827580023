import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { PartitionSeekButton } from '../partition-seek-button/PartitionSeekButton';
import { PartitionSelector } from '../partition-selector/PartitionSelector';

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.secondary.background[100],
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 50
  },
  button: {
    paddingHorizontal: 8,
    paddingBottom: 12,
    flexGrow: 1,
    flexBasis: 50
  },
  selector: {
    flexGrow: 0
  },
  buttonLeft: {
    justifyContent: 'flex-end'
  },
  buttonRight: {
    justifyContent: 'flex-start'
  }
});

export const PartitionNavigationBar = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={styles.container}>
      <PartitionSeekButton
        direction="back"
        style={[styles.button, styles.buttonLeft]}
      />
      <PartitionSelector style={styles.selector} />
      <PartitionSeekButton
        direction="forward"
        style={[styles.button, styles.buttonRight]}
      />
    </View>
  );
};
