import { useMemo } from 'react';
import { Interval } from 'luxon';
import { useActivePartition } from '../active-partition-context/ActivePartitionContext';
import { useSiteTime } from '../../hooks/useSiteTime';
import { useCurrentDateTime } from '../../../../lib/use-current-time';
import { partitionToDateTimeRange } from '../../helpers/partitionToDateTimeRange';
import EmptyContent from '../../../../components/empty-list-content/EmptyContent';
import DashboardIcon from '../../../../components/icons/DashboardIcon';

export type EmptyListProps = {
  loading: boolean;
};

const EmptyList = ({ loading }: EmptyListProps): JSX.Element => {
  const partition = useActivePartition();

  const siteTime = useSiteTime();

  const time = useCurrentDateTime();

  const isCurrent = useMemo(() => {
    const [start, end] = partitionToDateTimeRange(siteTime)(partition);
    const interval = Interval.fromDateTimes(start, end);

    return interval.contains(time);
  }, [siteTime, time]);

  if (loading) {
    return <></>;
  }

  if (isCurrent) {
    return (
      <EmptyContent
        title="No transactions yet"
        iconComponent={DashboardIcon}
        body="Come back once some transactions have been created"
      />
    );
  }

  return (
    <EmptyContent
      title="No transactions during this time period"
      iconComponent={DashboardIcon}
    />
  );
};

export default EmptyList;
