import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { View } from 'react-native';
import Header from './Header';
import Divider from './Divider';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import SectionTitle from './SectionTitle';
import Item from './Item';
import DashboardIcon from '../../../../components/icons/DashboardIcon';
import ProductsIcon from '../../../../components/icons/ProductsIcon';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import PreparationIcon from '../../../../components/icons/PreparationIcon';
import PassIcon from '../../../../components/icons/PassIcon';
import ClientSwitchButton from '../client-switch/ClientSwitchButton';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

const rStyles = DynamicStyleSheet.create({
  content: {
    marginHorizontal: 12
  }
});

export const DrawerContent = (
  props: DrawerContentComponentProps
): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PlatformScrollView
      stickyHeaderIndices={[0]}
      safeAreaEdges={['left', 'top', 'right', 'bottom']}
      accessibilityRole="menu"
      accessibilityLabel="navigation drawer"
    >
      <Header {...props} />

      <View style={styles.content}>
        <Divider />

        <ClientSwitchButton />

        <SectionTitle title="Operations" />

        <Item
          title="Prep"
          icon={PreparationIcon}
          state={props.state}
          screen="prep"
        />

        <Item title="Pass" icon={PassIcon} state={props.state} screen="pass" />

        <AuthorisedContent minRole={RoleId.superUser}>
          <SectionTitle title="Management" />

          <Item
            screen="dashboard"
            title="Dashboard"
            icon={DashboardIcon}
            state={props.state}
          />
        </AuthorisedContent>

        <SectionTitle title="Admin" />

        <Item
          screen="products"
          title="Products"
          icon={ProductsIcon}
          state={props.state}
        />
      </View>
    </PlatformScrollView>
  );
};
