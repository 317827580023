import { createStackNavigator } from '@react-navigation/stack';
import { SemanticColours } from '../../../theme/SemanticColours';
import { ProductNavigatorParamList } from './ProductNavigatorParamList';
import ProductListScreen from '../screens/product-list/ProductListScreen';
import ProductShowScreen from '../screens/product-show/ProductShowScreen';
import ProductVariantScreen from '../screens/variant-show/ProductVariantScreen';
import { useSizeClass } from '../../../lib/responsive-size-class';
import ProductMasterDetailScreen from '../screens/master-detail/ProductMasterDetailScreen';
import { ProductRelationshipsContextProvider } from '../data/ProductRelationshipsContext';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { HeaderMenuContextProvider } from '../../../components/header-menu/HeaderMenuContext';

const rStyles = DynamicStyleSheet.create({
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const Stack = createStackNavigator<ProductNavigatorParamList>();

const ProductNavigator = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  return (
    <HeaderMenuContextProvider>
      <ProductRelationshipsContextProvider>
        <Stack.Navigator
          screenOptions={{
            headerTintColor: styles.headerTint.color,
            headerStyle: styles.header,
            cardStyle: { flex: 1 }
          }}
        >
          <Stack.Screen
            name="list"
            options={{ title: 'Products' }}
            component={
              size === 'small' ? ProductListScreen : ProductMasterDetailScreen
            }
          />
          <Stack.Screen name="show" component={ProductShowScreen} />
          <Stack.Screen name="variant" component={ProductVariantScreen} />
        </Stack.Navigator>
      </ProductRelationshipsContextProvider>
    </HeaderMenuContextProvider>
  );
};

export default ProductNavigator;
