import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState
} from 'react';
import { PullerContextProvider } from './PullerContext';
import ZoneMonitor from './ZoneMonitor';

export type PreparationOrdersPullContextValue = {
  retainZone: (id: string) => { release: () => void };
};

export const PreparationOrdersPullContext =
  createContext<PreparationOrdersPullContextValue | null>(null);

export const PreparationOrdersPullContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [retentions, setRetentions] = useState<Record<string, number>>({});

  const retainZone: PreparationOrdersPullContextValue['retainZone'] =
    useCallback(id => {
      setRetentions(prev => ({ ...prev, [id]: (prev[id] ?? 0) + 1 }));

      let released = false;

      return {
        release: () => {
          if (released) return;

          released = true;
          setRetentions(prev => ({ ...prev, [id]: (prev[id] ?? 0) - 1 }));
        }
      };
    }, []);

  const value = useMemo(
    () => ({
      retainZone
    }),
    [retainZone]
  );

  return (
    <PreparationOrdersPullContext.Provider value={value}>
      <PullerContextProvider>
        {Object.entries(retentions)
          .filter(([_, count]) => count > 0)
          .map(([id]) => (
            <ZoneMonitor key={id} preparationZoneId={id} />
          ))}
      </PullerContextProvider>

      {children}
    </PreparationOrdersPullContext.Provider>
  );
};
