import { DateTime } from 'luxon';
import { SiteTimeSettings } from '../types/SiteTimeSettings';

export const dateTimeToReportDate =
  (siteSettings: SiteTimeSettings) =>
  (input?: DateTime): DateTime => {
    const date = (() => {
      if (!input) return DateTime.local({ zone: siteSettings.timeZone });

      if (input.zoneName !== siteSettings.timeZone) {
        return input.setZone(siteSettings.timeZone);
      }

      return input;
    })();

    const [hour, minute] = siteSettings.dayChangeTime.split(':').map(n => +n);

    const changeover = DateTime.local(
      date.year,
      date.month,
      date.day,
      hour,
      minute
    );

    if (date < changeover) {
      return DateTime.utc(date.year, date.month, date.day).minus({ days: 1 });
    }

    return DateTime.utc(date.year, date.month, date.day);
  };
