import { ComponentType, useEffect, useMemo, useState } from 'react';
import { SvgProps } from 'react-native-svg';
import { Group } from 'ts-array-extensions/lib/groupBy';
import { useServingZoneNameLookup } from '../../../../../../data-model/components/site-context/useServingZoneNameLookup';
import { useVoucherNamesLookup } from '../../../../../../data-model/components/site-context/useVoucherNamesLookup';
import { useCashDrawerNameLookup } from '../../../../../../data-model/components/site-context/useCashDrawerNameLookup';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { Partition } from '../../../../types/Partition';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { DashboardPaymentsForDateRow } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-payments-for-date/DashboardPaymentsForDateDesignDoc';
import PaymentCardIcon from '../../../../../../components/icons/PaymentCardIcon';
import CashIcon from '../../../../../../components/icons/CashIcon';
import VoucherIcon from '../../../../../../components/icons/VoucherIcon';
import { DashboardSummarisedData } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/DashboardSummarisedData';
import {
  AnyPaymentId,
  CardPaymentId,
  CashPaymentId,
  VoucherPaymentId
} from '../../../../../../data-model/schema/databases/site-transactions-archive/design/PaymentIds';
import {
  cardPaymentIdName,
  cashPaymentIdName
} from '../../../../helpers/paymentIdNames';

type Row<
  TValue extends DashboardSummarisedData | null = DashboardSummarisedData | null
> = {
  key: string;
  Icon?: ComponentType<SvgProps>;
  title: string;
  received: TValue;
  refund: TValue;
  navigationParams: AnyPaymentId;
};

type Section<
  TValue extends DashboardSummarisedData | null = DashboardSummarisedData | null
> = {
  key: string;
  Icon: ComponentType<SvgProps>;
  title: string;
  received: TValue;
  refund: TValue;
  data: Row<TValue>[];
};

type Lookup = Readonly<{ [id: string]: string | undefined }>;

type Lookups = {
  servingZones: Lookup;
  cashDrawers: Readonly<{
    [servingZoneId: string]: Lookup;
  }>;
  vouchers: Lookup;
};

type Options = { groupLevel: 3 };

const sumSummarisedData = (
  input: readonly DashboardSummarisedData[]
): DashboardSummarisedData => {
  return {
    units: input.sum(i => i.units),
    value: input.sum(i => i.value)
  };
};

const mapCardRow =
  (lookups: Lookups) =>
  (
    row: DashboardPaymentsForDateRow<Options, CardPaymentId>
  ): Row<DashboardSummarisedData | null> => {
    const servingZoneId = row.key[2][1];

    const { received = null, refund = null } = row.value;

    const servingZoneName = lookups.servingZones[servingZoneId];

    return {
      key: servingZoneId,
      title: cardPaymentIdName(servingZoneName),
      received,
      refund,
      navigationParams: row.key[2]
    };
  };

const mapCardSection =
  (lookups: Lookups) =>
  (
    input: readonly DashboardPaymentsForDateRow<Options, CardPaymentId>[]
  ): Section<DashboardSummarisedData | null> => {
    const data = input.map(mapCardRow(lookups)).sortBy(x => x.title);

    return {
      key: 'Card',
      Icon: PaymentCardIcon,
      title: 'Card',
      data,
      received: sumSummarisedData(data.compactMap(x => x.received)),
      refund: sumSummarisedData(data.compactMap(x => x.refund))
    };
  };

const mapCashRow =
  (lookups: Lookups) =>
  (
    row: DashboardPaymentsForDateRow<Options, CashPaymentId>
  ): Row<DashboardSummarisedData | null> => {
    const servingZoneId = row.key[2][1];
    const cashDrawerId = row.key[2][2];

    const servingZoneName = lookups.servingZones[servingZoneId];
    const cashDrawerName = lookups.cashDrawers[servingZoneId]?.[cashDrawerId];

    const { received = null, refund = null } = row.value;

    return {
      key: `${servingZoneId}-${cashDrawerId}`,
      title: cashPaymentIdName(servingZoneName, cashDrawerName),
      received,
      refund,
      navigationParams: row.key[2]
    };
  };

const mapCashSection =
  (lookups: Lookups) =>
  (
    input: readonly DashboardPaymentsForDateRow<Options, CashPaymentId>[]
  ): Section<DashboardSummarisedData | null> => {
    const data = input.map(mapCashRow(lookups)).sortBy(x => x.title);

    return {
      key: 'Cash',
      Icon: CashIcon,
      title: 'Cash',
      data,
      received: sumSummarisedData(data.compactMap(x => x.received)),
      refund: sumSummarisedData(data.compactMap(x => x.refund))
    };
  };

const mapVoucherRow =
  (lookups: Lookups) =>
  (
    row: DashboardPaymentsForDateRow<Options, VoucherPaymentId>
  ): Row<DashboardSummarisedData | null> => {
    const voucherId = row.key[2][1];

    const { received = null, refund = null } = row.value;

    return {
      key: voucherId,
      title: lookups.vouchers[voucherId] ?? 'Voucher payments',
      received,
      refund,
      navigationParams: row.key[2]
    };
  };

const mapVouchersSection =
  (lookups: Lookups) =>
  (
    input: readonly DashboardPaymentsForDateRow<Options, VoucherPaymentId>[]
  ): Section<DashboardSummarisedData | null> => {
    const data = input.map(mapVoucherRow(lookups)).sortBy(x => x.title);

    return {
      key: 'Voucher',
      Icon: VoucherIcon,
      title: 'Vouchers',
      data,
      received: sumSummarisedData(data.compactMap(x => x.received)),
      refund: sumSummarisedData(data.compactMap(x => x.refund))
    };
  };

const mapSection =
  (lookups: Lookups) =>
  (
    group: Group<
      'Card' | 'Cash' | 'Voucher',
      DashboardPaymentsForDateRow<Options>
    >
  ): Section | null => {
    switch (group.key) {
      case 'Card':
        return mapCardSection(lookups)(
          group.values as readonly DashboardPaymentsForDateRow<
            Options,
            CardPaymentId
          >[]
        );
      case 'Cash':
        return mapCashSection(lookups)(
          group.values as readonly DashboardPaymentsForDateRow<
            Options,
            CashPaymentId
          >[]
        );
      case 'Voucher':
        return mapVouchersSection(lookups)(
          group.values as readonly DashboardPaymentsForDateRow<
            Options,
            VoucherPaymentId
          >[]
        );
      default:
        return null;
    }
  };

const mapRows =
  (lookups: Lookups) =>
  (rows: readonly DashboardPaymentsForDateRow<Options>[]): Section[] => {
    return rows.groupBy(r => r.key[2][0]).compactMap(mapSection(lookups));
  };

const nullifyValues = (section: Section): Section<null> => {
  return {
    ...section,
    data: section.data.map(d => ({ ...d, received: null, refund: null })),
    received: null,
    refund: null
  };
};

export const usePaymentTypesListData = (partition: Partition) => {
  const { data: servingZones } = useServingZoneNameLookup();
  const { data: vouchers } = useVoucherNamesLookup();
  const { data: cashDrawers } = useCashDrawerNameLookup();

  const transactionsDb = useTransactionsDb();

  const [cache, setCache] = useState<Section<null>[]>([]);

  const intervalDate = useMemo(
    () => partitionToIntervalDate(partition),
    [partition]
  );

  const sales = useTransform(
    useQueryStream(transactionsDb.remote.design.dashboardPaymentsForDate.view, {
      groupLevel: 3,
      reduce: true,
      startKey: intervalDate,
      endKey: [...intervalDate, {}]
    }),
    rows => mapRows({ servingZones, vouchers, cashDrawers })(rows),
    () => cache,
    [servingZones, vouchers, cashDrawers]
  );

  useEffect(() => {
    if (sales.loading) return;

    setCache(sales.data.map(nullifyValues));
  }, [sales]);

  return sales;
};
