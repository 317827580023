import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo } from 'react';
import { ViewProps } from 'react-native';
import { PrepNavigatorParamList } from '../../../navigation/PrepNavigatorParamList';
import { useContrastTabTheme } from '../../../../../components/tabs/useContrastTabTheme';
import OrderCompletedIcon from '../../../../../components/icons/OrderCompletedIcon';
import { Tab } from '../../../../../components/tabs/Tab';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../lib/dynamic-style-sheet';
import { useQueryStream } from '../../../../../data-model/hooks/useQueryStream';
import { usePreparationOrdersDb } from '../../../../../data-model/components/site-context/usePreparationOrdersDb';

export type CompletedVisibilityTabProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  tab: {
    width: 64
  }
});

const CompletedVisibilityTab = React.memo<CompletedVisibilityTabProps>(
  ({ style, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const tabStyles = useContrastTabTheme();

    const navigation =
      useNavigation<
        StackNavigationProp<
          PrepNavigatorParamList,
          keyof PrepNavigatorParamList
        >
      >();

    const route = useRoute<RouteProp<PrepNavigatorParamList, 'tasks'>>();

    const db = usePreparationOrdersDb();

    const { data } = useQueryStream(db.local.design.countByStatus.views.main, {
      startKey: [route.params?.prepZoneId ?? '', '04 Ready'],
      endKey: [route.params?.prepZoneId ?? '', '04 Ready', []],
      groupLevel: 2,
      reduce: true
    });

    const readyCount = useMemo(() => {
      const value = data?.first()?.value ?? 0;

      if (value === 0) return '';
      if (value > 99) return '99';
      return String(value);
    }, [data]);

    return (
      <Tab
        style={[styles.tab, style]}
        styles={tabStyles}
        indicatorLayout="on-top"
        icon={OrderCompletedIcon}
        isActive={route.params?.tab === 'completed'}
        onPress={() =>
          navigation.setParams({
            tab: route.params?.tab === 'completed' ? undefined : 'completed'
          })
        }
        disableActive={false}
        badge={readyCount}
        {...rest}
      />
    );
  }
);

export default CompletedVisibilityTab;
