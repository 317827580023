import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { SiteCommandsDatabaseSchema } from './SiteCommandsDatabaseSchema';

export class SiteCommandsDatabase extends DatabasePair<SiteCommandsDatabaseSchema> {
  constructor(
    clientId: string,
    siteId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(
      `sp-${clientId}-${siteId}-commands`,
      SiteCommandsDatabaseSchema,
      queryConfig,
      syncConfig
    );
  }
}
