import { ComponentType } from 'react';
import { View, ViewProps } from 'react-native';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../theme/SemanticColours';
import CallToActionButton from '../buttons/CallToActionButton';
import { Text } from '../text/Text';

export type EmptyContentProps = Omit<ViewProps, 'children'> & {
  actionButton?: {
    iconComponent?: ComponentType<SvgProps>;
    onPress: () => void;
    title: string;
  };
  body?: string;
  iconComponent: ComponentType<SvgProps>;
  title?: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: SemanticColours.primary.background[100],
    borderRadius: 8,
    paddingHorizontal: 32,
    paddingVertical: 16,
    margin: 24
  },
  text: {
    marginVertical: 8,
    textAlign: 'center'
  },
  icon: {
    color: SemanticColours.primary.foreground[50],
    width: 24,
    height: 24
  },
  iconChrome: {
    backgroundColor: SemanticColours.primary.background['90'],
    borderRadius: 100,
    margin: 8,
    padding: 8
  },
  button: {
    marginVertical: 20
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    margin: 32
  }
});

const EmptyContent = ({
  actionButton,
  body,
  iconComponent: Icon,
  style,
  title,
  ...rest
}: EmptyContentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <View style={styles.iconChrome}>
        <Icon
          fill={styles.icon.color}
          width={styles.icon.width}
          height={styles.icon.height}
        />
      </View>
      {!!title && <Text.DocTitle style={styles.text}>{title}</Text.DocTitle>}
      {!!body && <Text.DocBody style={styles.text}>{body}</Text.DocBody>}
      {!!actionButton && (
        <CallToActionButton
          style={styles.button}
          title={actionButton.title}
          iconComponent={actionButton.iconComponent}
          onPress={actionButton.onPress}
        />
      )}
    </View>
  );
};

export default EmptyContent;
