/* eslint-disable @typescript-eslint/no-empty-function */
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NewClientInput, PersonalSecurity } from './types';

const PERSONAL_SECURITY_STORAGE_ITEM_KEY = 'PERSONAL_SECURITY';

export class Store {
  private constructor() {}

  public static async set(value: PersonalSecurity): Promise<void> {
    await AsyncStorage.setItem(
      PERSONAL_SECURITY_STORAGE_ITEM_KEY,
      JSON.stringify(value)
    );
  }

  public static async remove(): Promise<void> {
    await AsyncStorage.removeItem(PERSONAL_SECURITY_STORAGE_ITEM_KEY);
  }

  public static async get(): Promise<PersonalSecurity | null> {
    const existingStr = await AsyncStorage.getItem(
      PERSONAL_SECURITY_STORAGE_ITEM_KEY
    );

    return existingStr ? (JSON.parse(existingStr) as PersonalSecurity) : null;
  }

  public static async addOrReplaceClient(
    input: NewClientInput
  ): Promise<PersonalSecurity> {
    const existing = await this.get();

    const clients = existing
      ? existing.clients.filter(c => c.id !== input.id)
      : [];

    const next: PersonalSecurity = {
      ...(existing || {}),
      clients: [...clients, input],
      activeClientId: existing ? existing.activeClientId : input.id
    };

    await this.set(next);

    return next;
  }
}
