import { Tab, TabProps } from '../../../../components/tabs/Tab';
import { useContrastTabTheme } from '../../../../components/tabs/useContrastTabTheme';
import { useProductDataContext } from '../../data/ProductDataContext';

export type ProductTabProps = Omit<TabProps, 'title'>;

const ProductTab = (props: ProductTabProps): JSX.Element => {
  const { product, loading } = useProductDataContext();

  const styles = useContrastTabTheme();

  if (loading) return <Tab styles={styles} title=" " disabled isActive />;

  return (
    <Tab styles={styles} title={product?.productName ?? 'Product'} {...props} />
  );
};

export default ProductTab;
