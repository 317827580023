import { Database } from '../../support/Database';
import { SitesRepository } from './repositories/SitesRepository';

export class SitesDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly repositories = {
    sites: new SitesRepository(this.database)
  };
}
