import { Database } from '../../support/Database';
import { RunningBalanceDesignDoc } from './design/RunningBalanceDesignDoc';
import { StockTransactionRepository } from './repositories/StockTransactionRepository';

export class StockTransactionsDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly design = {
    runningBalance: new RunningBalanceDesignDoc(this.database)
  };

  public readonly repositories = {
    stockTransactions: new StockTransactionRepository(this.database)
  };
}
