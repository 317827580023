import { useEffect, useMemo, useState } from 'react';

type UsePromiseValue<T> =
  | { value: null; loading: true; error: null }
  | { value: T; loading: false; error: null }
  | { value: null; loading: false; error: Error | null };

export const usePromise = <T>(promise: Promise<T>): UsePromiseValue<T> => {
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let mounted = true;

    promise
      .then(v => {
        if (mounted) {
          setResult(v);
          setLoading(false);
        }
      })
      .catch(e => {
        if (mounted) {
          if (e instanceof Error) {
            setError(e);
          }
          setLoading(false);
        }
      });

    return () => {
      mounted = false;
    };
  }, [promise]);

  const hookValue: UsePromiseValue<T> = useMemo(() => {
    if (loading) {
      return { value: null, loading, error: null };
    }

    if (result) {
      return { value: result, loading, error: null };
    }

    if (error) {
      return { value: null, loading, error: null };
    }

    return { value: null, loading, error: null };
  }, [loading]);

  return hookValue;
};
