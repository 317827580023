import { View } from 'react-native';
import { IconButton } from '../buttons/IconButton';
import { CloseIcon } from '../icons/CloseIcon';
import TickIcon from '../icons/TickIcon';
import { Text } from '../text/Text';
import ModalHeader, { ModalHeaderProps } from '../modal-header/ModalHeader';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type ModalConfirmHeaderProps = Omit<ModalHeaderProps, 'children'> & {
  disabled?: boolean;
  onCross?: () => void;
  onTick?: () => void;
  tickEnabled?: boolean;
  title: string;
};

const rStyles = DynamicStyleSheet.create({
  icon: {
    flexShrink: 1,
    flexGrow: 0,
    flexBasis: 30,
    color: SemanticColours.secondary.foreground[100]
  },
  titleContainer: {
    flexGrow: 1,
    alignItems: 'center'
  },
  title: {
    textAlign: 'center',
    color: SemanticColours.secondary.foreground[100]
  }
});

const ModalConfirmHeader = ({
  disabled = false,
  onCross,
  onTick,
  tickEnabled = true,
  title,
  ...rest
}: ModalConfirmHeaderProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <ModalHeader {...rest}>
      <View style={styles.icon}>
        {!!onCross && (
          <IconButton
            disabled={disabled}
            iconComponent={CloseIcon}
            iconSize={22}
            onPress={onCross}
            iconColour={styles.icon.color}
          />
        )}
      </View>

      <View style={styles.titleContainer}>
        <Text.HeaderTitle style={styles.title}>{title}</Text.HeaderTitle>
      </View>

      <View style={styles.icon}>
        {!!onTick && (
          <IconButton
            disabled={disabled || !tickEnabled}
            iconComponent={TickIcon}
            iconSize={24}
            onPress={onTick}
            iconColour={styles.icon.color}
          />
        )}
      </View>
    </ModalHeader>
  );
};

export default ModalConfirmHeader;
