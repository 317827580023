import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { Document, DocumentProperties } from '../../types/Document';
import { Range } from '../../../lib/ts-range/Range';
import { LengthOf } from '../../../lib/ts-tuple/LengthOf';
import { TupleSplitHead } from '../../../lib/ts-tuple/Slice';
import { PartialTuple } from '../../../lib/ts-tuple/PartialTuple';
import { retryPromise } from '../../../lib/retry-promise';
import { Database } from './Database';
import { Subject } from '../../../lib/pub-sub/Subject';
import { SyncDisposable } from '../../../lib/disposable/SyncDisposable';
import { SyncDisposeBag } from '../../../lib/disposable/SyncDisposeBag';

type ViewKeyElement = string | number | boolean | null | Array<ViewKeyElement>;

export type ViewKey = ViewKeyElement | readonly ViewKeyElement[];

export type GroupLevelsFor<TKey> = TKey extends readonly unknown[]
  ? Range<0, LengthOf<TKey>>
  : 0 | 1 | undefined;

export type GroupLevelOf<TOptions, TKey extends ViewKey> = TOptions extends {
  groupLevel: infer N;
}
  ? N extends number
    ? N
    : never
  : TKey extends readonly unknown[]
  ? LengthOf<TKey>
  : 1;

type KeyFor<TOptions, TKey extends ViewKey> = GroupLevelOf<
  TOptions,
  TKey
> extends number
  ? TKey extends readonly unknown[]
    ? TupleSplitHead<TKey, GroupLevelOf<TOptions, TKey>>
    : TKey
  : TKey;

export type KeyForGroupLevel<
  TKey extends ViewKey,
  TGroupLevel extends number | undefined
> = TKey extends readonly unknown[]
  ? TGroupLevel extends number
    ? TupleSplitHead<TKey, TGroupLevel>
    : TKey
  : TKey;

// eslint-disable-next-line @typescript-eslint/ban-types
type RangeEndMarker = readonly never[] | {};

export type RangeKey<TKey> = TKey extends readonly unknown[]
  ?
      | TKey
      | readonly [...TKey, RangeEndMarker]
      | PartialTuple<TKey>
      | readonly [...PartialTuple<TKey>, RangeEndMarker]
  : TKey;

export type RangeKeyForGroupLevel<
  TKey extends ViewKey,
  TGroupLevel extends number | undefined
> = RangeKey<KeyForGroupLevel<TKey, TGroupLevel>>;

export type QueryOptions<
  TKey extends ViewKey,
  TGroupLevel extends GroupLevelsFor<TKey>
> = {
  readonly groupLevel?: TGroupLevel;
  readonly startKey?: RangeKeyForGroupLevel<TKey, TGroupLevel>;
  readonly endKey?: RangeKeyForGroupLevel<TKey, TGroupLevel>;
  readonly key?: TKey;
  readonly descending?: boolean;
  readonly includeDocs?: boolean;
  readonly reduce?: boolean;
  readonly limit?: number;
  readonly skip?: number;
  readonly inclusiveEnd?: boolean;
};

type DocumentType<
  TOptions,
  TDoc extends DocumentProperties
> = TOptions extends {
  readonly includeDocs: true;
}
  ? Document<TDoc>
  : never;

export type QueryRow<
  TKey extends ViewKey,
  TValue,
  TOptions,
  TDoc extends DocumentProperties
> = {
  readonly key: KeyFor<TOptions, TKey>;
  readonly value: TValue;
  readonly documentID: string;
  readonly document: DocumentType<TOptions, TDoc>;
};

export class DatabaseView<
  TKey extends ViewKey,
  TValue,
  TDoc extends DocumentProperties = DocumentProperties
> {
  constructor(
    public readonly database: Database,
    public readonly designDocumentId: string,
    public readonly viewName: string
  ) {}

  private readonly logger = createLogger(nameof({ DatabaseView }), {
    database: this.database.pouch.name,
    designDocumentId: this.designDocumentId,
    viewName: this.viewName
  });

  async getQuery<
    const TOptions extends QueryOptions<
      TKey,
      GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
    >
  >(options?: TOptions): Promise<QueryRow<TKey, TValue, TOptions, TDoc>[]> {
    this.logger.debug('Get query', { options });

    const queryName = `${this.designDocumentId.split('/')[1]}/${this.viewName}`;

    const result = await retryPromise(() =>
      this.database.pouch.query(queryName, {
        group_level: options?.groupLevel,
        startkey: options?.startKey,
        endkey: options?.endKey,
        key: options?.key,
        descending: options?.descending,
        include_docs: options?.includeDocs,
        reduce: options?.reduce ?? false,
        limit: options?.limit,
        skip: options?.skip,
        inclusive_end: options?.inclusiveEnd
      })
    );

    return result.rows.map(({ id, key, value, doc }) => {
      return {
        documentID: id as string,
        key: key as KeyFor<TOptions, TKey>,
        value: value as TValue,
        document: doc as DocumentType<TOptions, TDoc>
      };
    });
  }

  getQueryStream<
    const TOptions extends QueryOptions<
      TKey,
      GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
    >
  >(
    options: TOptions | undefined,
    onNext: (rows: readonly QueryRow<TKey, TValue, TOptions, TDoc>[]) => void,
    onError: (error: Error) => void
  ): SyncDisposable {
    let ended = false;

    const subject = new Subject<
      QueryRow<TKey, TValue, TOptions, TDoc>[] | Error | 'DO_FETCH'
    >();

    const disposable = new SyncDisposeBag(
      this.database.changes.subscribe({
        next: () => {
          if (!ended) subject.next('DO_FETCH');
        }
      }),

      // fetch handler
      subject.subscribe({
        next: v => {
          if (v !== 'DO_FETCH' || ended) return;

          this.getQuery<TOptions>(options)
            .then(results => {
              if (!ended) subject.next(results);
            })
            .catch((error: unknown) => {
              if (ended) return;

              if (error instanceof Error) {
                subject.next(error);
              } else {
                this.logger.error('Received non-Error instance from query', {
                  error
                });
              }
            });
        }
      }),

      // error handler
      subject.subscribe({
        next: v => {
          if (!(v instanceof Error) || ended) return;

          onError(v);

          setTimeout(() => {
            if (ended) return;

            subject.next('DO_FETCH');
          }, 1000);
        }
      }),

      // new data handler
      subject.subscribe({
        next: v => {
          if (!Array.isArray(v) || ended) return;

          onNext(v);
        }
      })
    );

    subject.next('DO_FETCH');

    return {
      dispose: () => {
        ended = true;
        disposable.dispose();
      }
    };
  }
}
