import {
  ErrorBoundaryPropsWithComponent,
  ErrorBoundary as Original
} from 'react-error-boundary';
import { ExceptionModel, trackError } from 'appcenter-crashes';
import { PropsWithChildren } from 'react';
import { Platform } from 'react-native';
import { ProblemContent } from '../unhappy-path-content/problem/ProblemContent';

export type ErrorBoundaryProps = Omit<
  PropsWithChildren<ErrorBoundaryPropsWithComponent>,
  'FallbackComponent'
> & {
  FallbackComponent?: ErrorBoundaryPropsWithComponent['FallbackComponent'];
};

export const ErrorBoundary = ({
  FallbackComponent = ProblemContent,
  onError,
  ...rest
}: ErrorBoundaryProps): JSX.Element => {
  return (
    <Original
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        onError?.(error, info);

        if (!__DEV__ && Platform.OS !== 'web') {
          const exceptionModel = ExceptionModel.createFromError(error);
          trackError(exceptionModel, info);
        }
      }}
      {...rest}
    />
  );
};
