import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';

export type DesignDocument = {
  views?: Record<string, { map: string; reduce?: string }>;
  filters?: Record<string, string>;
};

export class DesignDocumentDefinition {
  constructor(
    protected readonly documentId: string,
    protected readonly definition: DesignDocument
  ) {}

  private readonly log = createLogger(nameof({ DesignDocumentDefinition }), {
    documentId: this.documentId
  });

  private async shouldUpdate(
    database: PouchDB.Database
  ): Promise<
    { type: 'CREATE' } | { type: 'UPDATE'; _rev: string } | { type: 'NO' }
  > {
    try {
      const existing = await database.get<DesignDocument>(this.documentId);

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id: _, _rev, ...comparator } = existing;

      const [existingStr, defStr] = [comparator, this.definition].map(v =>
        JSON.stringify(v)
      );

      if (existingStr !== defStr) {
        return { type: 'UPDATE', _rev };
      }

      return { type: 'NO' };
    } catch (e: unknown) {
      if (e instanceof Error && e.name === 'not_found') {
        return { type: 'CREATE' };
      }
      throw e;
    }
  }

  public async ensureDefined(database: PouchDB.Database): Promise<void> {
    this.log.debug('Checking if needs updating');

    const result = await this.shouldUpdate(database);

    if (result.type === 'NO') {
      this.log.info('Does not require update');

      return;
    }

    const content = {
      _id: this.documentId,
      ...(result.type === 'UPDATE' ? { _rev: result._rev } : {}),
      ...this.definition
    };

    const { rev } = await database.put(content);

    this.log.info('Updated', { document: { ...content, _rev: rev } });

    if (!content.views) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const viewName of Object.keys(content.views)) {
      this.log.debug('Triggering a sequential view update', { viewName });

      const queryName = `${this.documentId.split('/')[1]}/${viewName}`;

      await database.query(queryName, { limit: 1 });
    }
  }
}
