import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';
import PlatformPressable from '../platform-pressable/PlatformPressable';
import HamburgerMenuIcon from '../icons/HamburgerMenuIcon';

const styles = StyleSheet.create({
  style: { paddingHorizontal: 12 }
});

export const DrawerToggle = (): JSX.Element => {
  const navigation =
    useNavigation<DrawerNavigationProp<Record<string, undefined>>>();

  return (
    <PlatformPressable
      onPress={navigation.toggleDrawer}
      style={styles.style}
      accessibilityLabel="reveal navigation drawer"
    >
      <HamburgerMenuIcon />
    </PlatformPressable>
  );
};
