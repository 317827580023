import { Logger } from '../../lib/logging';
import { verifyClient } from '../helpers/verifyClient';
import { PersonalSecurity } from './types';
import { verifyUser } from './verifyUser';

export const verify = async (
  security: PersonalSecurity,
  serverUrl: string,
  log: Logger
): Promise<PersonalSecurity | null> => {
  const maybeClients = await Promise.all(
    security.clients.map(async client => {
      const clientResult = await verifyClient(client.id, serverUrl, log);

      if (clientResult === null) {
        return null;
      }

      const userResult =
        'token' in client.user
          ? await verifyUser(client.user, serverUrl, log)
          : client.user;

      return {
        ...client, // for deviceId and defaultSiteId
        ...clientResult,
        user: userResult
      };
    })
  );

  const clients = maybeClients.compact();

  if (clients.none()) {
    return null;
  }

  const activeClientId = (() => {
    if (security.clients.map(c => c.id).includes(security.activeClientId)) {
      return security.activeClientId;
    }

    return clients.first(c => 'token' in c.user)?.id ?? clients[0].id;
  })();

  return {
    ...security,
    clients,
    activeClientId
  };
};
