import { forwardRef } from 'react';
import { TextInput } from 'react-native';
import { TextField, TextFieldProps } from '../text-field';

export type PasswordTextFieldProps = Omit<TextFieldProps, 'name'> &
  Partial<Pick<TextFieldProps, 'name'>>;

export const PasswordTextField = forwardRef<TextInput, PasswordTextFieldProps>(
  (props: PasswordTextFieldProps, ref): JSX.Element => (
    <TextField
      autoCapitalize="none"
      autoComplete="password"
      autoCorrect={false}
      clearButtonMode="while-editing"
      enablesReturnKeyAutomatically
      name="password"
      placeholder="password"
      returnKeyType="go"
      textContentType="password"
      secureTextEntry
      {...props}
      ref={ref}
    />
  )
);
