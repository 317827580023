import {
  ComponentType,
  ReactElement,
  cloneElement,
  useCallback,
  useMemo,
  useState
} from 'react';
import {
  LayoutChangeEvent,
  ScrollView,
  ScrollViewProps,
  View
} from 'react-native';
import Reanimated, {
  Easing,
  Layout,
  ZoomIn,
  ZoomOut
} from 'react-native-reanimated';
import { range } from 'ts-array-extensions';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import useSet from '../../lib/use-set';

const rStyles = DynamicStyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },

  content: {
    padding: 8,
    paddingTop: 10
  },

  columns: {
    flexDirection: 'row'
  },

  column: {
    flexDirection: 'column',
    flexBasis: 1,
    flexGrow: 1
  },

  cardContainer: {
    padding: 8,
    position: 'absolute'
  },

  layingOut: {
    opacity: 0
  }
});

type CardWallDatum = {
  key: string;
  element: ReactElement;
};

export type CardWallData = CardWallDatum[];

export type CardWallProps = Omit<ScrollViewProps, 'children'> & {
  numColumns?: number;
  data: CardWallData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListHeaderComponent?: ComponentType<any> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListEmptyComponent?: ComponentType<any> | null;
};

const CardWall = ({
  style,
  data,
  numColumns = 1,
  ListHeaderComponent,
  ListEmptyComponent,
  ...rest
}: CardWallProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [heights, setHeights] = useState({} as Record<string, number>);
  const [containerWidth, setContainerWidth] = useState(300);

  const containerLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  const cardLayout = useCallback((id: string, event: LayoutChangeEvent) => {
    const {
      layout: { height }
    } = event.nativeEvent;

    if (height > 0) {
      setHeights(orig => {
        if (orig[id] !== height) {
          return { ...orig, [id]: height };
        }

        return orig;
      });
    }
  }, []);

  const layout = useMemo(() => {
    const columns = range(1, numColumns).map(() => 0);
    const newLayout: Record<string, { y: number; column: number }> = {};

    data.forEach(({ key }) => {
      if (!heights[key]) return;

      const chosenIndex = columns.findIndex(v => v === columns.min());

      newLayout[key] = {
        y: columns[chosenIndex],
        column: chosenIndex
      };
      columns[chosenIndex] += heights[key];
    });

    return {
      cards: newLayout,
      overallHeight: columns.max() ?? 300
    };
  }, [heights, numColumns, data]);

  const [_, { has, toggle }] = useSet<string>();

  return (
    <ScrollView
      style={[style, styles.root]}
      contentContainerStyle={styles.content}
      {...rest}
    >
      {ListHeaderComponent && <ListHeaderComponent />}

      {data.length === 0 && ListEmptyComponent && <ListEmptyComponent />}

      <View
        style={[styles.columns, { height: layout.overallHeight }]}
        onLayout={containerLayout}
      >
        {data.map(({ key, element }) => {
          return (
            <Reanimated.View
              style={[
                styles.cardContainer,
                { width: containerWidth / numColumns },
                layout.cards[key]
                  ? {
                      top: layout.cards[key].y,
                      left:
                        (layout.cards[key].column * containerWidth) / numColumns
                    }
                  : styles.layingOut
              ]}
              key={key}
              onLayout={e => cardLayout(key, e)}
              layout={Layout.easing(Easing.ease)}
              entering={ZoomIn}
              exiting={ZoomOut}
            >
              {cloneElement(element, {
                expanded: has(key),
                onToggleExpansion: () => toggle(key)
              })}
            </Reanimated.View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default CardWall;
