import { useEffect } from 'react';
import { View } from 'react-native';
import CallToActionButton from '../../../../../components/buttons/CallToActionButton';
import { usePromiseFactory } from '../../../../../lib/use-promise/usePromiseFactory';
import { useProductExport } from '../../../data/useProductExport';
import { downloadLocalCsv } from './downloadLocalCsv';
import PlatformModal, {
  PlatformModalProps
} from '../../../../../components/platform-modal/PlatformModal';
import ModalConfirmHeader from '../../../../../components/modal-confirm-header/ModalConfirmHeader';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../lib/dynamic-style-sheet';
import { Text } from '../../../../../components/text/Text';
import CsvIcon from '../../../../../components/icons/CsvIcon';
import { SemanticColours } from '../../../../../theme/SemanticColours';
import ExportIcon from '../../../../../components/icons/ExportIcon';
import { nameof } from '../../../../../lib/name-of';

export type ExportModalProps = Omit<
  PlatformModalProps,
  'children' | 'onRequestClose'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    padding: 24,
    alignItems: 'center',
    backgroundColor: SemanticColours.primary.background[70],
    flex: 1
  },
  icon: {
    width: 48,
    height: 48,
    color: SemanticColours.primary.foreground[30]
  },
  text: {
    marginVertical: 24
  },
  button: {
    alignSelf: 'center'
  }
});

const ExportModal = ({ onDismiss, ...rest }: ExportModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const doExportPromise = useProductExport();

  const { invoke, result, inProgress } = usePromiseFactory(doExportPromise);

  useEffect(() => {
    if (result?.type !== 'SUCCESS') return;

    void downloadLocalCsv(result.data);
  }, [result]);

  return (
    <PlatformModal
      onRequestClose={onDismiss}
      {...rest}
      hugsContent
      testID={nameof({ ExportModal })}
    >
      <ModalConfirmHeader title="Export Products" onCross={onDismiss} />
      <View style={styles.container}>
        <CsvIcon
          width={styles.icon.width}
          height={styles.icon.height}
          fill={styles.icon.color}
        />

        <Text.DocBody style={styles.text}>
          Export your products to a CSV file to analyse using a spreadsheet app.
        </Text.DocBody>

        <CallToActionButton
          style={styles.button}
          title="Export now"
          onPress={invoke}
          disabled={inProgress}
          iconComponent={ExportIcon}
        />
      </View>
    </PlatformModal>
  );
};

export default ExportModal;
