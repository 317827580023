import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native-gesture-handler';
import PlatformModal from '../../components/platform-modal/PlatformModal';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../dynamic-style-sheet';
import { GlobalActivity } from './GlobalActivity';
import ModalConfirmHeader from '../../components/modal-confirm-header/ModalConfirmHeader';
import { SemanticColours } from '../../theme/SemanticColours';
import ListItemSeparator from '../../components/cells/ListItemSeparator';
import BasicCell from '../../components/cells/BasicCell';
import { nameof } from '../name-of';
import PlatformScrollView from '../../components/platform-scroll-view/PlatformScrollView';

export type ActivitiesModalProps = {
  dismiss: () => void;
  visible: boolean;
};

const rStyles = DynamicStyleSheet.create({
  root: {},
  list: {
    backgroundColor: SemanticColours.primary.background[80],
    flex: 1
  }
});

const ActivitiesModal = React.memo<ActivitiesModalProps>(
  ({ dismiss, visible }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const [data, setData] = useState<[string, string][]>([]);

    useEffect(() => {
      const activitySubscription = GlobalActivity.activities.subscribe({
        next: v => setData(v)
      });

      return () => {
        activitySubscription.dispose();
      };
    }, []);

    return (
      <PlatformModal
        style={styles.root}
        onRequestClose={dismiss}
        visible={visible}
      >
        <ModalConfirmHeader title="Background activity" onCross={dismiss} />
        <FlatList
          renderScrollComponent={scrollProps => (
            <PlatformScrollView {...scrollProps} />
          )}
          style={styles.list}
          data={data}
          keyExtractor={d => d[0]}
          ItemSeparatorComponent={ListItemSeparator}
          renderItem={({ item }) => <BasicCell title={item[1]} />}
        />
      </PlatformModal>
    );
  }
);

ActivitiesModal.displayName = nameof({ ActivitiesModal });

export default ActivitiesModal;
