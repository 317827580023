import React, { Children, ReactNode } from 'react';
import { ActivityIndicator, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import PreparationOrderLineSeparator from '../PreparationOrderLineSeparator';

export type CardLinesProps = ViewProps & { loading?: boolean };

const rStyles = DynamicStyleSheet.create({
  container: {},
  activity: {
    alignSelf: 'center',
    margin: 20
  },
  cell: {
    paddingVertical: 11,
    paddingHorizontal: 20
  }
});

const Cell = ({ children }: { children: ReactNode }): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return <View style={styles.cell}>{children}</View>;
};

const CardLines = React.memo<CardLinesProps>(
  ({ style, children, loading, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);
    const arrayChildren = Children.toArray(children);

    return (
      <View style={[styles.container, style]} {...rest}>
        {loading && <ActivityIndicator style={styles.activity} />}
        <PreparationOrderLineSeparator />
        {Children.map(arrayChildren, child => (
          <>
            <Cell>{child}</Cell>
            <PreparationOrderLineSeparator />
          </>
        ))}
      </View>
    );
  }
);

export default CardLines;
