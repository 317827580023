import { StyleSheet, View, ViewProps } from 'react-native';
import { useMemo } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export interface ListItemSeparatorProps extends Omit<ViewProps, 'children'> {
  inset?: boolean | number;
  contrastColourScheme?: boolean;
}

const baseStyles = StyleSheet.create({
  line: {
    borderBottomWidth: StyleSheet.hairlineWidth
  },
  inset: {
    marginLeft: 18
  }
});

const normalStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[100]
  },
  line: {
    borderBottomColor: SemanticColours.primary.foreground[15]
  }
});

const contrastStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.secondary.background[100]
  },
  line: {
    borderBottomColor: SemanticColours.secondary.foreground[30]
  }
});

const ListItemSeparator = Object.assign(
  ({
    style,
    inset = true,
    contrastColourScheme = false,
    ...rest
  }: ListItemSeparatorProps): JSX.Element => {
    const styles = useDynamicStyleSheet(
      contrastColourScheme ? contrastStyles : normalStyles
    );

    const insetStyle = useMemo(() => {
      if (!inset) return null;

      if (inset === true) {
        return baseStyles.inset;
      }

      return {
        marginLeft: inset
      };
    }, [inset, baseStyles]);

    return (
      <View style={[styles.container, style]} {...rest}>
        <View style={[baseStyles.line, styles.line, insetStyle]} />
      </View>
    );
  },
  {
    itemHeight: StyleSheet.hairlineWidth
  }
);

export default ListItemSeparator;
