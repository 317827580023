import React, { useMemo } from 'react';
import { ViewProps } from 'react-native';
import { Document } from '../../../../data-model/types/Document';
import {
  PreparationOrder,
  PreparationOrderStatuses
} from '../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import Timeline from '../../../../components/timeline/Timeline';

export type StatusTimelineProps = Omit<ViewProps, 'children'> & {
  document: Document<PreparationOrder>;
};

const rStyles = DynamicStyleSheet.create({ root: {} });

const StatusTimeline = React.memo<StatusTimelineProps>(
  ({ style, document, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const points = useMemo(() => {
      const done =
        document.statusHistory?.map(({ time, status }) => ({ time, status })) ??
        [];

      const remaining = PreparationOrderStatuses.filter(
        s => s > (done.last()?.status ?? '')
      ).map(s => ({ status: s, time: undefined }));

      return [...done, ...remaining].map(({ status, time }) => ({
        label: status.substring(3),
        time
      }));
    }, [document]);

    return <Timeline style={[styles.root, style]} points={points} {...rest} />;
  }
);

export default StatusTimeline;
