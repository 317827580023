import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

const rStyles = DynamicStyleSheet.create({
  activeIndicator: {
    backgroundColor: SemanticColours.secondary.foreground[65]
  },
  activeLabel: {
    color: SemanticColours.secondary.foreground[100]
  },
  label: {
    color: SemanticColours.secondary.foreground[60]
  }
});

export const useContrastTabTheme = () => {
  const styles = useDynamicStyleSheet(rStyles);

  return styles;
};
