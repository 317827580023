import { HeaderMenuOptions } from '../../../../components/header-menu/HeaderMenuOptions';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import ExportModal from '../modals/export/ExportModal';

const ExportMenuOption = (): JSX.Element => {
  const { present, dismiss, ...modal } = useModalControl();

  return (
    <>
      <HeaderMenuOptions
        options={[{ label: `Export products`, onSelect: present }]}
      />
      <ExportModal {...modal} onDismiss={dismiss} />
    </>
  );
};

export default ExportMenuOption;
