import { SvgProps } from 'react-native-svg';
import { PaymentType } from '../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import PaymentCardIcon from './PaymentCardIcon';
import VoucherIcon from './VoucherIcon';
import { assertNever } from '../../lib/assert-never';
import CashIcon from './CashIcon';
import NoSaleIcon from './NoSaleIcon';

export type PaymentIconProps = SvgProps & {
  paymentType: PaymentType;
};

const PaymentTypeIcon = ({ paymentType, ...rest }: PaymentIconProps) => {
  switch (paymentType) {
    case 'Card':
      return <PaymentCardIcon {...rest} />;
    case 'Cash':
      return <CashIcon {...rest} />;
    case 'Voucher':
      return <VoucherIcon {...rest} />;
    case 'NoSale':
      return <NoSaleIcon {...rest} />;
    default:
      return assertNever(paymentType);
  }
};

export default PaymentTypeIcon;
