/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { LogLevel } from './LogLevel';
import { LogSink } from './Sink';

export class UnstyledConsoleSink implements LogSink {
  next(
    level: LogLevel,
    component: string,
    message: string,
    additionalFields?: Record<string, unknown> | undefined
  ): void {
    console[level](`${component} : ${message}`, additionalFields);
  }
}
