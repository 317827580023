import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type TimelineLineProps = Omit<ViewProps, 'children'> & {
  completed?: boolean;
  visible?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    height: 5,
    borderBottomColor: SemanticColours.primary.foreground[80],
    borderBottomWidth: 1,
    flexGrow: 1
  },
  notCompleted: {
    borderBottomColor: SemanticColours.primary.foreground[15]
  },
  notVisible: {
    opacity: 0
  }
});

const TimelineLine = React.memo<TimelineLineProps>(
  ({ style, completed, visible = true, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View
        style={[
          styles.root,
          !completed && styles.notCompleted,
          !visible && styles.notVisible,
          style
        ]}
        {...rest}
      />
    );
  }
);

export default TimelineLine;
