import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../components/cells/LegacyCellGroup';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import EditPreparationZoneCell from './EditPreparationZoneCell';
import { useProductDataContext } from '../../data/ProductDataContext';
import PreparationIcon from '../../../../components/icons/PreparationIcon';
import PreparationEnabledCell from './PreparationEnabledCell';
import EditPreparationCourseCell from './EditPreparationCourseCell';

export type PreparationCellGroupProps = Omit<
  LegacyCellGroupProps,
  'headingText' | 'headingIconComponent' | 'children'
>;

const PreparationCellGroup = (
  props: PreparationCellGroupProps
): JSX.Element => {
  const data = useProductDataContext();

  return (
    <LegacyCellGroup
      headingText="Preparation"
      headingIconComponent={PreparationIcon}
      footerText={
        data.product?.requiresPreparation
          ? 'Products with the same course will be grouped together at preparation time.'
          : 'Enable this option to send orders for this product with a table number for preparation.'
      }
      {...props}
    >
      <PreparationEnabledCell />

      {data.product?.requiresPreparation && (
        <>
          <ListItemSeparator />
          <EditPreparationZoneCell />
          <ListItemSeparator />
          <EditPreparationCourseCell />
        </>
      )}
    </LegacyCellGroup>
  );
};

export default PreparationCellGroup;
