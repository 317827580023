import { useEffect, useMemo, useState } from 'react';
import { useReferenceDb } from '../../../../../../data-model/components/site-context/useReferenceDb';
import { useProductNameLookup } from '../../../../../../data-model/components/site-context/useProductNameLookup';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { Partition } from '../../../../types/Partition';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { formatMoney } from '../../../../../../humanisers/formatMoney';

export const useProductsListData = (partition: Partition) => {
  const referenceDb = useReferenceDb();

  const { data: productNameLookup } = useProductNameLookup();

  const { data: departmentNameLookup } = useTransform(
    useQueryStream(referenceDb.local.design.views.allDepartments),
    rows => Object.fromEntries(rows.map(row => [row.documentID, row.key[1]])),
    () => ({})
  );

  const { data: productDepartmentLookup } = useTransform(
    useQueryStream(referenceDb.local.design.views.productsByDepartment),
    rows => Object.fromEntries(rows.map(row => [row.documentID, row.key[0]])),
    () => ({})
  );

  const transactionsDb = useTransactionsDb();

  const [cache, setCache] = useState<
    {
      id: string;
      name: string;
      data: {
        id: string;
        name: string;
        value: string;
        units: string;
      }[];
      value: string;
    }[]
  >([]);

  const intervalDate = useMemo(
    () => partitionToIntervalDate(partition),
    [partition]
  );

  const sales = useTransform(
    useQueryStream(transactionsDb.remote.design.dashboardProductsForDate.view, {
      groupLevel: 3,
      reduce: true,
      startKey: intervalDate,
      endKey: [...intervalDate, []]
    }),
    rows =>
      rows
        .groupBy(row => {
          return productDepartmentLookup[row.key[2]];
        })
        .map(({ key: departmentId, values: products }) => {
          const groupTotal = products.sum(p => p.value.value);

          return {
            id: departmentId,
            name: !departmentId
              ? 'Ungrouped'
              : departmentNameLookup[departmentId] ?? 'No name',
            data: products
              .map(row => ({
                id: row.key[2],
                name: productNameLookup[row.key[2]],
                value: formatMoney(row.value.value),
                units: String(row.value.units)
              }))
              .sortBy(x => x.name),
            value: formatMoney(groupTotal)
          };
        })
        .sortBy(x => x.name),
    () => cache,
    [productNameLookup, departmentNameLookup, productDepartmentLookup]
  );

  useEffect(() => {
    if (sales.loading) return;

    setCache(
      sales.data.map(group => ({
        ...group,
        value: '',
        data: group.data.map(datum => ({
          ...datum,
          value: '',
          units: ''
        }))
      }))
    );
  }, [sales]);

  return sales;
};
