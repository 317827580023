import { assertNever } from '../../../../lib/assert-never';
import { isCalendarDayPartition } from '../../helpers/isCalendarDayPartition';
import { isCalendarMonthPartition } from '../../helpers/isCalendarMonthPartition';
import { isCalendarYearPartition } from '../../helpers/isCalendarYearPartition';
import { isIsoWeekPartition } from '../../helpers/isIsoWeekPartition';
import { partitionToDate } from '../../helpers/partitionToDate';
import { Partition } from '../../types/Partition';

export const formatPartitionRelative = (
  partition: Partition,
  relative: Partition
): string => {
  const relativeDate = partitionToDate(relative);
  const partitionDate = partitionToDate(partition);

  const sameYear = relativeDate.year === partitionDate.year;
  const sameMonth = sameYear && relativeDate.month === partitionDate.month;
  const sameDay =
    sameYear && sameMonth && relativeDate.day === partitionDate.day;

  const sameWeek =
    relativeDate.weekYear === partitionDate.weekYear &&
    relativeDate.weekNumber === partitionDate.weekNumber;

  if (isCalendarDayPartition(partition)) {
    if (sameDay) return 'Today';

    return partitionDate.toLocaleString({
      day: 'numeric',
      month: 'numeric',
      year: sameYear ? undefined : 'numeric'
    });
  }

  if (isIsoWeekPartition(partition)) {
    if (sameWeek) return 'This week';

    return String(partitionDate.weekNumber);
  }

  if (isCalendarMonthPartition(partition)) {
    return partitionDate.toLocaleString({
      month: 'short',
      year: sameYear ? undefined : '2-digit'
    });
  }

  if (isCalendarYearPartition(partition)) {
    if (sameYear) return 'This year';

    return partitionDate.toLocaleString({
      year: sameYear ? undefined : 'numeric'
    });
  }

  return assertNever(partition);
};
