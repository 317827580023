import baseX from 'base-x';
import uuid from 'react-native-uuid';
import { Buffer } from '@craftzdog/react-native-buffer';

const base62 = baseX(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
);

const encode = (id: string): string => {
  const data = Buffer.from(id.replace(/-/g, ''), 'hex');
  return base62.encode(data).padStart(22, '0');
};

export const newRandomUuid = (): string => {
  return encode(uuid.v4() as string);
};
