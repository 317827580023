import { ComponentType } from 'react';
import { ActivityIndicator, ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../theme/SemanticColours';
import { Text } from '../text/Text';

export type CellGroupHeadingProps = ViewProps & {
  iconComponent?: ComponentType<SvgProps>;
  loading?: boolean;
  title: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 4,
    paddingHorizontal: 16,
    backgroundColor: SemanticColours.primary.background[90]
  },
  icon: {
    marginRight: 10,
    color: SemanticColours.primary.foreground[50]
  },
  activity: {
    marginLeft: 8
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    paddingBottom: 6
  }
});

const CellGroupHeading = ({
  style,
  title,
  iconComponent: Icon,
  loading,
  ...rest
}: CellGroupHeadingProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <SafeAreaView
      style={[styles.container, style]}
      edges={['left', 'right']}
      {...rest}
    >
      {Icon && (
        <Icon
          height={16}
          width={16}
          style={styles.icon}
          fill={styles.icon.color}
        />
      )}
      <Text.SectionTitle>{title}</Text.SectionTitle>
      {loading && <ActivityIndicator style={styles.activity} />}
    </SafeAreaView>
  );
};

export default CellGroupHeading;
