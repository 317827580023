export const addRequestIdHeader = (
  opts: RequestInit | undefined,
  requestId: string
): RequestInit => {
  const headers = new Headers(opts?.headers ?? {});
  headers.set('x-request-id', requestId);

  return {
    ...opts,
    headers
  };
};
