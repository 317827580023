import { Promotion } from '../../documents/Promotion';
import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('promotion-') === 0) {
    emit([!!doc.deleted ?? false, String(doc.name)], null);
  }
};

export class AllPromotionsView extends DatabaseView<
  readonly [deleted: boolean, name: string],
  null,
  Promotion
> {
  static readonly viewName = 'allPromotions';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllPromotionsView.viewName);
  }
}
