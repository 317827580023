import React from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import {
  IconButton,
  IconButtonProps
} from '../../../../components/buttons/IconButton';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type ActionIconButtonProps = Omit<
  IconButtonProps,
  'iconColour' | 'iconSize'
>;

const rStyles = DynamicStyleSheet.create({
  root: {
    backgroundColor: SemanticColours.primary.foreground[10],
    borderColor: SemanticColours.primary.foreground[20],
    borderWidth: 1
  },
  icon: {
    color: SemanticColours.primary.foreground[50],
    height: 16
  }
});

const ActionIconButton = React.memo<ActionIconButtonProps>(
  ({ style, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <IconButton
        style={[styles.root, style]}
        {...rest}
        iconColour={styles.icon.color}
        iconSize={styles.icon.height}
      />
    );
  }
);

export default ActionIconButton;
