import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export const useSizeClass = (): 'small' | 'medium' | 'large' => {
  const isLarge = useMediaQuery({ minWidth: 1224 });
  const isMedium = useMediaQuery({ minWidth: 940 });

  const size = useMemo(() => {
    if (isLarge) {
      return 'large';
    }

    if (isMedium) {
      return 'medium';
    }

    return 'small';
  }, [isLarge, isMedium]);

  return size;
};
