import { Repository } from '../../../support/Repository';
import { newRandomUuid } from '../../../../../lib/uuid';
import { PriceBand } from '../documents/PriceBand';
import { Database } from '../../../support/Database';

export class PriceBandsRepository extends Repository<PriceBand> {
  constructor(database: Database) {
    super(database, () => `priceband-${newRandomUuid()}`);
  }
}
