import { ViewProps, ViewStyle } from 'react-native';
import { Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { usePromiseFactory } from '../../../../lib/use-promise/usePromiseFactory';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../../../../components/text-field';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { useSiteContext } from '../../../../data-model/components/site-context/useSiteId';
import { useDeviceId } from '../../../../components/device-context/useDeviceId';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import UserContext from '../../../../security/context/UserContext';
import { nameof } from '../../../../lib/name-of';

export type EditStockBalanceModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
  productId: string;
  currentBalance: number;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

type FormValues = {
  currentBalance: string;
  newBalance: string;
};

const validate = (values: FormValues): FormikErrors<FormValues> => {
  const asNum = +values.newBalance.trim();

  if (Number.isNaN(asNum)) {
    return { newBalance: 'Must be a number' };
  }

  if (asNum < 0) {
    return { newBalance: 'Must be a positive number, or zero' };
  }

  if (asNum % 1 !== 0) {
    return { newBalance: 'Must be a whole number' };
  }

  return {};
};

const EditStockBalanceModal = ({
  style,
  dismiss,
  visible,
  productId,
  currentBalance,
  ...rest
}: EditStockBalanceModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [error, setError] = useState<string | null>(null);

  const { stockTransactions } = useSiteContext().databases;
  const deviceID = useDeviceId();
  const user = useContextOrThrow(UserContext, nameof({ UserContext }));

  const { invoke, inProgress, result, reset } = usePromiseFactory(
    useCallback(
      async (newBalance: number) => {
        const adjustment = newBalance - currentBalance;

        if (adjustment === 0) return;

        await stockTransactions.local.repositories.stockTransactions.createDocument(
          {
            deviceID,
            createdAt: new Date().toISOString(),
            userID: user.userId,
            lines: [
              adjustment < 0
                ? {
                    type: 'WRITE_OFF',
                    productID: productId,
                    delta: {
                      sign: 'NEGATIVE',
                      wholes: Math.abs(adjustment)
                    }
                  }
                : {
                    type: 'WRITE_ON',
                    productID: productId,
                    delta: {
                      sign: 'POSITIVE',
                      wholes: Math.abs(adjustment)
                    }
                  }
            ]
          }
        );
      },
      [productId, deviceID, user, currentBalance, stockTransactions]
    )
  );

  useEffect(() => {
    if (!visible) {
      reset();
      setError(null);
    }
  }, [visible, reset]);

  useEffect(() => {
    if (!result) return;

    if (result?.type === 'SUCCESS') {
      dismiss();
      return;
    }

    setError('An unexpected error occurred.');
  }, [result]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      setError(null);
      invoke(+values.newBalance.trim());
    },
    [invoke, productId]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView
        style={[styles.container, style as ViewStyle]}
        {...rest}
      >
        <Formik
          initialValues={{
            currentBalance: `${currentBalance}`,
            newBalance: `${currentBalance}`
          }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title="Adjust stock on hand"
                onCross={dismiss}
                onTick={handleSubmit}
                tickEnabled={
                  !inProgress && currentBalance !== +formProps.values.newBalance
                }
              />

              <TextField
                {...formProps}
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                editable={false}
                inputStyle={styles.field}
                name={'currentBalance'}
                placeholder="Current balance"
              />

              <TextField
                {...formProps}
                autoCapitalize="none"
                autoCorrect={false}
                autoFocus
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                enablesReturnKeyAutomatically
                editable={!inProgress}
                inputStyle={styles.field}
                keyboardType="number-pad"
                name={'newBalance'}
                onSubmitEditing={() => handleSubmit()}
                placeholder="New balance"
                returnKeyType="go"
              />

              {error && (
                <ErrorMessage style={styles.error}>{error}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default EditStockBalanceModal;
