import { Calendar, CalendarProps } from 'react-native-calendars';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateData, MarkedDates } from 'react-native-calendars/src/types';
import { DateTime } from 'luxon';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import { View } from 'react-native';
import { TimeInterval } from '../../types/TimeInterval';
import { SemanticColours } from '../../../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { intervalDateToSubIntervals } from '../../helpers/intervalDateToSubIntervals';
import { usePartitionNavigation } from '../partition-navigation-context/usePartitionNavigation';
import { CalendarDayPartition } from '../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { partitionToIntervalDate } from '../../helpers/partitionToIntervalDate';
import LeftIcon from '../../../../components/icons/LeftIcon';
import RightIcon from '../../../../components/icons/RightIcon';

export type PartitionCalendarProps = Omit<CalendarProps, 'children'> & {
  selectedInterval: Exclude<TimeInterval, 'year'>;
  selectedDate: string;
  onSelect(date: string): void;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  content: {
    backgroundColor: SemanticColours.primary.background[90]
  },
  calendarSelectedDay: {
    backgroundColor: SemanticColours.secondary.background[100],
    color: SemanticColours.secondary.foreground[100]
  },
  arrow: {
    color: SemanticColours.primary.foreground[40]
  },
  today: {
    color: SemanticColours.primary.contrast[100]
  },
  title: {
    color: SemanticColours.primary.foreground[100]
  }
});

export const PartitionCalendar = ({
  selectedInterval,
  selectedDate,
  onSelect,
  ...rest
}: PartitionCalendarProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const calendar = usePartitionNavigation().day;

  const [visibleMonth, setVisibleMonth] = useState<{
    year: number;
    month: number;
  }>({
    year: +selectedDate.substring(0, 4),
    month: +selectedDate.substring(5, 7)
  });

  // load, or load more, to cover the visible month
  useEffect(() => {
    const to = DateTime.utc(visibleMonth.year, visibleMonth.month);
    const toKey: CalendarDayPartition = ['calendar', to.year, to.month, to.day];

    if (calendar.state.state === 'EMPTY') {
      calendar.reload(toKey);
      return;
    }

    if (calendar.state.state !== 'SUCCESS') return;

    if (!calendar.rangeContains(toKey)) {
      calendar.loadMore(toKey);
    }
  }, [visibleMonth, calendar.state]);

  const effectiveDate = useMemo(
    () =>
      selectedInterval === 'day'
        ? selectedDate
        : DateTime.fromISO(selectedDate)
            .startOf(selectedInterval)
            .toISODate() ?? selectedDate,
    [selectedInterval, selectedDate]
  );

  const markedDates: MarkedDates | undefined = useMemo(() => {
    if (!('rows' in calendar.state) || !calendar.state.rows) return undefined;

    const days = calendar.state.rows
      ?.filter(
        r =>
          r.partition[0] === 'calendar' &&
          r.partition[1] === visibleMonth.year &&
          r.partition[2] === visibleMonth.month
      )
      .map(r => partitionToIntervalDate(r.partition)[1]);

    if (selectedInterval === 'day') {
      return Object.fromEntries([
        ...days.map(date => {
          const marking: MarkingProps = {
            selected: date === effectiveDate,
            disableTouchEvent: date === effectiveDate,
            marked: true
          };

          return [date, marking] as const;
        }),
        ...(!days.includes(effectiveDate)
          ? [
              [
                effectiveDate,
                {
                  selected: true
                }
              ] as const
            ]
          : [])
      ]);
    }

    const range = intervalDateToSubIntervals([selectedInterval, effectiveDate]);

    const start = range.first();
    const end = range.last();

    if (!start || !end) {
      throw new Error(
        `Interval ${selectedInterval} ${effectiveDate} range is empty`
      );
    }

    const middle = range.slice(1, range.length - 1);

    const shared = {
      color: styles.calendarSelectedDay.backgroundColor,
      textColor: styles.calendarSelectedDay.color
    };

    return Object.fromEntries(
      days
        .outerJoin(range, (l, r) => l === r)
        .map(row => {
          const date = row.left ?? row.right;

          const marking: MarkingProps = {
            startingDay: date === start,
            endingDay: date === end,
            selected: middle.includes(date),
            disableTouchEvent: row.right !== null,
            marked: row.left !== null,
            ...(row.right !== null ? shared : {})
          };

          return [date, marking] as const;
        })
    );
  }, [selectedInterval, effectiveDate, calendar.state, visibleMonth]);

  const displayLoadingIndicator = useMemo(() => {
    return ['EMPTY', 'RELOADING', 'LOADING_MORE'].includes(
      calendar.state.state
    );
  }, [calendar.state]);

  const maxDate = useMemo(() => {
    if (calendar.state.state !== 'SUCCESS' || !calendar.state.rows?.any()) {
      return new Date().toISOString().substring(0, 10);
    }

    return partitionToIntervalDate(calendar.state.rows[0].partition)[1];
  }, [calendar.state]);

  const onDayPress = useCallback(
    (selection: DateData) => {
      if (selectedInterval === 'day') {
        onSelect(selection.dateString);
        return;
      }

      const dt = DateTime.utc(selection.year, selection.month, selection.day)
        .startOf(selectedInterval)
        .toISODate();

      if (dt) {
        onSelect(dt);
      }
    },
    [selectedInterval]
  );

  return (
    <View style={styles.container}>
      <Calendar
        theme={{
          calendarBackground: styles.content.backgroundColor,
          todayTextColor: styles.today.color,
          monthTextColor: styles.title.color,
          selectedDayBackgroundColor:
            styles.calendarSelectedDay.backgroundColor,
          selectedDayTextColor: styles.calendarSelectedDay.color
        }}
        onDayPress={onDayPress}
        current={selectedDate}
        firstDay={1}
        showSixWeeks
        showWeekNumbers={selectedInterval === 'week'}
        markingType={selectedInterval !== 'day' ? 'period' : undefined}
        markedDates={markedDates}
        maxDate={maxDate}
        displayLoadingIndicator={displayLoadingIndicator}
        onMonthChange={setVisibleMonth}
        renderArrow={direction =>
          direction === 'left' ? (
            <LeftIcon fill={styles.arrow.color} />
          ) : (
            <RightIcon fill={styles.arrow.color} />
          )
        }
        {...rest}
      />
    </View>
  );
};
