import { createContext } from 'react';
import { CalendarDayPartition } from '../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import {
  CalendarMonthPartition,
  CalendarYearPartition,
  IsoWeekPartition
} from '../../types/Partition';
import { PaginatedQueryHookValue } from '../../../../data-model/hooks/usePaginatedQuery';
import { CalendarViewKey } from '../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-dates/CalendarView';
import { TupleSplitHead } from '../../../../lib/ts-tuple/Slice';

export type PartitionNavigationSlice<
  TPartition,
  TGroupLevel extends number
> = PaginatedQueryHookValue<
  Readonly<{ partition: TPartition; value: number }>,
  TupleSplitHead<CalendarViewKey, TGroupLevel>
>;

export type PartitionNavigationContextValue = Readonly<{
  day: PartitionNavigationSlice<CalendarDayPartition, 4>;
  month: PartitionNavigationSlice<CalendarMonthPartition, 3>;
  year: PartitionNavigationSlice<CalendarYearPartition, 2>;
  week: PartitionNavigationSlice<IsoWeekPartition, 3>;
}>;

export const PartitionNavigationContext =
  createContext<PartitionNavigationContextValue | null>(null);
