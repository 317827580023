import { PropsWithChildren } from 'react';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';
import UnauthorisedScreen from './UnauthorisedScreen';

export type DrawerAuthorisationBoundaryProps = PropsWithChildren<{
  minRole: RoleId;
}>;

const DrawerAuthorisationBoundary = (
  props: DrawerAuthorisationBoundaryProps
): JSX.Element => {
  return (
    <AuthorisedContent fallbackComponent={UnauthorisedScreen} {...props} />
  );
};

export default DrawerAuthorisationBoundary;
