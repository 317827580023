import { SyncDisposable } from '../disposable/SyncDisposable';
import { createLogger } from '../logging';
import { nameof } from '../name-of';
import { Mapped } from '../pub-sub/Mapped';
import { Subject } from '../pub-sub/Subject';
import { Subscribable } from '../pub-sub/Subscribable';
import { withKeysRemoved } from '../remove-keys/removeKeys';
import { newRandomUuid } from '../uuid';

export class GlobalActivity {
  private static readonly _activities = new Subject<Record<string, string>>({
    initialValue: {}
  });
  private static readonly _hasActivity = new Subject<boolean>({
    initialValue: false
  });
  private static _activityCount = 0;

  private static log = createLogger(nameof({ GlobalActivity }));

  public static get hasActivity(): Subscribable<boolean> {
    return this._hasActivity;
  }

  public static activities: Subscribable<[string, string][]> = new Mapped(
    this._activities,
    v => Object.entries(v)
  );

  public static enroll(description: string): SyncDisposable {
    const key = newRandomUuid();
    let disposed = false;

    if (this._activityCount === 0) {
      this._hasActivity.next(true);
    }

    this._activityCount += 1;

    this._activities.next({
      ...this._activities.current,
      [key]: description
    });

    return {
      dispose: () => {
        if (disposed) return;

        disposed = true;

        if (this._activityCount === 1) {
          this._hasActivity.next(false);
        }

        this._activities.next(
          withKeysRemoved(this._activities.current ?? {}, key)
        );

        this._activityCount -= 1;
      }
    };
  }

  public static async track<T>(
    description: string,
    fn: () => Promise<T>
  ): Promise<T> {
    let enrolment: SyncDisposable | undefined;

    try {
      enrolment = this.enroll(description);
      const result = await fn();
      return result;
    } finally {
      enrolment?.dispose();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
}
