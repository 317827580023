import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { ReLoginNavigatorParamList } from '../../navigation/ReLoginNavigatorParamList';
import { GlobalActivityIndicator } from '../../../../lib/global-activity/GlobalActivityIndicator';
import { IconButton } from '../../../../components/buttons/IconButton';
import AddIcon from '../../../../components/icons/AddIcon';

export type HeaderAddButtonProps = {
  disabled?: boolean;
  onPress: () => void;
};

const rStyles = DynamicStyleSheet.create({
  rightButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12
  }
});

const HeaderAddButton = React.memo<HeaderAddButtonProps>(
  ({ disabled, onPress }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const navigation =
      useNavigation<
        StackNavigationProp<ReLoginNavigatorParamList, 'client-list'>
      >();

    useEffect(() => {
      navigation.setOptions({
        headerRight: headerProps => (
          <View style={styles.rightButtons}>
            <GlobalActivityIndicator color={headerProps.tintColor} />
            <IconButton
              iconColour={headerProps.tintColor}
              iconComponent={AddIcon}
              onPress={onPress}
              disabled={disabled}
            />
          </View>
        )
      });
    }, [navigation]);

    return <></>;
  }
);

export default HeaderAddButton;
