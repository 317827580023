import React, { useCallback, useEffect, useState } from 'react';
import SwitchCell from '../../../../components/cells/SwitchCell';
import { useProductDataContext } from '../../data/ProductDataContext';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { enableProductPreparation } from '../../commands/enableProductPreparation';
import { disableProductPreparation } from '../../commands/disableProductPreparation';
import { CellProps } from '../../../../components/cells/Cell';

export type PreparationEnabledCellProps = Omit<CellProps, 'children'>;

const PreparationEnabledCell = React.memo<PreparationEnabledCellProps>(
  props => {
    const data = useProductDataContext();

    const [isOn, setIsOn] = useState(false);

    useEffect(() => {
      if (data.loading) return;
      setIsOn(data.product?.requiresPreparation);
    }, [data]);

    const enableCommand = useCommand(enableProductPreparation, r => {
      if (r instanceof Error || r.type !== 'SUCCESS') {
        setIsOn(false);
      }
    });

    const disableCommand = useCommand(disableProductPreparation, r => {
      if (r instanceof Error || r.type !== 'SUCCESS') {
        setIsOn(true);
      }
    });

    const isOnChanged = useCallback((value: boolean) => {
      setIsOn(value);

      if (value) {
        enableCommand.invoke({ productId: data.productId });
      } else {
        disableCommand.invoke({ productId: data.productId });
      }
    }, []);

    return (
      <SwitchCell
        {...props}
        title="Send orders for preparation"
        loading={data.loading}
        isOn={isOn}
        isOnChanged={isOnChanged}
        disabled={enableCommand.inProgress || disableCommand.inProgress}
      />
    );
  }
);

export default PreparationEnabledCell;
