import { LogBox, Platform } from 'react-native';
import { UnstyledConsoleSink } from '../lib/logging/UnstyledConsoleSink';
import { ConsoleSink } from '../lib/logging/ConsoleSink';
import { LoggingConfig } from '../lib/logging/LoggingConfig';
import { WebSink } from '../lib/logging/WebSink';

if (__DEV__) {
  LoggingConfig.sink =
    Platform.OS === 'web' ? new WebSink() : new ConsoleSink();

  LogBox.ignoreLogs([
    'Require cycle: node_modules/pouchdb-utils',
    'Require cycle: node_modules/csv-string',

    // while iOS is mixed native/react
    'No native splash screen registered for given view controller'
  ]);
} else {
  LoggingConfig.sink =
    Platform.OS === 'web' ? new WebSink() : new UnstyledConsoleSink();
}
