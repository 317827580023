import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { ProductsRepository } from '../../../data-model/schema/databases/site-reference/repositories/ProductsRepository';
import { assertNever } from '../../../lib/assert-never';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import { ProductV3 } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

const commandType = 'createVariant' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      name: string;
      price: string;
      variantId: string;
    };
  }
}

export const createVariant = async (
  args: Omit<SiteCommands[typeof commandType], 'type' | 'variantId'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'NAME_CONFLICT' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const {
    reference: { local }
  } = ctx.databases;
  const { productId, name, price } = args;
  const { userId, createdAt } = ctx.meta;

  const variantId = ProductsRepository.nextVariantId();
  const allPriceBands = await local.design.views.allPriceBands.getQuery();
  const defaultPriceBandId = allPriceBands[0].documentID;
  const prices = { [defaultPriceBandId]: price };
  const compatProductId = local.repositories.products.nextId();

  const updateResult = await local.repositories.products.updateDocument<
    'NOT_FOUND' | 'NAME_CONFLICT',
    ProductV3
  >(productId, (old, update, abort) => {
    const asV3 = convertProductToV3(old);

    if (asV3 === 'N/A') {
      return abort('NOT_FOUND');
    }

    if (asV3.variants.some(v => v.name === name)) {
      return abort('NAME_CONFLICT');
    }

    return update({
      ...asV3,
      revisionUserID: userId,
      revisionTime: createdAt,
      variants: [
        ...asV3.variants,
        {
          $schema: 'DeclaredVariant',
          $schemaVersion: '2',
          id: variantId,
          name,
          prices,
          compatProductId,
          priceCalculation: 'None',
          valueType: 'Fixed'
        }
      ]
    });
  });

  if (updateResult.type === 'SKIPPED') {
    throw new Error('Impossible');
  }

  if (updateResult.type === 'NOT_FOUND') {
    return { type: 'NOT_FOUND' };
  }

  if (updateResult.type === 'ABORTED') {
    if (updateResult.reason === 'NOT_FOUND') {
      return { type: 'NOT_FOUND' };
    }
    if (updateResult.reason === 'NAME_CONFLICT') {
      return { type: 'NAME_CONFLICT' };
    }

    return assertNever(updateResult.reason);
  }

  const createCompatResult = await local.repositories.products.createDocument(
    compatProductId,
    {
      $schema: 'Product',
      $schemaVersion: '1',
      variantOfProductId: productId,
      variantId,
      department: updateResult.data.department,
      requiresPreparation: updateResult.data.requiresPreparation,
      preparationZone:
        'preparationZone' in updateResult.data
          ? updateResult.data.preparationZone
          : undefined,
      course:
        'course' in updateResult.data ? updateResult.data.course : undefined,
      prices,
      priceCalculation: 'None',
      valueType: 'Fixed',
      revisionUserID: userId,
      revisionTime: createdAt,
      name: `${updateResult.data.productName} ${name}`
    }
  );

  if (createCompatResult.type !== 'SUCCESS') {
    throw new Error('Improbable nextId conflict');
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      name,
      price,
      variantId
    },
    [productId, variantId, compatProductId]
  );

  return { type: 'SUCCESS', data: command };
};
