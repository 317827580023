import React, { useMemo } from 'react';
import { Text, View, ViewProps } from 'react-native';
import { PreparationOrderStatus } from '../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type PrepStatusBarProps = Omit<ViewProps, 'children'> & {
  status: PreparationOrderStatus;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    padding: 5
  },
  text: {
    color: SemanticColours.secondary.foreground[95],
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: '800',
    fontSize: 12,
    letterSpacing: 0.5
  },
  pending: {
    backgroundColor: SemanticColours.status.pending.foreground
  },
  released: {
    backgroundColor: SemanticColours.status.released.foreground
  },
  inProgress: {
    backgroundColor: SemanticColours.status.inProgress.foreground
  },
  ready: {
    backgroundColor: SemanticColours.status.ready.foreground
  },
  collected: {
    backgroundColor: SemanticColours.status.collected.foreground
  }
});

const PrepStatusBar = React.memo<PrepStatusBarProps>(
  ({ style, status, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const styleForStatus = useMemo(() => {
      switch (status) {
        case '01 Pending':
          return styles.pending;
        case '02 Released':
          return styles.released;
        case '03 In Progress':
          return styles.inProgress;
        case '04 Ready':
          return styles.ready;
        case '05 Collected':
          return styles.collected;
        default:
          return false;
      }
    }, [styles, status]);

    return (
      <View style={[styles.root, styleForStatus, style]} {...rest}>
        <Text style={styles.text}>{status.substring(3)}</Text>
      </View>
    );
  }
);

export default PrepStatusBar;
