import { useQueryStream } from '../../hooks/useQueryStream';
import { useTransform } from '../../hooks/useTransform';
import { useSecurityDb } from './useSecurityDb';

export const useUsernameLookup = () => {
  const db = useSecurityDb();

  return useTransform(
    useQueryStream(db.local.design.views.allUsers),
    rows => Object.fromEntries(rows.map(r => [r.documentID, r.key[1]])),
    () => ({})
  );
};
