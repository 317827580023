import { forwardRef, useCallback, useState } from 'react';
import {
  Pressable,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
  ViewProps
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { useForwardRef } from '../../lib/use-forward-ref';
import { SemanticColours } from '../../theme/SemanticColours';
import { ClearIcon } from '../icons/ClearIcon';
import { SearchIcon } from '../icons/SearchIcon';

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: SemanticColours.primary.background[90],
    borderBottomColor: SemanticColours.primary.foreground[20],
    borderBottomWidth: StyleSheet.hairlineWidth
  },
  containerFocussed: {
    backgroundColor: SemanticColours.primary.background[100],
    borderBottomColor: SemanticColours.primary.foreground[50],
    shadowColor: SemanticColours.primary.foreground[50],
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.15
  },
  input: {
    color: SemanticColours.primary.foreground[50],
    fontSize: 17,
    fontWeight: '300',
    height: 50,
    paddingLeft: 46,
    paddingRight: 20,
    width: '100%'
  },
  searchIcon: {
    position: 'absolute',
    alignSelf: 'center',
    left: 16,
    color: SemanticColours.primary.foreground[40]
  },
  clearIcon: {
    position: 'absolute',
    alignSelf: 'center',
    right: 16,
    color: SemanticColours.primary.foreground[60]
  },
  placeholder: {
    color: SemanticColours.primary.foreground[40]
  }
});

export type ListSearchCellProps = {
  onFocussedChanged?: (focussed: boolean) => void;
  onClear: () => void;
} & Pick<TextInputProps, 'onChangeText' | 'placeholder' | 'value'> &
  Pick<ViewProps, 'style'>;

export const ListSearchCell = forwardRef<TextInput, ListSearchCellProps>(
  (
    {
      placeholder,
      style,
      onFocussedChanged,
      onClear: onClearExt,
      onChangeText,
      value
    },
    parentRef
  ): JSX.Element => {
    const styles = useDynamicStyleSheet(rStyles);

    const [focussed, setFocussed] = useState(false);

    const onFocus = useCallback(() => {
      setFocussed(true);
      onFocussedChanged?.(true);
    }, [setFocussed, onFocussedChanged]);

    const onBlur = useCallback(() => {
      setFocussed(false);
      onFocussedChanged?.(false);
    }, [setFocussed, onFocussedChanged]);

    const ref = useForwardRef(parentRef);

    const onClear = useCallback(() => {
      onClearExt();
      ref.current?.blur();
    }, [ref, onClearExt]);

    return (
      <SafeAreaView
        edges={['left', 'right']}
        style={[styles.container, focussed && styles.containerFocussed, style]}
      >
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <TextInput
            ref={ref}
            style={styles.input}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            onChangeText={onChangeText}
            placeholderTextColor={styles.placeholder.color}
            value={value}
          />
          <SearchIcon
            style={styles.searchIcon}
            fill={styles.searchIcon.color}
            width={22}
            height={22}
          />
          {(!!value || focussed) && (
            <Pressable style={styles.clearIcon} onPress={onClear}>
              <ClearIcon fill={styles.clearIcon.color} width={22} height={22} />
            </Pressable>
          )}
        </View>
      </SafeAreaView>
    );
  }
);
