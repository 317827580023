import { ComponentType, PropsWithChildren } from 'react';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import UserContext from '../../context/UserContext';
import { nameof } from '../../../lib/name-of';
import { roleIsAtLeast } from '../../helpers/roleIsAtLeast';

const DefaultFallback = (): JSX.Element => <></>;

export type AuthorisedContentProps = PropsWithChildren<{
  minRole: RoleId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fallbackComponent?: ComponentType<any>;
}>;

const AuthorisedContent = ({
  children,
  minRole,
  fallbackComponent: Fallback = DefaultFallback
}: AuthorisedContentProps): JSX.Element => {
  const { role } = useContextOrThrow(UserContext, nameof({ UserContext }));

  const authorised = roleIsAtLeast(role, minRole);

  return <>{authorised ? children : <Fallback />}</>;
};

export default AuthorisedContent;
