import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../../../components/cells/LegacyCellGroup';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { isCalendarYearPartition } from '../../../../helpers/isCalendarYearPartition';
import { partitionToSubPartitions } from '../../../../helpers/partitionToSubPartitions';
import { viewKeysAreEqual } from '../../../../../../data-model/helpers/viewKeysAreEqual';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { partitionToParent } from '../../../../helpers/partitionToParent';
import { dateTimeToPartition } from '../../../../helpers/dateTimeToPartition';
import { getYear } from '../../../../helpers/getYear';
import { getMonth } from '../../../../helpers/getMonth';
import { sortViewKeys } from '../../../../../../data-model/helpers/sortViewKeys';
import { formatPartition } from '../../../../../../humanisers/formatPartition';
import { partitionToComponentKey } from '../../../../helpers/partitionToComponentKey';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';

export type MonthsCellGroupProps = Omit<LegacyCellGroupProps, 'children'>;

const MonthsCellGroup = (props: MonthsCellGroupProps): JSX.Element => {
  const partition = useActivePartition(isCalendarYearPartition);

  const siteSettings = useSiteTime();

  const months = useMemo(() => {
    const allMonths = partitionToSubPartitions(partition);

    const now = partitionToParent(dateTimeToPartition(siteSettings)(), 'month');

    const currentYear = getYear(now);
    const currentMonth = getMonth(now);

    return allMonths.filter(
      p => getYear(p) !== currentYear || getMonth(p) <= currentMonth
    );
  }, [partition]);

  const db = useTransactionsDb();

  const { data } = useTransform(
    useQueryStream(db.remote.design.dashboardDates.views.calendar, {
      groupLevel: 3,
      reduce: true,
      startKey: partition,
      endKey: [...partition, []]
    }),
    rows =>
      months
        .leftJoin(rows, (r, l) => viewKeysAreEqual(l.key, r))
        .map(({ left: month, right: row }) => ({
          key: month,
          title: formatPartition(siteSettings)(month, { year: undefined }),
          subtitle: row?.value.value ? formatMoney(row.value.value) : undefined
        }))
        .sort((a, b) => sortViewKeys(a.key, b.key)),
    () =>
      months.map(month => ({
        key: month,
        title: formatPartition(siteSettings)(month, { year: undefined }),
        subtitle: undefined
      }))
  );

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  return (
    <LegacyCellGroup headingText="Months" {...props}>
      {data.map(({ key, title, subtitle }, index) => (
        <React.Fragment key={partitionToComponentKey(key)}>
          <BasicCell
            iconRight={subtitle ? ForwardIcon : undefined}
            title={title}
            subtitle={subtitle}
            titlesOrientation="horizontal"
            onPress={
              subtitle
                ? () => {
                    navigation.push('show', {
                      partition: PartitionSerialiser.stringify(key)
                    });
                  }
                : undefined
            }
          />
          {index < data.length - 1 && <ListItemSeparator />}
        </React.Fragment>
      ))}
    </LegacyCellGroup>
  );
};

export default MonthsCellGroup;
