import React from 'react';
import { Text, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../theme/SemanticColours';
import { IconButton } from '../../../components/buttons/IconButton';
import EditIcon from '../../../components/icons/EditIcon';

export type ClientCellProps = Omit<ViewProps, 'children'> & {
  clientName: string;
  onPress?: () => void;
  disabled?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 11,
    backgroundColor: SemanticColours.secondary.background[100],
    borderRadius: 5,
    flexDirection: 'row'
  },
  titles: {
    flexDirection: 'column',
    flexGrow: 1
  },
  title: {
    color: SemanticColours.secondary.foreground[70],
    fontSize: 12,
    marginVertical: 1
  },
  value: {
    fontSize: 17,
    color: SemanticColours.secondary.foreground[90],
    marginVertical: 4
  },
  icon: {
    color: SemanticColours.secondary.foreground[80]
  }
});

const ClientCell = React.memo<ClientCellProps>(
  ({ style, clientName, disabled, onPress, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.container, style]} {...rest}>
        <View style={styles.titles}>
          <Text style={styles.title}>client</Text>
          <Text style={styles.value}>{clientName}</Text>
        </View>
        <IconButton
          iconComponent={EditIcon}
          onPress={onPress}
          disabled={disabled}
          iconColour={styles.icon.color}
        />
      </View>
    );
  }
);

export default ClientCell;
