import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { Product } from '../../../data-model/schema/databases/site-reference/documents/product/Product';
import { isProductV3 } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import {
  isProductV2Compatible,
  isDeclaredVariant
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV2';
import { isProductV1CompatVariant } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV1CompatVariant';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';
import { withKeysRemoved } from '../../../lib/remove-keys/removeKeys';

const commandType = 'disableProductPreparation' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
    };
  }
}

export const disableProductPreparation = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'INVALID_TYPE' }
  | { type: 'NOT_FOUND' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const log = createLogger(nameof({ disableProductPreparation }), { args });

  const { repositories } = ctx.databases.reference.local;
  const { productId } = args;
  const { userId, createdAt } = ctx.meta;

  const productResult = await repositories.products.updateDocument<
    'INVALID_TYPE',
    Product
  >(productId, (p, update, abort) => {
    const asV3 = convertProductToV3(p);

    if (asV3 === 'N/A') {
      return abort('INVALID_TYPE');
    }

    return update({
      ...asV3,
      requiresPreparation: false
      // leave preparationZone and course in case re-enabled
    });
  });

  if (productResult.type === 'ABORTED') {
    return { type: 'INVALID_TYPE' };
  }

  if (productResult.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  if (isProductV3(productResult.data)) {
    await Promise.all(
      productResult.data.variants.map(async v => {
        if (v.$schema !== 'DeclaredVariant') {
          return;
        }

        const compatResult =
          await repositories.products.updateDocument<'NOT_COMPAT'>(
            v.compatProductId,
            (old, update, abort) => {
              if (!isProductV1CompatVariant(old)) {
                return abort('NOT_COMPAT');
              }

              return update({
                ...withKeysRemoved(old, 'preparationZone', 'course'),
                revisionUserID: userId,
                revisionTime: createdAt,
                requiresPreparation: false
              });
            }
          );

        if (compatResult.type !== 'SUCCESS') {
          if (compatResult.type === 'NOT_FOUND') {
            log.warn(`Variant's compatProductId not found`, {
              productId,
              variant: v
            });
          } else if (compatResult.type === 'ABORTED') {
            log.warn(`Variant's compatProductId was not of the required type`, {
              productId,
              variant: v
            });
          }
        }
      })
    );
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId
    },
    [
      productId,
      ...(isProductV2Compatible(productResult.data)
        ? productResult.data.variants.compactMap(v =>
            isDeclaredVariant(v) ? v.compatProductId : null
          )
        : [])
    ]
  );

  return { type: 'SUCCESS', data: command };
};
