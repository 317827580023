import { ComponentType } from 'react';
import { View, ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import { Text } from '../../../../../../components/text/Text';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { formatQuantity } from '../../../../../../humanisers/formatQuantity';
import { DashboardSummarisedData } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/DashboardSummarisedData';

export type HeadingProps = ViewProps & {
  iconComponent: ComponentType<SvgProps>;
  paymentType: string;
  received: DashboardSummarisedData | null;
  refund: DashboardSummarisedData | null;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 30,
    backgroundColor: SemanticColours.primary.background[90]
  },
  icon: {
    marginRight: 10,
    color: SemanticColours.primary.foreground[50]
  },
  leftBits: {
    flexDirection: 'row',
    flexGrow: 1
  },
  rightBits: {
    flexGrow: 0,
    marginRight: 8
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    paddingBottom: 6,
    paddingRight: 34
  },
  rightBits: {
    marginRight: 4
  }
});

const Heading = ({
  style,
  paymentType,
  iconComponent: Icon,
  received,
  refund,
  ...rest
}: HeadingProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const quantity = received?.units;
  const value =
    !received && !refund ? null : (received?.value ?? 0) - (refund?.value ?? 0);

  return (
    <SafeAreaView
      style={[styles.container, style]}
      edges={['left', 'right']}
      {...rest}
    >
      <Icon
        height={16}
        width={16}
        style={styles.icon}
        fill={styles.icon.color}
      />

      <View style={styles.leftBits}>
        <Text.SectionTitle>{paymentType} </Text.SectionTitle>
        {quantity && (
          <Text.SectionTitle>({formatQuantity(quantity)})</Text.SectionTitle>
        )}
      </View>

      <View style={styles.rightBits}>
        {value && <Text.SectionTitle>{formatMoney(value)}</Text.SectionTitle>}
      </View>
    </SafeAreaView>
  );
};

export default Heading;
