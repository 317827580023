export class RateLimiter<T = undefined> {
  constructor(
    private readonly minIntervalMs: number,
    private readonly onNext: (value: T[]) => unknown
  ) {}

  private values: T[] = [];

  private timer: NodeJS.Timeout | undefined;

  private nextFlushNotBefore = 0;

  public next(value: T): void {
    this.values.push(value);

    if (this.timer) {
      // a future flush is already scheduled
      return;
    }

    const now = new Date().getTime();

    if (now > this.nextFlushNotBefore) {
      // we can send immediately
      this.flush();
      return;
    }

    // schedule the future flush
    this.timer = setTimeout(() => this.flush(), this.nextFlushNotBefore - now);
  }

  public flush(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }

    if (this.values.any()) {
      this.nextFlushNotBefore = new Date().getTime() + this.minIntervalMs;

      this.onNext(this.values);

      this.values = [];
    }
  }
}
