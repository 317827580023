import './conf/logging';
import './conf/wdyr';
import './conf/addToJSONToErrorPrototype';
import './conf/eventEmitter';
import './conf/base64shim';
import './conf/format';

import './entry-points';

import { registerRootComponent } from 'expo';

import App from './App';

registerRootComponent(App);
