const DEFAULT_NAME = 'Default';

export const cardPaymentIdName = (
  servingZoneName: string | undefined
): string => {
  return !servingZoneName || servingZoneName === DEFAULT_NAME
    ? 'Card'
    : servingZoneName;
};

export const cashPaymentIdName = (
  servingZoneName: string | undefined,
  cashDrawerName: string | undefined
): string => {
  if (!servingZoneName || servingZoneName === DEFAULT_NAME) {
    if (!cashDrawerName || cashDrawerName === DEFAULT_NAME) {
      return 'Cash drawer';
    }

    return cashDrawerName;
  }

  return [servingZoneName, cashDrawerName].join(' ');
};
