import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import {
  ProductV3,
  VariantArrayV2
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { isProductV1CompatVariant } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV1CompatVariant';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

const commandType = 'disableStockTracking' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      variantId: string;
    };
  }
}

export const disableStockTracking = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'INVALID_TYPE' }
  | { type: 'NOT_FOUND' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const log = createLogger(nameof({ disableStockTracking }), { args });

  const { reference, stockTransactions } = ctx.databases;
  const { productId, variantId } = args;
  const { userId, createdAt, deviceId } = ctx.meta;

  const productResult =
    await reference.local.repositories.products.updateDocument<
      'INVALID_TYPE' | 'NOT_FOUND',
      ProductV3
    >(productId, (p, update, abort) => {
      const asV3 = convertProductToV3(p);

      if (asV3 === 'N/A') {
        return abort('INVALID_TYPE');
      }

      const newVariants = [...asV3.variants] as VariantArrayV2;

      const variant = newVariants.find(v => v.id === variantId);

      if (!variant) {
        return abort('NOT_FOUND');
      }

      variant.stock = { mode: 'UNTRACKED' };

      return update({
        ...asV3,
        ...(variant.$schema === 'DefaultImpliedVariant' ||
        variant.$schema === 'DefaultUnnamedVariant'
          ? { stock: { mode: 'UNTRACKED' } }
          : {}),
        revisionUserID: userId,
        revisionTime: createdAt,
        variants: newVariants
      });
    });

  if (productResult.type === 'ABORTED') {
    return { type: productResult.reason };
  }

  if (productResult.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  const updatedVariant = productResult.data.variants.find(
    v => v.id === variantId
  );

  if (!updatedVariant) {
    throw new Error(
      `Atomicity fail. Product ${productId} variant ${variantId}`
    );
  }

  const variantProductId =
    updatedVariant.$schema === 'DeclaredVariant'
      ? updatedVariant.compatProductId
      : productId;

  const currentStockResult =
    await stockTransactions.remote.design.runningBalance.views.main.getQuery({
      startKey: variantProductId,
      endKey: `${variantProductId}\ufff0`,
      reduce: true
    });

  const qtyToWriteOff = (() => {
    const currentStock = currentStockResult.first()?.value;

    if (!currentStock) return 0;

    return (
      (currentStock.POSITIVE?.wholes ?? 0) -
      (currentStock.NEGATIVE?.wholes ?? 0)
    );
  })();

  if (qtyToWriteOff !== 0) {
    await stockTransactions.local.repositories.stockTransactions.createDocument(
      {
        userID: userId,
        createdAt,
        deviceID: deviceId,
        lines: [
          {
            type: 'PRODUCT_DISABLE',
            delta: {
              sign: qtyToWriteOff < 0 ? 'POSITIVE' : 'NEGATIVE',
              wholes: Math.abs(qtyToWriteOff)
            },
            productID: variantProductId
          }
        ]
      }
    );
  }

  if (updatedVariant.$schema === 'DeclaredVariant') {
    const compatResult =
      await reference.local.repositories.products.updateDocument<'NOT_COMPAT'>(
        updatedVariant.compatProductId,
        (old, update, abort) => {
          if (!isProductV1CompatVariant(old)) {
            return abort('NOT_COMPAT');
          }

          return update({
            ...old,
            revisionUserID: userId,
            revisionTime: createdAt,
            stock: { mode: 'UNTRACKED' }
          });
        }
      );

    if (compatResult.type !== 'SUCCESS') {
      if (compatResult.type === 'NOT_FOUND') {
        log.warn(`Variant's compatProductId not found`);
      } else if (compatResult.type === 'ABORTED') {
        log.warn(`Variant's compatProductId was not of the required type`);
      }
    }
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      variantId
    },
    [
      productId,
      variantId,
      ...(updatedVariant.$schema === 'DeclaredVariant'
        ? [updatedVariant.compatProductId]
        : [])
    ]
  );

  return { type: 'SUCCESS', data: command };
};
