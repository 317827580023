import { Database } from '../../../../support/Database';
import { ChangesByObjectTimeView } from './ChangesByObjectTimeView';

export class ChangesByObjectTimeDesignDoc {
  private static documentId = '_design/changes-by-object-time';

  static views = {
    [ChangesByObjectTimeView.viewName]: ChangesByObjectTimeView
  } as const;

  constructor(private readonly database: Database) {}

  public readonly views = Object.fromEntries(
    Object.entries(ChangesByObjectTimeDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, ChangesByObjectTimeDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof ChangesByObjectTimeDesignDoc.views]: InstanceType<
      (typeof ChangesByObjectTimeDesignDoc)['views'][P]
    >;
  };
}
