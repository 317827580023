export const addAuthorisationHeader = (
  opts: RequestInit | undefined,
  headerValue: string
): RequestInit => {
  const headers = new Headers(opts?.headers ?? {});
  headers.set('Authorization', headerValue);

  return {
    ...opts,
    headers
  };
};
