import { useMemo } from 'react';
import { newRandomUuid } from '../uuid';
import { createLogger } from './createLogger';
import { Logger } from './Logger';

export const useLogger = (
  component: string,
  staticFields?: Record<string, unknown>
): Logger => {
  const componentId = useMemo(newRandomUuid, []);

  return useMemo(() => {
    return createLogger(component, {
      componentId,
      ...staticFields
    });
  }, [JSON.stringify(staticFields)]);
};
