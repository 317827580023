import { useReferenceDb } from './useReferenceDb';
import { useQueryStream } from '../../hooks/useQueryStream';
import { useTransform } from '../../hooks/useTransform';

export const useCashDrawerNameLookup = () => {
  const db = useReferenceDb();

  return useTransform(
    useQueryStream(db.local.design.views.allServingZones, {
      includeDocs: true
    }),
    rows =>
      Object.fromEntries(
        rows.map(r => [
          r.documentID,
          Object.fromEntries(
            r.document.cashDrawers?.map(c => [c.ID, c.name]) ?? []
          )
        ])
      ),
    () => ({})
  );
};
