import { AppRegistry } from 'react-native';
import SiteEntryPoint, { SiteEntryPointProps } from '../SiteEntryPoint';
import { nameof } from '../../lib/name-of';
import PassNavigator from '../../features/prep-and-pass/navigation/PassNavigator';

type PassEntryPointProps = Omit<SiteEntryPointProps, 'navigatorComponent'>;

const PassEntryPoint = (props: PassEntryPointProps): JSX.Element => {
  return <SiteEntryPoint {...props} navigatorComponent={PassNavigator} />;
};

AppRegistry.registerComponent(nameof({ PassEntryPoint }), () => PassEntryPoint);
