import { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  Animated,
  GestureResponderEvent,
  Insets,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle
} from 'react-native';
import { PlatformPressableProps } from '../../../../components/platform-pressable/PlatformPressableProps';
import { useTheme } from '../../../../lib/theme-context';
import { Colours } from '../../../../theme/Colours';

const styles = StyleSheet.create({
  pressable: {
    borderWidth: 1,
    borderRadius: 1
  }
});

export const Button = forwardRef<View, PlatformPressableProps>(
  (
    {
      onPress,
      onPressIn,
      onPressOut,
      pressColor,
      style,
      disabled,
      hitSlop,
      ...rest
    },
    ref
  ): JSX.Element => {
    const theme = useTheme();
    const [hover, setHover] = useState(false);
    const [pressed, setPressed] = useState(false);

    useEffect(() => {
      if (disabled) {
        setHover(false);
        setPressed(false);
      }
    }, [disabled]);

    const stateStyle = useMemo(() => {
      const opacity = (() => {
        if (hover || pressed) return 1;
        if (disabled) return 0.25;
        return 0.7;
      })();

      const baseColour = theme === 'dark' ? Colours.white : Colours.black;

      const borderColor = baseColour.o(pressed ? 0.2 : 0).$;

      const backgroundColor = (() => {
        if (pressed) return pressColor ?? baseColour.o(0.05).$;
        if (hover) return baseColour.o(0.1).$;
        return undefined;
      })();

      return {
        transitionDuration: '150ms',
        opacity,
        borderColor,
        backgroundColor,
        cursor: disabled ? 'auto' : 'pointer'
      } as ViewStyle;
    }, [theme, pressColor, hover, pressed, disabled]);

    const handlePressIn = (e: GestureResponderEvent) => {
      setPressed(true);
      onPressIn?.(e);
    };

    const handlePressOut = (e: GestureResponderEvent) => {
      setPressed(false);
      onPressOut?.(e);
    };

    const webProps = disabled
      ? {}
      : ({
          onMouseDown: handlePressIn,
          onMouseUp: handlePressOut,
          onMouseOut: () => setPressed(false),
          onClick: onPress,
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false)
        } as ViewProps);

    return (
      <Animated.View
        ref={ref}
        style={[styles.pressable, stateStyle, style]}
        hitSlop={(hitSlop ?? undefined) as Insets | undefined}
        {...webProps}
        {...rest}
      ></Animated.View>
    );
  }
);
