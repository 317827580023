import { SectionList, SectionListProps } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  ProductListDepartmentData,
  ProductListRowData,
  useProductListData
} from '../../data/useProductListData';
import { useScrollTo } from './useScrollTo';
import { useDebounce } from '../../../../lib/use-debounce';
import { useSearchResults } from './useSearchResults';
import { ListState } from './ListState';
import EmptyList from './EmptyList';
import { ListSearchCell } from '../../../../components/cells/ListSearchCell';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import ProductGroupHeaderCell from '../cells/ProductGroupHeaderCell';
import ProductGroupEmptyFooter from '../cells/ProductGroupEmptyFooter';
import ProductListItem from '../cells/ProductListItem';
import useSet from '../../../../lib/use-set';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

export type ProductsListViewMethods = {
  ensureDepartmentExpanded: (departmentId: string | null) => void;
  scrollToDepartment: (departmentId: string | null) => void;
  scrollToProduct: (departmentId: string | null, productId: string) => void;
};

export type ProductsListViewProps = Pick<
  SectionListProps<ProductListRowData, ProductListDepartmentData>,
  'style'
> & {
  onRequestAdd: (departmentId?: string | null) => void;
  onSelect: (
    product: ProductListRowData,
    department: ProductListDepartmentData | null
  ) => void;
  highlightedProductId: string | null;
};

const ProductsListView = forwardRef<
  ProductsListViewMethods,
  ProductsListViewProps
>(
  (
    { highlightedProductId, onSelect, onRequestAdd, style },
    ref
  ): JSX.Element => {
    const [expandedDepartmentIds, { has, toggle, add }] = useSet<
      string | null
    >();

    const {
      data: listData,
      loading,
      indexOfDepartment,
      indexOfProduct
    } = useProductListData();

    const listDataWithExpandedSections = useMemo(() => {
      return listData?.map(({ department, data }) => ({
        department,
        data: has(department.id) ? data : []
      }));
    }, [listData, expandedDepartmentIds, has]);

    const listRef =
      useRef<SectionList<ProductListRowData, ProductListDepartmentData>>(null);

    const { scrollToDepartment, scrollToProduct } = useScrollTo(
      listRef,
      indexOfDepartment,
      indexOfProduct
    );

    useImperativeHandle(
      ref,
      () => ({
        ensureDepartmentExpanded: add,
        scrollToDepartment,
        scrollToProduct
      }),
      [scrollToDepartment, scrollToProduct]
    );

    const [searchValue, setSearchValue] = useState('');
    const [searchFocussed, setSearchFocussed] = useState(false);

    const debouncedSearchValue = useDebounce(searchValue, 250);

    const onClearSearch = useCallback(() => {
      setSearchValue('');
    }, [setSearchValue]);

    const searchResults = useSearchResults(listData, debouncedSearchValue);

    const searchActive = useMemo(
      () => !!searchValue || searchFocussed,
      [searchValue, searchFocussed]
    );

    const listState = useMemo<ListState>(() => {
      if (loading) {
        return 'loading';
      }

      if (searchFocussed && !searchValue) {
        return 'startSearch';
      }

      if (searchValue !== debouncedSearchValue) {
        return 'searching';
      }

      if (searchValue) {
        return 'endSearch';
      }

      return 'normal';
    }, [loading, searchFocussed, searchValue, debouncedSearchValue]);

    const searchRef = useRef<TextInput | null>(null);

    return (
      <SectionList
        ref={listRef}
        getItemLayout={(_, index) => ({
          length: 48,
          offset: 48 * index,
          index
        })}
        extraData={{ highlightedProductId }}
        renderScrollComponent={scrollProps => (
          <PlatformScrollView
            {...scrollProps}
            stickyHeaderHiddenOnScroll
            stickyHeaderIndices={[0]}
            keyboardShouldPersistTaps="always"
          />
        )}
        ListEmptyComponent={() => (
          <EmptyList
            listState={listState}
            onClear={() => {
              onClearSearch();
              searchRef.current?.blur();
            }}
            onAdd={() => onRequestAdd()}
          />
        )}
        ListHeaderComponent={
          <ListSearchCell
            placeholder="Search products"
            onChangeText={setSearchValue}
            value={searchValue}
            onClear={onClearSearch}
            onFocussedChanged={setSearchFocussed}
            ref={searchRef}
          />
        }
        initialNumToRender={15}
        sections={
          (searchActive ? searchResults : listDataWithExpandedSections) ?? []
        }
        ItemSeparatorComponent={ListItemSeparator}
        keyExtractor={row => row.id}
        renderSectionHeader={({ section: { department } }) => (
          <ProductGroupHeaderCell
            department={department}
            disabled={searchActive}
            expanded={searchActive || has(department.id)}
            onPress={() => toggle(department.id)}
            onRequestAdd={() => onRequestAdd(department.id)}
          />
        )}
        renderSectionFooter={({
          section: {
            department: { id, count }
          }
        }) => (count > 0 || !has(id) ? <></> : <ProductGroupEmptyFooter />)}
        renderItem={itemProps => (
          <ProductListItem
            highlighted={highlightedProductId === itemProps.item.id}
            onPress={() => onSelect(itemProps.item, itemProps.section)}
            {...itemProps}
          />
        )}
        style={style}
      />
    );
  }
);

export default ProductsListView;
