import React from 'react';
import ActionIconButton from '../card/ActionIconButton';
import VoidIcon from '../../../../components/icons/VoidIcon';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { undoStartPreparationOrder } from '../../commands/undoStartPreparationOrder';
import { Alert } from '../../../../components/platform-alert/Alert.web';
import { PlatformPressableProps } from '../../../../components/platform-pressable/PlatformPressableProps';
import ActionButton from '../../../../components/buttons/ActionButton';

export type UndoStartButtonProps = Omit<PlatformPressableProps, 'children'> & {
  preparationOrderId: string;
  size?: 'small' | 'large';
};

const UndoStartButton = React.memo<UndoStartButtonProps>(
  ({ preparationOrderId, size = 'small', ...rest }) => {
    const { invoke, inProgress } = useCommand(
      undoStartPreparationOrder,
      (result, retry) => {
        if (!(result instanceof Error) && result.type === 'SUCCESS') {
          return;
        }

        const errorMessage =
          result instanceof Error
            ? 'An unexpected error occurred.'
            : 'The task is not in the expected state.';

        Alert.alert(
          'Error',
          `Could not undo starting the task.\n\n${errorMessage}`,
          [
            { text: 'Cancel', onPress: () => {} },
            { text: 'Retry', onPress: () => retry() }
          ]
        );
      }
    );

    return size === 'small' ? (
      <ActionIconButton
        {...rest}
        iconComponent={VoidIcon}
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    ) : (
      <ActionButton
        {...rest}
        iconComponent={VoidIcon}
        label="Undo"
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    );
  }
);

export default UndoStartButton;
