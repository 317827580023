import React, { ReactElement, cloneElement } from 'react';
import { PressableProps, ViewProps } from 'react-native';
import Reanimated, { Easing, Layout } from 'react-native-reanimated';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';

export type CardProps = ViewProps & {
  header: ReactElement<PressableProps & { expanded: boolean }>;
  expandable: boolean;
  expanded?: boolean;
  onToggleExpansion?: () => void;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    borderRadius: 7,
    overflow: 'hidden'
  }
});

const Card = React.memo<CardProps>(
  ({
    style,
    header,
    children,
    expandable,
    expanded,
    onToggleExpansion,
    ...rest
  }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <Reanimated.View
        style={[styles.root, style]}
        {...rest}
        layout={Layout.easing(Easing.ease)}
      >
        {cloneElement(header, {
          onPress: expandable ? onToggleExpansion : undefined,
          expanded: expanded || !expandable
        })}
        {(!expandable || expanded) && children}
      </Reanimated.View>
    );
  }
);

export default Card;
