import { Database } from '../../support/Database';
import { CountByStatusViewDesignDoc } from './design/count-by-status/CountByStatusViewDesignDoc';
import { NewReleasedOrdersViewDesignDoc } from './design/new-released-orders/NewReleasedOrdersViewDesignDoc';
import { SummaryByStatusViewDesignDoc } from './design/summary-by-status/SummaryByStatusViewDesignDoc';
import { PreparationOrdersRepository } from './repositories/PreparationOrderRepository';

export class PreparationOrdersDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly design = {
    countByStatus: new CountByStatusViewDesignDoc(this.database),
    summaryByStatus: new SummaryByStatusViewDesignDoc(this.database),
    newReleasedOrders: new NewReleasedOrdersViewDesignDoc(this.database)
  };

  public readonly repository = new PreparationOrdersRepository(this.database);

  public async ensureSchemaApplied(): Promise<void> {
    await this.design.countByStatus.ensureDefined();
    await this.design.summaryByStatus.ensureDefined();
    await this.design.newReleasedOrders.ensureDefined();
  }
}
