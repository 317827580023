import { Platform } from 'react-native';
import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
  deviceType
} from 'react-device-detect';
import AsyncStorage from '@react-native-async-storage/async-storage';
import packageJson from '../../../package.json';
import { DeviceInfo } from './DeviceInfo';
import { newRandomUuid } from '../../lib/uuid';

export class DeviceInfoService {
  public static async get(): Promise<DeviceInfo> {
    const mobile = [mobileVendor, mobileModel].compact().join(' ');

    const appVersion = __DEV__ ? '0.0.0-development' : packageJson.version;

    const data: DeviceInfo = {
      deviceOwnedId: await DeviceInfoService.getDeviceOwnedId(),
      appVersion,
      deviceType: `${osName} ${osVersion} ${mobile} ${deviceType} ${browserName} ${browserVersion}`,
      platformType: Platform.OS,
      systemVersion: fullBrowserVersion
    };

    return Promise.resolve(data);
  }

  private static deviceOwnedIdKey = 'DEVICE_MANAGED_ID';

  private static async getDeviceOwnedId(): Promise<string> {
    const value = await AsyncStorage.getItem(
      DeviceInfoService.deviceOwnedIdKey
    );

    if (value) {
      return value;
    }

    const newId = newRandomUuid();

    await AsyncStorage.setItem(DeviceInfoService.deviceOwnedIdKey, newId);

    return newId;
  }
}
