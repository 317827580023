import { Database } from '../../../../support/Database';
import { DesignDocumentDefinition } from '../../../../support/DesignDocument';
import { SummaryByStatusView } from './SummaryByStatusView';

export class SummaryByStatusViewDesignDoc {
  private static documentId = '_design/summary-by-status';

  static views = {
    [SummaryByStatusView.viewName]: SummaryByStatusView
  } as const;

  static definition = new DesignDocumentDefinition(
    SummaryByStatusViewDesignDoc.documentId,
    {
      views: Object.fromEntries(
        Object.entries(SummaryByStatusViewDesignDoc.views).map(
          ([name, View]) => [name, View.definition]
        )
      )
    }
  );

  constructor(private readonly database: Database) {}

  public async ensureDefined(): Promise<void> {
    await SummaryByStatusViewDesignDoc.definition.ensureDefined(
      this.database.pouch
    );
  }

  public readonly views = Object.fromEntries(
    Object.entries(SummaryByStatusViewDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, SummaryByStatusViewDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof SummaryByStatusViewDesignDoc.views]: InstanceType<
      (typeof SummaryByStatusViewDesignDoc)['views'][P]
    >;
  };
}
