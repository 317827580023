import { CalendarMonthPartition } from '../types/Partition';

export const isCalendarMonthPartition = (
  value: unknown
): value is CalendarMonthPartition => {
  if (!value || !(typeof value === 'object') || !Array.isArray(value)) {
    return false;
  }

  return value[0] === 'calendar' && value.length === 3;
};
