import React from 'react';
import { View, ViewStyle } from 'react-native';
import { ViewProps } from 'react-device-detect';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';

export type CardHeaderProps = ViewProps;

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'stretch',
    flexDirection: 'column'
  }
});

const CardHeader = React.memo<CardHeaderProps>(
  ({ style, children, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style as ViewStyle]} {...rest}>
        {children}
      </View>
    );
  }
);

export default CardHeader;
