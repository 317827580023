import { useFormikContext } from 'formik';
import {
  useState,
  forwardRef,
  ComponentType,
  useMemo,
  useCallback
} from 'react';
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewProps,
  ViewStyle
} from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { useForwardRef } from '../../lib/use-forward-ref';
import { SemanticColours } from '../../theme/SemanticColours';
import ErrorMessage from '../error-message/ErrorMessage';
import { normalise } from './normalise';
import { Normaliser } from './Normaliser';
import { webStyle } from './webStyle';

export type TextFieldProps = Omit<TextInputProps, 'value'> & {
  containerComponent?: ComponentType<Pick<ViewProps, 'style' | 'children'>>;
  errorMessage?: string;
  hideErrorMessage?: boolean;
  inputStyle?: StyleProp<ViewStyle>;
  name: string;
  normaliser?: Normaliser | Normaliser[];
  secondaryColourScheme?: boolean;
};

const baseStyles = StyleSheet.create({
  input: {
    borderRadius: 5,
    borderWidth: 2,
    fontSize: 17,
    paddingHorizontal: 24,
    paddingVertical: 22
  },
  inputWithTitle: {
    paddingTop: 28,
    paddingBottom: 16
  },
  title: {
    fontSize: 12,
    left: 24,
    position: 'absolute',
    top: 9
  },
  errorMessage: {
    marginHorizontal: 22,
    marginVertical: 4
  },
  hide: {
    display: 'none'
  }
});

const noContrastStyles = DynamicStyleSheet.create({
  input: {
    borderColor: SemanticColours.primary.foreground[20],
    color: SemanticColours.primary.foreground[100]
  },
  title: {
    color: SemanticColours.primary.foreground[60]
  },
  focussed: {
    backgroundColor: SemanticColours.primary.foreground[10]
  },
  errored: {
    borderColor: SemanticColours.primary.error[100]
  },
  placeholder: {
    color: SemanticColours.primary.foreground[55]
  }
});

const secondaryStyles = DynamicStyleSheet.create({
  input: {
    borderColor: SemanticColours.secondary.foreground[20],
    color: SemanticColours.secondary.foreground[100]
  },
  title: {
    color: SemanticColours.secondary.foreground[60]
  },
  focussed: {
    backgroundColor: SemanticColours.secondary.foreground[10]
  },
  errored: {
    borderColor: SemanticColours.primary.error[100]
  },
  placeholder: {
    color: SemanticColours.secondary.foreground[55]
  }
});

export const TextField = forwardRef<TextInput, TextFieldProps>(
  (
    {
      containerComponent = View,
      secondaryColourScheme = false,
      errorMessage,
      hideErrorMessage,
      inputStyle,
      name,
      normaliser = v => v,
      onBlur,
      onChangeText,
      onFocus,
      style,
      placeholder,
      ...rest
    },
    parentRef
  ): JSX.Element => {
    const styles = useDynamicStyleSheet(
      secondaryColourScheme ? secondaryStyles : noContrastStyles
    );

    const [focussed, setFocussed] = useState(false);

    const { setFieldValue, values, errors, setFieldTouched } =
      useFormikContext<Record<string, string>>();

    const internalRef = useForwardRef(parentRef);

    const ContainerComponent = useMemo(() => containerComponent, []);

    const showTitle = !!values[name];

    const showError = (!!errors[name] || !!errorMessage) && !hideErrorMessage;

    const internalOnFocus = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        setFocussed(true);
        onFocus?.(e);
      },
      [onFocus]
    );

    const internalOnBlur = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        setFocussed(false);
        onBlur?.(e);
        setFieldTouched(name);
      },
      [onBlur, setFieldTouched]
    );

    const internalOnChangeText = useCallback(
      (e: string) => {
        onChangeText?.(e);
        setFieldValue(name, normalise(e, normaliser));
      },
      [onChangeText, setFieldValue]
    );

    return (
      <ContainerComponent style={style}>
        <View>
          <TextInput
            testID={`${name}FieldInput`}
            accessibilityLabel={`${placeholder ?? name}`}
            {...rest}
            style={[
              baseStyles.input,
              styles.input,
              inputStyle,
              focussed && styles.focussed,
              showError && styles.errored,
              showTitle && baseStyles.inputWithTitle,
              webStyle
            ]}
            onFocus={internalOnFocus}
            onBlur={internalOnBlur}
            onChangeText={internalOnChangeText}
            placeholder={placeholder}
            placeholderTextColor={styles.placeholder.color}
            ref={internalRef}
            value={values[name]}
          />
          <Text
            style={[
              baseStyles.title,
              styles.title,
              !showTitle && baseStyles.hide
            ]}
          >
            {placeholder}
          </Text>

          <ErrorMessage
            testID={`${name}FieldError`}
            style={[baseStyles.errorMessage, !showError && baseStyles.hide]}
          >
            {errorMessage ?? errors[name]}
          </ErrorMessage>
        </View>
      </ContainerComponent>
    );
  }
);
