import { LegacyCellGroupProps } from '../../../../../../components/cells/LegacyCellGroup';
import { isCalendarMonthPartition } from '../../../../helpers/isCalendarMonthPartition';
import { isCalendarYearPartition } from '../../../../helpers/isCalendarYearPartition';
import { isIsoWeekPartition } from '../../../../helpers/isIsoWeekPartition';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import MonthDaysCellGroup from './MonthWeeksCellGroup';
import MonthsCellGroup from './MonthsCellGroup';
import WeekdaysCellGroup from './WeekdaysCellGroup';

export type SubIntervalsCellGroupProps = Omit<LegacyCellGroupProps, 'children'>;

const SubPartitionsCellGroup = (
  props: SubIntervalsCellGroupProps
): JSX.Element => {
  const partition = useActivePartition();

  if (isIsoWeekPartition(partition)) {
    return <WeekdaysCellGroup {...props} />;
  }

  if (isCalendarYearPartition(partition)) {
    return <MonthsCellGroup {...props} />;
  }

  if (isCalendarMonthPartition(partition)) {
    return <MonthDaysCellGroup {...props} />;
  }

  return <></>;
};

export default SubPartitionsCellGroup;
