import { PropsWithChildren, useMemo } from 'react';
import { useTransactionsDb } from '../../../../data-model/components/site-context/useTransactionsDb';
import {
  PaginatedQueryHookValue,
  usePaginatedQuery
} from '../../../../data-model/hooks/usePaginatedQuery';
import {
  PartitionNavigationContext,
  PartitionNavigationContextValue
} from './PartitionNavigationContext';
import {
  QueryRow,
  RangeKey
} from '../../../../data-model/schema/support/DatabaseView';
import { ServiceTransaction } from '../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import { CalendarDayPartition } from '../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import {
  CalendarMonthPartition,
  CalendarYearPartition,
  IsoWeekPartition
} from '../../types/Partition';
import {
  DashboardDatesForUserKey,
  DashboardDatesForUserValue
} from '../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-dates-for-user/DashboardDatesForUserDesignDoc';
import { TupleSplitTail } from '../../../../lib/ts-tuple/Slice';

type CommonQueryOptions = {
  readonly descending: true;
  readonly limit: 30;
  readonly reduce: true;
};

function mapper<TPartition>(
  rows: readonly QueryRow<
    DashboardDatesForUserKey,
    DashboardDatesForUserValue,
    unknown,
    ServiceTransaction
  >[]
): Readonly<{ partition: TPartition; value: number }>[] {
  return rows.map(r => ({
    partition: r.key.slice(1) as TPartition,
    value: r.value.value
  }));
}

type UserPartitionNavigationProviderProps = PropsWithChildren<
  Readonly<{ userId: string }>
>;

function shim<TValue, const TKey extends readonly unknown[]>(
  userId: string,
  hook: PaginatedQueryHookValue<TValue, TKey>
): PaginatedQueryHookValue<TValue, TupleSplitTail<TKey, 1>> {
  const convertRangeKeyBack = (
    key: RangeKey<TKey>
  ): RangeKey<TupleSplitTail<TKey, 1>> =>
    key.slice(1) as unknown as RangeKey<TupleSplitTail<TKey, 1>>;

  const convertRangeKey = (
    key: RangeKey<TupleSplitTail<TKey, 1>>
  ): RangeKey<TKey> => [userId, ...key] as unknown as RangeKey<TKey>;

  return {
    rangeContains(key) {
      return hook.rangeContains(convertRangeKey(key));
    },
    loadMore(to) {
      return hook.loadMore(
        !to || typeof to === 'number' ? to : convertRangeKey(to)
      );
    },
    reload(to) {
      return hook.reload(
        !to || typeof to === 'number' ? to : convertRangeKey(to)
      );
    },
    state: {
      ...hook.state,
      ...('range' in hook.state
        ? {
            range: !hook.state.range
              ? undefined
              : ([
                  convertRangeKeyBack(hook.state.range[0]),
                  convertRangeKeyBack(hook.state.range[1])
                ] as const)
          }
        : {})
    } as PaginatedQueryHookValue<TValue, TupleSplitTail<TKey, 1>>['state']
  };
}

export const UserPartitionNavigationProvider = ({
  children,
  userId
}: UserPartitionNavigationProviderProps): JSX.Element => {
  const db = useTransactionsDb();

  const { view } = db.remote.design.dashboardDatesForUser;

  const commonQueryOptions: CommonQueryOptions = {
    descending: true,
    limit: 30,
    reduce: true
  };

  const calendarQueryOptions = {
    startKey: [userId, 'calendar', []],
    endKey: [userId, 'calendar']
  } as const;

  const dayQuery = usePaginatedQuery(
    view,
    {
      groupLevel: 5,
      ...calendarQueryOptions,
      ...commonQueryOptions
    },
    mapper<CalendarDayPartition>
  );

  const monthQuery = usePaginatedQuery(
    view,
    {
      groupLevel: 4,
      ...calendarQueryOptions,
      ...commonQueryOptions
    },
    mapper<CalendarMonthPartition>
  );

  const yearQuery = usePaginatedQuery(
    view,
    {
      groupLevel: 3,
      ...calendarQueryOptions,
      ...commonQueryOptions
    },
    mapper<CalendarYearPartition>
  );

  const weekQuery = usePaginatedQuery(
    view,
    {
      startKey: [userId, 'iso', []],
      endKey: [userId, 'iso'],
      groupLevel: 4,
      ...commonQueryOptions
    },
    mapper<IsoWeekPartition>
  );

  const day = useMemo(() => shim(userId, dayQuery), [userId, dayQuery]);
  const week = useMemo(() => shim(userId, weekQuery), [userId, weekQuery]);
  const month = useMemo(() => shim(userId, monthQuery), [userId, monthQuery]);
  const year = useMemo(() => shim(userId, yearQuery), [userId, yearQuery]);

  const value: PartitionNavigationContextValue = useMemo(
    () => ({
      day,
      month,
      year,
      week
    }),
    [day, month, year, week, userId]
  );

  return (
    <PartitionNavigationContext.Provider value={value}>
      {children}
    </PartitionNavigationContext.Provider>
  );
};
