import { View, ViewProps } from 'react-native';
import { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import ModalConfirmHeader from '../../../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TabBar } from '../../../../../../components/tabs/TabBar';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { useContrastTabTheme } from '../../../../../../components/tabs/useContrastTabTheme';
import { Tab } from '../../../../../../components/tabs/Tab';
import CalendarIcon from '../../../../../../components/icons/CalendarIcon';
import { ErrorBoundary } from '../../../../../../components/error-boundary/ErrorBoundary';
import { LazyMount } from '../../../../../../components/tabs/LazyMount';
import { PartitionCalendar } from '../../../../components/partition-calendar/PartitionCalendar';
import { dateToCalendarDayPartition } from '../../../../helpers/dateToCalendarDayPartition';
import { partitionForComparison } from './partitionForComparison';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { partitionToDate } from '../../../../helpers/partitionToDate';
import thenThrow from '../../../../../../lib/then-throw';
import ComparisonList from './ComparisonList';
import PlatformModal from '../../../../../../components/platform-modal/PlatformModal';
import { TimeInterval } from '../../../../types/TimeInterval';

export type ComparisonModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
  partition: CalendarDayPartition;
  defaultSelection: TimeInterval | CalendarDayPartition;
  onSelect(selection: TimeInterval | CalendarDayPartition): void;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  tabBar: {
    backgroundColor: SemanticColours.secondary.background[100],
    flexGrow: 0
  },
  tab: {
    flexBasis: 100,
    flexGrow: 1
  }
});

const ComparisonModal = ({
  style,
  dismiss,
  visible,
  partition,
  defaultSelection,
  onSelect,
  ...rest
}: ComparisonModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [selectedTab, setSelectedTab] = useState<'suggested' | 'custom'>(
    'suggested'
  );

  const [selectedComparison, setSelectedComparison] =
    useState(defaultSelection);

  const selectedPartition = useMemo(() => {
    return partitionForComparison(partition, selectedComparison);
  }, [partition, selectedComparison]);

  const tabStyles = useContrastTabTheme();

  const onCalendarSelect = useCallback(
    (date: string) => {
      const p = dateToCalendarDayPartition(
        DateTime.fromISO(date, { zone: 'utc' })
      );

      setSelectedComparison(p);
      onSelect(p);
      dismiss();
    },
    [onSelect, dismiss]
  );

  const onListSelect = useCallback(
    (c: TimeInterval | CalendarDayPartition) => {
      setSelectedComparison(c);
      onSelect(c);
      dismiss();
    },
    [onSelect, dismiss]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} {...rest}>
      <View style={[styles.container, style]}>
        <ModalConfirmHeader title="Compare with" onCross={() => dismiss()} />
        <TabBar style={styles.tabBar}>
          <Tab
            style={styles.tab}
            styles={tabStyles}
            title="Suggested"
            isActive={selectedTab === 'suggested'}
            onPress={() => setSelectedTab('suggested')}
          />
          <Tab
            style={styles.tab}
            styles={tabStyles}
            title="Custom"
            icon={CalendarIcon}
            isActive={selectedTab === 'custom'}
            onPress={() => setSelectedTab('custom')}
          />
        </TabBar>

        <ErrorBoundary>
          <LazyMount
            component={ComparisonList}
            componentProps={{
              partition,
              selection: selectedComparison,
              onSelect: onListSelect
            }}
            visible={selectedTab === 'suggested'}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <LazyMount
            component={PartitionCalendar}
            componentProps={{
              selectedInterval: 'day',
              selectedDate:
                partitionToDate(selectedPartition).toISODate() ??
                thenThrow('Invalid partition'),
              onSelect: onCalendarSelect
            }}
            visible={selectedTab === 'custom'}
          />
        </ErrorBoundary>
      </View>
    </PlatformModal>
  );
};

export default ComparisonModal;
