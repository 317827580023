import { ViewProps } from 'react-native';
import { useMemo } from 'react';
import { useVariantDataContext } from '../../data/VariantDataContext';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { useSiteContext } from '../../../../data-model/components/site-context/useSiteId';
import { useTransform } from '../../../../data-model/hooks/useTransform';
import { useQueryStream } from '../../../../data-model/hooks/useQueryStream';
import BasicCell from '../../../../components/cells/BasicCell';
import EditIcon from '../../../../components/icons/EditIcon';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import EditStockBalanceModal, {
  EditStockBalanceModalProps
} from '../modals/EditStockBalanceModal';

export type StockBalanceCellProps = Omit<ViewProps, 'children'>;

const StockBalanceCell = (props: StockBalanceCellProps): JSX.Element => {
  const { variant, productId } = useVariantDataContext();

  const { authorised } = useAuthorisation(RoleId.superUser);

  const variantProductId = useMemo(() => {
    if (variant && 'compatProductId' in variant) {
      return variant.compatProductId;
    }

    return productId;
  }, [variant, productId]);

  const { stockTransactions } = useSiteContext().databases;

  const balance = useTransform(
    useQueryStream(stockTransactions.remote.design.runningBalance.views.main, {
      startKey: variantProductId,
      endKey: `${variantProductId}\ufff0`,
      groupLevel: 1,
      reduce: true
    }),
    rows => {
      const currentStock = rows.first()?.value;

      if (!currentStock) return 0;

      return (
        (currentStock.POSITIVE?.wholes ?? 0) -
        (currentStock.NEGATIVE?.wholes ?? 0)
      );
    },
    () => null
  );

  const units = balance.data === 1 ? 'item' : 'items';

  const { present, ...modal } =
    useModalControl<
      Pick<EditStockBalanceModalProps, 'productId' | 'currentBalance'>
    >();

  return (
    <>
      <BasicCell
        title="On hand"
        subtitle={balance.data !== null ? `${balance.data} ${units}` : ''}
        titlesOrientation="horizontal"
        disabled={balance.loading}
        {...(authorised
          ? {
              iconRight: EditIcon,
              onPress: () =>
                present({
                  productId: variantProductId,
                  currentBalance: balance.data ?? 0
                })
            }
          : {})}
        {...props}
      />

      <EditStockBalanceModal {...modal} />
    </>
  );
};

export default StockBalanceCell;
