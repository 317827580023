import React, { Children } from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';

export type ActionButtonBarProps = ViewProps;

const rStyles = DynamicStyleSheet.create({
  root: {
    borderRadius: 7,
    flexDirection: 'row',
    overflow: 'hidden'
  },
  separator: {
    width: 2
  }
});

const ActionButtonBar = React.memo<ActionButtonBarProps>(
  ({ style, children, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);
    const arrayChildren = Children.toArray(children);

    return (
      <View style={[styles.root, style]} {...rest}>
        {Children.map(arrayChildren, (child, index) => (
          <>
            {child}
            {index < arrayChildren.length - 1 && (
              <View style={styles.separator} />
            )}
          </>
        ))}
      </View>
    );
  }
);

export default ActionButtonBar;
