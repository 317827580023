import React from 'react';
import ActionIconButton from '../card/ActionIconButton';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { collectPreparationOrder } from '../../commands/collectPreparationOrder';
import { Alert } from '../../../../components/platform-alert/Alert';
import { PlatformPressableProps } from '../../../../components/platform-pressable/PlatformPressableProps';
import ActionButton from '../../../../components/buttons/ActionButton';
import TickIcon from '../../../../components/icons/TickIcon';

export type CollectButtonProps = Omit<PlatformPressableProps, 'children'> & {
  preparationOrderId: string;
  size?: 'small' | 'large';
};

const CollectButton = React.memo<CollectButtonProps>(
  ({ preparationOrderId, size = 'small', ...rest }) => {
    const { invoke, inProgress } = useCommand(
      collectPreparationOrder,
      (result, retry) => {
        if (!(result instanceof Error) && result.type === 'SUCCESS') {
          return;
        }

        const errorMessage =
          result instanceof Error
            ? 'An unexpected error occurred.'
            : 'The task is not in the expected state.';

        Alert.alert(
          'Error',
          `Could not mark the task as collected.\n\n${errorMessage}`,
          [
            { text: 'Cancel', onPress: () => {} },
            { text: 'Retry', onPress: () => retry() }
          ]
        );
      }
    );

    return size === 'small' ? (
      <ActionIconButton
        {...rest}
        iconComponent={TickIcon}
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    ) : (
      <ActionButton
        {...rest}
        iconComponent={TickIcon}
        label="Collect"
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    );
  }
);

export default CollectButton;
