import { HslColour } from '../lib/colours/HslColour';
import { RgbColour } from '../lib/colours/RgbColour';
import { lightDarkColour } from '../lib/dynamic-style-sheet/lightDarkColour';

export class SemanticColours {
  private static bases = {
    primary: {
      background: {
        dark: new HslColour(300, 2, 50),
        light: new HslColour(300, 1, 50)
      },
      foreground: {
        dark: new HslColour(0, 0, 90),
        light: new HslColour(0, 0, 0)
      },
      contrast: {
        light: new RgbColour('b500b5'),
        dark: new RgbColour('ca4cca')
      },
      error: {
        dark: new HslColour(10, 100, 54),
        light: new HslColour(0, 100, 50)
      }
    },

    secondary: {
      background: {
        dark: new HslColour(300, 100, 30),
        light: new HslColour(300, 100, 30)
      },
      foreground: {
        dark: new HslColour(0, 0, 80),
        light: new HslColour(0, 0, 100)
      },
      error: {
        light: new HslColour(0, 100, 74),
        dark: new HslColour(10, 100, 61)
      }
    },

    warning: {
      background: {
        dark: new HslColour(47, 100, 10),
        light: new HslColour(47, 100, 90)
      },
      foreground: {
        dark: new HslColour(47, 100, 35),
        light: new HslColour(47, 100, 30)
      }
    },

    status: {
      ready: new HslColour(90, 60, 50),
      inProgress: new HslColour(33, 100, 50),
      pending: new HslColour(317, 60, 50),
      collected: new HslColour(0, 0, 50),
      released: new HslColour(223, 81, 50)
    }
  };

  static readonly primary = {
    background: {
      100: lightDarkColour(
        this.bases.primary.background.light.l(100).$,
        this.bases.primary.background.dark.l(12).$
      ),
      90: lightDarkColour(
        this.bases.primary.background.light.l(96).$,
        this.bases.primary.background.dark.l(10).$
      ),
      80: lightDarkColour(
        this.bases.primary.background.light.l(92).$,
        this.bases.primary.background.dark.l(8).$
      ),
      70: lightDarkColour(
        this.bases.primary.background.light.l(88).$,
        this.bases.primary.background.dark.l(6).$
      ),
      60: lightDarkColour(
        this.bases.primary.background.light.l(84).$,
        this.bases.primary.background.dark.l(4).$
      ),
      50: lightDarkColour(
        this.bases.primary.background.light.l(80).$,
        this.bases.primary.background.dark.l(2).$
      )
    },
    foreground: {
      100: lightDarkColour(
        this.bases.primary.foreground.light.o(1).$,
        this.bases.primary.foreground.dark.o(1).$
      ),
      95: lightDarkColour(
        this.bases.primary.foreground.light.o(0.95).$,
        this.bases.primary.foreground.dark.o(0.95).$
      ),
      90: lightDarkColour(
        this.bases.primary.foreground.light.o(0.9).$,
        this.bases.primary.foreground.dark.o(0.9).$
      ),
      85: lightDarkColour(
        this.bases.primary.foreground.light.o(0.85).$,
        this.bases.primary.foreground.dark.o(0.85).$
      ),
      80: lightDarkColour(
        this.bases.primary.foreground.light.o(0.8).$,
        this.bases.primary.foreground.dark.o(0.8).$
      ),
      75: lightDarkColour(
        this.bases.primary.foreground.light.o(0.75).$,
        this.bases.primary.foreground.dark.o(0.75).$
      ),
      70: lightDarkColour(
        this.bases.primary.foreground.light.o(0.7).$,
        this.bases.primary.foreground.dark.o(0.7).$
      ),
      65: lightDarkColour(
        this.bases.primary.foreground.light.o(0.65).$,
        this.bases.primary.foreground.dark.o(0.65).$
      ),
      60: lightDarkColour(
        this.bases.primary.foreground.light.o(0.6).$,
        this.bases.primary.foreground.dark.o(0.6).$
      ),
      55: lightDarkColour(
        this.bases.primary.foreground.light.o(0.55).$,
        this.bases.primary.foreground.dark.o(0.55).$
      ),
      50: lightDarkColour(
        this.bases.primary.foreground.light.o(0.5).$,
        this.bases.primary.foreground.dark.o(0.5).$
      ),
      45: lightDarkColour(
        this.bases.primary.foreground.light.o(0.45).$,
        this.bases.primary.foreground.dark.o(0.45).$
      ),
      40: lightDarkColour(
        this.bases.primary.foreground.light.o(0.4).$,
        this.bases.primary.foreground.dark.o(0.4).$
      ),
      35: lightDarkColour(
        this.bases.primary.foreground.light.o(0.35).$,
        this.bases.primary.foreground.dark.o(0.35).$
      ),
      30: lightDarkColour(
        this.bases.primary.foreground.light.o(0.3).$,
        this.bases.primary.foreground.dark.o(0.3).$
      ),
      25: lightDarkColour(
        this.bases.primary.foreground.light.o(0.25).$,
        this.bases.primary.foreground.dark.o(0.25).$
      ),
      20: lightDarkColour(
        this.bases.primary.foreground.light.o(0.2).$,
        this.bases.primary.foreground.dark.o(0.2).$
      ),
      15: lightDarkColour(
        this.bases.primary.foreground.light.o(0.15).$,
        this.bases.primary.foreground.dark.o(0.15).$
      ),
      10: lightDarkColour(
        this.bases.primary.foreground.light.o(0.1).$,
        this.bases.primary.foreground.dark.o(0.1).$
      ),
      5: lightDarkColour(
        this.bases.primary.foreground.light.o(0.05).$,
        this.bases.primary.foreground.dark.o(0.05).$
      )
    },
    error: {
      100: lightDarkColour(
        this.bases.primary.error.light.o(0.9).$,
        this.bases.primary.error.dark.o(0.8).$
      ),
      90: lightDarkColour(
        this.bases.primary.error.light.o(0.7).$,
        this.bases.primary.error.dark.o(0.6).$
      ),
      80: lightDarkColour(
        this.bases.primary.error.light.o(0.5).$,
        this.bases.primary.error.dark.o(0.4).$
      )
    },
    contrast: {
      100: lightDarkColour(
        this.bases.primary.contrast.light.o(0.9).$,
        this.bases.primary.contrast.dark.o(0.8).$
      ),
      90: lightDarkColour(
        this.bases.primary.contrast.light.o(0.7).$,
        this.bases.primary.contrast.dark.o(0.6).$
      ),
      80: lightDarkColour(
        this.bases.primary.contrast.light.o(0.5).$,
        this.bases.primary.contrast.dark.o(0.4).$
      )
    }
  };

  static readonly secondary = {
    background: {
      100: lightDarkColour(
        this.bases.secondary.background.light.l(30).$,
        this.bases.secondary.background.dark.l(10).$
      ),
      90: lightDarkColour(
        this.bases.secondary.background.light.l(27).$,
        this.bases.secondary.background.dark.l(8).$
      ),
      80: lightDarkColour(
        this.bases.secondary.background.light.l(24).$,
        this.bases.secondary.background.dark.l(7).$
      ),
      70: lightDarkColour(
        this.bases.secondary.background.light.l(21).$,
        this.bases.secondary.background.dark.l(5).$
      ),
      60: lightDarkColour(
        this.bases.secondary.background.light.l(18).$,
        this.bases.secondary.background.dark.l(4).$
      ),
      50: lightDarkColour(
        this.bases.secondary.background.light.l(15).$,
        this.bases.secondary.background.dark.l(2).$
      )
    },

    foreground: {
      100: lightDarkColour(
        this.bases.secondary.foreground.light.o(1).$,
        this.bases.secondary.foreground.dark.o(1).$
      ),
      95: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.95).$,
        this.bases.secondary.foreground.dark.o(0.95).$
      ),
      90: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.9).$,
        this.bases.secondary.foreground.dark.o(0.9).$
      ),
      85: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.85).$,
        this.bases.secondary.foreground.dark.o(0.85).$
      ),
      80: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.8).$,
        this.bases.secondary.foreground.dark.o(0.8).$
      ),
      75: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.75).$,
        this.bases.secondary.foreground.dark.o(0.75).$
      ),
      70: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.7).$,
        this.bases.secondary.foreground.dark.o(0.7).$
      ),
      65: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.65).$,
        this.bases.secondary.foreground.dark.o(0.65).$
      ),
      60: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.6).$,
        this.bases.secondary.foreground.dark.o(0.6).$
      ),
      55: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.55).$,
        this.bases.secondary.foreground.dark.o(0.55).$
      ),
      50: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.5).$,
        this.bases.secondary.foreground.dark.o(0.5).$
      ),
      45: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.45).$,
        this.bases.secondary.foreground.dark.o(0.45).$
      ),
      40: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.4).$,
        this.bases.secondary.foreground.dark.o(0.4).$
      ),
      35: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.35).$,
        this.bases.secondary.foreground.dark.o(0.35).$
      ),
      30: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.3).$,
        this.bases.secondary.foreground.dark.o(0.3).$
      ),
      25: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.25).$,
        this.bases.secondary.foreground.dark.o(0.25).$
      ),
      20: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.2).$,
        this.bases.secondary.foreground.dark.o(0.2).$
      ),
      15: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.15).$,
        this.bases.secondary.foreground.dark.o(0.15).$
      ),
      10: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.1).$,
        this.bases.secondary.foreground.dark.o(0.1).$
      ),
      5: lightDarkColour(
        this.bases.secondary.foreground.light.o(0.05).$,
        this.bases.secondary.foreground.dark.o(0.05).$
      )
    },

    error: {
      100: lightDarkColour(
        this.bases.secondary.error.light.$,
        this.bases.secondary.error.dark.$
      ),
      90: lightDarkColour(
        this.bases.secondary.error.light.o(0.9).$,
        this.bases.secondary.error.dark.o(0.9).$
      ),
      80: lightDarkColour(
        this.bases.secondary.error.light.o(0.8).$,
        this.bases.secondary.error.dark.o(0.8).$
      )
    }
  };

  static readonly status = {
    ready: {
      background: lightDarkColour(
        this.bases.status.ready.l(97).$,
        this.bases.status.ready.l(14).$
      ),
      foreground: lightDarkColour(
        this.bases.status.ready.l(50).$,
        this.bases.status.ready.l(20).$
      )
    },
    inProgress: {
      background: lightDarkColour(
        this.bases.status.inProgress.l(97).$,
        this.bases.status.inProgress.l(14).$
      ),
      foreground: lightDarkColour(
        this.bases.status.inProgress.l(50).$,
        this.bases.status.inProgress.l(20).$
      )
    },
    pending: {
      background: lightDarkColour(
        this.bases.status.pending.l(97).$,
        this.bases.status.pending.l(9).$
      ),
      foreground: lightDarkColour(
        this.bases.status.pending.l(50).$,
        this.bases.status.pending.l(15).$
      )
    },
    collected: {
      background: lightDarkColour(
        this.bases.status.collected.l(97).$,
        this.bases.status.collected.l(5).$
      ),
      foreground: lightDarkColour(
        this.bases.status.collected.l(50).$,
        this.bases.status.collected.l(11).$
      )
    },
    released: {
      background: lightDarkColour(
        this.bases.status.released.l(97).$,
        this.bases.status.released.l(14).$
      ),
      foreground: lightDarkColour(
        this.bases.status.released.l(50).$,
        this.bases.status.released.l(20).$
      )
    }
  };

  static readonly warnings = {
    background: {
      50: lightDarkColour(
        this.bases.warning.background.light.$,
        this.bases.warning.background.dark.$
      )
    },
    foreground: {
      50: lightDarkColour(
        this.bases.warning.foreground.light.l(30).$,
        this.bases.warning.foreground.dark.l(35).$
      ),
      60: lightDarkColour(
        this.bases.warning.foreground.light.l(40).$,
        this.bases.warning.foreground.dark.l(25).$
      )
    }
  };
}
