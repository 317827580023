import { GlobalActivity } from '../../../lib/global-activity/GlobalActivity';
import { isRemoteDb } from '../../helpers/isRemoteDb';
import {
  DatabaseView,
  GroupLevelOf,
  GroupLevelsFor,
  QueryOptions,
  QueryRow,
  ViewKey
} from '../../schema/support/DatabaseView';
import { DocumentProperties } from '../../types/Document';
import { Cancelable } from './Cancelable';

export class Operation<
  TKey extends ViewKey,
  TValue,
  TDoc extends DocumentProperties,
  const TOptions extends QueryOptions<
    TKey,
    GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
  >
> implements Cancelable
{
  constructor(
    private readonly view: DatabaseView<TKey, TValue, TDoc>,
    private readonly options: TOptions
  ) {}

  private readonly abort = new AbortController();

  public run(
    onSuccess: (rows: QueryRow<TKey, TValue, TOptions, TDoc>[]) => void,
    onError: (error: unknown) => void
  ): typeof this {
    const activityEnrolment = isRemoteDb(this.view.database.pouch)
      ? GlobalActivity.enroll(
          `Running query ${this.view.database.pouch.name}/${this.view.designDocumentId}/${this.view.viewName}`
        )
      : undefined;

    this.view
      .getQuery(this.options)
      .then(rows => {
        if (this.abort.signal.aborted) {
          return;
        }

        onSuccess(rows);
      })
      .catch((error: unknown) => {
        if (this.abort.signal.aborted) {
          return;
        }

        onError(error);
      })
      .finally(() => {
        activityEnrolment?.dispose();
      });

    return this;
  }

  public cancel(): void {
    this.abort.abort();
  }
}
