import { ActivityIndicator } from 'react-native';
import EmptyContent from '../../../../components/empty-list-content/EmptyContent';
import { SearchIcon } from '../../../../components/icons/SearchIcon';
import { CloseIcon } from '../../../../components/icons/CloseIcon';
import ProductsIcon from '../../../../components/icons/ProductsIcon';
import AddIcon from '../../../../components/icons/AddIcon';
import { ListState } from './ListState';

export type EmptyListProps = {
  listState: ListState;
  onAdd: () => void;
  onClear: () => void;
};

const EmptyList = ({
  listState,
  onAdd,
  onClear
}: EmptyListProps): JSX.Element => {
  if (listState === 'loading' || listState === 'searching') {
    return <ActivityIndicator style={{ marginVertical: 50 }} />;
  }

  if (listState === 'endSearch') {
    return (
      <EmptyContent
        title="Nothing matches your search"
        iconComponent={SearchIcon}
        body="Expand your search by using fewer terms"
        actionButton={{
          iconComponent: CloseIcon,
          title: 'Clear',
          onPress: onClear
        }}
      />
    );
  }

  if (listState === 'startSearch') {
    return <></>;
  }

  return (
    <EmptyContent
      title="No products yet"
      iconComponent={ProductsIcon}
      body="Products are the items that you sell to customers"
      actionButton={{
        iconComponent: AddIcon,
        title: 'Add new',
        onPress: onAdd
      }}
    />
  );
};

export default EmptyList;
