import { Database } from '../../../../support/Database';
import { DesignDocumentDefinition } from '../../../../support/DesignDocument';
import { CountByStatusView } from './CountByStatusView';

export class CountByStatusViewDesignDoc {
  private static documentId = '_design/count-by-status';

  static views = {
    [CountByStatusView.viewName]: CountByStatusView
  } as const;

  static definition = new DesignDocumentDefinition(
    CountByStatusViewDesignDoc.documentId,
    {
      views: Object.fromEntries(
        Object.entries(CountByStatusViewDesignDoc.views).map(([name, View]) => [
          name,
          View.definition
        ])
      )
    }
  );

  constructor(private readonly database: Database) {}

  public async ensureDefined(): Promise<void> {
    await CountByStatusViewDesignDoc.definition.ensureDefined(
      this.database.pouch
    );
  }

  public readonly views = Object.fromEntries(
    Object.entries(CountByStatusViewDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, CountByStatusViewDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof CountByStatusViewDesignDoc.views]: InstanceType<
      (typeof CountByStatusViewDesignDoc)['views'][P]
    >;
  };
}
