import { StackScreenProps } from '@react-navigation/stack';
import { StyleSheet, View } from 'react-native';
import { useCallback, useEffect, useRef } from 'react';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ProductsListView, {
  ProductsListViewMethods
} from '../../components/products-list-view/ProductsListView';
import { ProductDataContextProvider } from '../../data/ProductDataContext';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import CreateProductMenuOption from '../../components/menu-options/CreateProductMenuOption';
import CreateGroupMenuOption from '../../components/menu-options/CreateGroupMenuOption';
import DeleteProductMenuOption from '../../components/menu-options/DeleteProductMenuOption';
import { HeaderMenuButton } from '../../../../components/header-menu/HeaderMenuButton';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import CreateProductModal from '../../components/modals/CreateProductModal';
import DetailPane from './DetailPane';
import { HeaderNavigation } from '../../../../components/header-navigation/HeaderNavigation';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import ExportMenuOption from '../../components/menu-options/ExportMenuOption';
import { nameof } from '../../../../lib/name-of';

export type ProductMasterDetailScreenProps = StackScreenProps<
  ProductNavigatorParamList,
  'list'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    backgroundColor: SemanticColours.primary.background[90]
  },
  list: {
    flex: 1,
    flexBasis: '33%',
    flexGrow: 0,
    borderRightColor: SemanticColours.primary.foreground[15],
    borderRightWidth: StyleSheet.hairlineWidth
    // overflow: 'visible'
  }
});

const ProductMasterDetailScreen = (
  props: ProductMasterDetailScreenProps
): JSX.Element => {
  const { navigation, route } = props;

  const styles = useDynamicStyleSheet(rStyles);

  const ref = useRef<ProductsListViewMethods | null>(null);

  const onSelect = useCallback(
    (productId: string) => {
      navigation.setParams({ productId, variantId: undefined });
    },
    [navigation]
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: headerProps => <HeaderNavigation {...headerProps} />,
      headerRight: headerProps => <HeaderMenuButton {...headerProps} />
    });
  }, [navigation]);

  const onGroupCreated = useCallback(
    (id: string) => {
      ref.current?.ensureDepartmentExpanded(id);
      ref.current?.scrollToDepartment(id);
    },
    [ref.current]
  );

  const onProductCreated = useCallback(
    (productId: string, departmentId: string | null = null) => {
      ref.current?.ensureDepartmentExpanded(departmentId);
      ref.current?.scrollToProduct(departmentId, productId);
      navigation.setParams({ productId, variantId: undefined });
    },
    [ref.current, navigation]
  );

  const { present, ...modal } = useModalControl<{
    departmentId: string | null;
  }>();

  return (
    <View
      style={styles.container}
      testID={nameof({ ProductMasterDetailScreen })}
    >
      <AuthorisedContent minRole={RoleId.superUser}>
        <CreateGroupMenuOption onGroupCreated={onGroupCreated} />
        <CreateProductMenuOption onProductCreated={onProductCreated} />
        <CreateProductModal {...modal} onProductCreated={onProductCreated} />
        <ExportMenuOption />
      </AuthorisedContent>

      <ProductsListView
        ref={ref}
        style={styles.list}
        highlightedProductId={route.params?.productId ?? null}
        onSelect={product => onSelect(product.id)}
        onRequestAdd={departmentId => {
          present({ departmentId: departmentId ?? null });
        }}
      />

      {route.params?.productId && (
        <ProductDataContextProvider productId={route.params.productId}>
          <DetailPane />

          <AuthorisedContent minRole={RoleId.superUser}>
            <DeleteProductMenuOption
              onProductDeleted={() =>
                navigation.setParams({
                  productId: undefined,
                  variantId: undefined
                })
              }
            />
          </AuthorisedContent>
        </ProductDataContextProvider>
      )}
    </View>
  );
};

export default ProductMasterDetailScreen;
