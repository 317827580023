import { View, ViewProps } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import LegacyCellGroup from '../../../../../../components/cells/LegacyCellGroup';
import Cell from '../../../../../../components/cells/Cell';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { Text } from '../../../../../../components/text/Text';
import { assertRouteParam } from '../../../../../../lib/assert-route-param';
import { useData } from './useData';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';

export type ProductSummaryProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {},
  cell: {
    marginHorizontal: 24,
    marginVertical: 12
  },
  totalSales: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 16
  }
});

const ProductSummary = ({
  style,
  ...rest
}: ProductSummaryProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'product'>>();

  const productId = assertRouteParam(route.params, 'productId');

  const partition = useActivePartition();

  const { data } = useData(partition, productId);

  return (
    <View style={[styles.container, style]} {...rest}>
      <LegacyCellGroup headingText="Total sales">
        <Cell style={[styles.cell, styles.totalSales]}>
          <Text.BigNumber>
            {data.value !== null ? formatMoney(data.value) : ' '}
          </Text.BigNumber>

          <Text.DocBody>
            {data.units !== null ? `${data.units} items` : ' '}
          </Text.DocBody>
        </Cell>
      </LegacyCellGroup>
    </View>
  );
};

export default ProductSummary;
