import { Platform } from 'react-native';

import 'react-native-gesture-handler';
import '@expo/match-media';
import 'ts-array-extensions';

if (Platform.OS !== 'web') {
  require('react-native-url-polyfill/auto');
}

import './src/main';
