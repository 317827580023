import { ComponentType } from 'react';
import { Button, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Text } from '../text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    backgroundColor: SemanticColours.primary.background[80]
  },
  spacing: {
    marginVertical: 10,
    marginHorizontal: 10
  },
  icon: {
    opacity: 0.5,
    color: SemanticColours.primary.foreground[70]
  },
  text: {
    textAlign: 'center'
  },
  button: {
    marginVertical: 20
  }
});

export type UnhappyPathBaseContentProps = {
  icon: ComponentType<SvgProps>;
  title: string;
  hint: string;
  actionDescription?: string;
  onAction?: () => void;
};

export const UnhappyPathBaseContent = ({
  icon: Icon,
  title,
  hint,
  actionDescription = 'Retry',
  onAction
}: UnhappyPathBaseContentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container]}>
      <Icon style={[styles.spacing, styles.icon]} fill={styles.icon.color} />
      <Text.DocTitle style={[styles.spacing, styles.text]}>
        {title}
      </Text.DocTitle>
      <Text.DocBody style={[styles.spacing, styles.text]}>{hint}</Text.DocBody>
      {onAction && (
        <View style={[styles.spacing, styles.button]}>
          <Button onPress={onAction} title={actionDescription} />
        </View>
      )}
    </View>
  );
};
