import { HeaderMenuOptions } from '../../../../components/header-menu/HeaderMenuOptions';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import CreateProductModal from '../modals/CreateProductModal';

export type CreateProductMenuOptionProps = {
  onProductCreated: (productId: string, departmentId: string | null) => void;
};

const CreateProductMenuOption = ({
  onProductCreated
}: CreateProductMenuOptionProps): JSX.Element => {
  const { present, ...modal } = useModalControl();

  return (
    <>
      <HeaderMenuOptions
        options={[{ label: `Create new product`, onSelect: present }]}
      />
      <CreateProductModal
        onProductCreated={onProductCreated}
        departmentId={null}
        {...modal}
      />
    </>
  );
};

export default CreateProductMenuOption;
