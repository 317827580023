import { EvaluationContext } from './EvaluationContext';

export type Selector = (context: EvaluationContext) => boolean;

export class Selectors {
  public static default: Selector = () => true;

  public static light: Selector = ctx => ctx.theme === 'light';

  public static dark: Selector = ctx => ctx.theme === 'dark';

  public static small: Selector = ctx => ctx.size === 'small';

  public static medium: Selector = ctx => ctx.size === 'medium';

  public static large: Selector = ctx => ctx.size === 'large';

  public static mediumOrLarge: Selector = Selectors.some(
    Selectors.medium,
    Selectors.large
  );

  public static android: Selector = ctx => ctx.platform === 'android';

  public static ios: Selector = ctx => ctx.platform === 'ios';

  public static web: Selector = ctx => ctx.platform === 'web';

  public static all(...selectors: Selector[]): Selector {
    return ctx => selectors.every(s => s(ctx));
  }

  public static some(...selectors: Selector[]): Selector {
    return ctx => selectors.some(s => s(ctx));
  }
}
