import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { DEFAULT_UNNAMED_VARIANT_NAME } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV2';
import { isProductV1CompatVariant } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV1CompatVariant';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import {
  AnyVariantV2,
  ProductV3,
  VariantArrayV2
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

const commandType = 'updateVariantName' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      variantId: string;
      newName: string;
      oldName?: string;
    };
  }
}

const replaceVariant = (
  variants: VariantArrayV2,
  variant: AnyVariantV2
): VariantArrayV2 => {
  return variants.map(v =>
    v.id === variant.id ? variant : v
  ) as VariantArrayV2;
};

export const updateVariantName = async (
  args: Omit<SiteCommands[typeof commandType], 'type' | 'oldName'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const log = createLogger(nameof({ updateVariantName }), { args });

  const { repositories } = ctx.databases.reference.local;
  const { variantId, productId, newName } = args;
  const { userId, createdAt } = ctx.meta;

  let oldName: string | null = null;

  const result = await repositories.products.updateDocument<
    'NOT_FOUND',
    ProductV3
  >(productId, (old, update, abort) => {
    const asV3 = convertProductToV3(old);

    if (asV3 === 'N/A') {
      return abort('NOT_FOUND');
    }

    const variant = asV3.variants.find(v => v.id === variantId);

    if (!variant) {
      return abort('NOT_FOUND');
    }

    oldName = variant.name;

    const changes = (() => {
      if (variant.$schema === 'DeclaredVariant') {
        return {
          variants: replaceVariant(asV3.variants, {
            ...variant,
            name: newName
          })
        };
      }

      if (
        variant.$schema === 'DefaultUnnamedVariant' &&
        newName !== DEFAULT_UNNAMED_VARIANT_NAME
      ) {
        return {
          variants: replaceVariant(asV3.variants, {
            ...variant,
            $schema: 'DefaultImpliedVariant',
            name: newName
          }),
          name: `${asV3.productName} ${newName}`
        };
      }

      if (variant.$schema === 'DefaultImpliedVariant') {
        return {
          variants: replaceVariant(asV3.variants, {
            ...variant,
            name: newName
          }),
          name: `${asV3.productName} ${newName}`
        };
      }

      return { variants: asV3.variants };
    })();

    return update({
      ...asV3,
      ...changes,
      revisionUserID: userId,
      revisionTime: createdAt
    });
  });

  if (result.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  const updatedVariant = result.data.variants.find(v => v.id === variantId);

  if (!updatedVariant) {
    throw new Error(
      `Atomicity fail. Product ${productId} variant ${variantId}`
    );
  }

  if (updatedVariant.$schema === 'DeclaredVariant') {
    const compatResult =
      await repositories.products.updateDocument<'NOT_COMPAT'>(
        updatedVariant.compatProductId,
        (old, update, abort) => {
          if (!isProductV1CompatVariant(old)) {
            return abort('NOT_COMPAT');
          }

          return update({
            ...old,
            name: `${result.data.productName} ${newName}`,
            revisionUserID: userId,
            revisionTime: createdAt
          });
        }
      );

    if (compatResult.type !== 'SUCCESS') {
      if (compatResult.type === 'NOT_FOUND') {
        log.warn(`Variant's compatProductId not found`);
      } else if (compatResult.type === 'ABORTED') {
        log.warn(`Variant's compatProductId was not of the required type`);
      }
    }
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      variantId,
      newName,
      oldName: oldName ?? undefined
    },
    [
      productId,
      variantId,
      ...(updatedVariant.$schema === 'DeclaredVariant'
        ? [updatedVariant.compatProductId]
        : [])
    ]
  );

  return { type: 'SUCCESS', data: command };
};
