import { useMemo } from 'react';
import { DateTime, Duration, Interval } from 'luxon';
import { range } from 'ts-array-extensions';
import { Partition } from '../../../../types/Partition';
import { useSeriesData } from './useSeriesData';
import thenThrow from '../../../../../../lib/then-throw';
import { useCurrentDateTime } from '../../../../../../lib/use-current-time';

type ChartDataValue =
  | undefined
  | {
      primaryOrigin: number;
      primarySeries: {
        x: number;
        y: number;
      }[];
      secondarySeries: {
        x: number;
        y: number;
      }[];
      xAxis: {
        min: number;
        max: number;
        ticks: number[];
      };
      yAxis: {
        max: number;
      };
    };

export const useChartData = (
  partition: Partition,
  comparisonPartition: Partition
): ChartDataValue => {
  const { data: primaryData } = useSeriesData(partition);
  const { data: secondaryData } = useSeriesData(comparisonPartition);

  const currentTime = useCurrentDateTime();

  return useMemo(() => {
    if (
      typeof primaryData === 'undefined' ||
      typeof secondaryData === 'undefined'
    ) {
      return undefined;
    }

    const { startTime: dayStart, endTime: dayEnd } = primaryData;

    const xAxis = (() => {
      const series = [primaryData.points, secondaryData.points];

      const minAbsolute = (() => {
        const lowestInSeries = Math.min(
          ...series.compactMap(s => s.first()?.x)
        );

        if (lowestInSeries === Infinity) {
          return dayStart;
        }

        const roundedDown = DateTime.fromMillis(lowestInSeries + dayStart, {
          zone: 'utc'
        })
          .minus({ minutes: 15 })
          .startOf('hour')
          .toMillis();

        return Math.max(roundedDown, dayStart);
      })();

      const maxAbsolute = (() => {
        const highestInSeries = Math.max(
          ...series.compactMap(s => s.last()?.x)
        );

        if (highestInSeries === -Infinity) {
          return dayEnd;
        }

        const roundedUp = DateTime.fromMillis(highestInSeries + dayStart, {
          zone: 'utc'
        })
          .plus({ minutes: 15 })
          .startOf('hour')
          .plus({ hours: 1 })
          .toMillis();

        return Math.min(roundedUp, dayEnd);
      })();

      const tickIntervalHours = Math.ceil(
        Interval.fromDateTimes(
          DateTime.fromMillis(minAbsolute, { zone: 'utc' }),
          DateTime.fromMillis(maxAbsolute, { zone: 'utc' })
        ).length('hours') / 5
      );

      const [min, max] = [minAbsolute - dayStart, maxAbsolute - dayStart];

      return {
        min,
        max,
        ticks: range(
          min,
          max,
          Duration.fromObject({ hours: tickIntervalHours }).toMillis()
        )
      };
    })();

    const maxY = Math.max(
      primaryData.points.max(v => v.y) ?? 0,
      secondaryData.points.max(v => v.y) ?? 0
    );

    const primarySeries = (() => {
      const rawSeries = primaryData.points;

      if (!rawSeries.any()) return [];

      const lastPoint = rawSeries.last() ?? thenThrow('Impossible');
      const firstPoint = rawSeries.first() ?? thenThrow('Impossible');

      // remove last element because we might have to shift it back it time
      const series = rawSeries.slice(0, -1);

      const now = currentTime.toMillis() - dayStart;

      const ending = (() => {
        // where we want the series to end.
        const x = Math.min(xAxis.max, now);

        if (lastPoint.x >= x) {
          // shift it back
          return [
            {
              x,
              y: lastPoint.y
            }
          ];
        }

        // append a point at the end of the series
        return [
          lastPoint,
          {
            x,
            y: lastPoint.y
          }
        ];
      })();

      return [{ x: xAxis.min, y: firstPoint.y }, ...series, ...ending];
    })();

    const secondarySeries = (() => {
      const series = secondaryData.points;

      if (!series.any()) return [];

      return [
        { x: xAxis.min, y: series.first()?.y ?? thenThrow('Impossible') },
        ...series,
        { x: xAxis.max, y: series.last()?.y ?? thenThrow('Impossible') }
      ];
    })();

    return {
      primaryOrigin: dayStart,
      primarySeries,
      secondarySeries,
      xAxis,
      yAxis: {
        max: maxY
      }
    };
  }, [primaryData, secondaryData, currentTime]);
};
