import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback, useEffect } from 'react';
import { Tab } from '../../../../../../components/tabs/Tab';
import { TabBar, TabBarProps } from '../../../../../../components/tabs/TabBar';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import {
  BreakdownType,
  DashboardNavigatorParamList
} from '../../../../navigation/DashboardNavigatorParamList';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { useContrastTabTheme } from '../../../../../../components/tabs/useContrastTabTheme';
import TeamIcon from '../../../../../../components/icons/TeamIcon';
import ProductsIcon from '../../../../../../components/icons/ProductsIcon';
import PaymentsIcon from '../../../../../../components/icons/PaymentsIcon';

export type BreakdownTabBarProps = TabBarProps;

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.secondary.background[100]
  },
  tab: {
    flexBasis: 1,
    flexGrow: 1
  }
});

const BreakdownTabBar = ({
  style,
  children,
  ...rest
}: BreakdownTabBarProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'show'>>();

  const navigateFor = useCallback(
    (breakdownTabType: BreakdownType) => {
      navigation.setParams({ breakdownTabType });
    },
    [navigation]
  );

  useEffect(() => {
    if (!route.params?.breakdownTabType) {
      navigation.setParams({ breakdownTabType: 'users' });
    }
  }, [route]);

  const tabStyles = useContrastTabTheme();

  return (
    <TabBar style={[styles.container, style]} {...rest}>
      <Tab
        icon={TeamIcon}
        style={styles.tab}
        styles={tabStyles}
        title="Team"
        isActive={route.params?.breakdownTabType === 'users'}
        onPress={() => navigateFor('users')}
      />
      <Tab
        icon={ProductsIcon}
        style={styles.tab}
        styles={tabStyles}
        title="Products"
        isActive={route.params?.breakdownTabType === 'products'}
        onPress={() => navigateFor('products')}
      />
      <Tab
        icon={PaymentsIcon}
        style={styles.tab}
        styles={tabStyles}
        title="Payments"
        isActive={route.params?.breakdownTabType === 'payments'}
        onPress={() => navigateFor('payments')}
      />
      {children}
    </TabBar>
  );
};

export default BreakdownTabBar;
