import React from 'react';
import BasicCell, {
  BasicCellProps
} from '../../../../components/cells/BasicCell';
import CourseIcon from '../../../../components/icons/CourseIcon';

export type CourseCellProps = Omit<BasicCellProps, 'children'> & {
  course: string;
};

const CourseCell = React.memo<CourseCellProps>(({ course, ...rest }) => {
  return (
    <BasicCell
      titlesOrientation="horizontal"
      iconLeft={CourseIcon}
      title="Course"
      subtitle={course}
      {...rest}
    />
  );
});

export default CourseCell;
