import React from 'react';
import { Text, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import CourseIcon from '../../../../components/icons/CourseIcon';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type CourseProps = Omit<ViewProps, 'children'> & {
  course: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    width: 14,
    height: 14,
    color: SemanticColours.primary.foreground[40]
  },
  text: {
    marginLeft: 2,
    fontWeight: '600',
    fontSize: 17,
    lineHeight: 20.29,
    letterSpacing: 2.5,
    color: SemanticColours.primary.foreground[70]
  }
});

const Course = React.memo<CourseProps>(({ style, course, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <CourseIcon
        width={styles.icon.width}
        height={styles.icon.height}
        fill={styles.icon.color}
      />
      <Text style={styles.text}>{course}</Text>
    </View>
  );
});

export default Course;
