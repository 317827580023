import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavNavigatorParamList } from './NavNavigatorParamList';
import DashboardNavigator from '../dashboard/navigation/DashboardNavigator';
import ProductNavigator from '../product-management/navigation/ProductNavigator';
import { useSizeClass } from '../../lib/responsive-size-class';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import { DrawerContent } from './components/drawer-content/DrawerContent';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { HomeScreen } from '../home/HomeScreen';
import PrepNavigator from '../prep-and-pass/navigation/PrepNavigator';
import PassNavigator from '../prep-and-pass/navigation/PassNavigator';

const Drawer = createDrawerNavigator<NavNavigatorParamList>();

const rStyles = DynamicStyleSheet.create({
  drawer: {
    backgroundColor: SemanticColours.primary.background[100],
    borderRightWidth: 0,
    width: 282
  }
}).override(Selectors.large, {
  drawer: {
    width: 240
  }
});

const NavNavigator = (): JSX.Element => {
  const size = useSizeClass();
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Drawer.Navigator
      defaultStatus="open"
      drawerContent={props => <DrawerContent {...props} />}
      screenOptions={{
        drawerType: size === 'large' ? 'permanent' : 'front',
        headerShown: false,
        drawerStyle: styles.drawer
      }}
    >
      <Drawer.Screen name="home" component={HomeScreen} />
      <Drawer.Screen name="dashboard" component={DashboardNavigator} />
      <Drawer.Screen name="products" component={ProductNavigator} />
      <Drawer.Screen name="prep" component={PrepNavigator} />
      <Drawer.Screen name="pass" component={PassNavigator} />
    </Drawer.Navigator>
  );
};

export default NavNavigator;
