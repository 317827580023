import { Platform } from 'react-native';

export const getServerUrl = (): string => {
  if (Platform.OS === 'web') {
    return window.location.origin;
  }

  if (process.env.NODE_ENV === 'development') {
    if (Platform.OS === 'android') {
      return 'http://10.0.2.2:3001';
    }

    return 'http://localhost:3001';
    // return 'https://dev.usop-ops.com';
  }

  return 'https://serveplus.app';
};
