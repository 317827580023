import { PropsWithChildren, createContext, useMemo } from 'react';
import { useProductDataContext } from './ProductDataContext';
import { NotFoundContent } from '../../../components/unhappy-path-content/not-found/NotFoundContent';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { nameof } from '../../../lib/name-of';
import { AnyVariantV2 } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';

const NotFound = null;

type VariantDataContextValue = {
  productId: string;
  variantId: string;
} & (
  | {
      loading: true;
      variant: undefined;
    }
  | {
      loading: false;
      variant: AnyVariantV2;
    }
);

export const VariantDataContext = createContext<VariantDataContextValue | null>(
  null
);

export type VariantDataContextProviderProps = PropsWithChildren<{
  variantId: string;
}>;

export const VariantDataContextProvider = ({
  variantId,
  children
}: VariantDataContextProviderProps): JSX.Element => {
  const product = useProductDataContext();

  const value: VariantDataContextValue | typeof NotFound = useMemo(() => {
    const base = { productId: product.productId, variantId };

    if (product.loading) {
      return { ...base, loading: true, variant: undefined };
    }

    if (product === null) {
      return NotFound;
    }

    const variant = product.product.variants.find(v => v.id === variantId);

    if (!variant) {
      return NotFound;
    }

    return { ...base, loading: false, variant };
  }, [product, variantId]);

  if (value === NotFound) {
    return <NotFoundContent objectDescription="variant" />;
  }

  return (
    <VariantDataContext.Provider value={value}>
      {children}
    </VariantDataContext.Provider>
  );
};

export const useVariantDataContext = () =>
  useContextOrThrow(VariantDataContext, nameof({ VariantDataContext }));
