import React, { ComponentType } from 'react';
import { Text, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import PlatformPressable from '../platform-pressable/PlatformPressable';
import { PlatformPressableProps } from '../platform-pressable/PlatformPressableProps';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';

export type ActionButtonProps = Omit<PlatformPressableProps, 'children'> & {
  iconComponent: ComponentType<SvgProps>;
  label: string;
  colourScheme?: 'primary' | 'secondary';
};

const rStyles = DynamicStyleSheet.create({
  root: {
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: SemanticColours.primary.foreground[15],
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 18
  },
  secondaryRoot: {
    backgroundColor: SemanticColours.secondary.foreground[15]
  },
  icon: {
    color: SemanticColours.primary.foreground[50],
    width: 16,
    height: 16
  },
  secondaryIcon: {
    color: SemanticColours.secondary.foreground[50]
  },
  label: {
    color: SemanticColours.primary.foreground[70],
    leadingTrim: 'both',
    textEdge: 'cap',
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 1.05,
    textTransform: 'uppercase'
  },
  secondaryLabel: {
    color: SemanticColours.secondary.foreground[70]
  },
  item: {
    marginHorizontal: 4
  }
}).override(Selectors.mediumOrLarge, {
  root: {
    height: 42,
    paddingVertical: 6,
    paddingHorizontal: 22
  },
  icon: {
    width: 20,
    height: 20
  },
  label: {
    fontSize: 14
  },
  item: {
    marginHorizontal: 6
  }
});

const ActionButton = React.memo<ActionButtonProps>(
  ({ style, iconComponent: Icon, label, colourScheme, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <PlatformPressable
        style={[
          styles.root,
          colourScheme === 'secondary' && styles.secondaryRoot,
          style as ViewStyle
        ]}
        {...rest}
      >
        <Icon
          width={styles.icon.width}
          height={styles.icon.height}
          fill={
            colourScheme === 'secondary'
              ? styles.secondaryIcon.color
              : styles.icon.color
          }
          style={styles.item}
        />
        <Text
          style={[
            styles.label,
            colourScheme === 'secondary' && styles.secondaryLabel,
            styles.item
          ]}
        >
          {label}
        </Text>
      </PlatformPressable>
    );
  }
);

export default ActionButton;
