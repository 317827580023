import { ComponentType } from 'react';
import { ScrollViewProps, SectionList } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useDocument } from '../../../../../../data-model/hooks/useDocument';
import useTransactionLinesData from './useTransactionLinesData';
import CellGroupSeparator from '../../../../../../components/cells/CellGroupSeparator';
import GroupCell from './GroupCell';
import CellGroupHeading from '../../../../../../components/cells/CellGroupHeading';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type TransactionLinesListProps = ScrollViewProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListHeaderComponent?: ComponentType<any>;
};

const rStyles = DynamicStyleSheet.create({
  container: {},
  content: {
    paddingVertical: 16
  }
});

const TransactionLinesList = ({
  style,
  ...rest
}: TransactionLinesListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route =
    useRoute<RouteProp<DashboardNavigatorParamList, 'transaction'>>();

  if (!route.params?.transactionId) {
    throw new Error('Transaction id is required');
  }

  const transactionsDb = useTransactionsDb();

  const { document } = useDocument(
    transactionsDb.remote.repository,
    route.params.transactionId
  );

  const data = useTransactionLinesData(document);

  const { header, cell, Separator } = useCellGroupStyles('both');

  return (
    <SectionList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      style={[styles.container, style]}
      contentContainerStyle={styles.content}
      sections={data.sessions}
      initialNumToRender={15}
      ItemSeparatorComponent={Separator}
      renderSectionFooter={() => <CellGroupSeparator style={header} />}
      renderSectionHeader={({ section }) =>
        data.sessions.indexOf(section) === 0 ? (
          <>
            <CellGroupSeparator style={header} />
            <CellGroupHeading title="Lines" style={header} />
          </>
        ) : null
      }
      renderItem={({ item, index, section }) => (
        <GroupCell
          data={item}
          style={cell(index === 0, index === section.data.length - 1)}
        />
      )}
      {...rest}
    />
  );
};

export default TransactionLinesList;
