import React from 'react';
import BasicCell, {
  BasicCellProps
} from '../../../../components/cells/BasicCell';
import RestaurantTableIcon from '../../../../components/icons/RestaurantTableIcon';

export type TableCellProps = Omit<BasicCellProps, 'children'> & {
  tableNumber: string;
};

const TableCell = React.memo<TableCellProps>(({ tableNumber, ...rest }) => {
  return (
    <BasicCell
      titlesOrientation="horizontal"
      iconLeft={RestaurantTableIcon}
      title="Table"
      subtitle={tableNumber}
      {...rest}
    />
  );
});

export default TableCell;
