import { TupleToUnion } from '../../../lib/ts-tuple/TupleToUnion';

const SecuritySchemes = ['NONE', 'PERSONAL', 'SITE_OWNED'] as const;

export type SecurityScheme = TupleToUnion<typeof SecuritySchemes>;

export const isSecurityScheme = (value: unknown): value is SecurityScheme => {
  return (
    !!value &&
    typeof value === 'string' &&
    (SecuritySchemes as readonly string[]).includes(value)
  );
};
