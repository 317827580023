import React from 'react';
import ActionIconButton from '../card/ActionIconButton';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { releasePreparationOrder } from '../../commands/releasePreparationOrder';
import { Alert } from '../../../../components/platform-alert/Alert';
import ReleaseIcon from '../../../../components/icons/ReleaseIcon';
import { PlatformPressableProps } from '../../../../components/platform-pressable/PlatformPressableProps';
import ActionButton from '../../../../components/buttons/ActionButton';

export type ReleaseButtonProps = Omit<PlatformPressableProps, 'children'> & {
  preparationOrderId: string;
  size?: 'small' | 'large';
};

const ReleaseButton = React.memo<ReleaseButtonProps>(
  ({ preparationOrderId, size = 'small', ...rest }) => {
    const { invoke, inProgress } = useCommand(
      releasePreparationOrder,
      (result, retry) => {
        if (!(result instanceof Error) && result.type === 'SUCCESS') {
          return;
        }

        const errorMessage =
          result instanceof Error
            ? 'An unexpected error occurred.'
            : 'The task is not in the expected state.';

        Alert.alert('Error', `Could not release the task.\n\n${errorMessage}`, [
          { text: 'Cancel', onPress: () => {} },
          { text: 'Retry', onPress: () => retry() }
        ]);
      }
    );

    return size === 'small' ? (
      <ActionIconButton
        {...rest}
        iconComponent={ReleaseIcon}
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    ) : (
      <ActionButton
        {...rest}
        iconComponent={ReleaseIcon}
        label="Release"
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    );
  }
);

export default ReleaseButton;
