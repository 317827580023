import { useContext } from 'react';
import { nameof } from '../../lib/name-of';
import DeviceContext from './DeviceContext';

export const useDeviceId = (): string => {
  const device = useContext(DeviceContext);

  if (!device) {
    throw new Error(
      `${nameof({ useDeviceId })} required a ${nameof({ DeviceContext })}`
    );
  }

  return device.deviceId;
};
