import { useCallback, useMemo } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import ListItemSeparator, { ListItemSeparatorProps } from './ListItemSeparator';

const rStyles = DynamicStyleSheet.create({
  firstLeft: {
    borderTopLeftRadius: 12
  },
  lastLeft: {
    borderBottomLeftRadius: 12
  },
  firstRight: {
    borderTopRightRadius: 12
  },
  lastRight: {
    borderBottomRightRadius: 12
  },
  insetLeft: {
    marginLeft: 14
  },
  insetRight: {
    marginRight: 14
  }
}).override(Selectors.mediumOrLarge, {
  insetLeft: {
    marginLeft: 24
  },
  insetRight: {
    marginRight: 24
  }
});

export const useCellGroupStyles = (insets: 'left' | 'right' | 'both') => {
  const styles = useDynamicStyleSheet(rStyles);

  const margins = useMemo(() => {
    const left = insets === 'left' || insets === 'both';
    const right = insets === 'right' || insets === 'both';

    return [
      ...(left ? [styles.insetLeft] : []),
      ...(right ? [styles.insetRight] : [])
    ];
  }, [insets]);

  const Separator = useCallback(
    ({ style, ...rest }: ListItemSeparatorProps): JSX.Element => (
      <ListItemSeparator style={[style, margins]} {...rest} />
    ),
    [margins, styles]
  );

  return {
    header: margins,

    cell: useCallback(
      (first: boolean, last: boolean) => {
        const left = insets === 'left' || insets === 'both';
        const right = insets === 'right' || insets === 'both';

        return [
          ...margins,
          ...(left && first ? [styles.firstLeft] : []),
          ...(left && last ? [styles.lastLeft] : []),
          ...(right && first ? [styles.firstRight] : []),
          ...(right && last ? [styles.lastRight] : [])
        ];
      },
      [styles, insets, margins]
    ),

    Separator
  };
};
