import { useEffect, useMemo, useState, ComponentType } from 'react';
import { View } from 'react-native';
import { PropsType } from '../../lib/react-props-type';

export type LazyMountProps<TComponent> = {
  visible: boolean;
  component: TComponent;
  componentProps: PropsType<TComponent>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function LazyMount<const TComponent extends ComponentType<any>>({
  visible,
  component: Component,
  componentProps
}: LazyMountProps<TComponent>): JSX.Element {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (visible && !mounted) {
      setMounted(true);
    }
  }, [visible]);

  const Memoized = useMemo(
    () =>
      (props: { style: { display?: 'flex' | 'none' } }): JSX.Element => {
        if (!mounted) return <View {...props} />;

        const style = [
          props.style,
          'style' in componentProps ? componentProps.style : undefined
        ];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const allProps: any = {
          ...componentProps,
          ...props,
          style
        };

        return <Component {...allProps} />;
      },
    [mounted, componentProps]
  );

  return <Memoized style={{ display: visible ? 'flex' : 'none' }} />;
}
