import Svg, {
  SvgProps,
  Circle,
  Path,
  Defs,
  Mask,
  Rect
} from 'react-native-svg';

export const ClearIcon = ({ fill = '#000', ...props }: SvgProps) => (
  <Svg viewBox="0 0 28 28" width={28} height={28} {...props}>
    <Defs>
      <Mask id="cross">
        <Rect width={28} height={28} fill="#fff" />
        <Path
          d="m18.333 9.94-.94-.94-3.726 3.727L9.94 9 9 9.94l3.727 3.727L9 17.393l.94.94 3.727-3.726 3.726 3.726.94-.94-3.726-3.726 3.726-3.727Z"
          fill="#000"
        />
      </Mask>
    </Defs>
    <Circle
      opacity={0.5}
      cx={14}
      cy={14}
      r={14}
      fill={fill}
      mask="url(#cross)"
    />
  </Svg>
);
