import { Fragment, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { PersonalSecurityContext } from '../../../../security/personal/PersonalSecurityContext';
import { nameof } from '../../../../lib/name-of';
import { ReLoginNavigatorParamList } from '../../navigation/ReLoginNavigatorParamList';
import BasicCell from '../../../../components/cells/BasicCell';
import TickIcon from '../../../../components/icons/TickIcon';
import { ForwardIcon } from '../../../../components/icons/ForwardIcon';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import { Client } from '../../../../security/personal/types';
import { isAuthenticatedUser } from '../../../../security/personal/isAuthenticatedUser';
import HeaderAddButton from './AddHeader';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type ClientListScreenProps = StackScreenProps<
  ReLoginNavigatorParamList,
  'client-list'
>;

const rStyles = DynamicStyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[100]
  },
  container: {
    flex: 1,
    marginVertical: 70
  },
  cell: {
    backgroundColor: SemanticColours.secondary.background[100]
  },
  cellTitle: {
    color: SemanticColours.secondary.foreground[100]
  },
  cellIcon: {
    color: SemanticColours.secondary.foreground[100]
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    alignSelf: 'center',
    width: 445
  }
});

const ClientListScreen = ({
  navigation,
  route
}: ClientListScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { clients, switchToNewClient } = useContextOrThrow(
    PersonalSecurityContext,
    nameof({ PersonalSecurityContext })
  );

  const onAdd = useCallback(() => {
    navigation.push('add-client');
  }, [navigation]);

  const onSuccess = useCallback(
    (selection: Client) => {
      if (isAuthenticatedUser(selection.user)) {
        switchToNewClient({
          ...selection,
          user: selection.user
        });
        return;
      }

      navigation.push('re-login', {
        clientId: selection.id,
        clientName: selection.name
      });
    },
    [switchToNewClient, navigation]
  );

  const listData = useMemo(() => {
    return clients
      .sortBy(c => c.name)
      .map(c => ({
        ...c,
        selected: c.id === route.params?.activeClientId
      }));
  }, [clients, route]);

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <HeaderAddButton onPress={onAdd} />
        {listData.map((item, index) => (
          <Fragment key={item.id}>
            <BasicCell
              title={item.name}
              titleStyle={styles.cellTitle}
              iconRight={item.selected ? TickIcon : ForwardIcon}
              iconColour={styles.cellIcon.color}
              style={styles.cell}
              onPress={item.selected ? undefined : () => onSuccess(item)}
            />
            {index < listData.length - 1 && (
              <ListItemSeparator contrastColourScheme />
            )}
          </Fragment>
        ))}
      </View>
    </View>
  );
};

export default ClientListScreen;
