import { useCallback } from 'react';
import { useCommand } from '../../../../../../data-model/hooks/useCommand';
import { HeaderMenuOptions } from '../../../../../../components/header-menu/HeaderMenuOptions';
import { deleteServiceTransaction } from '../../../../commands/deleteServiceTransaction';
import { Alert } from '../../../../../../components/platform-alert/Alert';

export type DeleteTransactionMenuOptionProps = {
  transactionId: string;
  onTransactionDeleted: () => void;
};

const DeleteTransactionMenuOption = ({
  transactionId,
  onTransactionDeleted
}: DeleteTransactionMenuOptionProps): JSX.Element => {
  const { invoke, inProgress } = useCommand(
    deleteServiceTransaction,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        onTransactionDeleted();
        return;
      }

      if (!(result instanceof Error) && result.type === 'ABORTED') {
        return;
      }

      const errorMessage = (() => {
        if (result instanceof Error) {
          return 'An unexpected error occurred';
        }

        switch (result.type) {
          case 'NOT_FOUND':
            return 'The transaction no longer exists.';
          case 'OFFLINE':
            return 'You are currently offline.\n\nYou need an internet connection to delete transactions.';
          default:
            return 'An unexpected error occurred';
        }
      })();

      Alert.alert(
        'Error',
        `Could not delete the transaction.\n\n${errorMessage}`,
        [{ text: 'Cancel' }, { text: 'Retry', onPress: () => retry() }]
      );
    }
  );

  const onSelect = useCallback(() => {
    Alert.alert(
      'Confirm delete',
      `Are you sure you want to this transaction?`,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: () =>
            invoke({
              transactionId
            })
        }
      ]
    );
  }, [transactionId]);

  return (
    <HeaderMenuOptions
      options={[
        {
          label: `Delete`,
          style: 'destructive' as const,
          isDisabled: inProgress,
          onSelect
        }
      ]}
    />
  );
};

export default DeleteTransactionMenuOption;
