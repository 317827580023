import React from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import CardColumn, {
  CardColumnData,
  CardColumnProps
} from '../../../../../components/card/CardColumn';
import { PassNavigatorParamList } from '../../../navigation/PassNavigatorParamList';
import { assertRouteParam } from '../../../../../lib/assert-route-param';
import { usePreparationOrdersDb } from '../../../../../data-model/components/site-context/usePreparationOrdersDb';
import { useTransform } from '../../../../../data-model/hooks/useTransform';
import { useQueryStream } from '../../../../../data-model/hooks/useQueryStream';
import BoundCard from '../../../components/card/BoundCard';
import BoundCardFooter from './BoundCardFooter';
import EmptyContent from './EmptyContent';

export type TasksColumnProps = Omit<CardColumnProps, 'data'>;

const skeleton: CardColumnData = {
  data: []
};

const TasksColumn = React.memo<TasksColumnProps>(({ style, ...rest }) => {
  const route = useRoute<RouteProp<PassNavigatorParamList>>();
  const zoneId = assertRouteParam(route.params, 'prepZoneId');

  const db = usePreparationOrdersDb();

  const { data } = useTransform(
    useQueryStream(db.local.design.summaryByStatus.views.main, {
      startKey: [zoneId, '01 Pending'],
      endKey: [zoneId, '04 Ready', []]
    }),
    rows => {
      if (rows.none()) {
        return { data: [] };
      }

      return {
        data: rows.map(v => ({
          key: v.documentID,
          element: (
            <BoundCard
              queryRow={v}
              expandable
              footerComponent={BoundCardFooter}
            />
          )
        }))
      };
    },
    () => skeleton,
    [style]
  );

  return (
    <CardColumn
      style={style}
      {...rest}
      data={data}
      ListEmptyComponent={EmptyContent}
    />
  );
});

export default TasksColumn;
