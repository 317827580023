import { fail, success } from '@laurence79/ts-results';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { resilient } from '../api-resilience';
import { AbortReason } from './AbortReason';
import { ApiContext } from './ApiContext';
import { ApiHook } from './ApiHook';
import { ApiOptions } from './ApiOptions';
import { NO_OP } from './constants';
import { defaultOptions } from './defaultOptions';
import { getCacheKey } from './getCacheKey';
import { Invocation } from './Invocation';
import { inProgressInvocationStates } from './inProgressInvocationStates';
import { offlineInvocationStates } from './offlineInvocationStates';
import { WithoutAuthorization } from './WithoutAuthorization';
import { createLogger } from '../logging';
import { nameof } from '../name-of';
import { GlobalActivity } from '../global-activity/GlobalActivity';

export const useApiHook = <
  TArgs extends { authorization?: string | undefined },
  TResponse extends { status: number }
>(
  apiCall: (args: TArgs, fetchOptions?: RequestInit) => Promise<TResponse>,
  resourceType: string,
  hookOptions?: ApiOptions
): ApiHook<TArgs, TResponse> => {
  const log = createLogger(nameof({ useApiHook }));

  const context = useContext(ApiContext);

  const hookTimeOptions = useMemo(
    () => ({
      ...defaultOptions(),
      ...context?.options,
      ...hookOptions
    }),
    [context, hookOptions]
  );

  const { isConnected } = hookTimeOptions.connectivityProvider.useIsConnected();

  // starts as null, we should assume connected
  const offline = useMemo(() => isConnected === false, [isConnected]);

  const [invocation, setInvocation] = useState<Invocation<
    WithoutAuthorization<TArgs>,
    TResponse
  > | null>(null);

  // logging
  useEffect(() => {
    if (invocation) {
      log.debug('Invocation state change', {
        name: apiCall.name,
        state: invocation.state,
        offline,
        stage: invocation.result?.success ? invocation.result.data.stage : null,
        aborted: invocation.abortController.signal.aborted,
        reason:
          invocation.result?.success === false &&
          !(invocation.result.reason instanceof Error)
            ? invocation.result.reason
            : null
      });
    }
  }, [invocation, offline]);

  // start new, read cache if necessary
  useEffect(() => {
    if (invocation?.state !== 'NEW') {
      return;
    }

    const {
      options: { authorizer, cacheStrategy, cacheProvider },
      args
    } = invocation;

    const bailWithNoCache = () => {
      if (!offline) {
        setInvocation({
          ...invocation,
          state: 'WAITING_FOR_FETCH'
        });
      } else {
        setInvocation({
          ...invocation,
          state: 'OFFLINE',
          result: fail('OFFLINE_NO_CACHE')
        });
      }
    };

    if (cacheStrategy === 'NO_CACHE') {
      bailWithNoCache();
      return;
    }

    const maybeCache = cacheProvider.read<TResponse>(
      getCacheKey(args, resourceType)
    );

    if (!maybeCache) {
      bailWithNoCache();
      return;
    }

    const { cachedAt, data: response } = maybeCache;

    const authUserId = authorizer.getUserId();

    const cacheAllowed =
      cacheStrategy === 'CACHE_ALL_USERS' ||
      !maybeCache.userId ||
      authUserId === maybeCache.userId;

    if (cacheAllowed) {
      setInvocation({
        ...invocation,
        result: success({
          stage: offline ? 'FINAL' : 'INTERIM',
          cached: true,
          cachedAt,
          response
        }),
        state: offline ? 'OFFLINE_SETTLED' : 'WAITING_FOR_FETCH'
      });

      return;
    }

    setInvocation({
      ...invocation,
      state: offline ? 'OFFLINE' : 'WAITING_FOR_FETCH'
    });
  }, [invocation]);

  // trigger retry if no longer offline
  useEffect(() => {
    if (
      invocation &&
      offlineInvocationStates.includes(invocation.state) &&
      !offline
    ) {
      setInvocation({ ...invocation, state: 'WAITING_FOR_FETCH' });
    }
  }, [invocation, offline]);

  // do fetching
  useEffect(() => {
    if (invocation?.state !== 'WAITING_FOR_FETCH') {
      return NO_OP;
    }

    let mounted = true;

    const {
      authorizer,
      fetchRetries,
      fetchTimeoutMs,
      cacheExpiry,
      cacheProvider,
      cacheStrategy
    } = invocation.options;

    const token = authorizer.getToken();

    const fetchResponse = async () => {
      const args = {
        ...invocation.args,
        ...(token ? { authorization: `Bearer ${token}` } : {})
      } as TArgs;

      const fetchOptions = {
        signal: invocation.abortController.signal
      };

      const resilientOptions = {
        retries: fetchRetries,
        timeoutMs: fetchTimeoutMs,
        signal: invocation.abortController.signal
      };

      const response = await GlobalActivity.track('API call', () =>
        resilient(() => apiCall(args, fetchOptions), resilientOptions)
      );

      if (!mounted) return;

      if (!response.success) {
        if (
          response.reason instanceof Error &&
          response.reason.message.startsWith('Unexpected status 5') &&
          invocation.result &&
          invocation.result.success
        ) {
          log.info(`Sicking with cache because 5xx error`);

          setInvocation({
            ...invocation,
            state: 'SETTLED',
            result: success({
              ...invocation.result.data,
              stage: 'FINAL'
            })
          });
          return;
        }

        if (
          response.reason instanceof Error &&
          response.reason.name === 'AbortError'
        ) {
          // see https://javascript.info/fetch-abort
          setInvocation({
            ...invocation,
            state: 'ABORTED'
          });
        } else {
          setInvocation({
            ...invocation,
            state: 'SETTLED',
            result: fail(response.reason)
          });
        }

        return;
      }

      if (
        response.data.status >= 200 &&
        response.data.status <= 299 &&
        cacheStrategy !== 'NO_CACHE'
      ) {
        const cacheKey = getCacheKey(invocation.args, resourceType);

        const expireAt = new Date().getTime() + cacheExpiry;

        const userId = authorizer.getUserId();

        cacheProvider.write(cacheKey, userId, response.data, expireAt);
      }

      if (response.data.status === 401) {
        authorizer.notifyAuthFailed();
      }

      setInvocation({
        ...invocation,
        state: 'SETTLED',
        result: success({
          stage: 'FINAL',
          cached: false,

          // TODO: Something weird going on here. TResponse cast shouldn't
          // be required
          response: response.data as TResponse
        })
      });
    };

    // no need to catch
    void fetchResponse();

    return () => {
      mounted = false;
      if (invocation) {
        if (!invocation.result) {
          invocation.abortController.abort(AbortReason.unmounted);
        }
      }
    };
  }, [invocation]);

  const call: ApiHook<TArgs, TResponse>['call'] = useCallback(
    (newArgs, callOptions) => {
      setInvocation(existing => {
        if (existing) {
          if (!existing.result) {
            existing.abortController.abort(AbortReason.replaced);
          }
        }

        const options = {
          ...hookTimeOptions,
          ...callOptions
        };

        return {
          state: 'NEW',
          args: newArgs,
          options,
          abortController: new AbortController(),
          result: null
        };
      });
    },
    [setInvocation, hookTimeOptions]
  );

  return useMemo(
    () =>
      invocation
        ? {
            args: invocation.args,
            inProgress: inProgressInvocationStates.includes(invocation.state),
            result: invocation.result,
            call
          }
        : {
            args: null,
            inProgress: false,
            result: null,
            call
          },
    [invocation, call]
  );
};
