import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import * as SplashScreen from 'expo-splash-screen';
import { Platform, StyleSheet } from 'react-native';
import { createLogger } from '../logging';
import { nameof } from '../name-of';
import HoldingScreen from '../../components/holding-screen/HoldingScreen';
import { ThemeProvider } from '../theme-context';

const styles = StyleSheet.create({
  webHoldingScreen: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
});

export const RetainSplashContext = createContext<{
  retain(): void;
  release(): void;
} | null>(null);

export const RetainSplashContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const log = createLogger(nameof({ RetainSplashContextProvider }));

  const [retainCount, setRetainCount] = useState(0);

  const setHideHandle = useState<ReturnType<typeof setTimeout> | null>(null)[1];

  const [alreadyHidden, setAlreadyHidden] = useState(false);

  useEffect(() => {
    log.debug('Retain changed', { retainCount });

    setHideHandle(old => {
      if (old) {
        log.debug('Cancelling deferred hide', { retainCount });

        clearTimeout(old);
      }

      if (retainCount === 0) {
        log.debug('Scheduling deferred hide');

        return setTimeout(() => {
          log.debug('Hiding splash screen');
          setAlreadyHidden(true);
          void SplashScreen.hideAsync();
        }, 250);
      }

      return null;
    });
  }, [retainCount, setAlreadyHidden]);

  const checkNoop = useCallback(
    (fn: (old: number) => number) => {
      if (alreadyHidden) {
        log.debug(
          'Ignoring splash screen retain/release because already hidden'
        );
        return;
      }

      setRetainCount(fn);
    },
    [alreadyHidden, setRetainCount]
  );

  const value = useMemo(
    () => ({
      retain: () => checkNoop(i => i + 1),
      release: () => checkNoop(i => i - 1)
    }),
    [checkNoop]
  );

  return (
    <RetainSplashContext.Provider value={value}>
      {children}

      {Platform.OS === 'web' && !alreadyHidden && (
        <ThemeProvider theme="automatic">
          <HoldingScreen style={styles.webHoldingScreen} title="" />
        </ThemeProvider>
      )}
    </RetainSplashContext.Provider>
  );
};
