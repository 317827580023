import { GlobalActivity } from '../../lib/global-activity/GlobalActivity';
import { SyncDisposable } from '../../lib/disposable/SyncDisposable';
import { DatabaseSync, SyncStatus } from './DatabaseSync';

export class SyncActivityAdapter implements SyncDisposable {
  private static activeStates: Set<SyncStatus['state']> = new Set([
    'ACTIVE',
    'RECEIVE',
    'SEND'
  ]);

  constructor(private readonly syncs: DatabaseSync[]) {}

  private readonly disposals = this.syncs.map(s => {
    let activityEnrolment: SyncDisposable | undefined;

    const subscription = s.status.subscribe({
      next: ({ state }) => {
        if (!activityEnrolment && SyncActivityAdapter.activeStates.has(state)) {
          activityEnrolment = GlobalActivity.enroll(
            `Syncing\n${this.syncs.map(sync => sync.database.name).join('\n')}`
          );
        }

        if (activityEnrolment && !SyncActivityAdapter.activeStates.has(state)) {
          activityEnrolment.dispose();
          activityEnrolment = undefined;
        }
      }
    });

    return () => {
      activityEnrolment?.dispose();
      subscription.dispose();
    };
  });

  dispose(): void {
    this.disposals.forEach(d => d());
  }
}
