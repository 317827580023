import React, { useMemo } from 'react';
import Orig, {
  HeaderProps as OrigProps
} from '../../../../components/header/Header';
import {
  AnyPaymentId,
  CardPaymentId,
  CashPaymentId,
  VoucherPaymentId
} from '../../../../../../data-model/schema/databases/site-transactions-archive/design/PaymentIds';
import { useDocument } from '../../../../../../data-model/hooks/useDocument';
import { useReferenceDb } from '../../../../../../data-model/components/site-context/useReferenceDb';
import {
  cardPaymentIdName,
  cashPaymentIdName
} from '../../../../helpers/paymentIdNames';

export type HeaderProps<TPaymentId extends AnyPaymentId> = Omit<
  OrigProps,
  'title'
> & {
  paymentTypeId: TPaymentId;
};

const CardHeader = ({
  paymentTypeId,
  ...rest
}: HeaderProps<CardPaymentId>): JSX.Element => {
  const db = useReferenceDb();

  const { document: servingZone } = useDocument(
    db.local.repositories.servingZones,
    paymentTypeId[1]
  );

  return <Orig title={cardPaymentIdName(servingZone?.name)} {...rest} />;
};

const CashHeader = ({
  paymentTypeId,
  ...rest
}: HeaderProps<CashPaymentId>): JSX.Element => {
  const db = useReferenceDb();

  const { document: servingZone } = useDocument(
    db.local.repositories.servingZones,
    paymentTypeId[1]
  );

  const drawer = useMemo(() => {
    return servingZone?.cashDrawers?.find(c => c.ID === paymentTypeId[2]);
  }, [servingZone, paymentTypeId]);

  return (
    <Orig
      title={cashPaymentIdName(servingZone?.name, drawer?.name)}
      {...rest}
    />
  );
};

const VoucherHeader = ({
  paymentTypeId,
  ...rest
}: HeaderProps<VoucherPaymentId>): JSX.Element => {
  const db = useReferenceDb();

  const { document: voucher } = useDocument(
    db.local.repositories.vouchers,
    paymentTypeId[1]
  );

  return <Orig title={voucher?.name ?? 'Voucher'} {...rest} />;
};

function Header<TPaymentId extends AnyPaymentId>({
  paymentTypeId,
  ...rest
}: HeaderProps<TPaymentId>): JSX.Element {
  if (paymentTypeId[0] === 'Card') {
    return <CardHeader paymentTypeId={paymentTypeId} {...rest} />;
  }

  if (paymentTypeId[0] === 'Cash') {
    return <CashHeader paymentTypeId={paymentTypeId} {...rest} />;
  }

  if (paymentTypeId[0] === 'Voucher') {
    return <VoucherHeader paymentTypeId={paymentTypeId} {...rest} />;
  }

  return <Orig title="Payments" />;
}

export default React.memo(Header);
