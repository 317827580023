import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';
import { useCallback } from 'react';
import { OnboardingNavigatorParamList } from '../../navigation/OnboardingNavigatorParamList';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import Logo from './Logo';
import EnterClientForm, {
  EnterClientFormProps
} from '../../../../security/components/enter-client-form/EnterClientForm';
import ContactUs from './ContactUs';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { useProgress } from '../../components/progress-context/useProgress';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type WelcomeScreenProps = StackScreenProps<
  OnboardingNavigatorParamList,
  'welcome'
>;

const rStyles = DynamicStyleSheet.create({
  screen: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: SemanticColours.secondary.background[100]
  },
  container: {
    alignItems: 'stretch',
    paddingHorizontal: 24
  },
  leftPane: {
    alignItems: 'flex-end'
  },
  item: {
    marginVertical: 35
  },
  contactUs: {
    textAlign: 'center'
  }
})
  .override(Selectors.mediumOrLarge, {
    container: {
      flexDirection: 'row',
      width: '70%',
      alignItems: 'center'
    },
    pane: {
      padding: 35,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 1
    },
    item: {
      marginVertical: 10
    },
    contactUs: {
      textAlign: 'right'
    }
  })
  .override(Selectors.small, {
    logo: {
      alignSelf: 'center'
    }
  });

type ContentProps = Pick<EnterClientFormProps, 'onSuccess'>;

const SmallContent = ({ onSuccess }: ContentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={styles.screen} testID="WelcomeScreen">
      <View style={styles.container}>
        <Logo style={[styles.item, styles.logo]} />
        <EnterClientForm style={styles.item} onSuccess={onSuccess} />
        <ContactUs style={[styles.contactUs, styles.item]} />
      </View>
    </View>
  );
};

const LargeContent = ({ onSuccess }: ContentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={styles.screen} testID="WelcomeScreen">
      <View style={styles.container}>
        <View style={[styles.leftPane, styles.pane]}>
          <Logo style={styles.item} />
          <ContactUs style={[styles.contactUs, styles.item]} />
        </View>
        <View style={styles.pane}>
          <EnterClientForm onSuccess={onSuccess} />
        </View>
      </View>
    </View>
  );
};

export const WelcomeScreen = ({
  navigation
}: WelcomeScreenProps): JSX.Element => {
  const size = useSizeClass();

  const { updateProgress } = useProgress();

  const onSuccess = useCallback(
    (client: { id: string; name: string }) => {
      updateProgress({ client });

      navigation.push('login');
    },
    [updateProgress, navigation]
  );

  return size === 'small' ? (
    <SmallContent onSuccess={onSuccess} />
  ) : (
    <LargeContent onSuccess={onSuccess} />
  );
};
