import React, { useMemo } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../../../components/cells/LegacyCellGroup';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { isIsoWeekPartition } from '../../../../helpers/isIsoWeekPartition';
import { partitionToSubPartitions } from '../../../../helpers/partitionToSubPartitions';
import { viewKeysAreEqual } from '../../../../../../data-model/helpers/viewKeysAreEqual';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { formatPartition } from '../../../../../../humanisers/formatPartition';
import { partitionToComponentKey } from '../../../../helpers/partitionToComponentKey';
import { sortViewKeys } from '../../../../../../data-model/helpers/sortViewKeys';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';

export type WeekdaysCellGroupProps = Omit<LegacyCellGroupProps, 'children'>;

const WeekdaysCellGroup = (props: WeekdaysCellGroupProps): JSX.Element => {
  const partition = useActivePartition(isIsoWeekPartition);

  const siteSettings = useSiteTime();

  const days = useMemo(() => {
    return partitionToSubPartitions(partition);
  }, [partition]);

  const db = useTransactionsDb();

  const { data } = useTransform(
    useQueryStream(db.remote.design.dashboardDates.views.calendar, {
      groupLevel: 4,
      reduce: true,
      startKey: days[0],
      endKey: days[6],
      inclusiveEnd: true
    }),
    rows =>
      days
        .leftJoin(rows, (l, r) => viewKeysAreEqual(l, r.key))
        .map(({ left: day, right: row }) => ({
          key: day,
          title: formatPartition(siteSettings)(day, { year: undefined }),
          subtitle: row?.value.value ? formatMoney(row?.value.value) : undefined
        }))
        .sort((a, b) => sortViewKeys(a.key, b.key)),
    () =>
      days.map(day => ({
        key: day,
        title: formatPartition(siteSettings)(day, { year: undefined }),
        subtitle: undefined
      }))
  );

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  return (
    <LegacyCellGroup headingText="Days" {...props}>
      {data.map(({ key, title, subtitle }, index) => (
        <React.Fragment key={partitionToComponentKey(key)}>
          <BasicCell
            iconRight={subtitle ? ForwardIcon : undefined}
            title={title}
            subtitle={subtitle}
            titlesOrientation="horizontal"
            onPress={
              subtitle
                ? () => {
                    navigation.push('show', {
                      partition: PartitionSerialiser.stringify(key)
                    });
                  }
                : undefined
            }
          />
          {index < data.length - 1 && <ListItemSeparator />}
        </React.Fragment>
      ))}
    </LegacyCellGroup>
  );
};

export default WeekdaysCellGroup;
