import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import {
  isProductV2Compatible,
  isDeclaredVariant
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV2';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';

const commandType = 'deleteProduct' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
    };
  }
}

export const deleteProduct = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const { products } = ctx.databases.reference.local.repositories;
  const { productId } = args;
  const { userId, createdAt } = ctx.meta;

  const auditFields = {
    revisionUserID: userId,
    revisionTime: createdAt
  };

  const result = await products.updateDocument(productId, (p, update) =>
    update({ ...p, deleted: true, ...auditFields })
  );

  if (result.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  if (isProductV2Compatible(result.data)) {
    await Promise.all(
      result.data.variants
        .compactMap(v => (isDeclaredVariant(v) ? v : null))
        .map(variant =>
          products.updateDocument(variant.compatProductId, (old, update) =>
            update({ ...old, deleted: true, ...auditFields })
          )
        )
    );
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId
    },
    [
      productId,
      ...(isProductV2Compatible(result.data)
        ? result.data.variants.compactMap(v =>
            isDeclaredVariant(v) ? v.compatProductId : null
          )
        : [])
    ]
  );

  return { type: 'SUCCESS', data: command };
};
