import Reanimated from 'react-native-reanimated';
import { TextProps } from 'react-native';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { useValueFromPartitionNavigationCache } from '../../../../components/partition-navigation-context/useValueFromPartitionNavigationCache';

export type TotalSalesValueProps = Omit<TextProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  value: {
    fontSize: 44,
    lineHeight: 80,
    color: SemanticColours.primary.foreground[100]
  }
});

const TotalSalesValue = (props: TotalSalesValueProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const partition = useActivePartition();

  const db = useTransactionsDb();

  const getValue = useValueFromPartitionNavigationCache();

  const { data } = useTransform(
    useQueryStream(db.remote.design.dashboardDates.views.calendar, {
      groupLevel: partition.length,
      reduce: true,
      startKey: partition,
      endKey: [...partition, []],
      inclusiveEnd: true,
      limit: 1
    }),
    rows => rows.first()?.value.value ?? 0,
    () => getValue(partition)
  );

  return (
    <Reanimated.Text style={styles.value} {...props}>
      {data !== null ? formatMoney(data) : ' '}
    </Reanimated.Text>
  );
};

export default TotalSalesValue;
