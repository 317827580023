import { SyncDisposable } from '../disposable/SyncDisposable';
import { Readable } from './Readable';
import { Subscribable } from './Subscribable';
import { Subscriber } from './Subscriber';

export class Mapped<T, U> implements Subscribable<U>, Readable<U> {
  constructor(
    private readonly inner: Subscribable<T> & Readable<T>,
    private readonly mapFn: (v: T) => U
  ) {}

  public subscribe(
    subscriber: Subscriber<U>,
    options?: { readonly sendCurrentValue?: boolean | undefined } | undefined
  ): SyncDisposable {
    return this.inner.subscribe(
      {
        next: v => {
          subscriber.next(this.mapFn(v));
        }
      },
      options
    );
  }

  public get current(): U | undefined {
    return this.inner.current ? this.mapFn(this.inner.current) : undefined;
  }

  public map<V>(fn: (v: U) => V): Subscribable<V> {
    return new Mapped(this, fn);
  }
}
