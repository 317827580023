import { useMemo } from 'react';
import LegacyCellGroup from '../../../../components/cells/LegacyCellGroup';
import EditProductGroupCell from '../cells/EditProductGroupCell';
import ProductNameCell from '../cells/ProductNameCell';
import VariantsCellGroup from '../cells/VariantsCellGroup';
import { useProductDataContext } from '../../data/ProductDataContext';
import { VariantDataContextProvider } from '../../data/VariantDataContext';
import VariantDataView from '../variant-data-view/VariantDataView';
import VariantsIntroCellGroup from '../cells/VariantsIntroCellGroup';
import PreparationCellGroup from '../cells/PreparationCellGroup';
import ChangeHistoryCellGroup from '../cells/ChangeHistoryCellGroup';

type ProductDataViewProps = {
  includeVariants?: boolean;
};

const ProductDataView = ({
  includeVariants
}: ProductDataViewProps): JSX.Element => {
  const { product } = useProductDataContext();

  const singleUnnamedVariant = useMemo(
    () =>
      product?.variants.length === 1 &&
      product.variants[0].$schema === 'DefaultUnnamedVariant'
        ? product.variants[0]
        : undefined,
    [product]
  );

  return (
    <>
      <LegacyCellGroup headingText="Name">
        <ProductNameCell />
      </LegacyCellGroup>

      <LegacyCellGroup>
        <EditProductGroupCell />
      </LegacyCellGroup>

      {singleUnnamedVariant && (
        <VariantDataContextProvider variantId={singleUnnamedVariant.id}>
          <VariantDataView hideVariantName />
        </VariantDataContextProvider>
      )}

      {includeVariants && (
        <>
          {singleUnnamedVariant ? (
            <VariantsIntroCellGroup />
          ) : (
            <VariantsCellGroup />
          )}
        </>
      )}

      <PreparationCellGroup />

      <ChangeHistoryCellGroup />
    </>
  );
};

export default ProductDataView;
