import React from 'react';
import { View, ViewProps, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Colours } from '../../theme/Colours';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';

export type BadgeProps = Omit<ViewProps, 'children' | 'onLayout'> & {
  value: string | number;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: Colours.red.$,
    borderRadius: 8,
    paddingHorizontal: 3,
    shadowColor: Colours.black.$,
    shadowOffset: {
      width: -1,
      height: 1
    },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 10,
    minWidth: 16
  },
  text: {
    color: Colours.white.$,
    fontSize: 13,
    fontWeight: '500',
    textAlignVertical: 'center',
    textAlign: 'center',
    letterSpacing: -0.3
  }
}).override(Selectors.web, {
  text: {
    transform: [{ translateY: -0.5 }]
  }
});

const Badge = React.memo<BadgeProps>(({ style, value, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Text style={styles.text} allowFontScaling={false}>
        {value}
      </Text>
    </View>
  );
});

export default Badge;
