import { RemoteDatabaseConfiguration } from './RemoteDatabaseConfiguration';
import { LocalDatabase } from './LocalDatabase';
import { SchemaConstructor } from './SchemaConstructor';
import { RemoteDatabase } from './RemoteDatabase';
import { DatabaseSync } from '../../tools/DatabaseSync';
import { SyncActivityAdapter } from '../../tools/SyncActivityAdapter';
import { AsyncDisposable } from '../../../lib/disposable/AsyncDisposable';

export class DatabasePair<TLocalSchema, TRemoteSchema = TLocalSchema>
  implements AsyncDisposable
{
  constructor(
    public readonly name: string,
    private readonly SchemaClass:
      | SchemaConstructor<TLocalSchema & TRemoteSchema>
      | {
          Local: SchemaConstructor<TLocalSchema>;
          Remote: SchemaConstructor<TRemoteSchema>;
        },
    private readonly queryConfig: RemoteDatabaseConfiguration,
    private readonly syncConfig: RemoteDatabaseConfiguration
  ) {}

  private readonly localDatabase = new LocalDatabase(
    this.name,
    'Local' in this.SchemaClass ? this.SchemaClass.Local : this.SchemaClass,
    this.queryConfig
  );

  private readonly remoteDatabase = new RemoteDatabase(
    this.name,
    'Remote' in this.SchemaClass ? this.SchemaClass.Remote : this.SchemaClass,
    this.queryConfig
  );

  public readonly pouches: Readonly<{
    local: PouchDB.Database;
    remote: PouchDB.Database;
  }> = {
    local: this.localDatabase.pouch,
    remote: this.remoteDatabase.pouch
  };

  public readonly local = this.localDatabase.schema;

  public readonly remote = this.remoteDatabase.schema;

  public pullReplication: DatabaseSync | undefined;
  private pullActivityAdapter: SyncActivityAdapter | undefined;

  public async cancelPullReplication() {
    if (this.pullActivityAdapter) {
      this.pullActivityAdapter.dispose();
    }

    if (this.pullReplication) {
      await this.pullReplication.dispose();
    }
  }

  public async configurePullReplication(
    options?: PouchDB.Replication.ReplicateOptions
  ): Promise<void> {
    await this.cancelPullReplication();

    this.pullReplication = new DatabaseSync(
      this,
      'pull',
      this.syncConfig,
      options
    );

    this.pullActivityAdapter = new SyncActivityAdapter([this.pullReplication]);
  }

  public pushReplication: DatabaseSync | undefined;
  private pushActivityAdapter: SyncActivityAdapter | undefined;

  public async cancelPushReplication() {
    if (this.pushActivityAdapter) {
      this.pushActivityAdapter.dispose();
    }

    if (this.pushReplication) {
      await this.pushReplication.dispose();
    }
  }

  public async configurePushReplication(
    options?: PouchDB.Replication.ReplicateOptions
  ): Promise<void> {
    await this.cancelPushReplication();

    this.pushReplication = new DatabaseSync(
      this,
      'push',
      this.syncConfig,
      options
    );

    this.pushActivityAdapter = new SyncActivityAdapter([this.pushReplication]);
  }

  async dispose(): Promise<void> {
    await Promise.all([
      this.cancelPullReplication(),
      this.cancelPushReplication()
    ]);
  }
}
