import CardLines, { CardLinesProps } from './CardLines';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { nameof } from '../../../../lib/name-of';
import { usePreparationOrdersDb } from '../../../../data-model/components/site-context/usePreparationOrdersDb';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { ProductLookupCache } from '../ProductLookupCache';
import PreparationOrderLine from '../PreparationOrderLine';
import Note from '../Note';

export type BoundCardLinesProps = Omit<CardLinesProps, 'children'> & {
  documentId: string;
};

const BoundCardLines = ({
  documentId,
  ...rest
}: BoundCardLinesProps): JSX.Element => {
  const products = useContextOrThrow(
    ProductLookupCache,
    nameof({ ProductLookupCache })
  );

  const db = usePreparationOrdersDb();

  const { document } = useDocument(db.local.repository, documentId);

  return (
    <CardLines {...rest}>
      {document?.lines.map(line => (
        <PreparationOrderLine
          key={line.ID}
          text={products.data[line.productID]}
          note={line.notes ? <Note text={line.notes} /> : undefined}
        />
      ))}
    </CardLines>
  );
};

export default BoundCardLines;
