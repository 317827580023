import { DateTime, Duration } from 'luxon';
import { useEffect, useState } from 'react';

export const useCurrentDateTime = (
  update = Duration.fromObject({ minutes: 1 })
) => {
  const [dateTime, setDateTime] = useState(DateTime.now());

  useEffect(() => {
    let cancel: NodeJS.Timeout;

    const scheduleNext = () => {
      cancel = setTimeout(() => {
        setDateTime(DateTime.now());
        scheduleNext();
      }, update.toMillis());
    };

    scheduleNext();

    return () => clearTimeout(cancel);
  }, [update]);

  return dateTime;
};
