import { useCallback } from 'react';
import { Partition } from '../../types/Partition';
import { usePartitionNavigation } from './usePartitionNavigation';
import { isCalendarDayPartition } from '../../helpers/isCalendarDayPartition';
import { arraysAreShallowEqual } from '../../../../helpers/arraysAreShallowEqual';
import { isCalendarMonthPartition } from '../../helpers/isCalendarMonthPartition';
import { isCalendarYearPartition } from '../../helpers/isCalendarYearPartition';
import { isIsoWeekPartition } from '../../helpers/isIsoWeekPartition';

export const useValueFromPartitionNavigationCache = () => {
  const ctx = usePartitionNavigation();

  return useCallback(
    (partition: Partition) => {
      const { day, week, month, year } = ctx;

      if (isCalendarDayPartition(partition)) {
        return 'rows' in day.state
          ? day.state.rows?.find(r =>
              arraysAreShallowEqual(r.partition, partition)
            )?.value ?? null
          : null;
      }
      if (isCalendarMonthPartition(partition)) {
        return 'rows' in month.state
          ? month.state.rows?.find(r =>
              arraysAreShallowEqual(r.partition, partition)
            )?.value ?? null
          : null;
      }
      if (isCalendarYearPartition(partition)) {
        return 'rows' in year.state
          ? year.state.rows?.find(r =>
              arraysAreShallowEqual(r.partition, partition)
            )?.value ?? null
          : null;
      }
      if (isIsoWeekPartition(partition)) {
        return 'rows' in week.state
          ? week.state.rows?.find(r =>
              arraysAreShallowEqual(r.partition, partition)
            )?.value ?? null
          : null;
      }
      return null;
    },
    [ctx]
  );
};
