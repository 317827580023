import { StyleSheet, ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

export type TabBarProps = ViewProps;

const styles = StyleSheet.create({
  bar: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
});

export const TabBar = ({ style, ...rest }: TabBarProps): JSX.Element => (
  <SafeAreaView
    edges={['left', 'right']}
    style={[styles.bar, style]}
    {...rest}
  />
);
