import { useMemo } from 'react';
import BoundCard from '../../../../components/card/BoundCard';
import CardWall, {
  CardWallProps
} from '../../../../../../components/card/CardWall';
import CellGroupHeading from '../../../../../../components/cells/CellGroupHeading';
import BoundCardFooter from '../BoundCardFooter';
import { useTasksData } from '../../../../data/TasksDataContext';

export type InProgressWallProps = Omit<CardWallProps, 'data'>;

const Header = (): JSX.Element => <CellGroupHeading title="In Progress" />;

const InProgressWall = ({ numColumns, ...rest }: InProgressWallProps) => {
  const { data: allTasks } = useTasksData();

  const data = useMemo(() => {
    return allTasks
      .filter(v => v.key[1] === '03 In Progress')
      .map(v => ({
        key: v.documentID,
        element: (
          <BoundCard
            queryRow={v}
            expandable={false}
            footerComponent={BoundCardFooter}
          />
        )
      }));
  }, [allTasks]);

  return (
    <CardWall
      {...rest}
      numColumns={numColumns}
      data={data}
      ListHeaderComponent={data.any() ? Header : undefined}
    />
  );
};

export default InProgressWall;
