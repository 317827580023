import { useContext } from 'react';
import { nameof } from '../../../lib/name-of';
import { ClientContext } from './ClientContext';

export const useClientId = (): string => {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error(
      `${nameof({ useClientId })} required a ${nameof({ ClientContext })}`
    );
  }

  return client.clientId;
};
