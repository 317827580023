import { useCallback, useMemo } from 'react';
import { View, ViewProps } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { isCalendarDayPartition } from '../../../../helpers/isCalendarDayPartition';
import ComparisonModal from './ComparisonModal';
import useModalControl from '../../../../../../lib/use-modal/useModalControl';
import SalesCumeChart from './SalesCumeChart';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import { isPartition } from '../../../../helpers/isPartition';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { TimeInterval } from '../../../../types/TimeInterval';

export type DaySalesVisualProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'column'
  }
}).override(Selectors.web, {
  container: {
    width: '100%'
  }
});

const DaySalesVisual = ({
  style,
  ...rest
}: DaySalesVisualProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'show'>>();

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  const partition = useActivePartition(isCalendarDayPartition);

  const comparison = useMemo(() => {
    if (!route.params?.['chart-comparison-type']) return 'month';

    if (route.params['chart-comparison-type'] !== 'custom') {
      return route.params['chart-comparison-type'];
    }

    if (!route.params['custom-chart-comparison-partition']) return 'month';

    const parsedPartition = PartitionSerialiser.parse(
      route.params['custom-chart-comparison-partition']
    );

    if (!isCalendarDayPartition(parsedPartition)) return 'month';

    return parsedPartition;
  }, [route]);

  const { present, ...modal } = useModalControl();

  const requestChangeComparison = useCallback(() => present(), [present]);

  const onComparisonSelect = useCallback(
    (selection: TimeInterval | CalendarDayPartition) => {
      if (isPartition(selection)) {
        navigation.setParams({
          'chart-comparison-type': 'custom',
          'custom-chart-comparison-partition':
            PartitionSerialiser.stringify(selection)
        });

        return;
      }

      navigation.setParams({ 'chart-comparison-type': selection });
    },
    [navigation]
  );

  return (
    <View style={[styles.container, style]} {...rest}>
      <SalesCumeChart
        comparison={comparison}
        partition={partition}
        requestChangeComparison={requestChangeComparison}
      />

      <ComparisonModal
        defaultSelection={comparison}
        partition={partition}
        onSelect={onComparisonSelect}
        {...modal}
      />
    </View>
  );
};

export default DaySalesVisual;
