import {
  PressableProps,
  SectionListRenderItemInfo,
  ViewStyle
} from 'react-native';
import { useEffect, useMemo } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import PressableCell from '../../../../components/cells/PressableCell';
import { ForwardIcon } from '../../../../components/icons/ForwardIcon';
import { Text } from '../../../../components/text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { formatMoney } from '../../../../humanisers/formatMoney';
import SelectedArrow from '../../../../components/cells/SelectedArrow';
import {
  ProductListDepartmentData,
  ProductListRowData
} from '../../data/useProductListData';

export type ProductListItemProps = Omit<PressableProps, 'children'> &
  SectionListRenderItemInfo<ProductListRowData, ProductListDepartmentData> & {
    highlighted: boolean;
  };

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingLeft: 16,
    paddingRight: 12
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  selected: {
    backgroundColor: SemanticColours.primary.background['70']
  },
  title: {
    marginHorizontal: 6,
    flexWrap: 'wrap',
    flex: 1
  },
  subtitle: {
    textAlign: 'right',
    marginHorizontal: 6,
    flexShrink: 0
  },
  forwardIcon: {
    color: SemanticColours.primary.foreground[40],
    flexShrink: 0
  },
  arrow: {
    marginRight: -28,
    marginTop: -12,
    marginBottom: -12,
    marginLeft: 12
  }
});

const ProductListItem = ({
  style,
  item,
  highlighted,
  ...rest
}: ProductListItemProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  useEffect(() => {
    rest.separators.updateProps('leading', { inset: !highlighted });
    rest.separators.updateProps('trailing', { inset: !highlighted });
  }, [highlighted]);

  const priceStr = useMemo(() => {
    const [from, to] = item.priceRange;
    if (from === to) {
      return formatMoney(from);
    }

    return `${formatMoney(from)} - ${formatMoney(to)}`;
  }, [item.priceRange[0], item.priceRange[1]]);

  return (
    <PressableCell
      style={[styles.container, style as ViewStyle]}
      {...rest}
      highlighted={highlighted}
    >
      <SafeAreaView edges={['left', 'right']} style={styles.content}>
        <Text.CellTitle style={styles.title}>
          {item.name || 'No name'}
        </Text.CellTitle>
        <Text.CellSubtitle style={styles.subtitle}>
          {priceStr}
        </Text.CellSubtitle>
        {size === 'small' && (
          <ForwardIcon fill={styles.forwardIcon.color} width={18} height={18} />
        )}
      </SafeAreaView>
      {size !== 'small' && highlighted && (
        <SelectedArrow
          style={styles.arrow}
          fill={styles.selected.backgroundColor}
        />
      )}
    </PressableCell>
  );
};

export default ProductListItem;
