import React from 'react';
import { TextProps, Linking } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Text } from '../../../../components/text/Text';
import { usePromise } from '../../../../lib/use-promise/usePromise';
import PlatformPressable from '../../../../components/platform-pressable/PlatformPressable';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type ContactUsProps = Omit<TextProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  text: {
    color: SemanticColours.secondary.foreground[70]
  },
  underline: {
    textDecorationLine: 'underline',
    color: SemanticColours.secondary.foreground[90]
  }
});

const URL = 'https://serveplus.co.uk';

const ContactUs = React.memo<ContactUsProps>(({ style, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  const { loading, value: canOpen } = usePromise(Linking.canOpenURL(URL));

  return (
    <PlatformPressable
      onPress={() => {
        void Linking.openURL(URL);
      }}
    >
      <Text.DocBody style={[styles.text, style]} {...rest}>
        If you are new to Serveplus and interested in learning about how we can
        help your business, please{' '}
        <Text.Emphasis
          style={loading || canOpen ? styles.underline : undefined}
        >
          contact us
        </Text.Emphasis>
      </Text.DocBody>
    </PlatformPressable>
  );
});

export default ContactUs;
