import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';

const commandType = 'createProductGroup' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      name: string;
      departmentId: string;
    };
  }
}

export const createProductGroup = async (
  args: Omit<SiteCommands[typeof commandType], 'type' | 'departmentId'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const { departments } = ctx.databases.reference.local.repositories;
  const { name } = args;
  const { userId, createdAt } = ctx.meta;

  const result = await departments.createDocument({
    name,
    revisionTime: createdAt,
    revisionUserID: userId
  });

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      name,
      departmentId: result._id
    },
    [result._id]
  );

  return { type: 'SUCCESS', data: command };
};
