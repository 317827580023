import { ReactNode, createContext } from 'react';
import { useProductNameLookup } from '../../../data-model/components/site-context/useProductNameLookup';

export type ProductLookupCacheType = ReturnType<typeof useProductNameLookup>;

export const ProductLookupCache = createContext<ProductLookupCacheType | null>(
  null
);

const ProductLookupCacheProvider = ({
  children
}: {
  children?: ReactNode;
}): JSX.Element => {
  const value = useProductNameLookup();

  return (
    <ProductLookupCache.Provider value={value}>
      {children}
    </ProductLookupCache.Provider>
  );
};

export default ProductLookupCacheProvider;
