import { isPartition } from '../helpers/isPartition';
import { Partition } from '../types/Partition';
import { SerialisedPartition } from './DashboardNavigatorParamList';

export class PartitionSerialiser {
  static parse(value: SerialisedPartition): Partition | null {
    const a = value.split('-');

    const [type, ...maybeNumbers] = a;

    const maybePartition = [type, ...maybeNumbers.map(n => +n)];

    if (isPartition(maybePartition)) {
      return maybePartition;
    }

    return null;
  }

  static stringify(value: Partition): SerialisedPartition {
    return value.join('-');
  }
}
