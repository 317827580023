import {
  HeaderBackButton,
  HeaderBackButtonProps
} from '@react-navigation/elements';
import { useSizeClass } from '../../lib/responsive-size-class';
import { canDismiss } from '../../modules/navigation/dismissViewController';
import DismissBackButton from '../../entry-points/DismissBackButton';
import { DrawerToggle } from '../drawer-toggle/DrawerToggle';

export const HeaderNavigation = (
  headerProps: HeaderBackButtonProps
): JSX.Element => {
  const size = useSizeClass();

  if (headerProps.canGoBack) {
    return (
      <HeaderBackButton
        {...headerProps}
        {...(size === 'small' ? { label: 'Back' } : {})}
      />
    );
  }

  if (canDismiss()) {
    return <DismissBackButton {...headerProps} />;
  }

  if (size !== 'large') {
    return <DrawerToggle />;
  }

  return <></>;
};
