import { useEffect, useMemo, useState } from 'react';
import { DocumentProperties } from '../types/Document';
import {
  DatabaseView,
  GroupLevelOf,
  GroupLevelsFor,
  QueryOptions,
  QueryRow,
  ViewKey
} from '../schema/support/DatabaseView';
import { isRemoteDb } from '../helpers/isRemoteDb';
import { useLogger } from '../../lib/logging/useLogger';
import { nameof } from '../../lib/name-of';
import { GlobalActivity } from '../../lib/global-activity/GlobalActivity';

type UseQueryResult<
  TKey extends ViewKey,
  TValue,
  TDoc extends DocumentProperties,
  TOptions extends QueryOptions<
    TKey,
    GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
  >
> =
  | {
      readonly loading: true;
      readonly data: undefined;
      readonly error: Error | undefined;
    }
  | {
      readonly loading: false;
      readonly data: readonly QueryRow<TKey, TValue, TOptions, TDoc>[];
      readonly error: Error | undefined;
    };

export const useQueryStream = <
  TKey extends ViewKey,
  TValue,
  TDoc extends DocumentProperties,
  const TOptions extends QueryOptions<
    TKey,
    GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
  >
>(
  view: DatabaseView<TKey, TValue, TDoc>,
  options?: TOptions
): UseQueryResult<TKey, TValue, TDoc, TOptions> => {
  const log = useLogger(nameof({ useQueryStream }));

  const [data, setData] = useState<
    readonly QueryRow<TKey, TValue, TOptions, TDoc>[] | null
  >(null);

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setData(null);

    const activityEnrolment = isRemoteDb(view.database.pouch)
      ? GlobalActivity.enroll(
          `Running query ${view.database.pouch.name}/${view.designDocumentId}/${view.viewName}`
        )
      : undefined;

    const { dispose: end } = view.getQueryStream<TOptions>(
      options,
      newData => {
        setData(newData);
        setError(null);
        activityEnrolment?.dispose();
      },
      newError => {
        setError(newError);
        activityEnrolment?.dispose();
      }
    );

    return () => {
      end();

      // possible duplicate - doesn't matter
      activityEnrolment?.dispose();
    };
  }, [
    view.constructor.name,
    view.database.pouch.name,
    view.designDocumentId,
    view.viewName,
    JSON.stringify(options)
  ]);

  const result: UseQueryResult<TKey, TValue, TDoc, TOptions> = useMemo(() => {
    if (data === null) {
      return { loading: true, data: undefined, error: error ?? undefined };
    }

    return {
      loading: false,
      data,
      error: error ?? undefined
    };
  }, [data, error]);

  useEffect(() => {
    if (!error) return;

    log.error(error);
  }, [error]);

  return result;
};
