import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../theme/SemanticColours';

export type CellGroupSeparatorProps = Omit<ViewProps, 'children'> & {
  large?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    height: 18,
    backgroundColor: SemanticColours.primary.background[90]
  },
  large: { height: 24 }
}).override(Selectors.mediumOrLarge, {
  container: { height: 20 },
  large: { height: 30 }
});

const CellGroupSeparator = ({
  style,
  large,
  ...rest
}: CellGroupSeparatorProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, large && styles.large, style]} {...rest} />
  );
};

export default CellGroupSeparator;
