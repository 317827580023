import React, { useMemo } from 'react';
import { Text, View, ViewProps } from 'react-native';
import { DateTime, Duration } from 'luxon';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useCurrentDateTime } from '../../../../lib/use-current-time';

export type RelativeTimeProps = Omit<ViewProps, 'children'> & {
  time: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: SemanticColours.primary.foreground[20],
    flexDirection: 'row',
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 4
  },
  text: {
    color: SemanticColours.primary.foreground[100],
    textAlign: 'center',
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '400',
    fontVariant: ['tabular-nums'],
    letterSpacing: 1.5
  }
});

const RelativeTime = React.memo<RelativeTimeProps>(
  ({ style, time, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const currentTime = useCurrentDateTime(Duration.fromObject({ seconds: 1 }));

    const value = useMemo(() => {
      const timeSince = currentTime.diff(
        DateTime.fromISO(time, { zone: 'utc' }),
        ['minutes', 'seconds']
      );

      if (timeSince.minutes > 99) {
        return '>99m';
      }

      const { minutes } = timeSince;
      const seconds = Math.floor(timeSince.seconds);

      return [minutes, seconds]
        .map(n => n.toString().padStart(2, '0'))
        .join(':');
    }, [time, currentTime]);

    return (
      <View style={[styles.container, style]} {...rest}>
        <Text style={styles.text}>{value}</Text>
      </View>
    );
  }
);

export default RelativeTime;
