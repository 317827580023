import { ComponentType } from 'react';
import { ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import { Text } from '../text/Text';
import PressableCell, { PressableCellProps } from './PressableCell';

export type ButtonCellProps = PressableCellProps & {
  title: string;
  iconComponent?: ComponentType<SvgProps>;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 18
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    color: SemanticColours.primary.contrast[100],
    marginLeft: 11,
    fontWeight: '500'
  }
});

const ButtonCell = ({
  iconComponent: Icon,
  style,
  title,
  ...rest
}: ButtonCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PressableCell style={[styles.container, style as ViewStyle]} {...rest}>
      <SafeAreaView edges={['left', 'right']} style={styles.content}>
        {Icon && <Icon width={20} height={20} fill={styles.title.color} />}
        <Text.CellTitle style={styles.title}>{title}</Text.CellTitle>
      </SafeAreaView>
    </PressableCell>
  );
};

export default ButtonCell;
