import { ProductsRepository } from './repositories/ProductsRepository';
import { DesignDoc } from './design/DesignDoc';
import { DepartmentsRepository } from './repositories/DepartmentsRepository';
import { PriceBandsRepository } from './repositories/PriceBandsRepository';
import { ServingZonesRepository } from './repositories/ServingZonesRepository';
import { VouchersRepository } from './repositories/VoucherRepository';
import { Database } from '../../support/Database';
import { PreparationZonesRepository } from './repositories/PreparationZonesRepository';

export class ReferenceDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly design = new DesignDoc(this.database);

  public readonly repositories = {
    products: new ProductsRepository(this.database),
    departments: new DepartmentsRepository(this.database),
    preparationZones: new PreparationZonesRepository(this.database),
    priceBands: new PriceBandsRepository(this.database),
    servingZones: new ServingZonesRepository(this.database),
    vouchers: new VouchersRepository(this.database)
  };

  public async ensureSchemaApplied(): Promise<void> {
    await this.design.ensureDefined();
  }
}
