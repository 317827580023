import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { ReferenceDatabaseSchema } from './ReferenceDatabaseSchema';

export class ReferenceDatabase extends DatabasePair<ReferenceDatabaseSchema> {
  constructor(
    clientId: string,
    siteId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(
      `sp-${clientId}-${siteId}-reference`,
      ReferenceDatabaseSchema,
      queryConfig,
      syncConfig
    );
  }
}
