import { useCallback, useMemo, useState } from 'react';
import { ViewProps, ViewStyle } from 'react-native';
import { updateVariantPrice } from '../../commands/updateVariantPrice';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import BasicCell from '../../../../components/cells/BasicCell';
import AddIcon from '../../../../components/icons/AddIcon';
import { CloseIcon } from '../../../../components/icons/CloseIcon';
import { formatMoney } from '../../../../humanisers/formatMoney';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import EditPriceModal from '../modals/EditPriceModal';
import { useVariantDataContext } from '../../data/VariantDataContext';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { Alert } from '../../../../components/platform-alert/Alert';

export type EditScheduledPriceCellProps = ViewProps & {
  priceBandId: string;
  priceBandName: string;
  currentPrice: string;
  inherited: boolean;
  canInheritFromPriceBandName: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 12
  },
  text: {
    flexGrow: 1,
    marginRight: 20
  },
  icon: {
    color: SemanticColours.primary.foreground[35]
  }
});

const EditScheduledPriceCell = ({
  style,
  priceBandId,
  priceBandName,
  currentPrice,
  inherited,
  canInheritFromPriceBandName,
  ...rest
}: EditScheduledPriceCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { productId, variantId } = useVariantDataContext();

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const { invoke, inProgress } = useCommand(
    updateVariantPrice,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        dismissModal();
        return;
      }

      const errorMessage =
        result instanceof Error
          ? 'An unexpected error occurred.'
          : 'The product no longer exists.';

      Alert.alert(
        'Error',
        `Could not update the product's price.\n\n${errorMessage}`,
        [
          { text: 'Cancel', onPress: () => dismissModal() },
          { text: 'Retry', onPress: () => retry() }
        ]
      );
    }
  );

  const onSubmit = useCallback(
    (price: string) => {
      invoke({
        productId,
        variantId,
        priceBandId,
        newValue: { inherit: false, price }
      });
    },
    [productId, priceBandId]
  );

  const onRemove = useCallback(() => {
    Alert.alert(
      'Confirm',
      `Are you sure you want to remove the ${priceBandName} change?\n\nThe ${priceBandName} price will revert to the ${canInheritFromPriceBandName} price.`,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Remove',
          style: 'destructive',
          onPress: () =>
            invoke({
              productId,
              priceBandId,
              variantId,
              newValue: { inherit: true }
            })
        }
      ]
    );
  }, [priceBandName, productId, priceBandId]);

  const icons = useMemo(() => {
    return inherited
      ? [
          {
            component: AddIcon
          }
        ]
      : [
          {
            component: CloseIcon,
            onPress: onRemove
          }
        ];
  }, [inherited]);

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <>
      <BasicCell
        style={[styles.container, style as ViewStyle]}
        {...rest}
        disabled={inProgress}
        title={priceBandName || 'No name'}
        subtitle={inherited ? '' : formatMoney(+currentPrice * 100)}
        titlesOrientation="horizontal"
        {...(authorised
          ? { iconsRight: icons, onPress: () => setModalVisible(true) }
          : {})}
      />
      <EditPriceModal
        disabled={inProgress}
        initialPrice={currentPrice}
        onDismiss={dismissModal}
        onSubmit={onSubmit}
        title={`Edit ${priceBandName} price`}
        visible={modalVisible}
      />
    </>
  );
};

export default EditScheduledPriceCell;
