import React, { PropsWithChildren, useEffect, useState } from 'react';
import { DatabasePair } from '../../schema/support/DatabasePair';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { ServerContext } from '../server-context/ServerContext';
import { nameof } from '../../../lib/name-of';
import { SyncStatus } from '../../tools/DatabaseSync';
import HoldingScreen from '../../../components/holding-screen/HoldingScreen';
import { SyncWaiter } from '../../tools/SyncWaiter';
import { SitesDatabaseSchema } from '../../schema/databases/client-sites/SitesDatabaseSchema';
import { SitesDatabase } from '../../schema/databases/client-sites/SitesDatabase';
import { SecurityDatabaseSchema } from '../../schema/databases/client-security/SecurityDatabaseSchema';
import { SecurityDatabase } from '../../schema/databases/client-security/SecurityDatabase';
import { QueryAuthorisationContext } from '../../../security/context/QueryAuthorisationContext';
import { ReplicationAuthorisationContext } from '../../../security/context/ReplicationAuthorisationContext';

export type ClientContextType = {
  clientId: string;
  databases: {
    security: DatabasePair<SecurityDatabaseSchema>;
    sites: DatabasePair<SitesDatabaseSchema>;
  };
};

export type ClientContextProviderProps = PropsWithChildren<{
  clientId: string;
}>;

export const ClientContext = React.createContext<ClientContextType | null>(
  null
);

export const ClientContextProvider = ({
  clientId,
  children
}: ClientContextProviderProps): JSX.Element => {
  const [value, setValue] = useState<ClientContextType | null>(null);

  const { dbBaseUrl } = useContextOrThrow(
    ServerContext,
    nameof({ ServerContext })
  );

  const { authoriser: queryAuthoriser } = useContextOrThrow(
    QueryAuthorisationContext,
    nameof({ QueryAuthorisationContext })
  );

  const { authoriser: syncAuthoriser } = useContextOrThrow(
    ReplicationAuthorisationContext,
    nameof({ ReplicationAuthorisationContext })
  );

  useEffect(() => {
    const queryConfig = {
      dbBaseUrl,
      authoriser: queryAuthoriser
    };
    const syncConfig = {
      dbBaseUrl,
      authoriser: syncAuthoriser
    };

    const databases: ClientContextType['databases'] = {
      security: new SecurityDatabase(clientId, queryConfig, syncConfig),
      sites: new SitesDatabase(clientId, queryConfig, syncConfig)
    };

    const abort = new AbortController();

    void (async () => {
      await Promise.all([
        databases.security.configurePullReplication(),
        databases.sites.configurePullReplication()
      ]);

      await SyncWaiter.waitMany(
        [
          databases.security.pullReplication,
          databases.sites.pullReplication
        ].compact(),
        new Set<SyncStatus['state']>(['PAUSED', 'STOPPED', 'ERROR', 'DENIED']),
        abort
      );

      await Promise.all([databases.security.local.ensureSchemaApplied()]);

      if (abort.signal.aborted) return;

      setValue({
        clientId,
        databases
      });
    })();

    return () => {
      abort.abort();

      void Promise.all([
        databases.security.dispose(),
        databases.sites.dispose()
      ]);

      setValue(null);
    };
  }, [clientId, dbBaseUrl, queryAuthoriser, syncAuthoriser]);

  if (value === null) {
    return <HoldingScreen />;
  }

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};
