import React from 'react';
import { ViewProps } from 'react-native';
import Orig from '../../../../../components/empty-list-content/EmptyContent';
import WaitIcon from '../../../../../components/icons/WaitIcon';

export type EmptyContentProps = Omit<ViewProps, 'children'>;

const EmptyContent = React.memo<EmptyContentProps>(props => {
  return (
    <Orig
      title="No tasks"
      body="Tasks will appear here once there are new orders."
      iconComponent={WaitIcon}
      {...props}
    />
  );
});

export default EmptyContent;
