import { SyncDisposable } from '../../lib/disposable/SyncDisposable';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { newRandomUuid } from '../../lib/uuid';
import { DatabaseSync, SyncStatus } from './DatabaseSync';

export class SyncWaiter {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static async waitMany(
    syncs: DatabaseSync[],
    states: Set<SyncStatus['state']>,
    abortController?: AbortController
  ): Promise<boolean> {
    const result = await Promise.all(
      syncs.map(sync => SyncWaiter.wait(sync, states, abortController))
    );

    return result.every(e => e);
  }

  public static async wait(
    sync: DatabaseSync,
    states: Set<SyncStatus['state']>,
    abortController?: AbortController
  ): Promise<boolean> {
    const logger = createLogger(nameof({ SyncWaiter }), {
      invocationId: newRandomUuid()
    });

    logger.debug('Waiting for status', {
      states: [...states],
      database: sync.database.pouches.local.name,
      direction: sync.direction
    });

    const alreadySatisfied =
      sync.status.current?.state && states.has(sync.status.current.state);

    if (alreadySatisfied) {
      logger.info('Already satisfied');
      return Promise.resolve(true);
    }

    return new Promise<boolean>(resolve => {
      let statusSubscription: SyncDisposable;

      const onAbort = () => {
        logger.info('Aborted', { state: sync.status.current?.state });
        statusSubscription?.dispose();
        resolve(false);
      };

      abortController?.signal.addEventListener('abort', onAbort);

      statusSubscription = sync.status.subscribe(
        {
          next: ({ state }) => {
            if (states.has(state)) {
              logger.info('Satisfied', { state });

              abortController?.signal.removeEventListener('abort', onAbort);
              statusSubscription?.dispose();
              resolve(true);
            }
          }
        },
        { sendCurrentValue: true }
      );
    });
  }
}
