import React from 'react';
import { PressableProps, ViewStyle, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../theme/SemanticColours';
import EditIcon from '../../../components/icons/EditIcon';
import PlatformPressable from '../../../components/platform-pressable/PlatformPressable';

export type NoteProps = Omit<PressableProps, 'children'> & {
  text: string;
  editable?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderRadius: 7,
    backgroundColor: SemanticColours.primary.foreground[15],
    marginVertical: 1,
    marginHorizontal: 6
  },
  text: {
    color: SemanticColours.primary.foreground[85],
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '500',
    marginLeft: 8
  },
  icon: {
    color: SemanticColours.primary.foreground[40],
    width: 12,
    height: 12
  }
});

const Note = React.memo<NoteProps>(({ style, text, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PlatformPressable style={[styles.root, style as ViewStyle]} {...rest}>
      <EditIcon
        fill={styles.icon.color}
        width={styles.icon.width}
        height={styles.icon.height}
      />
      <Text style={styles.text}>{text}</Text>
    </PlatformPressable>
  );
});

export default Note;
