import { Selectors } from './Selectors';
import { DynamicStyleProperty } from './DynamicStyleProperty';

export const lightDarkColour =
  (light: string, dark: string): DynamicStyleProperty<string> =>
  () =>
    [
      { selector: Selectors.light, value: light },
      { selector: Selectors.dark, value: dark }
    ];
