import Svg, { SvgProps, Path } from 'react-native-svg';

const CollapseIcon = ({ fill = '#ffffff', ...rest }: SvgProps) => (
  <Svg viewBox="0 0 15 15" width={15} height={15} {...rest}>
    <Path
      d="M7.492 4.5a.5.5 0 0 0-.345.146l-5 5a.5.5 0 1 0 .707.707L7.5 5.708l4.646 4.647a.5.5 0 1 0 .708-.708l-5-5a.5.5 0 0 0-.362-.146Z"
      fill={fill}
    />
  </Svg>
);

export default CollapseIcon;
