import { Department } from '../../documents/Department';
import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const map = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('department-') === 0) {
    emit([!!doc.deleted ?? false, String(doc.name)], null);
  }
};

export class AllDepartmentsView extends DatabaseView<
  readonly [deleted: boolean, name: string],
  null,
  Department
> {
  static readonly viewName = 'allDepartments';

  static definition = {
    map: map.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllDepartmentsView.viewName);
  }
}
