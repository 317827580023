import React, { useMemo } from 'react';
import { ViewStyle } from 'react-native';
import CardColumn, {
  CardColumnProps
} from '../../../../../../components/card/CardColumn';
import BoundCard from '../../../../components/card/BoundCard';
import NoToDoContent from '../NoToDoContent';
import { flattenStyle } from '../../../../../../lib/dynamic-style-sheet/flattenStyle';
import BoundCardFooter from '../BoundCardFooter';
import Heading from '../../../../components/Heading';
import { useTasksData } from '../../../../data/TasksDataContext';
import { PreparationOrderStatus } from '../../../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';

export type ToDoColumnProps = Omit<CardColumnProps, 'data'> & {
  hideHeadings?: boolean;
};

const relevantStatuses = new Set<PreparationOrderStatus>([
  '01 Pending',
  '02 Released'
]);

const ToDoColumn = React.memo<ToDoColumnProps>(
  ({ style, hideHeadings, ...rest }) => {
    const ListHeaderComponent = useMemo(() => {
      const flattened = flattenStyle(style as object[]) as ViewStyle;

      return (
        <Heading
          label="Todo"
          style={
            flattened && 'backgroundColor' in flattened
              ? { backgroundColor: flattened.backgroundColor }
              : undefined
          }
        />
      );
    }, [style]);

    const { data: allTasks } = useTasksData();

    const data = useMemo(() => {
      const relevantTasks = allTasks.filter(t =>
        relevantStatuses.has(t.key[1])
      );

      if (relevantTasks.none()) {
        return {
          data: []
        };
      }

      return {
        data: relevantTasks
          .sortBy(r => r.key[2])
          .sortBy(r => r.key[1], 'desc')
          .map(v => ({
            key: v.documentID,
            element: (
              <BoundCard
                queryRow={v}
                expandable={v.key[1] === '01 Pending'}
                footerComponent={BoundCardFooter}
              />
            )
          }))
      };
    }, [allTasks]);

    return (
      <CardColumn
        style={style}
        {...rest}
        data={data}
        ListEmptyComponent={NoToDoContent}
        ListHeaderComponent={hideHeadings ? undefined : ListHeaderComponent}
      />
    );
  }
);

export default ToDoColumn;
