import qs from 'qs';
import { fetch } from 'cross-fetch';

export type ClientId = string;

export type ClientName = string;

export type ClientResponsePayload = { id: ClientId; name: ClientName };

export type SiteId = string;

export type AuthenticatedClientResponsePayload = ClientResponsePayload & {
  defaultSiteId: SiteId;
};

export type RequestValidationFailedBadRequestResponsePayload = {
  type: 'REQUEST_VALIDATION_FAILED';
  fields: Array<{ path: string; message: string }>;
};

export type SessionId = string;

export type Timestamp = string;

export type UserId = string;

export type Username = string;

export type Role =
  | 'role-4d913cfc-cf5d-424b-a673-36e3e9be37d9'
  | 'role-8f926bff-9868-4db6-bd9c-42ed7f86e2a4'
  | 'role-e23cd77c-1fb2-410c-add5-81e64f35daeb';

export type DeviceOwnedId = string;

export type DeviceRequestPayload = {
  deviceOwnedId: DeviceOwnedId;
  appVersion: string;
  deviceType: string;
  platformType: 'ios' | 'android' | 'windows' | 'macos' | 'web';
  systemVersion: string;
};

export type DeviceId = string;

export type DeviceResponsePayload = DeviceRequestPayload & { id: DeviceId };

export type SessionResponsePayload = {
  id: SessionId;
  clientId: ClientId;
  createdAt: Timestamp;
  expireAt?: Timestamp;
  lastRefreshAt?: Timestamp;
  userId: UserId;
  username: Username;
  fullName: string;
  role: Role;
  device: DeviceResponsePayload;
  canRemotelySignOut: boolean;
};

export type Password = string;

export type SessionRequestPayload = {
  username: Username;
  password: Password;
  device: DeviceRequestPayload;
};

export type SessionTokenResponsePayload = SessionResponsePayload & {
  token: string;
};

export type DeviceName = string;

export type SharedDeviceRequestPayload = {
  deviceOwnedId: DeviceOwnedId;
  publicKey: string;
  name: DeviceName;
};

export type SharedDeviceResponsePayload = { deviceId?: DeviceId };

type ResponseWithData<TStatus, TData> = {
  status: TStatus;
  body: TData;
};

type LogFn = (message: string, data: Record<string, unknown>) => void;

export class UsopApiClient {
  public constructor(
    public readonly baseUrl: string,
    private readonly logger?: LogFn
  ) {}

  public async getHealth(
    args: { authorization?: string },
    options?: RequestInit
  ): Promise<ResponseWithData<200, { ok?: boolean }>> {
    const { authorization } = args;

    const method = 'GET';
    const url = `${this.baseUrl}/api/health`;

    const response = await fetch(url, {
      method,
      headers: {
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {})
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 200:
        return {
          status: $status,
          body: (await response.json()) as { ok?: boolean }
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async getClientById(
    args: { xRequestId?: string; authorization?: string; clientId: ClientId },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<
        200,
        ClientResponsePayload | AuthenticatedClientResponsePayload
      >
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<404, undefined>
  > {
    const { xRequestId, authorization, clientId } = args;

    const method = 'GET';
    const url = `${this.baseUrl}/api/clients/${clientId}`;

    const response = await fetch(url, {
      method,
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {})
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 200:
        return {
          status: $status,
          body: (await response.json()) as
            | ClientResponsePayload
            | AuthenticatedClientResponsePayload
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 404:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async getSession(
    args: { xRequestId?: string; authorization?: string },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<200, SessionResponsePayload>
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<401, undefined>
  > {
    const { xRequestId, authorization } = args;

    const method = 'GET';
    const url = `${this.baseUrl}/api/session`;

    const response = await fetch(url, {
      method,
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {})
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 200:
        return {
          status: $status,
          body: (await response.json()) as SessionResponsePayload
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 401:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async deleteSession(
    args: { xRequestId?: string; authorization?: string },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<204, undefined>
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<401, undefined>
  > {
    const { xRequestId, authorization } = args;

    const method = 'DELETE';
    const url = `${this.baseUrl}/api/session`;

    const response = await fetch(url, {
      method,
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {})
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 204:
        return {
          status: $status,
          body: undefined
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 401:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async getSessions(
    args: {
      userId: UserId;
      xRequestId?: string;
      authorization?: string;
      clientId: ClientId;
    },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<200, Array<SessionResponsePayload>>
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<401, undefined>
    | ResponseWithData<403, undefined>
    | ResponseWithData<404, undefined>
  > {
    const { xRequestId, authorization, userId, clientId } = args;

    const query = qs.stringify({ ['userId']: userId });

    const method = 'GET';
    const url = `${this.baseUrl}/api/clients/${clientId}/sessions?${query}`;

    const response = await fetch(url, {
      method,
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {})
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 200:
        return {
          status: $status,
          body: (await response.json()) as Array<SessionResponsePayload>
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 401:
        return {
          status: $status,
          body: undefined
        };

      case 403:
        return {
          status: $status,
          body: undefined
        };

      case 404:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async postSession(
    args: {
      xRequestId?: string;
      authorization?: string;
      clientId: ClientId;
      body: SessionRequestPayload;
    },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<201, SessionTokenResponsePayload>
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<404, undefined>
  > {
    const { xRequestId, authorization, clientId, body } = args;

    const method = 'POST';
    const url = `${this.baseUrl}/api/clients/${clientId}/sessions`;

    const response = await fetch(url, {
      method,
      body: JSON.stringify(body),
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {}),
        'Content-Type': 'application/json'
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 201:
        return {
          status: $status,
          body: (await response.json()) as SessionTokenResponsePayload
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 404:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async SessionsdeleteSession(
    args: {
      xRequestId?: string;
      authorization?: string;
      clientId: ClientId;
      sessionId: SessionId;
    },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<204, undefined>
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<401, undefined>
    | ResponseWithData<403, undefined>
    | ResponseWithData<404, undefined>
  > {
    const { xRequestId, authorization, clientId, sessionId } = args;

    const method = 'DELETE';
    const url = `${this.baseUrl}/api/clients/${clientId}/sessions/${sessionId}`;

    const response = await fetch(url, {
      method,
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {})
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 204:
        return {
          status: $status,
          body: undefined
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 401:
        return {
          status: $status,
          body: undefined
        };

      case 403:
        return {
          status: $status,
          body: undefined
        };

      case 404:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }

  public async postSharedDevice(
    args: {
      xRequestId?: string;
      authorization?: string;
      clientId: ClientId;
      body: SharedDeviceRequestPayload;
    },
    options?: RequestInit
  ): Promise<
    | ResponseWithData<201, SharedDeviceResponsePayload>
    | ResponseWithData<400, RequestValidationFailedBadRequestResponsePayload>
    | ResponseWithData<403, undefined>
    | ResponseWithData<404, undefined>
    | ResponseWithData<409, undefined>
  > {
    const { xRequestId, authorization, clientId, body } = args;

    const method = 'POST';
    const url = `${this.baseUrl}/api/clients/${clientId}/shared-devices`;

    const response = await fetch(url, {
      method,
      body: JSON.stringify(body),
      headers: {
        ...(typeof xRequestId !== 'undefined' && xRequestId !== null
          ? { ['X-Request-Id']: xRequestId }
          : {}),
        ...(typeof authorization !== 'undefined' && authorization !== null
          ? { ['authorization']: authorization }
          : {}),
        'Content-Type': 'application/json'
      },
      ...options
    });

    const { status: $status } = response;

    this.logger?.('REST API Call', { method, url, status: $status });

    switch ($status) {
      case 201:
        return {
          status: $status,
          body: (await response.json()) as SharedDeviceResponsePayload
        };

      case 400:
        return {
          status: $status,
          body: (await response.json()) as RequestValidationFailedBadRequestResponsePayload
        };

      case 403:
        return {
          status: $status,
          body: undefined
        };

      case 404:
        return {
          status: $status,
          body: undefined
        };

      case 409:
        return {
          status: $status,
          body: undefined
        };

      default:
        throw new Error(`Unexpected status ${$status}`);
    }
  }
}
