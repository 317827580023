import { TupleToUnion } from '../ts-tuple/TupleToUnion';

export const withKeysRemoved = <T, K extends (keyof T)[]>(
  object: T,
  ...keys: K
): Omit<T, TupleToUnion<K>> => {
  const copy = { ...object };
  keys.forEach(key => {
    delete copy[key];
  });

  return copy;
};
