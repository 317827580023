import { DateTime } from 'luxon';
import { Partition } from '../types/Partition';
import { SiteTimeSettings } from '../types/SiteTimeSettings';
import { isIsoWeekPartition } from './isIsoWeekPartition';
import { isCalendarDayPartition } from './isCalendarDayPartition';
import { isCalendarMonthPartition } from './isCalendarMonthPartition';
import { isCalendarYearPartition } from './isCalendarYearPartition';
import { assertNever } from '../../../lib/assert-never';

export const partitionToDateTimeRange =
  (siteSettings: SiteTimeSettings) =>
  (partition: Partition): [DateTime, DateTime] => {
    const [hour, minute] = siteSettings.dayChangeTime.split(':').map(n => +n);

    const opts = { zone: siteSettings.timeZone };

    if (isCalendarDayPartition(partition)) {
      const start = DateTime.local(
        partition[1],
        partition[2],
        partition[3],
        hour,
        minute,
        opts
      );

      return [start.toUTC(), start.plus({ days: 1 }).toUTC()];
    }

    if (isIsoWeekPartition(partition)) {
      const start = DateTime.fromObject(
        {
          weekYear: partition[1],
          weekNumber: partition[2],
          weekday: 1,
          hour,
          minute
        },
        opts
      );

      return [start.toUTC(), start.plus({ weeks: 1 }).toUTC()];
    }

    if (isCalendarMonthPartition(partition)) {
      const start = DateTime.local(
        partition[1],
        partition[2],
        1,
        hour,
        minute,
        opts
      );

      return [start.toUTC(), start.plus({ months: 1 }).toUTC()];
    }

    if (isCalendarYearPartition(partition)) {
      const start = DateTime.local(partition[1], 1, 1, hour, minute, opts);

      return [start.toUTC(), start.plus({ years: 1 }).toUTC()];
    }

    return assertNever(partition);
  };
