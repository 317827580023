import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';
import { ComponentType, memo } from 'react';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { assertRouteParam } from '../../../../lib/assert-route-param';
import { PaymentPartitionNavigationProvider } from '../../components/partition-navigation-context/PaymentPartitionNavigationProvider';
import { DefaultPartitionNavigation } from '../../navigation/DefaultPartitionNavigation';
import Header from './components/header/Header';
import { PartitionNavigationBar } from '../../components/partition-navigation-bar/PartitionNavigationBar';
import {
  ActivePartitionContextProvider,
  useActivePartition
} from '../../components/active-partition-context/ActivePartitionContext';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import Summary from './components/summary/Summary';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import TransactionsList from './components/transaction-list/TransactionsList';
import SubIntervals from './components/sub-intervals/SubIntervalsList';
import { isCalendarDayPartition } from '../../helpers/isCalendarDayPartition';
import VerticalDivider from '../../../../components/vertical-divider/VerticalDivider';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type PaymentTypeScreenProps = StackScreenProps<
  DashboardNavigatorParamList,
  'payment-type'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'row',
    backgroundColor: SemanticColours.primary.background[90]
  },
  pane: {
    flex: 1
  }
}).override(Selectors.mediumOrLarge, {
  pane: {
    flexBasis: 350,
    flexGrow: 0,
    flexShrink: 0
  },
  rightPane: {
    flexGrow: 1
  }
});

const PaymentTypeScreenContent = memo(
  ({
    ListHeaderComponent
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ListHeaderComponent?: ComponentType<any>;
  }): JSX.Element => {
    const partition = useActivePartition();

    if (isCalendarDayPartition(partition)) {
      return <TransactionsList ListHeaderComponent={ListHeaderComponent} />;
    }

    return <SubIntervals ListHeaderComponent={ListHeaderComponent} />;
  }
);

export const PaymentTypeScreen = ({
  route
}: PaymentTypeScreenProps): JSX.Element => {
  const paymentTypeId = assertRouteParam(route.params, 'paymentTypeId');

  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  return (
    <ErrorBoundary>
      <PaymentPartitionNavigationProvider paymentId={paymentTypeId}>
        <DefaultPartitionNavigation />

        <ActivePartitionContextProvider>
          <Header paymentTypeId={paymentTypeId} />
          <PartitionNavigationBar />

          <View style={styles.container}>
            <View style={styles.pane}>
              {size !== 'small' && <Summary />}

              {size === 'small' && (
                <PaymentTypeScreenContent ListHeaderComponent={Summary} />
              )}
            </View>
            {size !== 'small' && (
              <>
                <VerticalDivider />
                <View style={styles.rightPane}>
                  <PaymentTypeScreenContent />
                </View>
              </>
            )}
          </View>
        </ActivePartitionContextProvider>
      </PaymentPartitionNavigationProvider>
    </ErrorBoundary>
  );
};
