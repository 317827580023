import { DateTime } from 'luxon';
import { assertNever } from '../../../lib/assert-never';
import { Partition } from '../types/Partition';

export const partitionToDate = (period: Partition): DateTime => {
  switch (period[0]) {
    case 'calendar': {
      switch (period.length) {
        case 2:
          return DateTime.utc(period[1], 1, 1);

        case 3:
          return DateTime.utc(period[1], period[2], 1);

        case 4:
          return DateTime.utc(period[1], period[2], period[3]);

        default:
          return assertNever(period);
      }
    }

    case 'iso': {
      return DateTime.fromObject(
        {
          weekYear: period[1],
          weekNumber: period[2]
        },
        { zone: 'utc' }
      );
    }

    default:
      return assertNever(period);
  }
};
