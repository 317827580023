import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { PreparationOrder } from '../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import { PreparationOrdersRepository } from '../../../data-model/schema/databases/site-preparation-orders/repositories/PreparationOrderRepository';
import { withKeysRemoved } from '../../../lib/remove-keys/removeKeys';

const commandType = 'undoStartPreparationOrder' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      preparationOrderId: string;
    };
  }
}

export const undoStartPreparationOrder = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'INVALID_STATE' }
> => {
  const { repository } = ctx.databases.preparationOrders.local;
  const { preparationOrderId } = args;
  const { userId, createdAt } = ctx.meta;

  const result = await repository.updateDocument<
    'INVALID_STATE',
    PreparationOrder
  >(preparationOrderId, (old, update, abort) => {
    if (old.status !== '03 In Progress') {
      return abort('INVALID_STATE');
    }

    // if this task has ever been released than return to that
    const undoToState =
      old.statusHistory?.find(s => s.status === '02 Released')?.status ??
      '01 Pending';

    const ID = PreparationOrdersRepository.nextStatusHistoryId();

    return update({
      ...old,
      status: undoToState,
      statuses: withKeysRemoved(old.statuses ?? {}, '03 In Progress'),
      statusHistory: [
        ...(old.statusHistory ?? []),
        {
          ID,
          status: undoToState,
          time: createdAt,
          userID: userId
        }
      ]
    });
  });

  if (result.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      preparationOrderId
    },
    [preparationOrderId]
  );

  return { type: 'SUCCESS', data: command };
};
