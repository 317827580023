import { UsopApiClient } from '../../api/generated/UsopApiClient.generated';
import { RoleId } from '../../data-model/schema/databases/client-security/documents/Role';
import { Logger } from '../../lib/logging';
import { AuthenticatedUser, User } from './types';
import { userAsUnauthenticated } from './userAsUnauthenticated';

export const verifyUser = async (
  user: AuthenticatedUser,
  serverUrl: string,
  log: Logger
): Promise<User | AuthenticatedUser> => {
  const api = new UsopApiClient(serverUrl);

  const userResult = await api.getSession({
    authorization: `Bearer ${user.token}`
  });

  if (userResult.status !== 200) {
    log.debug('User not verified', {
      user,
      result: userResult
    });

    return userAsUnauthenticated(user);
  }

  const {
    role,
    id: userSessionId,
    userId,
    username,
    fullName
  } = userResult.body;

  return {
    ...user,
    userId,
    username,
    fullName,
    role: role as RoleId,
    userSessionId
  };
};
