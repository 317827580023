import { ComponentType, forwardRef } from 'react';
import { View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colours } from '../../theme/Colours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import PlatformPressable from '../platform-pressable/PlatformPressable';
import { PlatformPressableProps } from '../platform-pressable/PlatformPressableProps';

const rStyles = DynamicStyleSheet.create({
  button: {
    backgroundColor: Colours.transparent.$,
    borderRadius: 10,
    padding: 8,
    marginVertical: -4,
    alignItems: 'center',
    justifyContent: 'center'
  },
  disabled: {
    opacity: 0.5
  },
  icon: {
    color: SemanticColours.primary.foreground[100]
  }
});

export type IconButtonProps = Omit<PlatformPressableProps, 'children'> & {
  iconColour?: string;
  iconComponent: ComponentType<SvgProps>;
  iconSize?: number;
};

export const IconButton = forwardRef<View, IconButtonProps>(
  (
    {
      style,
      iconComponent: Icon,
      disabled,
      iconColour,
      iconSize = 24,
      ...rest
    },
    ref
  ): JSX.Element => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <PlatformPressable
        ref={ref}
        accessibilityRole="button"
        style={[styles.button, style as ViewStyle]}
        disabled={disabled}
        {...rest}
      >
        <Icon
          style={[disabled && styles.disabled]}
          fill={iconColour ?? styles.icon.color}
          width={iconSize}
          height={iconSize}
        />
      </PlatformPressable>
    );
  }
);
