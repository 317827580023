import { CalendarDayPartition } from '../../../data-model/schema/databases/site-transactions-archive/design/Partitions';

export const isCalendarDayPartition = (
  value: unknown
): value is CalendarDayPartition => {
  if (!value || !(typeof value === 'object') || !Array.isArray(value)) {
    return false;
  }

  return value[0] === 'calendar' && value.length === 4;
};
