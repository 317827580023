import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import TimelineItem from './TimelineItem';

export type TimelineProps = Omit<ViewProps, 'children'> & {
  points: {
    label: string;
    time?: string;
  }[];
};

const rStyles = DynamicStyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
});

const Timeline = React.memo<TimelineProps>(({ style, points, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.root, style]} {...rest}>
      {points.map(({ label, time }, index) => {
        const completed = !!time;
        const nextCompleted = !!points[index + 1]?.time;
        const isFirst = index === 0;
        const isLast = index === points.length - 1;

        return (
          <TimelineItem
            key={label}
            completed={completed}
            nextCompleted={nextCompleted}
            label={label}
            time={time}
            isFirst={isFirst}
            isLast={isLast}
          />
        );
      })}
    </View>
  );
});

export default Timeline;
