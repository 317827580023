import { SyncDisposable } from './SyncDisposable';

export class SyncDisposeBag implements SyncDisposable {
  constructor(...initialContent: SyncDisposable[]) {
    this.content.push(...initialContent);
  }

  private content: SyncDisposable[] = [];

  public add(disposable: SyncDisposable) {
    this.content.push(disposable);
  }
  public dispose(): void {
    this.content.forEach(i => i.dispose());
    this.content = [];
  }
}
