import { Formik } from 'formik';
import { ModalProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../../../../components/text-field';
import { formatMoney } from '../../../../humanisers/formatMoney';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';

export type EditPriceModalProps = Omit<
  ModalProps,
  'children' | 'onRequestClose'
> & {
  disabled: boolean;
  initialPrice: string;
  onSubmit: (newPrice: string) => void;
  title: string;
};

type FormValues = {
  price: string;
};

const validate = ({ price }: FormValues) => {
  if (!price) {
    return {
      price: 'Enter a price'
    };
  }

  if (!/^-?\d+(\.\d{0,2})?$/.test(price)) {
    return {
      price: 'Enter a valid price'
    };
  }

  return {};
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  }
});

const EditPriceModal = ({
  disabled,
  initialPrice,
  onDismiss,
  onSubmit,
  title,
  ...rest
}: EditPriceModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PlatformModal onRequestClose={onDismiss} {...rest} hugsContent>
      <KeyboardDismissView style={styles.container}>
        <Formik
          initialValues={{
            price: formatMoney(+initialPrice * 100, false)
          }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={({ price }) => onSubmit(price)}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title={title}
                onCross={onDismiss}
                onTick={handleSubmit}
                tickEnabled={!disabled}
              />
              <TextField
                {...formProps}
                autoComplete="off"
                autoFocus
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView edges={['left', 'right']} {...props} />
                )}
                editable={!disabled}
                enablesReturnKeyAutomatically
                inputStyle={styles.field}
                keyboardType="numbers-and-punctuation"
                name={'price'}
                onSubmitEditing={() => handleSubmit()}
                placeholder="Price"
                returnKeyType="done"
              />
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default EditPriceModal;
