import { ViewProps } from 'react-native';
import { ComponentType } from 'react';
import { SvgProps } from 'react-native-svg';
import {
  DrawerNavigationState,
  ParamListBase,
  useLinkProps
} from '@react-navigation/native';
import { NavNavigatorParamList } from '../../NavNavigatorParamList';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { Text } from '../../../../components/text/Text';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { Button } from '../button/Button';

export type ItemProps = Omit<ViewProps, 'children'> & {
  screen: keyof NavNavigatorParamList;
  title: string;
  icon: ComponentType<SvgProps>;
  state: DrawerNavigationState<ParamListBase>;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    opacity: 0.7,
    borderRadius: 6
  },
  active: {
    backgroundColor: SemanticColours.primary.foreground[15]
  },
  item: {
    marginHorizontal: 8
  },
  icon: {
    color: SemanticColours.primary.foreground[65],
    width: 22,
    height: 22
  },
  text: {
    color: SemanticColours.primary.foreground[95],
    flexGrow: 1
  }
}).override(Selectors.android, { container: { borderRadius: 1 } });

const Item = ({
  screen,
  style,
  title,
  icon: Icon,
  state,
  ...rest
}: ItemProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const props = useLinkProps({
    to: { screen: 'nav', params: { screen, options: {} } }
  });

  return (
    <Button
      style={[
        style,
        styles.container,
        state.routeNames[state.index] === screen && styles.active
      ]}
      accessibilityLabel={title}
      {...rest}
      {...props}
    >
      <Icon
        style={styles.item}
        fill={styles.icon.color}
        width={styles.icon.width}
        height={styles.icon.height}
      />
      <Text.Navigation1 style={[styles.item, styles.text]}>
        {title}
      </Text.Navigation1>
    </Button>
  );
};

export default Item;
