import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { withKeysRemoved } from '../../../lib/remove-keys/removeKeys';
import { arraysAreShallowEqual } from '../../../helpers/arraysAreShallowEqual';
import { usePreparationOrdersDb } from '../site-context/usePreparationOrdersDb';

export type PullerContextValue = {
  registerIdsForKey: (key: string, ids: string[]) => void;
  releaseKey: (key: string) => void;
};

export const PullerContext = createContext<PullerContextValue | null>(null);

export const PullerContextProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, setState] = useState<Record<string, string[]>>({});

  const registerIdsForKey: PullerContextValue['registerIdsForKey'] =
    useCallback((key, ids) => {
      setState(prev => ({ ...prev, [key]: ids }));
    }, []);

  const releaseKey: PullerContextValue['releaseKey'] = useCallback(key => {
    setState(prev => withKeysRemoved(prev, key));
  }, []);

  const value: PullerContextValue = useMemo(
    () => ({
      registerIdsForKey,
      releaseKey
    }),
    [registerIdsForKey, releaseKey]
  );

  const [ids, setIds] = useState<string[]>([]);

  useEffect(() => {
    const newIds = Object.values(state).flat().distinct().sort();

    if (!arraysAreShallowEqual(ids, newIds)) {
      setIds(newIds);
    }
  }, [state]);

  const db = usePreparationOrdersDb();

  useEffect(() => {
    void (async () => {
      if (ids.none()) {
        await db.cancelPullReplication();
      } else {
        await db.configurePullReplication({ doc_ids: ids });
      }
    })();
  }, [db, ids]);

  return (
    <PullerContext.Provider value={value}>{children}</PullerContext.Provider>
  );
};
