import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import { useTransactionsDb } from '../../../../data-model/components/site-context/useTransactionsDb';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { NotFoundContent } from '../../../../components/unhappy-path-content/not-found/NotFoundContent';
import Header from '../../components/header/Header';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import TransactionSummary from './components/transaction-summary/TransactionSummary';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import TransactionLinesList from './components/transaction-lines/TransactionLinesList';
import VerticalDivider from '../../../../components/vertical-divider/VerticalDivider';
import DeleteTransactionMenuOption from './components/delete/DeleteTransactionMenuOption';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type TransactionScreenProps = StackScreenProps<
  DashboardNavigatorParamList,
  'transaction'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'row',
    backgroundColor: SemanticColours.primary.background[90]
  },
  pane: {
    flex: 1
  }
}).override(Selectors.mediumOrLarge, {
  pane: {
    flexBasis: 400,
    flexGrow: 0,
    flexShrink: 0,
    paddingVertical: 16
  },
  rightPane: {
    flexGrow: 1
  }
});

const TransactionScreen = ({
  route,
  navigation
}: TransactionScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  if (!route.params?.transactionId) {
    throw new Error('Transaction id is required');
  }

  const transactionsDb = useTransactionsDb();

  const { document } = useDocument(
    transactionsDb.remote.repository,
    route.params.transactionId
  );

  const size = useSizeClass();

  const isFocussed = useIsFocused();

  return document === null ? (
    <NotFoundContent objectDescription="transaction" />
  ) : (
    <ErrorBoundary>
      <Header title="Transaction" />

      {isFocussed && (
        <DeleteTransactionMenuOption
          transactionId={route.params.transactionId}
          onTransactionDeleted={() => navigation.goBack()}
        />
      )}

      <View style={styles.container}>
        <View style={styles.pane}>
          {size !== 'small' && <TransactionSummary />}

          {size === 'small' && (
            <TransactionLinesList ListHeaderComponent={TransactionSummary} />
          )}
        </View>
        {size !== 'small' && (
          <>
            <VerticalDivider />
            <View style={styles.rightPane}>
              <TransactionLinesList />
            </View>
          </>
        )}
      </View>
    </ErrorBoundary>
  );
};

export default TransactionScreen;
