import { View, ViewProps, ViewStyle } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import TableNumber from './TableNumber';
import Course from './Course';
import RelativeTime from './RelativeTime';
import AbsoluteTime from './AbsoluteTime';

export type CardHeaderLayoutProps = Omit<ViewProps, 'children'> & {
  layout: 'active' | 'inactive';
  course?: string;
  time: string;
  tableNumber?: string;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1,
    height: 58,
    paddingVertical: 16
  },
  left: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    left: 20
  },
  right: {
    position: 'absolute',
    right: 20
  }
});

const CardHeaderLayout = ({
  style,
  course,
  time,
  tableNumber,
  layout,
  ...rest
}: CardHeaderLayoutProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.root, style as ViewStyle]} {...rest}>
      {tableNumber && (
        <TableNumber tableNumber={tableNumber} style={styles.left} />
      )}

      {layout === 'active' ? (
        <RelativeTime time={time} />
      ) : (
        <AbsoluteTime time={time} />
      )}

      {course && +course > 0 && <Course course={course} style={styles.right} />}
    </View>
  );
};

export default CardHeaderLayout;
