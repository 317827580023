import { View, ViewProps } from 'react-native';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Formik, FormikErrors } from 'formik';
import { TextInput } from 'react-native-gesture-handler';
import { useUsopApi } from '../../../api/useUsopApi';
import { DeviceInfoService } from '../../../services/device-info/DeviceInfoService';
import ConfirmHeader from '../../../features/onboarding/components/ConfirmHeader';
import { Normalisers, TextField } from '../../../components/text-field';
import { nameof } from '../../../lib/name-of';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { PasswordTextField } from '../../../components/password-text-field';
import { SessionTokenResponsePayload } from '../../../api/generated/UsopApiClient.generated';
import ClientCell from './ClientCell';

export type LoginFormProps = Omit<ViewProps, 'children'> & {
  clientId: string;
  clientName: string;
  defaultUsername?: string;
  onRequestClientChange?: () => void;
  onSuccess: (payload: SessionTokenResponsePayload) => void;
  disabled?: boolean;
};

type FormValues = {
  username: string;
  password: string;
};

const rStyles = DynamicStyleSheet.create({
  form: {
    paddingVertical: 10
  },
  field: {
    marginVertical: 10
  }
});

const LoginForm = ({
  clientId,
  clientName,
  defaultUsername,
  onSuccess,
  onRequestClientChange,
  style,
  disabled,
  ...rest
}: LoginFormProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { inProgress, result, call } = useUsopApi('postSession');

  const validate = useCallback(
    ({ username, password }: FormValues): FormikErrors<FormValues> => {
      if (!username.trim()) {
        return { username: 'Required' };
      }

      if (!password.trim()) {
        return { password: 'Required' };
      }

      // from OpenAPI
      if (!/^[a-z0-9_]{3,39}$/.test(username.trim().toLowerCase())) {
        return {
          username: "This doesn't look valid"
        };
      }

      return {};
    },
    []
  );

  const onSubmit = useCallback(
    async ({ username, password }: FormValues) => {
      const device = await DeviceInfoService.get();
      call({
        clientId,
        body: { username: username.trim().toLowerCase(), password, device }
      });
    },
    [call, clientId]
  );

  const errorMessage = useMemo(() => {
    if (!result || inProgress) {
      return undefined;
    }

    if (!result.success) {
      if (result.reason === 'OFFLINE_NO_CACHE') {
        return 'You are currently offline. You need an active internet connection to login.';
      }

      return 'An unexpected error occurred, please try again.';
    }

    switch (result.data.response.status) {
      case 400:
        return 'Username or password is incorrect. Check and try again.';
      case 201:
        return undefined;
      default:
        return 'An unexpected error occurred, please try again.';
    }
  }, [result, inProgress]);

  useEffect(() => {
    if (result?.success && result.data.response.status === 201) {
      onSuccess(result.data.response.body);
    }
  }, [result]);

  const passwordRef = useRef<TextInput>(null);

  return (
    <View style={[style, styles.form]} {...rest}>
      <Formik
        initialValues={{ username: defaultUsername ?? '', password: '' }}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, ...formProps }) => (
          <>
            <ConfirmHeader
              disabled={inProgress || disabled}
              onPress={() => handleSubmit()}
            />
            <ClientCell
              clientName={clientName}
              style={styles.field}
              onPress={onRequestClientChange}
              disabled={inProgress || disabled}
            />
            <TextField
              {...formProps}
              autoCapitalize="none"
              autoCorrect={false}
              autoFocus={typeof defaultUsername === 'undefined'}
              clearButtonMode="while-editing"
              secondaryColourScheme
              editable={!inProgress && !disabled}
              enablesReturnKeyAutomatically
              name={nameof<FormValues>('username')}
              normaliser={[Normalisers.trim, Normalisers.lowercase]}
              onSubmitEditing={() => passwordRef.current?.focus()}
              placeholder="username"
              returnKeyType="next"
              style={styles.field}
            />
            <PasswordTextField
              {...formProps}
              autoFocus={!!defaultUsername}
              secondaryColourScheme
              editable={!inProgress && !disabled}
              onSubmitEditing={() => handleSubmit()}
              ref={passwordRef}
              style={styles.field}
              errorMessage={errorMessage}
            />
          </>
        )}
      </Formik>
    </View>
  );
};

export default LoginForm;
