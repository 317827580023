import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { createProductGroup } from '../../commands/createProductGroup';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../../../../components/text-field';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import { useReferenceDb } from '../../../../data-model/components/site-context/useReferenceDb';
import { useQueryStream } from '../../../../data-model/hooks/useQueryStream';
import { assertNever } from '../../../../lib/assert-never';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';

export type CreateGroupModalProps = Omit<ViewProps, 'children'> & {
  onGroupCreated?: (departmentId: string) => void;
  dismiss: () => void;
  visible: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

const CreateGroupModal = ({
  style,
  dismiss,
  visible,
  onGroupCreated,
  ...rest
}: CreateGroupModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { data: departmentData } = useQueryStream(
    useReferenceDb().local.design.views.allDepartments
  );

  const validate = useCallback(
    ({ value: newValue }: { value: string }) => {
      if (!newValue.trim()) {
        return { value: 'Name is required' };
      }

      const trimmed = newValue.trim();

      const existing = departmentData?.find(
        d =>
          !d.key[0] &&
          trimmed.localeCompare(d.key[1].trim(), undefined, {
            sensitivity: 'base'
          }) === 0
      );

      if (existing) {
        return { value: 'Name is already in use' };
      }

      return {};
    },
    [departmentData]
  );

  const [commandError, setCommandError] = useState<string | null>(null);

  const { invoke, inProgress, reset } = useCommand(
    createProductGroup,
    result => {
      if (result instanceof Error) {
        setCommandError('An unexpected error occurred.');
        return;
      }

      if (result.type === 'UNAUTHORISED') {
        setCommandError('You are not authorised to make this change.');
        return;
      }

      if (result.type === 'SUCCESS') {
        onGroupCreated?.(result.data.departmentId);
        dismiss();
        return;
      }

      assertNever(result);
    }
  );

  useEffect(() => {
    if (!visible) {
      reset();
      setCommandError(null);
    }
  }, [visible, reset]);

  const onSubmit = useCallback(
    ({ value: name }: { value: string }) => {
      setCommandError(null);
      invoke({ name: name.trim() });
    },
    [invoke]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView style={[styles.container, style]} {...rest}>
        <Formik
          initialValues={{ value: '' }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title="New group"
                onCross={dismiss}
                onTick={handleSubmit}
              />
              <TextField
                {...formProps}
                autoCapitalize="words"
                autoCorrect
                autoFocus
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                enablesReturnKeyAutomatically
                editable={!inProgress}
                inputStyle={styles.field}
                name={'value'}
                onSubmitEditing={() => handleSubmit()}
                placeholder="Group name"
                returnKeyType="done"
              />
              {commandError && (
                <ErrorMessage style={styles.error}>{commandError}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default CreateGroupModal;
