import { ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type ModalHeaderProps = ViewProps;

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.secondary.background[100],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8
  }
});

const ModalHeader = ({ style, ...rest }: ModalHeaderProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <SafeAreaView
      style={[styles.container, style]}
      edges={['left', 'top', 'right']}
      accessibilityRole="header"
      {...rest}
    />
  );
};

export default ModalHeader;
