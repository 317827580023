import { AsyncDisposable } from './AsyncDisposable';

export class AsyncDisposeBag implements AsyncDisposable {
  constructor(...initialContent: AsyncDisposable[]) {
    this.content.push(...initialContent);
  }

  private content: AsyncDisposable[] = [];

  public add(fn: AsyncDisposable) {
    this.content.push(fn);
  }
  async dispose(): Promise<void> {
    await Promise.all(this.content.map(i => i.dispose()));
    this.content = [];
  }
}
