import { createStackNavigator } from '@react-navigation/stack';
import { OnboardingNavigatorParamList } from './OnboardingNavigatorParamList';
import { SemanticColours } from '../../../theme/SemanticColours';
import { WelcomeScreen } from '../screens/welcome/WelcomeScreen';
import LoginScreen from '../screens/login/LoginScreen';
import { OnboardingProgressContextProvider } from '../components/progress-context/ProgressContext';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';

const Stack = createStackNavigator<OnboardingNavigatorParamList>();

const rStyles = DynamicStyleSheet.create({
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

export const OnboardingNavigator = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <OnboardingProgressContextProvider>
      <Stack.Navigator
        screenOptions={{
          headerTintColor: styles.headerTint.color,
          headerStyle: styles.header
        }}
      >
        <Stack.Screen
          name="welcome"
          component={WelcomeScreen}
          options={{ title: 'Device setup' }}
        />

        <Stack.Screen
          name="login"
          component={LoginScreen}
          options={{ title: 'Login' }}
        />
      </Stack.Navigator>
    </OnboardingProgressContextProvider>
  );
};
