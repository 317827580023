import { DateTime, Interval } from 'luxon';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { partitionToDate } from '../../../../helpers/partitionToDate';
import { TimeInterval } from '../../../../types/TimeInterval';

const weekDayShift = (weekday: number, startOn: number): number => {
  return weekday >= startOn ? weekday - startOn : weekday + 7 - startOn;
};

const payDayOfMonth = (month: DateTime): DateTime => {
  const startOfNextMonth = month.plus({ months: 1 }).startOf('month');

  return startOfNextMonth.minus({
    days: weekDayShift(startOfNextMonth.weekday, 5)
  });
};

const distanceFromPayDay = (date: DateTime): [number, number] => {
  const next = payDayOfMonth(date.startOf('month'));
  const last = payDayOfMonth(date.minus({ months: 1 }).startOf('month'));

  return [
    Interval.fromDateTimes(last, date).length('days'),
    Interval.fromDateTimes(date, next).length('days')
  ];
};

export const partitionForComparison = (
  partition: CalendarDayPartition,
  comparison: TimeInterval | CalendarDayPartition
): CalendarDayPartition => {
  const fromDt = ({ year, month, day }: DateTime): CalendarDayPartition => [
    'calendar',
    year,
    month,
    day
  ];

  if (comparison === 'day') {
    return fromDt(partitionToDate(partition).minus({ days: 1 }));
  }

  if (comparison === 'week') {
    return fromDt(partitionToDate(partition).minus({ weeks: 1 }));
  }

  if (comparison === 'month') {
    const date = partitionToDate(partition);
    const [fromLast, toNext] = distanceFromPayDay(date);

    if (toNext < fromLast) {
      // second half of pay month
      const lastPayday = payDayOfMonth(
        date.minus({ months: 1 }).startOf('month')
      );
      return fromDt(lastPayday.minus({ days: toNext }));
    }

    // first half
    const paydayBeforeLast = payDayOfMonth(
      date.minus({ months: 2 }).startOf('month')
    );
    return fromDt(paydayBeforeLast.plus({ days: fromLast }));
  }

  if (comparison === 'year') {
    const date = partitionToDate(partition);
    const oneYearAgo = date.minus({ years: 1 });

    const crossesLeapYear =
      Interval.fromDateTimes(oneYearAgo, date).length('days') === 366;

    return fromDt(oneYearAgo.plus({ days: crossesLeapYear ? 2 : 1 }));
  }

  return comparison;
};
