import React from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import CardWall, {
  CardWallData,
  CardWallProps
} from '../../../../../components/card/CardWall';
import { PassNavigatorParamList } from '../../../navigation/PassNavigatorParamList';
import { assertRouteParam } from '../../../../../lib/assert-route-param';
import { usePreparationOrdersDb } from '../../../../../data-model/components/site-context/usePreparationOrdersDb';
import { useTransform } from '../../../../../data-model/hooks/useTransform';
import { useQueryStream } from '../../../../../data-model/hooks/useQueryStream';
import BoundCard from '../../../components/card/BoundCard';
import BoundCardFooter from './BoundCardFooter';
import EmptyContent from './EmptyContent';

export type TasksWallProps = Omit<CardWallProps, 'data'> & {
  expandable: boolean;
};

const skeleton: CardWallData = [];

const TasksWall = React.memo<TasksWallProps>(
  ({ numColumns, expandable, ...rest }) => {
    const route = useRoute<RouteProp<PassNavigatorParamList, 'tasks'>>();
    const zoneId = assertRouteParam(route.params, 'prepZoneId');

    const db = usePreparationOrdersDb();

    const { data } = useTransform(
      [
        useQueryStream(db.local.design.summaryByStatus.views.main, {
          startKey: [zoneId, '01 Pending'],
          endKey: [zoneId, '04 Ready', []]
        })
      ],
      rows =>
        rows.flat().map(v => ({
          key: v.documentID,
          element: (
            <BoundCard
              queryRow={v}
              footerComponent={BoundCardFooter}
              expandable={expandable}
            />
          )
        })),
      () => skeleton
    );

    return (
      <CardWall
        {...rest}
        numColumns={numColumns}
        data={data}
        ListEmptyComponent={EmptyContent}
      />
    );
  }
);

export default TasksWall;
