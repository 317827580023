import { PropsWithChildren, createContext, useMemo } from 'react';
import { nameof } from '../../../lib/name-of';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { useReferenceDb } from '../../../data-model/components/site-context/useReferenceDb';
import { NotFoundContent } from '../../../components/unhappy-path-content/not-found/NotFoundContent';
import { useDocument } from '../../../data-model/hooks/useDocument';
import { ProductV3 } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

const NotFound = null;

type ProductDataContextValue = {
  productId: string;
} & (
  | {
      loading: true;
      product: undefined;
    }
  | {
      loading: false;
      product: ProductV3;
    }
);

export const ProductDataContext = createContext<ProductDataContextValue | null>(
  null
);

export type ProductDataContextProviderProps = PropsWithChildren<{
  productId: string;
}>;

export const ProductDataContextProvider = ({
  productId,
  children
}: ProductDataContextProviderProps): JSX.Element => {
  const data = useDocument(
    useReferenceDb().local.repositories.products,
    productId
  );

  const value: ProductDataContextValue | typeof NotFound = useMemo(() => {
    if (data.document === null) {
      return NotFound;
    }

    if (data.loading) {
      return { loading: true, product: undefined, productId };
    }

    const asV3 = convertProductToV3(data.document);

    if (asV3 === 'N/A') {
      return NotFound;
    }

    return { loading: false, product: asV3, productId };
  }, [data]);

  if (value === NotFound) {
    return <NotFoundContent objectDescription="product" />;
  }

  return (
    <ProductDataContext.Provider value={value}>
      {children}
    </ProductDataContext.Provider>
  );
};

export const useProductDataContext = () =>
  useContextOrThrow(ProductDataContext, nameof({ ProductDataContext }));
