import { dateTimeToReportDate } from '../features/dashboard/helpers/dateTimeToReportDate';
import { isCalendarDayPartition } from '../features/dashboard/helpers/isCalendarDayPartition';
import { isCalendarMonthPartition } from '../features/dashboard/helpers/isCalendarMonthPartition';
import { isCalendarYearPartition } from '../features/dashboard/helpers/isCalendarYearPartition';
import { isIsoWeekPartition } from '../features/dashboard/helpers/isIsoWeekPartition';
import { partitionToDate } from '../features/dashboard/helpers/partitionToDate';
import { Partition } from '../features/dashboard/types/Partition';
import { SiteTimeSettings } from '../features/dashboard/types/SiteTimeSettings';
import { assertNever } from '../lib/assert-never';

export const formatPartition =
  (siteSettings: SiteTimeSettings) =>
  (partition: Partition, options?: Intl.DateTimeFormatOptions): string => {
    const now = dateTimeToReportDate(siteSettings)();

    const input = partitionToDate(partition);

    const sameYear = now.year === input.year;
    const sameMonth = sameYear && now.month === input.month;
    const sameDay = sameYear && sameMonth && now.day === input.day;

    const sameWeek =
      now.weekYear === input.weekYear && now.weekNumber === input.weekNumber;

    if (isCalendarDayPartition(partition)) {
      if (sameDay && !options) return 'Today';

      return input.toLocaleString({
        weekday: sameWeek || sameMonth ? 'long' : 'short',
        day: 'numeric',
        month: 'numeric',
        year: sameYear ? undefined : 'numeric',
        ...options
      });
    }

    if (isIsoWeekPartition(partition)) {
      if (sameWeek) return 'This week';

      const wc = input.toLocaleString({
        day: 'numeric',
        month: 'numeric',
        year: sameYear ? undefined : '2-digit',
        ...options
      });

      return `W${input.weekNumber} (${wc})`;
    }

    if (isCalendarMonthPartition(partition)) {
      return input.toLocaleString({
        month: 'long',
        year: sameYear ? undefined : 'numeric',
        ...options
      });
    }

    if (isCalendarYearPartition(partition)) {
      return String(input.year);
    }

    return assertNever(partition);
  };
