import React, { useMemo } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { DateTime } from 'luxon';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../../../components/cells/LegacyCellGroup';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { partitionToSubPartitions } from '../../../../helpers/partitionToSubPartitions';
import { viewKeysAreEqual } from '../../../../../../data-model/helpers/viewKeysAreEqual';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { formatPartition } from '../../../../../../humanisers/formatPartition';
import { partitionToComponentKey } from '../../../../helpers/partitionToComponentKey';
import { sortViewKeys } from '../../../../../../data-model/helpers/sortViewKeys';
import { isCalendarMonthPartition } from '../../../../helpers/isCalendarMonthPartition';
import thenThrow from '../../../../../../lib/then-throw';
import { partitionToDateTimeRange } from '../../../../helpers/partitionToDateTimeRange';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';

export type MonthDaysCellGroupProps = Omit<LegacyCellGroupProps, 'children'>;

const MonthDaysCellGroup = (props: MonthDaysCellGroupProps): JSX.Element => {
  const partition = useActivePartition(isCalendarMonthPartition);

  const siteSettings = useSiteTime();

  const weeks = useMemo(() => {
    const allWeeks = partitionToSubPartitions(partition);

    return allWeeks.filter(
      w => partitionToDateTimeRange(siteSettings)(w)[0] < DateTime.now()
    );
  }, [partition, siteSettings]);

  const db = useTransactionsDb();

  const { data } = useTransform(
    useQueryStream(db.remote.design.dashboardDates.views.calendar, {
      groupLevel: 3,
      reduce: true,
      startKey: weeks.first() ?? thenThrow('Unexpected empty partitions'),
      endKey: [
        ...(weeks.last() ?? thenThrow('Unexpected empty partitions')),
        []
      ],
      inclusiveEnd: true
    }),
    rows =>
      weeks
        .leftJoin(rows, (l, r) => viewKeysAreEqual(l, r.key))
        .map(({ left: week, right: row }) => ({
          key: week,
          title: formatPartition(siteSettings)(week),
          subtitle: row?.value.value ? formatMoney(row?.value.value) : undefined
        }))
        .sort((a, b) => sortViewKeys(a.key, b.key)),
    () =>
      weeks.map(week => ({
        key: week,
        title: formatPartition(siteSettings)(week),
        subtitle: undefined
      }))
  );

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  return (
    <LegacyCellGroup headingText="Days" {...props}>
      {data.map(({ key, title, subtitle }, index) => (
        <React.Fragment key={partitionToComponentKey(key)}>
          <BasicCell
            iconRight={subtitle ? ForwardIcon : undefined}
            title={title}
            subtitle={subtitle}
            titlesOrientation="horizontal"
            onPress={
              subtitle
                ? () => {
                    navigation.push('show', {
                      partition: PartitionSerialiser.stringify(key)
                    });
                  }
                : undefined
            }
          />
          {index < data.length - 1 && <ListItemSeparator />}
        </React.Fragment>
      ))}
    </LegacyCellGroup>
  );
};

export default MonthDaysCellGroup;
