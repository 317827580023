import {
  HeaderBackButton,
  HeaderBackButtonProps
} from '@react-navigation/elements';
import { Colours } from '../theme/Colours';
import { dismissViewController } from '../modules/navigation/dismissViewController';

export type DismissBackButtonProps = HeaderBackButtonProps;

const DismissBackButton = (props: DismissBackButtonProps): JSX.Element => {
  return (
    <HeaderBackButton
      {...props}
      label="Serve+"
      tintColor={Colours.white.$}
      onPress={() => {
        void dismissViewController();
      }}
    />
  );
};

export default DismissBackButton;
