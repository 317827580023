export enum RoleId {
  admin = 'role-4d913cfc-cf5d-424b-a673-36e3e9be37d9',
  superUser = 'role-8f926bff-9868-4db6-bd9c-42ed7f86e2a4',
  staff = 'role-e23cd77c-1fb2-410c-add5-81e64f35daeb'
}

export type Role = {
  name: string;
  group: string;
  roleDescription: string;
  permissions: {
    permissionPath: string;
    permittedActions: string[];
  }[];
};
