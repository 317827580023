import { TextStyle } from 'react-native';
import Cell, { CellProps } from './Cell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Text } from '../text/Text';

export type KeyValueCellProps = Omit<CellProps, 'children'> & {
  keyStyle?: TextStyle;
  valueStyle?: TextStyle;
  keyText: string;
  valueText: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 10
  },
  keyStyle: {
    fontSize: 15,
    lineHeight: 26,
    width: '50%',
    textAlign: 'right',
    paddingRight: 16
  }
});

const KeyValueCell = ({
  style,
  keyStyle,
  valueStyle,
  keyText,
  valueText,
  ...rest
}: KeyValueCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Cell style={[styles.container, style]} {...rest}>
      <Text.CellSubtitle style={[styles.keyStyle, keyStyle]}>
        {keyText}
      </Text.CellSubtitle>
      <Text.CellTitle style={valueStyle}>{valueText}</Text.CellTitle>
    </Cell>
  );
};

export default KeyValueCell;
