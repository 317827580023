import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Voucher } from '../../documents/Voucher';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document<Voucher>) {
  'public source';

  if (doc._id.indexOf('voucher-') === 0) {
    emit([!!doc.deleted ?? false, String(doc.name)], null);
  }
};

export class AllVouchersView extends DatabaseView<
  readonly [deleted: boolean, name: string],
  null,
  Voucher
> {
  static readonly viewName = 'allVouchers';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllVouchersView.viewName);
  }
}
