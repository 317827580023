import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { TransactionsArchiveDatabaseSchema } from './TransactionsArchiveDatabaseSchema';

export class TransactionsArchiveDatabase extends DatabasePair<TransactionsArchiveDatabaseSchema> {
  constructor(
    clientId: string,
    siteId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(
      `sp-${clientId}-${siteId}-transactions-archive`,
      TransactionsArchiveDatabaseSchema,
      queryConfig,
      syncConfig
    );
  }
}
