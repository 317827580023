import Svg, { SvgProps, Polygon } from 'react-native-svg';

const SelectedArrow = (props: SvgProps) => (
  <Svg
    viewBox="0 0 1 2"
    width={16}
    height={48}
    fill="#FFFFFF"
    preserveAspectRatio="none"
    {...props}
  >
    <Polygon points="0,0 1,1 0,2" />
  </Svg>
);

export default SelectedArrow;
