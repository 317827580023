import { DateTime } from 'luxon';
import { IntervalDate } from '../types/IntervalDate';
import { Partition } from '../types/Partition';
import { assertNever } from '../../../lib/assert-never';

export const intervalDateToPartition = ([
  interval,
  date
]: IntervalDate): Partition => {
  const dateElements = date.split('-').map(x => +x) as [number, number, number];

  switch (interval) {
    case 'year':
      return ['calendar', dateElements[0]];
    case 'month':
      return ['calendar', ...(dateElements.slice(0, 2) as [number, number])];
    case 'day':
      return ['calendar', ...dateElements];
    case 'week': {
      const dt = DateTime.utc(
        dateElements[0],
        dateElements[1],
        dateElements[2]
      );
      return ['iso', dt.weekYear, dt.weekNumber];
    }
    default:
      return assertNever(interval);
  }
};
