import { ScrollViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import ProductDataView from '../../components/product-data-view/ProductDataView';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

type ProductTabContentProps = Omit<ScrollViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingVertical: 6
  }
});

const ProductTabContent = (props: ProductTabContentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PlatformScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      keyboardShouldPersistTaps="always"
      {...props}
    >
      <ProductDataView />
    </PlatformScrollView>
  );
};

export default ProductTabContent;
