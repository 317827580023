export const assertRouteParam = <
  const T extends object,
  K extends keyof T,
  U extends NonNullable<T[K]> = NonNullable<T[K]>
>(
  params: T | undefined,
  key: K,
  guard?: (value: NonNullable<T[K]>) => value is U
): U => {
  if (!params || !(key in params)) {
    throw new Error(`Route param ${String(key)} required`);
  }

  const value = params[key];

  if (typeof value === 'undefined' || value === null) {
    throw new Error(`Route param ${String(key)} required`);
  }

  if (guard) {
    if (guard(value)) {
      return value;
    }

    throw new Error(`Route param ${String(key)} failed guard`);
  }

  return value as U;
};
