import { useMemo } from 'react';
import { SiteContext } from '../../../data-model/components/site-context/SiteContext';
import { nameof } from '../../../lib/name-of';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { SiteTimeSettings } from '../types/SiteTimeSettings';

export const useSiteTime = (): SiteTimeSettings => {
  const ctx = useContextOrThrow(SiteContext, nameof({ SiteContext }));

  const settings: SiteTimeSettings = useMemo(
    () => ({
      dayChangeTime: ctx.site.dayChangeTime ?? '06:00',
      timeZone: ctx.site.timeZone ?? 'Europe/London'
    }),
    [ctx.site]
  );

  return settings;
};
