import React from 'react';
import { Text, TextProps } from 'react-native';
import { DateTime } from 'luxon';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useSiteTime } from '../../../dashboard/hooks/useSiteTime';

export type AbsoluteTimeProps = Omit<TextProps, 'children'> & {
  time: string;
};

const rStyles = DynamicStyleSheet.create({
  text: {
    color: SemanticColours.primary.foreground[70],
    textAlign: 'center',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '400'
  }
});

const AbsoluteTime = React.memo<AbsoluteTimeProps>(
  ({ style, time, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const { timeZone } = useSiteTime();

    return (
      <Text style={[style, styles.text]} {...rest}>
        {DateTime.fromISO(time, { zone: timeZone }).toFormat('HH:mm')}
      </Text>
    );
  }
);

export default AbsoluteTime;
