import { CommandContext } from '../types/CommandContext';
import { CommandBase } from '../schema/databases/shared/documents/CommandBase';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { Document } from '../types/Document';

export const createCommandDocument = async <
  T extends SiteCommands[keyof SiteCommands]
>(
  ctx: CommandContext,
  data: T,
  referenceIds: string[]
): Promise<Document<T & CommandBase>> => {
  const log = createLogger(nameof({ createCommandDocument }));

  const { commands } = ctx.databases;
  const { userId, deviceId, createdAt } = ctx.meta;

  const properties: T & CommandBase = {
    ...data,
    userId,
    deviceId,
    createdAt,
    referenceIds
  };

  const cmdResult = await commands.local.repositories.commands.createDocument(
    properties
  );

  log.info('Command success', cmdResult);

  return cmdResult as unknown as Document<T & CommandBase>;
};
