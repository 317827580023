import { useMemo } from 'react';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { Partition } from '../../../../types/Partition';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useValueFromPartitionNavigationCache } from '../../../../components/partition-navigation-context/useValueFromPartitionNavigationCache';
import { AnyPaymentId } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/PaymentIds';

const DEFAULT_VALUE = {
  totalValue: null,
  receivedQty: null,
  refundQty: null
};

export const useData = (partition: Partition, paymentTypeId: AnyPaymentId) => {
  const intervalDate = useMemo(
    () => partitionToIntervalDate(partition),
    [partition]
  );

  const db = useTransactionsDb();

  const getValue = useValueFromPartitionNavigationCache();

  return useTransform(
    useQueryStream(db.remote.design.dashboardPaymentsForDate.view, {
      startKey: [...intervalDate, paymentTypeId],
      endKey: [...intervalDate, paymentTypeId, {}],
      groupLevel: 3,
      reduce: true
    }),

    rows => {
      const data = rows.map(r => r.value).first();

      if (!data) return DEFAULT_VALUE;

      const { received, refund } = data;

      return {
        totalValue: (received?.value ?? 0) - (refund?.value ?? 0),
        receivedQty: received?.units ?? null,
        refundQty: refund?.units ?? null
      };
    },

    () => ({
      ...DEFAULT_VALUE,
      totalValue: getValue(partition)
    })
  );
};
