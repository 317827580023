import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import NetInfo from '@react-native-community/netinfo';
import fetch from 'cross-fetch';
import { Client, NewClientInput, PersonalSecurity } from './types';
import { useLogger } from '../../lib/logging/useLogger';
import { nameof } from '../../lib/name-of';
import { useContextOrThrow } from '../../lib/use-context-or-throw';
import { SecuritySchemeContext } from '../components/scheme/SecuritySchemeContext';
import { verify } from './verify';
import { RetainSplash } from '../../lib/retain-splash/RetainSplash';
import { AuthorisationContextType } from '../context/AuthorisationContextType';
import { addAuthorisationHeader } from '../helpers/addAuthorisationHeader';
import { QueryAuthorisationContext } from '../context/QueryAuthorisationContext';
import { ReplicationAuthorisationContext } from '../context/ReplicationAuthorisationContext';
import { ServerContext } from '../../data-model/components/server-context/ServerContext';
import { ClientContextProvider } from '../../data-model/components/client-context/ClientContext';
import UserContext from '../context/UserContext';
import { SiteContextProvider } from '../../data-model/components/site-context/SiteContextProvider';
import DeviceContext from '../../components/device-context/DeviceContext';
import { Store } from './Store';
import { userAsUnauthenticated } from './userAsUnauthenticated';
import { isAuthenticatedUser } from './isAuthenticatedUser';
import { ReLoginNavigator } from '../../features/login/navigation/ReLoginNavigator';
import {
  PersonalSecurityContext,
  PersonalSecurityContextType
} from './PersonalSecurityContext';
import { useLaunchArguments } from '../../lib/launch-arguments';
import { newRandomUuid } from '../../lib/uuid';
import { addRequestIdHeader } from '../../helpers/addRequestIdHeader';

export const PersonalSecurityProvider = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const log = useLogger(nameof({ PersonalSecurityProvider }));

  const launchArgs = useLaunchArguments<LaunchArguments | undefined>();

  const [state, setStateInternal] = useState<PersonalSecurity | null>(null);

  const { setCurrentScheme } = useContextOrThrow(
    SecuritySchemeContext,
    nameof({ SecuritySchemeContext })
  );

  const setState = useCallback(
    (
      newState:
        | PersonalSecurity
        | null
        | ((oldState: PersonalSecurity | null) => PersonalSecurity | null)
    ) => {
      if (typeof newState !== 'function') {
        setStateInternal(newState);

        if (!newState) {
          setCurrentScheme('NONE');
          void Store.remove();
        } else {
          void Store.set(newState);
        }

        return;
      }

      setStateInternal(old => {
        const newStateResult = newState(old);

        if (!newStateResult) {
          setCurrentScheme('NONE');
          void Store.remove();
        } else {
          void Store.set(newStateResult);
        }

        return newStateResult;
      });
    },
    [setCurrentScheme]
  );

  const { apiBaseUrl: serverUrl } = useContextOrThrow(
    ServerContext,
    nameof({ ServerContext })
  );

  const deauthorise = useCallback((clientId: string) => {
    setState(old => {
      if (!old) {
        log.warn('Attempt to deauthorise when the state is empty');
        return old;
      }

      const oldClient = old.clients.find(c => c.id === clientId);

      if (!oldClient) {
        log.warn('Attempt to deauthorise a client not found in state');
        return old;
      }

      if (!isAuthenticatedUser(oldClient.user)) {
        log.warn('Attempt to deauthorise an already already deauthorised user');
        return old;
      }

      const replacementClient: Client = {
        ...oldClient,
        user: userAsUnauthenticated(oldClient.user)
      };

      return {
        ...old,
        clients: [
          ...old.clients.filter(c => c.id !== clientId),
          replacementClient
        ]
      };
    });
  }, []);

  const switchToNewClient = useCallback((input: NewClientInput) => {
    setState(old => {
      const clients = old ? old.clients.filter(c => c.id !== input.id) : [];

      const next: PersonalSecurity = {
        ...(state || {}),
        clients: [...clients, input],
        activeClientId: input.id
      };

      return next;
    });
  }, []);

  const removeClient = useCallback((clientId: string) => {
    setState(old => {
      if (!old) return old;

      const clients = old.clients.filter(c => c.id !== clientId);

      if (clients.none()) {
        return null;
      }

      const next: PersonalSecurity = {
        ...(old || {}),
        clients,
        activeClientId:
          old.activeClientId === clientId ? clients[0].id : old.activeClientId
      };

      return next;
    });
  }, []);

  const switchToExistingClient = useCallback((clientId: string) => {
    setState(old => {
      if (!old || !old.clients.find(c => c.id === clientId)) {
        return old;
      }

      const next: PersonalSecurity = {
        ...old,
        activeClientId: clientId
      };

      return next;
    });
  }, []);

  const contextValue: PersonalSecurityContextType | null = useMemo(() => {
    return state
      ? {
          ...state,
          switchToExistingClient,
          switchToNewClient,
          removeClient
        }
      : null;
  }, [state, switchToNewClient, switchToExistingClient, removeClient]);

  useEffect(() => {
    let mounted = true;

    void (async () => {
      const done = async (value: PersonalSecurity) => {
        if (mounted) setStateInternal(value);

        log.info('Using configuration', value);
        await Store.set(value);
      };

      const existing = await (async () => {
        if (launchArgs?.personalSecurity) {
          const { personalSecurity } = launchArgs;

          log.debug('Using personal security from launch arguments', {
            personalSecurity
          });

          await Store.set(personalSecurity);
          return personalSecurity;
        }
        return Store.get();
      })();

      if (!existing) {
        // bail, shouldn't be here
        setCurrentScheme('NONE');
        return undefined;
      }

      const netInfo = await NetInfo.fetch();

      if (netInfo.isInternetReachable === false) {
        log.debug('Not verifying configuration because internet not reachable');
        return done(existing);
      }

      log.debug('Verifying security configuration');

      const verified = await verify(existing, serverUrl, log);

      if (!verified) {
        setCurrentScheme('NONE');
        return undefined;
      }

      log.info('Existing configuration verified', { verified });

      return done(verified);
    })();

    return () => {
      mounted = false;
    };
  }, []);

  const activeClient = useMemo(() => {
    if (!state?.activeClientId) return null;

    const client = state.clients.find(c => c.id === state.activeClientId);

    return client ?? null;
  }, [state]);

  const authorisation: AuthorisationContextType = useMemo(() => {
    const headerValue =
      activeClient && isAuthenticatedUser(activeClient.user)
        ? `Bearer ${activeClient.user.token}`
        : undefined;

    return {
      authoriser: {
        authorisedFetch: headerValue
          ? async (url, opts) => {
              const requestId = newRandomUuid();

              return fetch(
                url,
                addRequestIdHeader(
                  addAuthorisationHeader(opts, headerValue),
                  requestId
                )
              );
            }
          : fetch
      }
    };
  }, [activeClient?.user, deauthorise]);

  if (!state || !activeClient || !contextValue) {
    return <RetainSplash />;
  }

  if (!isAuthenticatedUser(activeClient.user)) {
    return (
      <PersonalSecurityContext.Provider value={contextValue}>
        <ReLoginNavigator
          defaultClient={{ id: activeClient.id, name: activeClient.name }}
        />
      </PersonalSecurityContext.Provider>
    );
  }

  return (
    <PersonalSecurityContext.Provider value={contextValue}>
      <QueryAuthorisationContext.Provider value={authorisation}>
        <ReplicationAuthorisationContext.Provider value={authorisation}>
          <ClientContextProvider clientId={activeClient.id}>
            <DeviceContext.Provider
              value={{ deviceId: activeClient.user.deviceId }}
            >
              <UserContext.Provider value={activeClient.user}>
                <SiteContextProvider siteId={activeClient.defaultSiteId}>
                  {children}
                </SiteContextProvider>
              </UserContext.Provider>
            </DeviceContext.Provider>
          </ClientContextProvider>
        </ReplicationAuthorisationContext.Provider>
      </QueryAuthorisationContext.Provider>
    </PersonalSecurityContext.Provider>
  );
};
