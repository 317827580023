import { useMemo } from 'react';
import { useProductDataContext } from '../../data/ProductDataContext';
import { Tab } from '../../../../components/tabs/Tab';
import { useContrastTabTheme } from '../../../../components/tabs/useContrastTabTheme';
import { Text } from '../../../../components/text/Text';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import { IconButton } from '../../../../components/buttons/IconButton';
import AddIcon from '../../../../components/icons/AddIcon';
import AddVariantModal from '../../components/modals/AddVariantModal';
import { Button } from '../../../../components/buttons/Button';
import { SemanticColours } from '../../../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';

export type VariantTabsProps = {
  selectedVariantId: string | undefined;
  onSelect(variantId: string): void;
};

const rStyles = DynamicStyleSheet.create({
  title: {
    marginBottom: 16,
    marginLeft: 50,
    marginRight: 18,
    color: SemanticColours.secondary.foreground[100]
  },
  button: {
    paddingHorizontal: 17,
    paddingTop: 13,
    paddingBottom: 21
  },
  buttonTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const VariantTabs = ({
  selectedVariantId,
  onSelect
}: VariantTabsProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { productId, product } = useProductDataContext();

  const { present, ...modal } = useModalControl();

  const tabStyles = useContrastTabTheme();

  const hideVariants = useMemo(() => {
    return (
      !product ||
      (product.variants.length === 1 &&
        product.variants[0].$schema === 'DefaultUnnamedVariant')
    );
  }, [product]);

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <>
      {hideVariants ? (
        authorised && (
          <Button
            iconComponent={AddIcon}
            title="Add variant"
            style={styles.button}
            onPress={() => present()}
            iconColour={styles.buttonTint.color}
            titleStyle={styles.buttonTint}
          />
        )
      ) : (
        <>
          <Text.Emphasis style={styles.title}>Variants</Text.Emphasis>
          {product?.variants.map(({ id, name }) => (
            <Tab
              key={id}
              title={name}
              isActive={id === selectedVariantId}
              onPress={() => onSelect(id)}
              styles={tabStyles}
            />
          ))}

          {authorised && (
            <IconButton
              style={styles.button}
              iconComponent={AddIcon}
              onPress={() => present()}
              iconColour={styles.buttonTint.color}
            />
          )}
        </>
      )}

      <AddVariantModal
        productId={productId}
        {...modal}
        onVariantCreated={onSelect}
      />
    </>
  );
};

export default VariantTabs;
