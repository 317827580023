import { useMemo } from 'react';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { Partition } from '../../../../types/Partition';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useValueFromPartitionNavigationCache } from '../../../../components/partition-navigation-context/useValueFromPartitionNavigationCache';
import { DashboardUsersForDateValue } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-users-for-date/DashboardUsersForDateDesignDoc';

const DEFAULT_VALUE = {
  value: null as number | null,
  card: null as number | null,
  cash: null as number | null,
  vouchers: null as number | null,
  lines: null as number | null,
  voids: null as number | null,
  noSales: null as number | null,
  paymentLines: null as number | null,
  allPayments: null as number | null
};

const mapValue = ({
  value: { Sale, Void, Promotion, Refund, Card, Cash, Voucher, NoSale }
}: {
  value: DashboardUsersForDateValue;
}) => ({
  value: [Sale?.value, Void?.value, Promotion?.value, Refund?.value]
    .compact()
    .sum(),
  card: Card?.value ?? null,
  cash: Cash?.value ?? null,
  vouchers: Voucher?.value ?? null,
  lines: Sale?.units ?? null,
  voids: Void?.units ?? null,
  noSales: NoSale?.units ?? null,
  paymentLines: [Card?.units, Cash?.units, Voucher?.units, NoSale?.units]
    .compact()
    .sum(),
  allPayments: [Card?.value, Cash?.value, Voucher?.value].compact().sum()
});

export const useData = (partition: Partition, userId: string) => {
  const intervalDate = useMemo(
    () => partitionToIntervalDate(partition),
    [partition]
  );

  const db = useTransactionsDb();

  const getValue = useValueFromPartitionNavigationCache();

  return useTransform(
    useQueryStream(db.remote.design.dashboardUsersForDate.view, {
      startKey: [...intervalDate, userId],
      endKey: [...intervalDate, userId, []],
      groupLevel: 3,
      reduce: true
    }),

    rows => rows.map(mapValue).first() ?? DEFAULT_VALUE,

    () => ({
      ...DEFAULT_VALUE,
      value: getValue(partition)
    })
  );
};
