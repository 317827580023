import { Database } from '../../support/Database';
import { DesignDoc } from './design/DesignDoc';
import { UsersRepository } from './repositories/UsersRepository';

export class SecurityDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly design = new DesignDoc(this.database);

  public readonly repositories = {
    users: new UsersRepository(this.database)
  };

  public async ensureSchemaApplied(): Promise<void> {
    await this.design.ensureDefined();
  }
}
