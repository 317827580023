import { collate } from '@craftzdog/pouchdb-collate-react-native';
import { SyncDisposable } from '../../../lib/disposable/SyncDisposable';
import { Subject } from '../../../lib/pub-sub/Subject';
import { Subscribable } from '../../../lib/pub-sub/Subscribable';
import {
  DatabaseView,
  GroupLevelOf,
  GroupLevelsFor,
  QueryOptions,
  QueryRow,
  RangeKeyForGroupLevel,
  ViewKey
} from '../../schema/support/DatabaseView';
import { DocumentProperties } from '../../types/Document';
import { Cancelable } from './Cancelable';
import { Operation } from './Operation';
import { QueryPaginatorState } from './QueryPaginatorState';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { viewKeysAreEqual } from '../../helpers/viewKeysAreEqual';
import panic from '../../../lib/then-throw/panic';

export class QueryPaginator<
  TKey extends ViewKey,
  TValue,
  TDoc extends DocumentProperties,
  const TOptions extends QueryOptions<
    TKey,
    GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
  >,
  const U
> implements SyncDisposable
{
  constructor(
    public readonly view: DatabaseView<TKey, TValue, TDoc>,
    public readonly baseOptions: TOptions,
    transformer?: (
      rows: readonly QueryRow<TKey, TValue, TOptions, TDoc>[]
    ) => readonly U[]
  ) {
    this._transformer = transformer ?? (v => v as U[]);
  }

  private _transformer: (
    rows: readonly QueryRow<TKey, TValue, TOptions, TDoc>[]
  ) => readonly U[];

  private readonly log = createLogger(nameof({ QueryPaginator }), {
    database: this.view.database.pouch.name,
    designDocumentId: this.view.designDocumentId,
    viewName: this.view.viewName
  });

  private readonly _state = new Subject<
    QueryPaginatorState<
      U,
      RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
    >
  >({ initialValue: { state: 'EMPTY' } });

  public readonly state: Subscribable<
    QueryPaginatorState<
      U,
      RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
    >
  > = this._state;

  private originalRows: QueryRow<TKey, TValue, TOptions, TDoc>[] | undefined;

  private rows: U[] | undefined;

  private range:
    | [
        RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>,
        RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
      ]
    | undefined;

  private currentOperation?: Cancelable;

  private getQueryOptions(
    to: number | RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>,
    from?: RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
  ): TOptions {
    return {
      ...this.baseOptions,
      ...(typeof to === 'number'
        ? { limit: to }
        : { endKey: to, limit: undefined }),
      ...(from
        ? {
            startKey:
              // this one deserves some explanation!
              //
              // When we're descending, the first row of results is inconsistent
              // between group levels that match the key length and those that
              // don't.
              //
              // e.g.
              //   from ['calendar', 2023, 7]
              //     => first row = ['calendar', 2023, 6]
              //   from ['calendar', 2023, 7, 16]
              //     => first row = ['calendar', 2023, 7, 16]
              //
              // so by adding a [] to the end of the key, we
              // ensure consistent results across all key lengths
              this.baseOptions.descending && Array.isArray(from)
                ? [...from, []]
                : from
          }
        : {})
    };
  }

  public reload(
    to: number | RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
  ): void {
    this.currentOperation?.cancel();

    this.log.debug('Reloading', { to });

    this._state.next({
      ...this._state.current,
      state: 'RELOADING'
    });

    const options = this.getQueryOptions(to);

    const op = new Operation(this.view, options).run(
      newRows => {
        this.rows = undefined;
        this.range = undefined;

        this.receiveRows(newRows, options);
      },
      error => this.receiveError(error)
    );

    this.currentOperation = op;
  }

  public loadMore(
    to: number | RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
  ): void {
    this.currentOperation?.cancel();

    this.log.debug('Loading more', { to });

    this._state.next({
      ...this._state.current,
      rows: this.rows ?? [],
      state: 'LOADING_MORE'
    });

    const options = this.getQueryOptions(to, this.range?.[1]);

    const op = new Operation(this.view, options).run(
      newRows => this.receiveRows(newRows, options),
      error => this.receiveError(error)
    );

    this.currentOperation = op;
  }

  private mergeInNewRows(
    newRows: QueryRow<TKey, TValue, TOptions, TDoc>[]
  ): void {
    // make sure that there isn't overlap with the new rows
    const rowsToUse = (() => {
      if (
        !this.originalRows ||
        this.originalRows.length === 0 ||
        newRows.length === 0
      ) {
        return newRows;
      }

      const lastKeyOfOriginal = this.originalRows.last()?.key ?? panic();
      const firstKeyOfNew = newRows[0].key;

      if (viewKeysAreEqual(lastKeyOfOriginal, firstKeyOfNew)) {
        return newRows.slice(1);
      }

      return newRows;
    })();

    if (this.originalRows) {
      this.originalRows.push(...rowsToUse);
    } else {
      this.originalRows = [...rowsToUse];
    }

    const transformedRows = this._transformer(rowsToUse);

    if (this.rows) {
      this.rows.push(...transformedRows);
    } else {
      this.rows = [...transformedRows];
    }
  }

  private updateRange(
    newRows: QueryRow<TKey, TValue, TOptions, TDoc>[],
    options: TOptions
  ): void {
    const rangeStart =
      this.range?.[0] ??
      options.startKey ??
      (newRows.first()?.key as RangeKeyForGroupLevel<
        TKey,
        GroupLevelOf<TOptions, TKey>
      >);

    const rangeEnd = options.limit
      ? (newRows.last()?.key as RangeKeyForGroupLevel<
          TKey,
          GroupLevelOf<TOptions, TKey>
        >) ?? this.range?.[1]
      : options.endKey;

    this.range = rangeStart && rangeEnd ? [rangeStart, rangeEnd] : undefined;

    this.log.debug('Range updated', { range: this.range });
  }

  private receiveRows(
    newRows: QueryRow<TKey, TValue, TOptions, TDoc>[],
    options: TOptions
  ): void {
    this.log.debug('Rows received', { rowCount: newRows.length });

    this.mergeInNewRows(newRows);

    this.updateRange(newRows, options);

    const hasMore =
      newRows.any() && (!options.limit || newRows.length >= options.limit);

    this._state.next({
      state: 'SUCCESS',
      rows: this.rows ?? [],
      range: this.range,
      hasMore
    });
  }

  private receiveError(error: unknown): void {
    this.log.debug('Error received', { error });

    this._state.next({
      ...this._state.current,
      state: 'ERROR',
      error
    });
  }

  public rangeContains(
    key: RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
  ): boolean {
    if (!this.range) return false;

    const [start, end] = this.baseOptions.descending
      ? [...this.range].reverse()
      : this.range;

    return collate(start, key) <= 0 && collate(key, end) <= 0;
  }

  public reTransform(
    transformer: (
      rows: readonly QueryRow<TKey, TValue, TOptions, TDoc>[]
    ) => readonly U[]
  ): void {
    this._transformer = transformer;

    if (
      !this.originalRows ||
      !this._state.current ||
      !('rows' in this._state.current)
    )
      return;

    this.log.debug('Re-transforming rows', {
      rowCount: this.originalRows.length
    });

    this.rows = [...this._transformer(this.originalRows)];

    this._state.next({
      ...this._state.current,
      rows: this.rows ?? []
    });
  }

  public dispose(): void {
    this.currentOperation?.cancel();
  }
}
