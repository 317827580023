import ButtonCell from '../../../../components/cells/ButtonCell';
import AddIcon from '../../../../components/icons/AddIcon';
import { useProductDataContext } from '../../data/ProductDataContext';
import AddVariantModal from '../modals/AddVariantModal';
import useModalControl from '../../../../lib/use-modal/useModalControl';

export type AddVariantCellProps = {
  onVariantCreated?: (variantId: string, productId: string) => void;
};

const AddVariantCell = ({
  onVariantCreated
}: AddVariantCellProps): JSX.Element => {
  const { productId } = useProductDataContext();

  const { present, ...modal } = useModalControl();

  return (
    <>
      <ButtonCell
        title="Add variant"
        iconComponent={AddIcon}
        onPress={() => present()}
      />
      <AddVariantModal
        productId={productId}
        onVariantCreated={onVariantCreated}
        {...modal}
      />
    </>
  );
};

export default AddVariantCell;
