import React, { useMemo } from 'react';
import CardColumn, {
  CardColumnProps
} from '../../../../../../components/card/CardColumn';
import BoundCard from '../../../../components/card/BoundCard';
import NoInProgressContent from '../NoInProgressContent';
import BoundCardFooter from '../BoundCardFooter';
import { useTasksData } from '../../../../data/TasksDataContext';

export type InProgressColumnProps = Omit<CardColumnProps, 'data'>;

const InProgressColumn = React.memo<InProgressColumnProps>(
  ({ style, ...rest }) => {
    const { data: allTasks } = useTasksData();

    const data = useMemo(() => {
      const relevantTasks = allTasks.filter(v => v.key[1] === '03 In Progress');

      if (relevantTasks.none()) {
        return { data: [] };
      }

      return {
        data: relevantTasks.map(v => ({
          key: v.documentID,
          element: (
            <BoundCard
              queryRow={v}
              expandable={false}
              footerComponent={BoundCardFooter}
            />
          )
        }))
      };
    }, [allTasks]);

    return (
      <CardColumn
        style={style}
        {...rest}
        data={data}
        ListEmptyComponent={NoInProgressContent}
      />
    );
  }
);

export default InProgressColumn;
