import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ViewProps } from 'react-device-detect';
import { ViewStyle } from 'react-native';
import { Text } from '../../../../components/text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import PartitionModal from '../partition-modal/PartitionModal';
import { Colours } from '../../../../theme/Colours';
import SelectIcon from '../../../../components/icons/SelectIcon';
import { useActivePartition } from '../active-partition-context/ActivePartitionContext';
import { formatPartition } from '../../../../humanisers/formatPartition';
import { useSiteTime } from '../../hooks/useSiteTime';
import PlatformPressable from '../../../../components/platform-pressable/PlatformPressable';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { PartitionSerialiser } from '../../navigation/PartitionSerialiser';

const rStyles = DynamicStyleSheet.create({
  button: {
    backgroundColor: Colours.transparent.$,
    padding: 6,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10
  },
  title: {
    marginHorizontal: 6,
    color: SemanticColours.secondary.foreground[100]
  },
  icon: {
    marginHorizontal: 3
  }
});

export type PartitionSelectorProps = Omit<ViewProps, 'children'>;

export const PartitionSelector = ({
  style,
  ...rest
}: PartitionSelectorProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const partition = useActivePartition();

  const siteTime = useSiteTime();

  const navigation =
    useNavigation<NavigationProp<DashboardNavigatorParamList, 'show'>>();

  const { present, ...modal } = useModalControl();

  return (
    <>
      <PartitionModal
        defaultPartition={partition}
        onSelect={newPartition =>
          navigation.setParams({
            partition: PartitionSerialiser.stringify(newPartition)
          })
        }
        {...modal}
      />
      <PlatformPressable
        style={[style as ViewStyle, styles.button]}
        onPress={() => present()}
        {...rest}
      >
        <Text.HeaderTitle style={styles.title}>
          {formatPartition(siteTime)(partition)}
        </Text.HeaderTitle>
        <SelectIcon width={18} height={18} style={styles.icon} />
      </PlatformPressable>
    </>
  );
};
