import { range } from 'ts-array-extensions';
import { DateTime } from 'luxon';
import { IntervalDate } from '../types/IntervalDate';

const DAY_M_SEC = 24 * 60 * 60 * 1000;

const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getUTCDate();
};

export const intervalDateToSubIntervals = ([
  interval,
  dateStr
]: IntervalDate): string[] => {
  if (interval === 'week') {
    const weekStart = DateTime.fromISO(dateStr, { zone: 'utc' }).toMillis();

    return range(0, 6).map(
      day => new Date(weekStart + day * DAY_M_SEC).toISOString().split('T')[0]
    );
  }

  if (interval === 'month') {
    const date = new Date(dateStr);
    const timeInt = date.getTime() - (date.getUTCDate() - 1) * DAY_M_SEC;
    const monthStart = new Date(timeInt);

    return range(
      0,
      daysInMonth(monthStart.getUTCFullYear(), monthStart.getUTCMonth() + 1)
    ).map(
      day =>
        new Date(monthStart.getTime() + day * DAY_M_SEC)
          .toISOString()
          .split('T')[0]
    );
  }

  return [dateStr];
};
