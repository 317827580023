import { ScrollViewProps, SectionList } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { ComponentType } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { isCalendarDayPartition } from '../../../../helpers/isCalendarDayPartition';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { useUserTransactionListData } from './useUserTransactionListData';
import Cell from './Cell';
import CellGroupHeading from '../../../../../../components/cells/CellGroupHeading';
import CellGroupSeparator from '../../../../../../components/cells/CellGroupSeparator';
import ServingZoneIcon from '../../../../../../components/icons/ServingZoneIcon';
import { assertRouteParam } from '../../../../../../lib/assert-route-param';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type UserTransactionsListProps = Omit<ScrollViewProps, 'children'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListHeaderComponent?: ComponentType<any>;
};

const rStyles = DynamicStyleSheet.create({
  container: { flex: 1 }
});

const UserTransactionsList = ({
  style,
  ...rest
}: UserTransactionsListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'user'>>();
  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'user'>>();

  const userId = assertRouteParam(route.params, 'userId');

  const partition = useActivePartition(isCalendarDayPartition);

  const { data: sections } = useUserTransactionListData(userId, partition);

  const { header, cell, Separator } = useCellGroupStyles('left');

  return (
    <SectionList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      style={[styles.container, style]}
      sections={sections}
      keyExtractor={d => d.key}
      initialNumToRender={15}
      ItemSeparatorComponent={Separator}
      renderSectionFooter={() => <CellGroupSeparator style={header} />}
      renderSectionHeader={({ section: { name } }) => (
        <>
          <CellGroupSeparator style={header} />
          <CellGroupHeading
            title={name}
            iconComponent={!name ? undefined : ServingZoneIcon}
            style={header}
          />
        </>
      )}
      renderItem={({ item, index, section }) => (
        <Cell
          {...item}
          style={cell(index === 0, index === section.data.length - 1)}
          onPress={() =>
            navigation.push('transaction', {
              transactionId: item.transactionId
            })
          }
        />
      )}
      {...rest}
    />
  );
};

export default UserTransactionsList;
