import { View, ViewProps, ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect, useMemo, useState } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { Text } from '../../../../components/text/Text';
import { Colours } from '../../../../theme/Colours';
import { useActivePartition } from '../active-partition-context/ActivePartitionContext';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import LeftIcon from '../../../../components/icons/LeftIcon';
import RightIcon from '../../../../components/icons/RightIcon';
import { PropsType } from '../../../../lib/react-props-type';
import { formatPartitionRelative } from './formatPartitionRelative';
import { usePartitionNavigation } from '../partition-navigation-context/usePartitionNavigation';
import { isCalendarDayPartition } from '../../helpers/isCalendarDayPartition';
import { isIsoWeekPartition } from '../../helpers/isIsoWeekPartition';
import { isCalendarMonthPartition } from '../../helpers/isCalendarMonthPartition';
import { isCalendarYearPartition } from '../../helpers/isCalendarYearPartition';
import { assertNever } from '../../../../lib/assert-never';
import { comparePartitions } from '../../helpers/comparePartitions';
import { Partition } from '../../types/Partition';
import PlatformPressable from '../../../../components/platform-pressable/PlatformPressable';
import { PartitionNavigationSlice } from '../partition-navigation-context/PartitionNavigationContext';
import { PartitionSerialiser } from '../../navigation/PartitionSerialiser';

const rStyles = DynamicStyleSheet.create({
  button: {
    backgroundColor: Colours.transparent.$,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5
  },
  buttonTitle: {
    color: SemanticColours.secondary.foreground[100]
  },
  icon: {
    color: SemanticColours.secondary.foreground[100]
  },
  iconContainer: {
    marginHorizontal: 2
  }
});

export type PartitionSeekButtonProps = Omit<
  PropsType<typeof PlatformPressable>,
  'children'
> & {
  direction: 'forward' | 'back';
};

export const PartitionSeekButton = ({
  direction,
  style,
  ...rest
}: PartitionSeekButtonProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const partition = useActivePartition();

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  const partitionNavContext = usePartitionNavigation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const partitionNav: PartitionNavigationSlice<Partition, any> = useMemo(() => {
    if (isCalendarDayPartition(partition)) {
      return partitionNavContext.day;
    }
    if (isIsoWeekPartition(partition)) {
      return partitionNavContext.week;
    }
    if (isCalendarMonthPartition(partition)) {
      return partitionNavContext.month;
    }
    if (isCalendarYearPartition(partition)) {
      return partitionNavContext.year;
    }
    return assertNever(partition);
  }, [partitionNavContext, partition]);

  const [nextPartition, setNextPartition] = useState<Partition | null>(null);

  useEffect(() => {
    if (partitionNav.state.state === 'EMPTY') {
      partitionNav.reload(partition);
      return;
    }

    if (partitionNav.state.state !== 'SUCCESS') return;

    if (!partitionNav.state.rows) return;

    if (!partitionNav.rangeContains(partition)) {
      partitionNav.reload(partition);
      return;
    }

    const currentIndex =
      partitionNav.state.rows?.findIndex(
        r => comparePartitions(r.partition, partition) === 0
      ) ?? -1;

    if (currentIndex === -1) {
      setNextPartition(null);
      return;
    }

    if (direction === 'forward') {
      if (currentIndex > 0) {
        setNextPartition(partitionNav.state.rows[currentIndex - 1].partition);
      } else {
        setNextPartition(null);
      }
      return;
    }

    if (direction === 'back') {
      if (currentIndex < partitionNav.state.rows.length - 1) {
        setNextPartition(partitionNav.state.rows[currentIndex + 1].partition);
        return;
      }

      setNextPartition(null);
      if (partitionNav.state.hasMore) {
        partitionNav.loadMore(3);
      }
    }
  }, [partitionNav, partition, direction]);

  return nextPartition ? (
    <PlatformPressable
      style={[styles.button, style]}
      onPress={() => {
        navigation.setParams({
          partition: PartitionSerialiser.stringify(nextPartition)
        });
      }}
      {...rest}
    >
      {direction === 'back' && (
        <LeftIcon
          fill={styles.icon.color}
          width={18}
          height={18}
          style={styles.iconContainer}
        />
      )}
      <Text.DocBody style={styles.buttonTitle}>
        {formatPartitionRelative(nextPartition, partition)}
      </Text.DocBody>
      {direction === 'forward' && (
        <RightIcon
          fill={styles.icon.color}
          width={18}
          height={18}
          style={styles.iconContainer}
        />
      )}
    </PlatformPressable>
  ) : (
    <View
      style={[styles.button, style as ViewStyle]}
      {...(rest as ViewProps)}
    />
  );
};
