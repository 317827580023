import { forwardRef, useMemo } from 'react';
import { Platform, ScrollView, ScrollViewProps } from 'react-native';
import { Edge, useSafeAreaInsets } from 'react-native-safe-area-context';
import { flattenStyle } from '../../lib/dynamic-style-sheet/flattenStyle';

export type PlatformScrollViewProps = ScrollViewProps & {
  safeAreaEdges?: Edge[];
};

const PlatformInsetsScrollView = forwardRef<
  ScrollView,
  PlatformScrollViewProps
>(
  (
    {
      contentContainerStyle,
      safeAreaEdges = ['left', 'right', 'bottom'],
      ...rest
    },
    ref
  ): JSX.Element => {
    const { left, right, top, bottom } = useSafeAreaInsets();

    const style = useMemo(() => {
      return flattenStyle([
        contentContainerStyle,
        {
          ...(safeAreaEdges?.includes('left') ? { paddingLeft: left } : {}),
          ...(safeAreaEdges?.includes('right') ? { paddingRight: right } : {}),
          ...(safeAreaEdges?.includes('top') ? { paddingTop: top } : {}),
          ...(safeAreaEdges?.includes('bottom')
            ? { paddingBottom: bottom }
            : {})
        }
      ]);
    }, [
      JSON.stringify(contentContainerStyle),
      JSON.stringify(safeAreaEdges),
      left,
      right,
      top,
      bottom
    ]);

    return <ScrollView contentContainerStyle={style} ref={ref} {...rest} />;
  }
);

const PlatformScrollView = forwardRef<ScrollView, PlatformScrollViewProps>(
  (props, ref): JSX.Element => {
    if (Platform.OS === 'ios') {
      return (
        <ScrollView
          contentInsetAdjustmentBehavior="automatic"
          ref={ref}
          {...props}
        />
      );
    }

    return <PlatformInsetsScrollView ref={ref} {...props} />;
  }
);

export default PlatformScrollView;
