import PricingCellGroup from '../cells/PricingCellGroup';
import LegacyCellGroup from '../../../../components/cells/LegacyCellGroup';
import EditVariantNameCell from '../cells/EditVariantNameCell';
import StockCellGroup from '../cells/StockCellGroup';

type VariantDataViewProps = {
  hideVariantName?: boolean;
};

const VariantDataView = ({
  hideVariantName = false
}: VariantDataViewProps): JSX.Element => {
  return (
    <>
      {!hideVariantName && (
        <LegacyCellGroup headingText="Variant name">
          <EditVariantNameCell />
        </LegacyCellGroup>
      )}

      <PricingCellGroup />

      <StockCellGroup />

      {/* 
        Hidden while we work out what to do with options

        <OptionsCellGroup /> 
      */}
    </>
  );
};

export default VariantDataView;
