import { Repository } from '../../../support/Repository';
import { newRandomUuid } from '../../../../../lib/uuid';
import { StockTransaction } from '../documents/StockTransaction';
import { Database } from '../../../support/Database';

export class StockTransactionRepository extends Repository<StockTransaction> {
  constructor(database: Database) {
    super(database, () => `stocktransaction-${newRandomUuid()}`);
  }
}
