import { ScrollViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import VariantDataView from '../../components/variant-data-view/VariantDataView';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

type VariantTabContentProps = Omit<ScrollViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingVertical: 6
  }
});

const VariantTabContent = (props: VariantTabContentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PlatformScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      safeAreaEdges={['left', 'right']}
      keyboardShouldPersistTaps="always"
      {...props}
    >
      <VariantDataView />
    </PlatformScrollView>
  );
};

export default VariantTabContent;
