export const downloadLocalCsv = (data: string[]): Promise<void> => {
  const blob = new Blob([data.join('')], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'products.csv';
  link.href = url;
  link.click();

  return Promise.resolve();
};
