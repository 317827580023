import { View, ViewProps } from 'react-native';
import { useCallback, useEffect, useState } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import ModalHeader from '../../../../components/modal-header/ModalHeader';
import { Tab } from '../../../../components/tabs/Tab';
import { TabBar } from '../../../../components/tabs/TabBar';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PartitionList from './PartitionList';
import { IconButton } from '../../../../components/buttons/IconButton';
import { CloseIcon } from '../../../../components/icons/CloseIcon';
import ListIcon from '../../../../components/icons/ListIcon';
import CalendarIcon from '../../../../components/icons/CalendarIcon';
import TickIcon from '../../../../components/icons/TickIcon';
import { PartitionCalendar } from '../partition-calendar/PartitionCalendar';
import { intervalStartDate } from '../../helpers/intervalStartDate';
import { useContrastTabTheme } from '../../../../components/tabs/useContrastTabTheme';
import NoIcon from '../../../../components/icons/NoIcon';
import { Partition } from '../../types/Partition';
import { partitionToTimeInterval } from '../../helpers/partitionToTimeInterval';
import { partitionToDate } from '../../helpers/partitionToDate';
import { partitionToIntervalDate } from '../../helpers/partitionToIntervalDate';
import { intervalDateToPartition } from '../../helpers/intervalDateToPartition';
import thenThrow from '../../../../lib/then-throw';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import { GlobalActivityIndicator } from '../../../../lib/global-activity/GlobalActivityIndicator';
import { LazyMount } from '../../../../components/tabs/LazyMount';
import { TimeInterval } from '../../types/TimeInterval';
import { withErrorBoundary } from '../../../../components/error-boundary/withErrorBoundary';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';

export type PartitionModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
  defaultPartition: Partition;
  onSelect(partition: Partition): void;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  tabBar: {
    backgroundColor: SemanticColours.secondary.background[100],
    flexGrow: 0
  },
  tab: {
    flexBasis: 100,
    flexGrow: 1
  },
  activity: {
    position: 'absolute',
    right: 60
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const TIME_INTERVALS: TimeInterval[] = ['day', 'week', 'month', 'year'];

const TABS = TIME_INTERVALS.map(interval => ({
  interval,
  title: interval.substring(0, 1).toUpperCase() + interval.substring(1)
}));

const PartitionModal = ({
  style,
  dismiss,
  visible,
  defaultPartition,
  onSelect,
  ...rest
}: PartitionModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [selectedInterval, setSelectedInterval] = useState(
    partitionToTimeInterval(defaultPartition)
  );

  const [selectedDate, setSelectedDate] = useState(
    partitionToDate(defaultPartition).toISODate() ??
      thenThrow('Invalid date from partition')
  );

  useEffect(() => {
    const [interval, date] = partitionToIntervalDate(defaultPartition);

    setSelectedDate(date);
    setSelectedInterval(interval);
  }, [defaultPartition]);

  const sendOnSelect = useCallback(
    (date: string) => {
      onSelect(
        intervalDateToPartition([
          selectedInterval,
          intervalStartDate([selectedInterval, date])
        ])
      );
    },
    [selectedInterval, selectedDate, onSelect]
  );

  const [mode, setMode] = useState<'list' | 'calendar'>('list');

  useEffect(() => {
    if (mode === 'calendar' && selectedInterval === 'year') {
      setSelectedInterval('month');
    }
  }, [mode]);

  const tabStyles = useContrastTabTheme();

  const size = useSizeClass();

  const localOnSelect = useCallback(
    (date: string) => {
      setSelectedDate(date);
      sendOnSelect(date);
      dismiss();
    },
    [sendOnSelect, dismiss]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} {...rest}>
      <View style={[styles.container, style]}>
        <ModalHeader>
          <IconButton
            iconComponent={CloseIcon}
            iconSize={22}
            onPress={() => dismiss()}
            iconColour={styles.headerTint.color}
          />
          <TabBar style={styles.tabBar}>
            <Tab
              icon={ListIcon}
              onPress={() => setMode('list')}
              isActive={mode === 'list'}
              title="List"
              styles={tabStyles}
              hideIndicator
            />
            <Tab
              icon={CalendarIcon}
              onPress={() => setMode('calendar')}
              isActive={mode === 'calendar'}
              title="Calendar"
              styles={tabStyles}
              hideIndicator
            />
          </TabBar>
          <IconButton
            iconComponent={mode === 'list' ? NoIcon : TickIcon}
            iconSize={22}
            onPress={() => {
              sendOnSelect(selectedDate);
              dismiss();
            }}
            disabled={mode === 'list' || typeof selectedDate === 'undefined'}
            iconColour={styles.headerTint.color}
          />
          {size === 'small' && (
            <GlobalActivityIndicator
              color={styles.headerTint.color}
              style={styles.activity}
            />
          )}
        </ModalHeader>
        <TabBar style={styles.tabBar}>
          {TABS.filter(t => mode !== 'calendar' || t.interval !== 'year').map(
            ({ interval, title }) => (
              <Tab
                style={styles.tab}
                styles={tabStyles}
                key={interval}
                title={title}
                isActive={selectedInterval === interval}
                onPress={() => setSelectedInterval(interval)}
              />
            )
          )}
        </TabBar>

        <LazyMount
          component={withErrorBoundary(PartitionList)}
          componentProps={{
            selectedInterval,
            selectedDate,
            onSelect: localOnSelect
          }}
          visible={mode === 'list' || selectedInterval === 'year'}
        />
        {selectedInterval !== 'year' && (
          <LazyMount
            component={withErrorBoundary(PartitionCalendar)}
            componentProps={{
              selectedInterval,
              selectedDate,
              onSelect: localOnSelect
            }}
            visible={mode === 'calendar'}
          />
        )}
      </View>
    </PlatformModal>
  );
};

export default PartitionModal;
