import { View } from 'react-native';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { SemanticColours } from '../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { NavNavigatorParamList } from '../navigation/NavNavigatorParamList';
import { HeaderNavigation } from '../../components/header-navigation/HeaderNavigation';
import { nameof } from '../../lib/name-of';

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[70],
    flex: 1
  },
  header: {
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  }
});

export const HomeScreen = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<DrawerNavigationProp<NavNavigatorParamList>>();

  useEffect(() => {
    navigation.setOptions({
      headerLeft: headerProps => <HeaderNavigation {...headerProps} />,
      headerShown: true,
      headerStyle: styles.header,
      headerTransparent: true,
      headerTitle: ''
    });
  }, [navigation]);

  return <View style={styles.container} testID={nameof({ HomeScreen })} />;
};
