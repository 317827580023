import { createContext } from 'react';
import { SecurityScheme } from './SecurityScheme';

export interface SecuritySchemeContextType {
  currentScheme: SecurityScheme;
  setCurrentScheme(scheme: SecurityScheme): void;
}

export const SecuritySchemeContext = createContext<
  SecuritySchemeContextType | undefined
>(undefined);
