import { ScrollViewProps } from 'react-native';
import { useEffect, useMemo, useState } from 'react';
import Reanimated, { Easing, Layout } from 'react-native-reanimated';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { useSecurityDb } from '../../../../../../data-model/components/client-context/useSecurityDb';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import EmptyList from '../../../../components/empty-list/EmptyList';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type UsersListProps = Omit<ScrollViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[90]
  },
  content: {
    marginVertical: 14
  },
  subtitle: {
    flexShrink: 0
  }
}).override(Selectors.mediumOrLarge, {
  content: {
    marginVertical: 24
  }
});

const UsersList = ({ style, ...rest }: UsersListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const securityDb = useSecurityDb();
  const transactionsDb = useTransactionsDb();

  const users = useQueryStream(securityDb.local.design.views.allUsers);

  const partition = useActivePartition();

  const intervalDate = useMemo(
    () => partitionToIntervalDate(partition),
    [partition]
  );

  const sales = useQueryStream(
    transactionsDb.remote.design.dashboardUsersForDate.view,
    {
      groupLevel: 3,
      reduce: true,
      startKey: intervalDate,
      endKey: [...intervalDate, []]
    }
  );

  const [cache, setCache] = useState<{ documentID: string; name: string }[]>(
    []
  );

  useEffect(() => {
    if (!users.data || !sales.data) return;

    setCache(
      users.data
        ?.innerJoin(sales.data, (l, r) => l.documentID === r.key[2])
        .map(d => ({
          documentID: d.left.documentID,
          name: d.left.key[1]
        }))
        .sortBy(r => r.name)
    );
  }, [users, sales]);

  const { data, loading } = useTransform(
    sales,
    rows =>
      users.data
        ?.innerJoin(rows, (l, r) => l.documentID === r.key[2])
        .map(({ left, right: { value } }) => ({
          documentID: left.documentID,
          name: left.key[1],
          value: formatMoney(
            [
              value.Sale?.value,
              value.Void?.value,
              value.Refund?.value,
              value.Promotion?.value
            ]
              .compact()
              .sum() ?? 0
          )
        }))
        .sortBy(r => r.name) ?? [],
    () =>
      cache.map(({ documentID, name }) => ({
        documentID,
        name,
        value: ' '
      })) ?? [],
    [users]
  );

  const { cell, Separator } = useCellGroupStyles('left');

  const navigation =
    useNavigation<
      StackNavigationProp<DashboardNavigatorParamList, 'show' | 'users'>
    >();

  return (
    <Reanimated.FlatList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      ListEmptyComponent={() => <EmptyList loading={loading} />}
      style={[styles.container, style]}
      contentContainerStyle={styles.content}
      data={data}
      keyExtractor={d => d.documentID}
      initialNumToRender={15}
      ItemSeparatorComponent={Separator}
      itemLayoutAnimation={Layout.easing(Easing.ease)}
      renderItem={({ item, index }) => (
        <BasicCell
          iconRight={loading ? undefined : ForwardIcon}
          title={item.name}
          subtitle={item.value}
          titlesOrientation="horizontal"
          style={cell(index === 0, index === data.length - 1)}
          onPress={
            loading
              ? undefined
              : () => {
                  navigation.push('user', {
                    partition: PartitionSerialiser.stringify(partition),
                    userId: item.documentID
                  });
                }
          }
        />
      )}
      {...rest}
    />
  );
};

export default UsersList;
