/* eslint-disable func-names */
import { Database } from '../../../support/Database';
import { DesignDocumentDefinition } from '../../../support/DesignDocument';
import { AllUsersView } from './views/AllUsersView';

export class DesignDoc {
  private static documentId = '_design/views';

  static views = {
    [AllUsersView.viewName]: AllUsersView
  } as const;

  static definition = new DesignDocumentDefinition(DesignDoc.documentId, {
    views: Object.fromEntries(
      Object.entries(DesignDoc.views).map(([name, View]) => [
        name,
        View.definition
      ])
    )
  });

  constructor(private readonly database: Database) {}

  public async ensureDefined(): Promise<void> {
    await DesignDoc.definition.ensureDefined(this.database.pouch);
  }

  public readonly views = Object.fromEntries(
    Object.entries(DesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, DesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof DesignDoc.views]: InstanceType<
      (typeof DesignDoc)['views'][P]
    >;
  };
}
