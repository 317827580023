import { TupleToUnion } from '../../../../../lib/ts-tuple/TupleToUnion';

export type PreparationOrderLine = {
  ID: string;
  productID: string;
  notes?: string;
  optionSetSelections: Record<string, string[]>;
  serviceTransactionLineID?: string;
};

export const PreparationOrderStatuses = [
  '01 Pending',
  '02 Released',
  '03 In Progress',
  '04 Ready',
  '05 Collected'
] as const;

export type PreparationOrderStatus = TupleToUnion<
  typeof PreparationOrderStatuses
>;

export type PreparationOrderStatusHistory = {
  ID: string;
  status: PreparationOrderStatus;
  time: string;
  userID: string;
};

export type PreparationOrder = {
  preparationZone: string;
  customerType: string;
  tabID?: string;
  customerName?: string;
  tableNumber?: string;
  userID: string;
  time: string;
  course: string;
  lines: PreparationOrderLine[];
  status?: PreparationOrderStatus;
  statuses?: Partial<Record<PreparationOrderStatus, string>>;
  statusHistory?: PreparationOrderStatusHistory[];
  serviceTransactionID?: string;
  deleted?: boolean;
};
