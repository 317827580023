import Cell from '../../../../../../components/cells/Cell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../../../components/cells/LegacyCellGroup';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import { isCalendarDayPartition } from '../../../../helpers/isCalendarDayPartition';
import TotalSalesValue from '../total-sales-value/TotalSalesValue';
import DaySalesVisual from '../day-sales-visual/DaySalesVisual';

export type TotalSalesCellGroupProps = Omit<LegacyCellGroupProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: { flexDirection: 'column' }
});

const TotalSalesCellGroup = (props: TotalSalesCellGroupProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const partition = useActivePartition();

  return (
    <LegacyCellGroup headingText="Total sales" {...props}>
      <Cell style={[styles.container]}>
        <TotalSalesValue />

        {isCalendarDayPartition(partition) && <DaySalesVisual />}
      </Cell>
    </LegacyCellGroup>
  );
};

export default TotalSalesCellGroup;
