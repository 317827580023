/* eslint-disable no-nested-ternary */

/*
 * Shameless rip off from https://unpkg.com/browse/@react-navigation/elements@1.2.1/src/PlatformPressable.tsx
 * Added ref.
 */

import * as React from 'react';
import {
  Animated,
  Easing,
  GestureResponderEvent,
  Platform,
  Pressable,
  View
} from 'react-native';
import { PlatformPressableProps } from './PlatformPressableProps';
import { useTheme } from '../../lib/theme-context';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const ANDROID_VERSION_LOLLIPOP = 21;
const ANDROID_SUPPORTS_RIPPLE =
  Platform.OS === 'android' && Platform.Version >= ANDROID_VERSION_LOLLIPOP;

/**
 * PlatformPressable provides an abstraction on top of Pressable to handle platform differences.
 */
const PlatformPressable = React.forwardRef<View, PlatformPressableProps>(
  (
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { onPressIn, onPressOut, android_ripple, pressColor, style, ...rest },
    ref
  ): JSX.Element => {
    const theme = useTheme();
    const [opacity] = React.useState(() => new Animated.Value(1));

    const animateTo = (toValue: number, duration: number) => {
      if (ANDROID_SUPPORTS_RIPPLE) {
        return;
      }

      Animated.timing(opacity, {
        toValue,
        duration,
        easing: Easing.inOut(Easing.quad),
        useNativeDriver: true
      }).start();
    };

    const handlePressIn = (e: GestureResponderEvent) => {
      animateTo(0.3, 0);
      onPressIn?.(e);
    };

    const handlePressOut = (e: GestureResponderEvent) => {
      animateTo(1, 200);
      onPressOut?.(e);
    };

    return (
      <AnimatedPressable
        ref={ref}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        android_ripple={
          ANDROID_SUPPORTS_RIPPLE
            ? {
                color:
                  pressColor !== undefined
                    ? pressColor
                    : theme === 'dark'
                    ? 'rgba(255, 255, 255, .32)'
                    : 'rgba(0, 0, 0, .32)',
                ...android_ripple
              }
            : undefined
        }
        style={[{ opacity: !ANDROID_SUPPORTS_RIPPLE ? opacity : 1 }, style]}
        accessibilityRole="button"
        {...rest}
      />
    );
  }
);

export default PlatformPressable;
