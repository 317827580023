import { AppRegistry } from 'react-native';
import DashboardNavigator from '../../features/dashboard/navigation/DashboardNavigator';
import SiteEntryPoint, { SiteEntryPointProps } from '../SiteEntryPoint';
import { nameof } from '../../lib/name-of';

type DashboardEntryPointProps = Omit<SiteEntryPointProps, 'navigatorComponent'>;

const DashboardEntryPoint = (props: DashboardEntryPointProps): JSX.Element => {
  return <SiteEntryPoint {...props} navigatorComponent={DashboardNavigator} />;
};

AppRegistry.registerComponent(
  nameof({ DashboardEntryPoint }),
  () => DashboardEntryPoint
);
