import { ScrollView, ScrollViewProps, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export type ScrollTabBarProps = ScrollViewProps;

const styles = StyleSheet.create({
  root: {
    flexGrow: 0,
    flexShrink: 0
  },
  bar: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
});

export const ScrollTabBar = ({
  contentContainerStyle,
  style,
  ...rest
}: ScrollTabBarProps): JSX.Element => {
  const { left, right } = useSafeAreaInsets();

  return (
    <ScrollView
      horizontal
      style={[styles.root, style]}
      contentContainerStyle={[
        styles.bar,
        {
          paddingLeft: left,
          paddingRight: right
        },
        contentContainerStyle
      ]}
      {...rest}
    />
  );
};
