import { HeaderTitleProps } from '@react-navigation/elements';
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PlatformPressable from '../../../components/platform-pressable/PlatformPressable';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { Colours } from '../../../theme/Colours';
import { SemanticColours } from '../../../theme/SemanticColours';
import { Text } from '../../../components/text/Text';
import SelectIcon from '../../../components/icons/SelectIcon';
import { useReferenceDb } from '../../../data-model/components/site-context/useReferenceDb';
import { PrepNavigatorParamList } from '../navigation/PrepNavigatorParamList';
import { useQueryStream } from '../../../data-model/hooks/useQueryStream';
import SelectFromListModal from '../../../components/select-from-list-modal/SelectFromListModal';

const rStyles = DynamicStyleSheet.create({
  button: {
    backgroundColor: Colours.transparent.$,
    padding: 6,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10
  },
  title: {
    marginHorizontal: 6,
    color: SemanticColours.secondary.foreground[100]
  },
  icon: {
    marginHorizontal: 3
  }
});

const ZoneSwitcherWithZone = ({
  style,
  prepZoneId
}: HeaderTitleProps & { prepZoneId: string }): JSX.Element => {
  const navigation = useNavigation<NavigationProp<PrepNavigatorParamList>>();

  const styles = useDynamicStyleSheet(rStyles);

  const zones = useQueryStream(
    useReferenceDb().local.design.views.allPreparationZones,
    { includeDocs: true }
  );

  const zoneName = useMemo(() => {
    return zones.data?.find(z => z.documentID === prepZoneId)?.document.name;
  }, [zones, prepZoneId]);

  useEffect(() => {
    navigation.setOptions({
      title: ['Prep', zoneName].compact().join(' | ')
    });
  }, [navigation, zoneName]);

  const options = useMemo(() => {
    return (
      zones.data?.map(row => ({
        id: row.documentID,
        name: row.document.name ?? 'No name'
      })) ?? []
    );
  }, [zones]);

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const onAccept = useCallback(
    (id: string) => {
      navigation.setParams({ prepZoneId: id });
      setModalVisible(false);
    },
    [navigation]
  );

  return (
    <>
      <PlatformPressable
        style={[style, styles.button]}
        onPress={() => setModalVisible(true)}
      >
        <Text.HeaderTitle style={styles.title}>
          {zoneName ?? ''}
        </Text.HeaderTitle>
        <SelectIcon width={18} height={18} style={styles.icon} />
      </PlatformPressable>

      <SelectFromListModal
        immediate
        extractItemId={d => d.id}
        extractItemTitle={d => d.name}
        items={options}
        onAccept={onAccept}
        onDismiss={dismissModal}
        selectedItem={prepZoneId}
        title="Select zone"
        visible={modalVisible}
        loading={zones.loading}
      />
    </>
  );
};

const ZoneSwitcher = (props: HeaderTitleProps): JSX.Element => {
  const route = useRoute<RouteProp<PrepNavigatorParamList>>();

  return route.params?.prepZoneId ? (
    <ZoneSwitcherWithZone {...props} prepZoneId={route.params.prepZoneId} />
  ) : (
    <></>
  );
};

export default ZoneSwitcher;
