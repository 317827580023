import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { NavNavigatorParamList } from '../../NavNavigatorParamList';
import { DrawerToggle } from '../../../../components/drawer-toggle/DrawerToggle';
import { NotAuthorisedContent } from '../../../../components/unhappy-path-content/not-authorised/NotAuthorisedContent';

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[70],
    flex: 1
  },
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const UnauthorisedScreen = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<DrawerNavigationProp<NavNavigatorParamList>>();

  useEffect(() => {
    navigation.setOptions({
      headerStyle: styles.header,
      headerTintColor: styles.headerTint.color,
      headerLeft: () => <DrawerToggle />,
      headerShown: true,
      title: 'Unauthorised'
    });
  }, [styles]);

  return (
    <View style={styles.container}>
      <NotAuthorisedContent objectDescription="this" />
    </View>
  );
};

export default UnauthorisedScreen;
