import {
  Chart as OrigChart,
  Line,
  Area,
  HorizontalAxis
} from 'react-native-responsive-linechart';
import React, { useMemo } from 'react';
import { PropsType } from '../../../../../../lib/react-props-type';
import { Tooltip } from './Tooltip';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../../../theme/SemanticColours';

type ChartProps = Omit<PropsType<typeof OrigChart>, 'children'> & {
  primaryOrigin: number;
  primarySeries: {
    x: number;
    y: number;
  }[];
  secondarySeries: {
    x: number;
    y: number;
  }[];
  xAxis: { min: number; max: number; ticks: number[] };
  yAxis: { max: number };
};

const rStyles = DynamicStyleSheet.create({
  padding: {
    left: 40,
    bottom: 30,
    right: 40,
    top: 6
  },
  primaryLine: {
    color: SemanticColours.primary.contrast[100]
  },
  primaryArea: { color: SemanticColours.primary.contrast[80] },
  secondaryLine: {
    color: SemanticColours.primary.foreground[25]
  },
  text: {
    color: SemanticColours.primary.foreground[80]
  },
  gridLines: {
    color: SemanticColours.primary.foreground[30]
  }
});

const Chart = React.memo(
  ({
    primaryOrigin,
    primarySeries,
    secondarySeries,
    xAxis,
    yAxis,
    ...rest
  }: ChartProps): JSX.Element => {
    const styles = useDynamicStyleSheet(rStyles);

    const horizontalAxisTheme: PropsType<typeof HorizontalAxis>['theme'] =
      useMemo(
        () => ({
          axis: {
            stroke: {
              color: styles.gridLines.color,
              width: 1
            }
          },
          grid: {
            stroke: {
              color: styles.gridLines.color,
              width: 1
            }
          },
          labels: {
            label: {
              color: styles.text.color,
              fontSize: 12,
              dy: -20,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
            },
            formatter: (v: number) =>
              new Date(v + primaryOrigin).toTimeString().substring(0, 5)
          },
          ticks: {
            visible: false
          }
        }),
        [primaryOrigin, styles]
      );

    const yDomain = useMemo(() => ({ min: 0, max: yAxis.max * 1.2 }), [yAxis]);

    const xDomain = useMemo(
      () => ({
        min: xAxis.min,
        max: xAxis.max
      }),
      [xAxis]
    );

    const secondaryLineTheme = useMemo(
      () => ({
        stroke: { color: styles.secondaryLine.color, width: 2 }
      }),
      [styles]
    );

    const primaryAreaTheme = useMemo(
      () => ({
        gradient: {
          from: { color: styles.primaryArea.color, opacity: 0.4 },
          to: { color: styles.primaryArea.color, opacity: 0.2 }
        }
      }),
      [styles]
    );

    const primaryLineTheme = useMemo(
      () => ({
        stroke: { color: styles.primaryLine.color, width: 4 },
        scatter: {
          selected: {
            color: styles.primaryLine.color,
            width: 8,
            height: 8,
            rx: 4
          }
        }
      }),
      [styles]
    );

    return (
      <OrigChart
        {...rest}
        padding={styles.padding}
        yDomain={yDomain}
        xDomain={xDomain}
      >
        {secondarySeries.any() && (
          <Line data={secondarySeries} theme={secondaryLineTheme} />
        )}

        {primarySeries.any() && (
          <Area data={primarySeries} theme={primaryAreaTheme} />
        )}

        {primarySeries.any() && (
          <Line
            data={primarySeries}
            theme={primaryLineTheme}
            tooltipComponent={<Tooltip />}
          />
        )}

        <HorizontalAxis tickValues={xAxis.ticks} theme={horizontalAxisTheme} />
      </OrigChart>
    );
  }
);

export default Chart;
