import { ComponentType, useMemo } from 'react';
import { DateTime } from 'luxon';
import { SvgProps } from 'react-native-svg';
import { useServingZoneNameLookup } from '../../../../../../data-model/components/site-context/useServingZoneNameLookup';
import { useProductNameLookup } from '../../../../../../data-model/components/site-context/useProductNameLookup';
import { useUsernameLookup } from '../../../../../../data-model/components/client-context/useUserNameLookup';
import AddIcon from '../../../../../../components/icons/AddIcon';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import VoidIcon from '../../../../../../components/icons/VoidIcon';
import { usePromotionsLookup } from '../../../../../../data-model/components/site-context/usePromotionsLookup';
import { useVoucherNamesLookup } from '../../../../../../data-model/components/site-context/useVoucherNamesLookup';
import { useLogger } from '../../../../../../lib/logging/useLogger';
import { nameof } from '../../../../../../lib/name-of';
import {
  AnyServiceTransactionLine,
  ServiceTransaction,
  ServiceTransactionSession
} from '../../../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import { Logger } from '../../../../../../lib/logging';
import { useSiteTime } from '../../../../hooks/useSiteTime';

export type Line =
  | {
      key: string;
      type: 'EVENT';
      description: string;
    }
  | {
      key: string;
      type: 'SALE' | 'PAYMENT' | 'PROMOTION';
      IconComponent: ComponentType<SvgProps>;
      description: string;
      amount: string;
    };

export type LineGroup = {
  key: string;
  dt: DateTime;
  lines: Line[];
};

type Session = {
  key: string;
  data: LineGroup[];
};

type TransactionLinesData = {
  sessions: Session[];
};

type NameLookups = {
  users: { [id: string]: string };
  servingZones: { [id: string]: string };
  products: { [id: string]: string };
  promotions: { [id: string]: string };
  vouchers: { [id: string]: string };
};

const mapLine = (
  line: AnyServiceTransactionLine,
  lookups: NameLookups,
  log: Logger
): Line | null => {
  const key = line.time;

  const amount = formatMoney(+line.amount * 100);

  const IconComponent = line.reversalType ? VoidIcon : AddIcon;

  switch (line.lineType) {
    case 'Sale': {
      return {
        key,
        type: 'SALE',
        IconComponent,
        description: lookups.products[line.productID],
        amount
      };
    }

    case 'Promotion': {
      return {
        key,
        type: 'PROMOTION',
        IconComponent,
        description: lookups.promotions[line.promotionID],
        amount
      };
    }

    case 'Payment': {
      return {
        key,
        type: 'PAYMENT',
        IconComponent,
        description: (() => {
          if (line.paymentType === 'Voucher' && line.voucherID) {
            return `${lookups.vouchers[line.voucherID]} voucher payment`;
          }

          if (line.paymentType === 'NoSale') {
            return 'No Sale';
          }

          const reason = (() => {
            if (line.paymentReason === 'Change') return 'change';
            if (line.paymentReason === 'Refund') return 'refund';
            return 'payment';
          })();

          return `${line.paymentType} ${reason}`;
        })(),
        amount
      };
    }

    default:
      log.warn('Unrecognised line type', { line });

      return null;
  }
};

const mapSessions = (
  document: ServiceTransaction,
  lookups: NameLookups,
  timeZone: string,
  log: Logger
): Session[] => {
  if (!document.sessions) return [];

  const { users, servingZones } = lookups;

  let lastSession: ServiceTransactionSession | undefined;

  return document.sessions.map((session, index) => {
    const lines = document.lines.filter(l => l.sessionID === session.ID);

    let currentLineGroup: LineGroup = {
      key: session.ID,
      dt: DateTime.fromISO(session.startTime, { zone: timeZone }),
      lines: [
        {
          key: `${session.ID}-start`,
          type: 'EVENT',
          description: [
            lastSession ? 'Opened' : 'Created',

            'by',
            users[session.userID],

            ...(!lastSession ||
            lastSession.servingZoneID !== session.servingZoneID
              ? ['in', servingZones[session.servingZoneID]]
              : [])
          ].join(' ')
        }
      ]
    };

    lastSession = session;

    const groups: LineGroup[] = [currentLineGroup];

    lines.forEach(line => {
      const groupDt = DateTime.fromISO(line.time, { zone: timeZone });

      if (groupDt.diff(currentLineGroup.dt).as('seconds') > 5) {
        currentLineGroup = {
          key: line.ID,
          dt: groupDt,
          lines: []
        };

        groups.push(currentLineGroup);
      }

      const lineOut = mapLine(line, lookups, log);

      if (lineOut) {
        currentLineGroup.lines.push(lineOut);
      }
    });

    if (session.endTime) {
      groups.push({
        key: `${session.ID}-end-line`,
        dt: DateTime.fromISO(session.endTime, { zone: timeZone }),
        lines: [
          {
            key: `${session.ID}-end`,
            type: 'EVENT',
            description:
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              index === document.sessions!.length - 1 &&
              document.state === 'Closed'
                ? 'Closed'
                : 'Saved'
          }
        ]
      });
    }

    return { key: session.ID, data: groups };
  });
};

const useTransactionLinesData = (
  document: ServiceTransaction | null | undefined
) => {
  const log = useLogger(nameof({ useTransactionLinesData }));

  const { data: servingZones } = useServingZoneNameLookup();
  const { data: products } = useProductNameLookup();
  const { data: users } = useUsernameLookup();
  const { data: promotions } = usePromotionsLookup();
  const { data: vouchers } = useVoucherNamesLookup();

  const { timeZone } = useSiteTime();

  const result: TransactionLinesData | 'NOT_FOUND' = useMemo(() => {
    if (!document) {
      return { sessions: [] };
    }

    const lookups = {
      servingZones,
      products,
      users,
      promotions,
      vouchers
    };

    return {
      sessions: mapSessions(document, lookups, timeZone, log)
    };
  }, [document, servingZones, products, users, vouchers, promotions, timeZone]);

  return result;
};

export default useTransactionLinesData;
