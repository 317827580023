import React from 'react';
import { View, ViewProps, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import SelectIcon from '../../../../components/icons/SelectIcon';

export type ClientSwitchContentProps = Omit<ViewProps, 'children'> & {
  clientName: string;
  userName: string;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'center',
    gap: 16,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20
  },
  clientName: {
    color: SemanticColours.primary.foreground[80],
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 24
  },
  userName: {
    color: SemanticColours.primary.foreground[60],
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 24
  },
  userAndClient: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0
  },
  icon: {
    width: 20,
    height: 20,
    color: SemanticColours.primary.foreground[60]
  }
});

const ClientSwitchContent = React.memo<ClientSwitchContentProps>(
  ({ style, clientName, userName, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style]} {...rest}>
        <View style={styles.userAndClient}>
          <Text style={styles.clientName}>{clientName}</Text>
          <Text style={styles.userName}>{userName}</Text>
        </View>
        <SelectIcon
          fill={styles.icon.color}
          width={styles.icon.width}
          height={styles.icon.height}
        />
      </View>
    );
  }
);

export default ClientSwitchContent;
