import CellGroup, {
  CellGroupProps
} from '../../../../components/cells/CellGroup';
import StockIcon from '../../../../components/icons/StockIcon';
import { useVariantDataContext } from '../../data/VariantDataContext';
import StockBalanceCell from './StockBalanceCell';
import StockModeCell from './StockModeCell';

export type StockCellGroupProps = Omit<
  CellGroupProps,
  'headingText' | 'headingIconComponent' | 'children'
>;

const StockCellGroup = (props: StockCellGroupProps): JSX.Element => {
  const { variant } = useVariantDataContext();

  return (
    <CellGroup headingText="Stock" headingIconComponent={StockIcon} {...props}>
      <StockModeCell />

      {variant?.stock?.mode === 'TRACKED' && <StockBalanceCell />}
    </CellGroup>
  );
};

export default StockCellGroup;
