import { StyleSheet, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type VerticalDividerProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  line: {
    backgroundColor: SemanticColours.primary.foreground[15],
    width: StyleSheet.hairlineWidth
  }
});

const VerticalDivider = ({
  style,
  ...rest
}: VerticalDividerProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return <View style={[styles.line, style]} {...rest}></View>;
};

export default VerticalDivider;
