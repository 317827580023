import React, { useMemo } from 'react';
import { btoa } from 'react-native-quick-base64';
import { QueryAuthorisationContext } from '../context/QueryAuthorisationContext';
import { AuthorisationContextType } from '../context/AuthorisationContextType';
import { ReplicationAuthorisationContext } from '../context/ReplicationAuthorisationContext';
import { ClientContextProvider } from '../../data-model/components/client-context/ClientContext';
import UserContext from '../context/UserContext';
import { SiteContextProvider } from '../../data-model/components/site-context/SiteContextProvider';
import { addAuthorisationHeader } from '../helpers/addAuthorisationHeader';
import DeviceContext from '../../components/device-context/DeviceContext';
import PouchDB from '../../data-model/pouchdb';
import { RoleId } from '../../data-model/schema/databases/client-security/documents/Role';

export type LegacySecurityProviderProps = React.PropsWithChildren<{
  userId: string;
  username: string;
  fullName: string;
  role: RoleId;
  clientId: string;
  siteId: string;
  deviceId: string;
  dbUsername: string;
  dbPassword: string;
}>;

export const LegacySecurityProvider = ({
  children,
  clientId,
  deviceId,
  userId,
  username,
  fullName,
  role,
  siteId,
  dbUsername,
  dbPassword
}: LegacySecurityProviderProps): JSX.Element => {
  const authorisation: AuthorisationContextType = useMemo(() => {
    const encoded = btoa([dbUsername, dbPassword].join(':'));

    const headerValue = `Basic ${encoded}`;

    return {
      authoriser: {
        authorisedFetch: (url, opts) =>
          PouchDB.fetch(url, addAuthorisationHeader(opts, headerValue))
      }
    };
  }, [dbUsername, dbPassword]);

  return (
    <QueryAuthorisationContext.Provider value={authorisation}>
      <ReplicationAuthorisationContext.Provider value={authorisation}>
        <ClientContextProvider clientId={clientId}>
          <DeviceContext.Provider value={{ deviceId }}>
            <UserContext.Provider value={{ userId, username, fullName, role }}>
              <SiteContextProvider siteId={siteId}>
                {children}
              </SiteContextProvider>
            </UserContext.Provider>
          </DeviceContext.Provider>
        </ClientContextProvider>
      </ReplicationAuthorisationContext.Provider>
    </QueryAuthorisationContext.Provider>
  );
};
