import { StackScreenProps } from '@react-navigation/stack';
import { View } from 'react-native';
import { ComponentType, memo } from 'react';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import {
  ActivePartitionContextProvider,
  useActivePartition
} from '../../components/active-partition-context/ActivePartitionContext';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { DefaultPartitionNavigation } from '../../navigation/DefaultPartitionNavigation';
import { PartitionNavigationBar } from '../../components/partition-navigation-bar/PartitionNavigationBar';
import Header from '../../components/header/Header';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { useSecurityDb } from '../../../../data-model/components/client-context/useSecurityDb';
import UserTransactionsList from './components/user-transactions/UserTransactionsList';
import UserSummary from './components/user-summary/UserSummary';
import VerticalDivider from '../../../../components/vertical-divider/VerticalDivider';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { isCalendarDayPartition } from '../../helpers/isCalendarDayPartition';
import UserSubIntervalsList from './components/user-sub-intervals/UserSubIntervalsList';
import { UserPartitionNavigationProvider } from '../../components/partition-navigation-context/UserPartitionNavigationProvider';
import { assertRouteParam } from '../../../../lib/assert-route-param';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { formatUserFullName } from '../../../../humanisers/formatUserFullName';

export type UserScreenProps = StackScreenProps<
  DashboardNavigatorParamList,
  'user'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'row',
    backgroundColor: SemanticColours.primary.background[90]
  },
  pane: {
    flex: 1
  }
}).override(Selectors.mediumOrLarge, {
  pane: {
    flexBasis: 477,
    flexGrow: 0,
    flexShrink: 0
  },
  rightPane: {
    flexGrow: 1
  }
});

const UserScreenContent = memo(
  ({
    ListHeaderComponent
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ListHeaderComponent?: ComponentType<any>;
  }): JSX.Element => {
    const partition = useActivePartition();

    if (isCalendarDayPartition(partition)) {
      return <UserTransactionsList ListHeaderComponent={ListHeaderComponent} />;
    }

    return <UserSubIntervalsList ListHeaderComponent={ListHeaderComponent} />;
  }
);

const UserScreen = ({ route }: UserScreenProps): JSX.Element => {
  const userId = assertRouteParam(route.params, 'userId');

  const styles = useDynamicStyleSheet(rStyles);

  const db = useSecurityDb();

  const { document: user } = useDocument(db.local.repositories.users, userId);

  const size = useSizeClass();

  return (
    <ErrorBoundary>
      <UserPartitionNavigationProvider userId={userId}>
        <DefaultPartitionNavigation />

        <ActivePartitionContextProvider>
          <Header title={user ? formatUserFullName(user) : undefined} />
          <PartitionNavigationBar />
          <View style={styles.container}>
            <View style={styles.pane}>
              {size !== 'small' && <UserSummary />}

              {size === 'small' && (
                <UserScreenContent ListHeaderComponent={UserSummary} />
              )}
            </View>
            {size !== 'small' && (
              <>
                <VerticalDivider />
                <View style={styles.rightPane}>
                  <UserScreenContent />
                </View>
              </>
            )}
          </View>
        </ActivePartitionContextProvider>
      </UserPartitionNavigationProvider>
    </ErrorBoundary>
  );
};

export default UserScreen;
