import React from 'react';
import { View, ViewProps, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ActionButtonBar from '../../../../components/buttons/ActionButtonBar';
import ActionButton from '../../../../components/buttons/ActionButton';
import LogOutIcon from '../../../../components/icons/LogOutIcon';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';

export type ActiveClientCellProps = Omit<ViewProps, 'children'> & {
  clientName: string;
  userFullName: string;
  username: string;
  onLogOutPress: () => void;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: 11,
    alignSelf: 'stretch',
    backgroundColor: SemanticColours.secondary.background[90],
    paddingVertical: 18,
    paddingHorizontal: 28
  },
  clientName: {
    alignSelf: 'stretch',
    color: SemanticColours.secondary.foreground[90],
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 26
  },
  user: {
    alignSelf: 'stretch',
    color: SemanticColours.secondary.foreground[90]
  }
}).override(Selectors.mediumOrLarge, {
  root: {
    paddingVertical: 22,
    paddingHorizontal: 32,
    borderRadius: 7
  },
  clientName: {
    fontSize: 19
  }
});

const ActiveClientCell = React.memo<ActiveClientCellProps>(
  ({ style, clientName, userFullName, username, onLogOutPress, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style]} {...rest}>
        <Text style={styles.clientName}>{clientName}</Text>
        <Text style={styles.user}>
          {userFullName} ({username})
        </Text>

        <ActionButtonBar>
          <ActionButton
            colourScheme="secondary"
            label="Log out"
            iconComponent={LogOutIcon}
            onPress={() => onLogOutPress()}
          />
        </ActionButtonBar>
      </View>
    );
  }
);

export default ActiveClientCell;
