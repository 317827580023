import Svg, { SvgProps, Path } from 'react-native-svg';

const AddIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 30 30" width={24} height={24} fill="#FFFFFF" {...props}>
    <Path
      translate={[4, 4]}
      d="M11.977 2.378A1.6 1.6 0 0 0 10.4 4v6.4H4a1.6 1.6 0 1 0 0 3.2h6.4V20a1.6 1.6 0 1 0 3.2 0v-6.4H20a1.6 1.6 0 1 0 0-3.2h-6.4V4a1.602 1.602 0 0 0-1.623-1.622Z"
    />
  </Svg>
);

export default AddIcon;
