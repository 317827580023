import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../components/cells/LegacyCellGroup';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import AddVariantCell from './AddVariantCell';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';

export type VariantsIntroCellGroupProps = Omit<
  LegacyCellGroupProps,
  'children' | 'headingText' | 'footerText'
>;

const VariantsIntroCellGroup = (
  props: VariantsIntroCellGroupProps
): JSX.Element => {
  const navigation = useNavigation<NavigationProp<ProductNavigatorParamList>>();

  const onVariantCreated = useCallback(
    (variantId: string, productId: string) => {
      navigation.navigate('variant', {
        productId,
        variantId
      });
    },
    [navigation]
  );

  return (
    <AuthorisedContent minRole={RoleId.superUser}>
      <LegacyCellGroup
        headingText="Variants"
        footerText="Use variants to add different sizes or flavours of the same product"
        {...props}
      >
        <AddVariantCell onVariantCreated={onVariantCreated} />
      </LegacyCellGroup>
    </AuthorisedContent>
  );
};

export default VariantsIntroCellGroup;
