import { ComponentType } from 'react';
import { Text, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import { PropsType } from '../../lib/react-props-type';
import PlatformPressable from '../platform-pressable/PlatformPressable';

export type ButtonProps = Omit<
  PropsType<typeof PlatformPressable>,
  'children'
> & {
  iconComponent?: ComponentType<SvgProps>;
  title: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: SemanticColours.secondary.background[100],
    borderRadius: 4,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 18,
    paddingVertical: 9
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 11,
    color: SemanticColours.secondary.foreground[100]
  },
  title: {
    color: SemanticColours.secondary.foreground[100],
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 0.6,
    lineHeight: 26,
    textTransform: 'uppercase'
  }
});

const CallToActionButton = ({
  iconComponent: Icon,
  style,
  title,
  ...rest
}: ButtonProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <PlatformPressable
      style={[styles.container, style as ViewStyle]}
      {...rest}
      accessibilityLabel={title}
    >
      {Icon && (
        <Icon
          width={styles.icon.width}
          height={styles.icon.height}
          style={styles.icon}
          color={styles.icon.color}
        />
      )}
      <Text style={styles.title}>{title}</Text>
    </PlatformPressable>
  );
};

export default CallToActionButton;
