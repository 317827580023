import { ComponentType, memo } from 'react';
import { ErrorBoundary } from './ErrorBoundary';

export function withErrorBoundary<const TProps>(
  Component: ComponentType<TProps>
): ComponentType<TProps> {
  return memo(props => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  ));
}
