import Svg, { SvgProps, Path } from 'react-native-svg';

const ExpandIcon = ({ fill = '#ffffff', ...rest }: SvgProps) => (
  <Svg viewBox="0 0 15 15" width={15} height={15} {...rest}>
    <Path
      d="M12.495 4.493a.5.5 0 0 0-.348.153L7.5 9.293 2.854 4.646a.5.5 0 1 0-.707.708l5 5a.5.5 0 0 0 .707 0l5-5a.5.5 0 0 0-.359-.86Z"
      fill={fill}
    />
  </Svg>
);

export default ExpandIcon;
