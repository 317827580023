import { LayoutChangeEvent, ScrollView, View } from 'react-native';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useCallback, useMemo, useState } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DefaultZoneNavigation from '../../components/DefaultZoneNavigation';
import Header from './components/Header';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ToDoColumn, { ToDoColumnProps } from './components/columns/TodoColumn';
import { PrepNavigatorParamList } from '../../navigation/PrepNavigatorParamList';
import ProductLookupCacheProvider from '../../components/ProductLookupCache';
import InProgressWall from './components/columns/InProgressWall';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import StatusTabBar from './components/StatusTabBar';
import { LazyMount } from '../../../../components/tabs/LazyMount';
import InProgressColumn, {
  InProgressColumnProps
} from './components/columns/InProgressColumn';
import CompletedColumn, {
  CompletedColumnProps
} from './components/columns/CompletedColumn';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import PullIncompletePreparationOrders from '../../components/PullIncompletePreparationOrders';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import DetailModalContent from '../../components/detail-modal/DetailModalContent';
import { TasksDataContextProvider } from '../../data/TasksDataContext';

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: SemanticColours.primary.background[70]
  },
  toDos: {
    backgroundColor: SemanticColours.primary.background[80]
  },
  completed: {
    backgroundColor: SemanticColours.primary.background[70]
  },
  modal: {
    backgroundColor: SemanticColours.primary.background[90]
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    flexDirection: 'row'
  },
  toDos: {
    width: 286,
    flexGrow: 0
  },
  completed: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 286,
    shadowColor: '#000',
    shadowOffset: {
      width: -5,
      height: 5
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,

    elevation: 10
  }
});

const LargeContent = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<PrepNavigatorParamList, 'tasks'>>();

  const isFocussed = useIsFocused();

  const [columns, setColumns] = useState(3);

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!isFocussed) return;

      const { width } = e.nativeEvent.layout;

      // desired card width 274
      const newColumns = Math.floor((width - 10) / 260);

      setColumns(newColumns <= 0 ? 1 : newColumns);
    },
    [isFocussed]
  );

  const completedProps: CompletedColumnProps = useMemo(
    () => ({
      style: styles.completed
    }),
    [styles]
  );

  return (
    <View style={styles.container}>
      <ToDoColumn style={styles.toDos} />

      <InProgressWall numColumns={columns} onLayout={onLayout} />

      <LazyMount
        component={CompletedColumn}
        componentProps={completedProps}
        visible={route.params?.tab === 'completed'}
      />
    </View>
  );
};

const SmallContent = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<PrepNavigatorParamList, 'tasks'>>();

  const insets = useSafeAreaInsets();

  const toDosProps: ToDoColumnProps = useMemo(
    () => ({
      style: styles.toDos,
      hideHeadings: true,
      contentContainerStyle: {
        marginLeft: insets.left,
        marginRight: insets.right
      }
    }),
    [styles, insets]
  );

  const inProgressProps: InProgressColumnProps = useMemo(
    () => ({
      contentContainerStyle: {
        marginLeft: insets.left,
        marginRight: insets.right
      }
    }),
    [styles, insets]
  );

  const completedProps: CompletedColumnProps = useMemo(
    () => ({
      style: styles.completed,
      hideHeadings: true,
      contentContainerStyle: {
        marginLeft: insets.left,
        marginRight: insets.right
      }
    }),
    [styles, insets]
  );

  return (
    <View style={styles.container}>
      <StatusTabBar />

      <LazyMount
        component={ToDoColumn}
        componentProps={toDosProps}
        visible={route.params?.tab === 'todo'}
      />

      <LazyMount
        component={InProgressColumn}
        componentProps={inProgressProps}
        visible={route.params?.tab === 'in-progress'}
      />

      <LazyMount
        component={CompletedColumn}
        componentProps={completedProps}
        visible={route.params?.tab === 'completed'}
      />
    </View>
  );
};

const PrepScreen = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  const route = useRoute<RouteProp<PrepNavigatorParamList>>();
  const navigation =
    useNavigation<StackNavigationProp<PrepNavigatorParamList>>();

  const closeModal = useCallback(() => {
    navigation.setParams({ taskId: undefined });
  }, [route]);

  return (
    <ErrorBoundary>
      <DefaultZoneNavigation />
      <Header />
      <ProductLookupCacheProvider>
        {!!route.params?.prepZoneId && (
          <TasksDataContextProvider zoneId={route.params.prepZoneId}>
            <PullIncompletePreparationOrders
              preparationZoneId={route.params.prepZoneId}
            />

            {size === 'small' ? <SmallContent /> : <LargeContent />}
          </TasksDataContextProvider>
        )}

        <PlatformModal
          visible={!!route.params?.taskId}
          onRequestClose={closeModal}
        >
          <ModalConfirmHeader title="Order detail" onCross={closeModal} />
          {route.params?.taskId && (
            <ScrollView style={styles.modal}>
              <DetailModalContent
                orderId={route.params.taskId}
                perspective="prep"
              />
            </ScrollView>
          )}
        </PlatformModal>
      </ProductLookupCacheProvider>
    </ErrorBoundary>
  );
};

export default PrepScreen;
