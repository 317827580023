import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import BasicCell from '../../../../components/cells/BasicCell';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../components/cells/LegacyCellGroup';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useProductDataContext } from '../../data/ProductDataContext';
import { formatMoney } from '../../../../humanisers/formatMoney';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import { useProductRelationshipsContext } from '../../data/ProductRelationshipsContext';
import AddVariantCell from './AddVariantCell';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';

export type VariantsCellGroupProps = Omit<
  LegacyCellGroupProps,
  'children' | 'headingText'
>;

const rStyles = DynamicStyleSheet.create({ container: {} });

const VariantsCellGroup = ({
  style,
  ...rest
}: VariantsCellGroupProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { product, productId } = useProductDataContext();

  const priceBands = useProductRelationshipsContext().data?.priceBands;

  const navigation = useNavigation<NavigationProp<ProductNavigatorParamList>>();

  const onVariantCreated = useCallback(
    (variantId: string) => {
      navigation.navigate('variant', {
        productId,
        variantId
      });
    },
    [navigation, productId]
  );

  return (
    <LegacyCellGroup
      style={[styles.container, style]}
      headingText="Variants"
      footerText={
        product?.variants.length === 1
          ? 'Add more variants for different sizes or flavours of this product'
          : undefined
      }
      {...rest}
    >
      {product &&
        product.variants.map((variant, index) => (
          <React.Fragment key={variant.id}>
            <BasicCell
              title={variant.name}
              subtitle={(() => {
                if (!priceBands) return '';

                const allPrices =
                  'prices' in variant
                    ? Object.entries(variant.prices)
                        .filter(e => Object.keys(priceBands).includes(e[0]))
                        .map(e => +e[1] * 100)
                        .sortBy(p => p)
                    : [];

                const [from, to] = [allPrices.min() ?? 0, allPrices.max() ?? 0];

                if (from === to) {
                  return formatMoney(from);
                }

                return `${formatMoney(from)} - ${formatMoney(to)}`;
              })()}
              titlesOrientation="horizontal"
              iconRight={BasicCell.ForwardIcon}
              onPress={() => {
                navigation.navigate('variant', {
                  productId,
                  variantId: variant.id
                });
              }}
            />

            {index !== product.variants.length - 1 && <ListItemSeparator />}
          </React.Fragment>
        ))}

      <AuthorisedContent minRole={RoleId.superUser}>
        <ListItemSeparator />
        <AddVariantCell onVariantCreated={onVariantCreated} />
      </AuthorisedContent>
    </LegacyCellGroup>
  );
};

export default VariantsCellGroup;
