import { useReferenceDb } from './useReferenceDb';
import { useQueryStream } from '../../hooks/useQueryStream';
import { useTransform } from '../../hooks/useTransform';

export const useProductNameLookup = () => {
  const db = useReferenceDb();

  return useTransform(
    useQueryStream(db.local.design.views.allProducts),
    rows => Object.fromEntries(rows.map(r => [r.documentID, r.key[1]])),
    () => ({})
  );
};
