import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import { VariantArrayV2 } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';
import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { DEFAULT_UNNAMED_VARIANT_NAME } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV2';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';

const commandType = 'createProduct' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      name: string;
      departmentId: string | null;
      standardPrice: string;
    };
  }
}

export const createProduct = async (
  args: Omit<SiteCommands[typeof commandType], 'type' | 'productId'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | {
      type: 'NAME_IN_USE';
    }
  | {
      type: 'UNAUTHORISED';
    }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const {
    repositories,
    design: { views }
  } = ctx.databases.reference.local;

  const { name, departmentId, standardPrice } = args;
  const { userId, createdAt } = ctx.meta;

  const existingProducts = await views.allProducts.getQuery({
    includeDocs: true
  });

  const existingProductNames = existingProducts
    .map(p => convertProductToV3(p.document))
    .compactMap(p => (p !== 'N/A' && !p.deleted ? p.productName : null));

  if (existingProductNames.includes(name)) {
    return { type: 'NAME_IN_USE' };
  }

  const allPriceBands = await views.allPriceBands.getQuery();

  const defaultPriceBandId = allPriceBands[0].documentID;

  const prices = { [defaultPriceBandId]: standardPrice };

  const variants: VariantArrayV2 = [
    {
      $schema: 'DefaultUnnamedVariant',
      $schemaVersion: '2',
      id: 'DEFAULT',
      name: DEFAULT_UNNAMED_VARIANT_NAME,
      prices,
      priceCalculation: 'None',
      valueType: 'Fixed'
    }
  ];

  const product = await repositories.products.createDocument({
    $schema: 'Product',
    $schemaVersion: '3',
    name,
    productName: name,
    prices,
    variants,
    revisionTime: createdAt,
    revisionUserID: userId,
    ...(departmentId && { department: departmentId }),
    priceCalculation: 'None',
    valueType: 'Fixed'
  });

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId: product._id,
      name,
      standardPrice,
      departmentId
    },
    [product._id]
  );

  return { type: 'SUCCESS', data: command };
};
