/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeviceId } from '../../components/device-context/useDeviceId';
import { useSiteContext } from '../components/site-context/useSiteId';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { CommandContext } from '../types/CommandContext';
import { useContextOrThrow } from '../../lib/use-context-or-throw';
import UserContext from '../../security/context/UserContext';

type CommandFn = (args: any, ctx: CommandContext) => Promise<any>;

type ArgsType<Fn> = Fn extends (args: infer A, ctx: CommandContext) => any
  ? A
  : never;

type ResultType<Fn> = Fn extends (
  args: any,
  ctx: CommandContext
) => Promise<infer R>
  ? R
  : never;

type Invocation<Fn extends CommandFn> = {
  args: ArgsType<Fn>;
  state: 'WAITING_TO_RUN' | 'COMPLETE';
  result: ResultType<Fn> | Error | null;
};

const NO_OP = () => {};

export const useCommand = <Fn extends CommandFn>(
  fn: Fn,
  onComplete?: (result: ResultType<Fn> | Error, retry: () => void) => unknown
) => {
  const log = createLogger(nameof({ useCommand }), { command: fn.name });

  const [invocation, setInvocation] = useState<Invocation<Fn> | null>(null);

  const { databases } = useSiteContext();

  const deviceId = useDeviceId();

  const user = useContextOrThrow(UserContext, nameof({ UserContext }));

  useEffect(() => {
    if (invocation?.state !== 'WAITING_TO_RUN') {
      return NO_OP;
    }

    let mounted = true;

    const ctx: CommandContext = {
      databases,
      meta: {
        createdAt: new Date().toISOString(),
        userId: user.userId,
        role: user.role,
        deviceId
      }
    };

    fn(invocation.args, ctx)
      .then((r: ResultType<Fn>) => {
        if (!mounted) return;

        setInvocation({
          ...invocation,
          state: 'COMPLETE',
          result: r
        });
      })
      .catch((e: Error) => {
        log.error(e);
        if (!mounted) return;

        setInvocation({
          ...invocation,
          state: 'COMPLETE',
          result: e
        });
      });

    return () => {
      mounted = false;
    };
  }, [invocation]);

  const invoke = useCallback((args: ArgsType<Fn>) => {
    setInvocation({
      args,
      state: 'WAITING_TO_RUN',
      result: null
    });
  }, []);

  useEffect(() => {
    if (!invocation?.result) {
      return;
    }

    onComplete?.(invocation.result, () => invoke(invocation.args));
  }, [invocation]);

  return useMemo(
    () =>
      invocation
        ? {
            inProgress: invocation.state === 'WAITING_TO_RUN',
            result: invocation.result,
            invoke,
            reset: () => setInvocation(null)
          }
        : {
            inProgress: false,
            result: null,
            invoke,
            reset: () => setInvocation(null)
          },
    [invocation, invoke]
  );
};
