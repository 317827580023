import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import Nugget from '../../../../../../components/nugget/Nugget';
import PaymentCardIcon from '../../../../../../components/icons/PaymentCardIcon';
import { formatPercent } from '../../../../../../humanisers/formatPercent';
import CashIcon from '../../../../../../components/icons/CashIcon';
import VoucherIcon from '../../../../../../components/icons/VoucherIcon';

export type PaymentTypesProps = Omit<ViewProps, 'children'> & {
  allPayments: number | null;
  card: number | null;
  cash: number | null;
  vouchers: number | null;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -10
  },
  nugget: {
    marginHorizontal: 10
  }
});

const PaymentTypes = React.memo<PaymentTypesProps>(
  ({ style, allPayments, cash, card, vouchers, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.container, style]} {...rest}>
        {card && allPayments ? (
          <Nugget
            style={styles.nugget}
            icon={PaymentCardIcon}
            title="Card"
            value={formatPercent(card, allPayments)}
          />
        ) : null}

        {cash && allPayments ? (
          <Nugget
            style={styles.nugget}
            icon={CashIcon}
            title="Cash"
            value={formatPercent(cash, allPayments)}
          />
        ) : null}

        {vouchers && allPayments ? (
          <Nugget
            style={styles.nugget}
            icon={VoucherIcon}
            title="Vouchers"
            value={formatPercent(vouchers, allPayments)}
          />
        ) : null}
      </View>
    );
  }
);

export default PaymentTypes;
