import React from 'react';
import { View, ViewProps, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ActionButtonBar from '../../../../components/buttons/ActionButtonBar';
import ActionButton from '../../../../components/buttons/ActionButton';
import SwapIcon from '../../../../components/icons/SwapIcon';

export type SessionCellProps = Omit<ViewProps, 'children'> & {
  clientName: string;
  onSwitchPress: () => void;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center'
  },
  clientName: {
    color: SemanticColours.secondary.foreground[90],
    flexGrow: 1,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 26
  }
}).override(Selectors.mediumOrLarge, {
  root: {
    paddingVertical: 16,
    paddingHorizontal: 28
  },
  clientName: {
    fontSize: 17
  }
});

const SessionCell = React.memo<SessionCellProps>(
  ({ style, clientName, onSwitchPress, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style]} {...rest}>
        <Text style={styles.clientName}>{clientName}</Text>

        <ActionButtonBar>
          <ActionButton
            colourScheme="secondary"
            label="Switch to"
            iconComponent={SwapIcon}
            onPress={() => onSwitchPress()}
          />
        </ActionButtonBar>
      </View>
    );
  }
);

export default SessionCell;
