import { AVPlaybackSource } from 'expo-av';
import { Sound } from 'expo-av/build/Audio';
import { useCallback, useEffect, useState } from 'react';

export const useSound = (source: AVPlaybackSource): { play: () => void } => {
  const [sound, setSound] = useState<Sound>();

  useEffect(() => {
    let mounted = true;

    void (async () => {
      const result = await Sound.createAsync(source);

      if (mounted) {
        setSound(result.sound);
      }
    })();

    return () => {
      mounted = false;
      void sound?.unloadAsync();
    };
  }, [source]);

  const play = useCallback(() => {
    void sound?.replayAsync();
  }, [sound]);

  return { play };
};
