/* eslint-disable func-names */
import { Database } from '../../../support/Database';
import { DesignDocumentDefinition } from '../../../support/DesignDocument';
import { AllDepartmentsView } from './views/AllDepartmentsView';
import { AllPreparationZonesView } from './views/AllPreparationZonesView';
import { AllPriceBandsView } from './views/AllPriceBandsView';
import { AllProductOptionSetsView } from './views/AllProductOptionSetsView';
import { AllProductsView } from './views/AllProductsView';
import { AllPromotionsView } from './views/AllPromotionsView';
import { AllServingZonesView } from './views/AllServingZonesView';
import { AllVouchersView } from './views/AllVouchersView';
import { NonVariantProductsByDepartmentView } from './views/NonVariantProductsByDepartmentView';
import { PreparationZonesByNameView } from './views/PreparationZonesByNameView';
import { ProductOptionSetsByNameView } from './views/ProductOptionSetsByNameView';
import { ProductsByDepartmentView } from './views/ProductsByDepartmentView';
import { ProductsByNameView } from './views/ProductsByNameView';

export class DesignDoc {
  private static documentId = '_design/views';

  static views = {
    [AllDepartmentsView.viewName]: AllDepartmentsView,
    [AllPreparationZonesView.viewName]: AllPreparationZonesView,
    [AllPriceBandsView.viewName]: AllPriceBandsView,
    [AllProductOptionSetsView.viewName]: AllProductOptionSetsView,
    [AllProductsView.viewName]: AllProductsView,
    [AllPromotionsView.viewName]: AllPromotionsView,
    [AllVouchersView.viewName]: AllVouchersView,
    [AllServingZonesView.viewName]: AllServingZonesView,
    [NonVariantProductsByDepartmentView.viewName]:
      NonVariantProductsByDepartmentView,
    [PreparationZonesByNameView.viewName]: PreparationZonesByNameView,
    [ProductOptionSetsByNameView.viewName]: ProductOptionSetsByNameView,
    [ProductsByNameView.viewName]: ProductsByNameView,
    [ProductsByDepartmentView.viewName]: ProductsByDepartmentView
  } as const;

  static definition = new DesignDocumentDefinition(DesignDoc.documentId, {
    views: Object.fromEntries(
      Object.entries(DesignDoc.views).map(([name, View]) => [
        name,
        View.definition
      ])
    )
  });

  constructor(private readonly database: Database) {}

  public async ensureDefined(): Promise<void> {
    await DesignDoc.definition.ensureDefined(this.database.pouch);
  }

  public readonly views = Object.fromEntries(
    Object.entries(DesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, DesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof DesignDoc.views]: InstanceType<
      (typeof DesignDoc)['views'][P]
    >;
  };
}
