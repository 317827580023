import { FlatList, ScrollViewProps } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { ComponentType, useCallback, useMemo } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { isCalendarDayPartition } from '../../../../helpers/isCalendarDayPartition';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import Cell from './Cell';
import { assertRouteParam } from '../../../../../../lib/assert-route-param';
import { useProductTransactionListData } from './useProductTransactionListData';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import CellGroupHeading from '../../../../../../components/cells/CellGroupHeading';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type ProductTransactionsListProps = Omit<ScrollViewProps, 'children'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListHeaderComponent?: ComponentType<any>;
};

const rStyles = DynamicStyleSheet.create({
  container: {},
  content: {
    marginVertical: 14
  }
}).override(Selectors.mediumOrLarge, {
  content: {
    marginVertical: 24
  }
});

const ProductTransactionsList = ({
  style,
  ListHeaderComponent,
  ...rest
}: ProductTransactionsListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'product'>>();
  const navigation =
    useNavigation<
      StackNavigationProp<DashboardNavigatorParamList, 'product'>
    >();

  const productId = assertRouteParam(route.params, 'productId');

  const partition = useActivePartition(isCalendarDayPartition);

  const data = useProductTransactionListData(productId, partition);

  const rows = useMemo(() => {
    return 'rows' in data.state && data.state.rows ? data.state.rows : [];
  }, [data]);

  const onEndReached = useCallback(() => {
    if (data.state.state === 'SUCCESS' && data.state.hasMore) data.loadMore();
  }, [data]);

  const { cell, header, Separator } = useCellGroupStyles('left');

  const Header = useMemo(
    () => (): JSX.Element => {
      return (
        <>
          {ListHeaderComponent && <ListHeaderComponent />}
          <CellGroupHeading title="Lines" style={header} />
        </>
      );
    },
    [ListHeaderComponent, styles]
  );

  return (
    <FlatList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      style={[styles.container, style]}
      contentContainerStyle={styles.content}
      data={rows}
      keyExtractor={d => d.key}
      initialNumToRender={15}
      ListHeaderComponent={Header}
      ItemSeparatorComponent={Separator}
      renderItem={({ item, index }) => (
        <Cell
          {...item}
          style={cell(index === 0, index === rows.length - 1)}
          onPress={() =>
            navigation.push('transaction', {
              transactionId: item.transactionId
            })
          }
        />
      )}
      onEndReachedThreshold={1}
      onEndReached={onEndReached}
      {...rest}
    />
  );
};

export default ProductTransactionsList;
