import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { DatabaseReplicator } from '../../../data-model/tools/DatabaseReplicator';
import { GlobalActivity } from '../../../lib/global-activity/GlobalActivity';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';

const commandType = 'deleteServiceTransaction' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      transactionId: string;
    };
  }
}

export const deleteServiceTransaction = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'OFFLINE' }
  | { type: 'ABORTED' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const { transactionId } = args;

  const { transactionsArchive } = ctx.databases;

  const replicationResult = await GlobalActivity.track(
    'Downloading document',
    () =>
      DatabaseReplicator.replicate(
        transactionsArchive.pouches.remote,
        transactionsArchive.pouches.local,
        { doc_ids: [transactionId] }
      )
  );

  if (replicationResult.type !== 'SUCCESS') {
    return replicationResult;
  }

  const { userId, createdAt } = ctx.meta;

  const auditFields = {
    revisionUserID: userId,
    revisionTime: createdAt
  };

  const result = await transactionsArchive.local.repository.updateDocument(
    transactionId,
    (p, update) => update({ ...p, deleted: true, ...auditFields })
  );

  if (result.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      transactionId
    },
    [transactionId]
  );

  return { type: 'SUCCESS', data: command };
};
