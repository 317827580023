import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { Line as Data } from './useTransactionLinesData';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { Text } from '../../../../../../components/text/Text';

export type LineProps = Omit<ViewProps, 'children'> & {
  data: Data;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 4
  },
  iconContainer: {
    flexGrow: 0,
    marginRight: 6,
    marginVertical: 4
  },
  icon: {
    color: SemanticColours.primary.foreground[35],
    width: 18,
    height: 18
  },
  event: {
    color: SemanticColours.primary.foreground[50]
  },
  promotion: {
    fontStyle: 'italic'
  },
  description: {
    flexWrap: 'wrap',
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 12
  },
  amount: {
    flexGrow: 0,
    flexShrink: 0
  }
});

const Line = ({ style, data, ...rest }: LineProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      {'IconComponent' in data && (
        <View style={styles.iconContainer}>
          <data.IconComponent
            fill={styles.icon.color}
            width={styles.icon.width}
            height={styles.icon.height}
          />
        </View>
      )}
      <Text.CellTitle
        style={[
          data.type === 'EVENT' && styles.event,
          data.type === 'PROMOTION' && styles.promotion,
          styles.description
        ]}
      >
        {data.description}
      </Text.CellTitle>
      {'amount' in data && (
        <Text.CellSubtitle style={styles.amount}>
          {data.amount}
        </Text.CellSubtitle>
      )}
    </View>
  );
};

export default Line;
