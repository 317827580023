import React, { useCallback, useMemo, useRef } from 'react';
import { TextInput, View, ViewProps } from 'react-native';
import { Formik, FormikErrors } from 'formik';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { Normalisers, TextField } from '../../../../components/text-field';
import { nameof } from '../../../../lib/name-of';
import { usePromiseFactory } from '../../../../lib/use-promise/usePromiseFactory';
import { useUsopApiClient } from '../../../../api/useUsopApiClient';
import { DeviceInfoService } from '../../../../services/device-info/DeviceInfoService';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { PersonalSecurityContext } from '../../../../security/personal/PersonalSecurityContext';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { PasswordTextField } from '../../../../components/password-text-field';
import { Text } from '../../../../components/text/Text';

export type LoginModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
};

type FormValues = {
  clientId: string;
  username: string;
  password: string;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[90]
  },
  form: {
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  field: {
    marginVertical: 10
  },
  gap: {
    height: 16
  },
  prompt: {
    color: SemanticColours.secondary.foreground[80],
    marginVertical: 20
  }
});

const LoginModal = React.memo<LoginModalProps>(
  ({ style, visible, dismiss, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const { switchToNewClient, activeClientId, clients } = useContextOrThrow(
      PersonalSecurityContext,
      nameof({ PersonalSecurityContext })
    );

    const validate = useCallback(
      ({
        clientId,
        username,
        password
      }: FormValues): FormikErrors<FormValues> => {
        if (!clientId.trim()) {
          return { clientId: 'Required' };
        }

        if (clientId.trim() === activeClientId) {
          return { clientId: `You're already logged in to this client` };
        }

        // from OpenAPI
        if (
          !/^[a-z][a-z0-9_$()+-]{1,250}$/.test(clientId.trim().toLowerCase())
        ) {
          return {
            clientId: 'Enter a valid clientId'
          };
        }

        if (!username.trim()) {
          return { username: 'Required' };
        }

        if (!password.trim()) {
          return { password: 'Required' };
        }

        // from OpenAPI
        if (!/^[a-z0-9_]{3,39}$/.test(username.trim().toLowerCase())) {
          return {
            username: "This doesn't look valid"
          };
        }

        return {};
      },
      [activeClientId]
    );

    const usernameRef = useRef<TextInput>(null);
    const passwordRef = useRef<TextInput>(null);

    const apiClient = useUsopApiClient();

    const { invoke, inProgress, result } = usePromiseFactory(
      useCallback(async ({ clientId, username, password }: FormValues) => {
        const device = await DeviceInfoService.get();

        const loginResponse = await apiClient.postSession({
          clientId,
          body: { username: username.trim().toLowerCase(), password, device }
        });

        if (loginResponse.status !== 201) {
          return {
            type: loginResponse.body?.fields
              .map(f => f.path)
              .includes('body/clientId')
              ? ('CLIENT' as const)
              : ('CREDENTIALS' as const)
          } as const;
        }

        const authorisedClientResponse = await apiClient.getClientById({
          clientId,
          authorization: `Bearer ${loginResponse.body.token}`
        });

        if (
          authorisedClientResponse.status !== 200 ||
          !('defaultSiteId' in authorisedClientResponse.body)
        ) {
          return { type: 'CLIENT' } as const;
        }

        const {
          userId,
          fullName,
          role,
          id: userSessionId,
          token,
          device: { id: deviceId }
        } = loginResponse.body;

        const { name, defaultSiteId } = authorisedClientResponse.body;

        switchToNewClient({
          id: clientId,
          name,
          defaultSiteId,
          user: {
            userId,
            username,
            fullName,
            role: role as RoleId,
            userSessionId,
            token,
            deviceId
          }
        });

        return undefined;
      }, [])
    );

    const activeClient = useMemo(() => {
      const client = clients.find(c => c.id === activeClientId);

      return client ?? null;
    }, [activeClientId, clients]);

    return (
      <PlatformModal visible={visible} onRequestClose={dismiss} {...rest}>
        <View style={[styles.root, style]}>
          <Formik
            initialValues={{ clientId: '', username: '', password: '' }}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={invoke}
          >
            {({ handleSubmit, ...formProps }) => (
              <>
                <ModalConfirmHeader
                  title="Add another client"
                  onCross={() => dismiss()}
                  onTick={() => handleSubmit()}
                />

                <View style={styles.form}>
                  <Text.DocBody style={styles.prompt}>
                    You will remain logged in to {activeClient?.name} and you
                    can switch back at any time
                  </Text.DocBody>

                  <TextField
                    {...formProps}
                    autoComplete="off"
                    autoFocus
                    autoCapitalize="none"
                    clearButtonMode="while-editing"
                    secondaryColourScheme
                    editable={!inProgress}
                    enablesReturnKeyAutomatically
                    keyboardType="default"
                    keyboardAppearance="dark"
                    name={nameof<FormValues>('clientId')}
                    normaliser={[Normalisers.trim, Normalisers.lowercase]}
                    onSubmitEditing={() => usernameRef.current?.focus()}
                    placeholder="client id"
                    returnKeyType="next"
                    errorMessage={
                      result?.type === 'SUCCESS' &&
                      result.data?.type === 'CLIENT'
                        ? 'Not found. Check and try again.'
                        : undefined
                    }
                    style={styles.field}
                  />

                  <View style={styles.gap} />

                  <TextField
                    {...formProps}
                    autoCapitalize="none"
                    autoCorrect={false}
                    clearButtonMode="while-editing"
                    secondaryColourScheme
                    editable={!inProgress}
                    enablesReturnKeyAutomatically
                    name={nameof<FormValues>('username')}
                    normaliser={[Normalisers.trim, Normalisers.lowercase]}
                    onSubmitEditing={() => passwordRef.current?.focus()}
                    placeholder="username"
                    ref={usernameRef}
                    returnKeyType="next"
                    style={styles.field}
                  />
                  <PasswordTextField
                    {...formProps}
                    secondaryColourScheme
                    editable={!inProgress}
                    onSubmitEditing={() => handleSubmit()}
                    ref={passwordRef}
                    style={styles.field}
                    errorMessage={
                      result?.type === 'SUCCESS' &&
                      result.data?.type === 'CREDENTIALS'
                        ? 'Username or password is incorrect. Check and try again.'
                        : undefined
                    }
                  />
                </View>
              </>
            )}
          </Formik>
        </View>
      </PlatformModal>
    );
  }
);

export default LoginModal;
