import { ReactNode, createContext, useMemo, useState } from 'react';
import { newRandomUuid } from '../../lib/uuid';

export type Option = {
  label: string;
  style?: 'destructive';
  isDisabled?: boolean;
  onSelect: () => void;
};

type HeaderMenuContextType = {
  allOptions: Option[];
  registerOptions(options: Option[]): () => void;
};

export const HeaderMenuContext = createContext<HeaderMenuContextType | null>(
  null
);

type HeaderMenuContextProviderProps = {
  children?: ReactNode | undefined;
};

export const HeaderMenuContextProvider = ({
  children
}: HeaderMenuContextProviderProps): JSX.Element => {
  const [options, setOptions] = useState<[string, Option[]][]>([]);

  const value: HeaderMenuContextType = useMemo(
    () => ({
      allOptions: options.flatMap(o => o[1]),

      registerOptions(newOptions) {
        const id = newRandomUuid();
        setOptions(o => [...o, [id, newOptions]]);

        return () => setOptions(o => o.filter(oo => oo[0] !== id));
      }
    }),
    [options, setOptions]
  );

  return (
    <HeaderMenuContext.Provider value={value}>
      {children}
    </HeaderMenuContext.Provider>
  );
};
