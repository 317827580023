import { CalendarDayPartition } from '../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { assertNever } from '../../../lib/assert-never';
import {
  IntervalDate,
  IntervalDay,
  IntervalMonth,
  IntervalWeek,
  IntervalYear
} from '../types/IntervalDate';
import {
  CalendarMonthPartition,
  CalendarYearPartition,
  IsoWeekPartition,
  Partition
} from '../types/Partition';
import { partitionToDate } from './partitionToDate';

export function partitionToIntervalDate(
  period: CalendarYearPartition
): IntervalYear;
export function partitionToIntervalDate(
  period: CalendarMonthPartition
): IntervalMonth;
export function partitionToIntervalDate(period: IsoWeekPartition): IntervalWeek;
export function partitionToIntervalDate(
  period: CalendarDayPartition
): IntervalDay;
export function partitionToIntervalDate(period: Partition): IntervalDate;
export function partitionToIntervalDate(period: Partition): IntervalDate {
  const date = partitionToDate(period).toISODate();

  if (!date) {
    throw new Error('Invalid period');
  }

  switch (period[0]) {
    case 'calendar': {
      switch (period.length) {
        case 2:
          return ['year', date] as const;

        case 3:
          return ['month', date] as const;

        case 4:
          return ['day', date] as const;

        default:
          return assertNever(period);
      }
    }

    case 'iso': {
      return ['week', date] as const;
    }

    default:
      return assertNever(period);
  }
}
