import { StackScreenProps } from '@react-navigation/stack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { ErrorBoundary } from 'react-error-boundary';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import ProductsListView, {
  ProductsListViewMethods
} from '../../components/products-list-view/ProductsListView';
import { HeaderMenuButton } from '../../../../components/header-menu/HeaderMenuButton';
import CreateProductMenuOption from '../../components/menu-options/CreateProductMenuOption';
import CreateGroupMenuOption from '../../components/menu-options/CreateGroupMenuOption';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import CreateProductModal from '../../components/modals/CreateProductModal';
import { ProblemContent } from '../../../../components/unhappy-path-content/problem/ProblemContent';
import { HeaderNavigation } from '../../../../components/header-navigation/HeaderNavigation';
import { SemanticColours } from '../../../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import ExportMenuOption from '../../components/menu-options/ExportMenuOption';
import { nameof } from '../../../../lib/name-of';

export type ProductListScreenProps = StackScreenProps<
  ProductNavigatorParamList,
  'list'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  add: {
    marginHorizontal: 8
  }
});

const ProductListScreen = (props: ProductListScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { navigation } = props;

  const ref = useRef<ProductsListViewMethods | null>(null);

  const [highlightedProductId, setHighlightedProductId] = useState<
    string | null
  >(null);

  const onSelect = useCallback(
    (productId: string) => {
      setHighlightedProductId(productId);
      navigation.navigate('show', { productId });
    },
    [navigation]
  );

  const { present, ...modal } = useModalControl<{
    departmentId: string | null;
  }>();

  useEffect(() => {
    navigation.setOptions({
      headerLeft: headerProps => <HeaderNavigation {...headerProps} />,
      headerRight: headerProps => <HeaderMenuButton {...headerProps} />
    });
  }, [navigation]);

  const onDepartmentCreated = useCallback(
    (id: string) => {
      ref.current?.ensureDepartmentExpanded(id);
      ref.current?.scrollToDepartment(id);
    },
    [ref.current]
  );

  const onProductCreated = useCallback(
    (productId: string, departmentId: string | null = null) => {
      ref.current?.ensureDepartmentExpanded(departmentId);
      ref.current?.scrollToProduct(departmentId, productId);
      setHighlightedProductId(productId);
      navigation.navigate('show', { productId });
    },
    [ref.current, setHighlightedProductId]
  );

  const isFocused = useIsFocused();

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <ErrorBoundary FallbackComponent={ProblemContent}>
      <View style={styles.container} testID={nameof({ ProductListScreen })}>
        {isFocused && authorised && (
          <>
            <CreateProductMenuOption onProductCreated={onProductCreated} />
            <CreateGroupMenuOption onGroupCreated={onDepartmentCreated} />
            <ExportMenuOption />
          </>
        )}

        <CreateProductModal {...modal} onProductCreated={onProductCreated} />

        <ProductsListView
          ref={ref}
          highlightedProductId={highlightedProductId}
          onSelect={product => onSelect(product.id)}
          onRequestAdd={departmentId => {
            present({ departmentId: departmentId ?? null });
          }}
        />
      </View>
    </ErrorBoundary>
  );
};

export default ProductListScreen;
