import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { ServiceTransaction } from '../../../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { QueryRow } from '../../../../../../data-model/schema/support/DatabaseView';
import {
  DashboardPaymentsForDateKey,
  DashboardPaymentsForDateValue
} from '../../../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-payments-for-date/DashboardPaymentsForDateDesignDoc';
import { useUsernameLookup } from '../../../../../../data-model/components/client-context/useUserNameLookup';
import { usePaginatedQuery } from '../../../../../../data-model/hooks/usePaginatedQuery';
import { AnyPaymentId } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/PaymentIds';

const mapRow =
  (
    timeZone: string,
    users: {
      [k: string]: string;
    }
  ) =>
  (
    rawRow: QueryRow<
      DashboardPaymentsForDateKey,
      DashboardPaymentsForDateValue,
      unknown,
      ServiceTransaction
    >
  ) => {
    // we know we're inputting a day so our result WILL look like this
    const row = rawRow as QueryRow<
      [
        interval: 'day',
        date: string,
        paymentId: AnyPaymentId,
        time: string,
        lineId: string,
        userId: string
      ],
      DashboardPaymentsForDateValue,
      object,
      ServiceTransaction
    >;

    return {
      key: `${row.documentID}-${row.key[4]}`,
      transactionId: row.documentID,
      time: DateTime.fromISO(row.key[3], { zone: timeZone }).toLocaleString(
        DateTime.TIME_24_SIMPLE
      ),
      user: users[row.key[5]] ?? 'Unknown user',
      value: row.value
    };
  };

export const useTransactionListData = (
  paymentId: AnyPaymentId,
  partition: CalendarDayPartition
) => {
  const db = useTransactionsDb();

  const { data: users } = useUsernameLookup();

  const intervalDate = useMemo(() => {
    return partitionToIntervalDate(partition);
  }, [partition]);

  const { timeZone } = useSiteTime();

  const result = usePaginatedQuery(
    db.remote.design.dashboardPaymentsForDate.view,
    {
      startKey: [...intervalDate, paymentId],
      endKey: [...intervalDate, paymentId, []],
      limit: 20
    },
    rows => rows.map(mapRow(timeZone, users)),
    [users, timeZone]
  );

  useEffect(() => {
    if (result.state.state === 'EMPTY') {
      result.reload();
    }
  }, [result]);

  return result;
};
