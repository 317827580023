import React, { useMemo } from 'react';
import { ViewStyle } from 'react-native';
import CardColumn, {
  CardColumnProps
} from '../../../../../../components/card/CardColumn';
import BoundCard from '../../../../components/card/BoundCard';
import { flattenStyle } from '../../../../../../lib/dynamic-style-sheet/flattenStyle';
import BoundCardFooter from '../BoundCardFooter';
import Heading from '../../../../components/Heading';
import { PreparationOrderStatus } from '../../../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import { useTasksData } from '../../../../data/TasksDataContext';

export type CompletedColumnProps = Omit<CardColumnProps, 'data'> & {
  hideHeadings?: boolean;
};

const relevantStatuses = new Set<PreparationOrderStatus>([
  '04 Ready',
  '05 Collected'
]);

const CompletedColumn = React.memo<CompletedColumnProps>(
  ({ style, hideHeadings, ...rest }) => {
    const { data: allTasks } = useTasksData();

    const ListHeaderComponent = useMemo(() => {
      const flattened = flattenStyle(style as object[]) as ViewStyle;

      return (
        <Heading
          label="Done"
          style={
            flattened && 'backgroundColor' in flattened
              ? { backgroundColor: flattened.backgroundColor }
              : undefined
          }
        />
      );
    }, [style]);

    const data = useMemo(() => {
      const relevantTasks = allTasks.filter(t =>
        relevantStatuses.has(t.key[1])
      );

      return {
        data: relevantTasks.map(v => ({
          key: v.documentID,
          element: (
            <BoundCard
              queryRow={v}
              expandable={v.key[1] === '05 Collected'}
              footerComponent={BoundCardFooter}
            />
          )
        }))
      };
    }, [allTasks]);

    return (
      <CardColumn
        style={style}
        {...rest}
        data={data}
        ListHeaderComponent={hideHeadings ? undefined : ListHeaderComponent}
      />
    );
  }
);

export default CompletedColumn;
