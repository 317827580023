import { memo } from 'react';
import { ViewStyle } from 'react-native';
import BasicCell, {
  BasicCellProps
} from '../../../../components/cells/BasicCell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Partition } from '../../types/Partition';
import NoIcon from '../../../../components/icons/NoIcon';
import TickIcon from '../../../../components/icons/TickIcon';
import { formatPartition } from '../../../../humanisers/formatPartition';
import { useSiteTime } from '../../hooks/useSiteTime';
import { formatMoney } from '../../../../humanisers/formatMoney';
import { comparePartitions } from '../../helpers/comparePartitions';

export type PartitionListCellProps = Omit<
  BasicCellProps,
  'title' | 'subtitle' | 'onPress'
> & {
  partition: Partition;
  value: number;
  selected: boolean;
  onPress(partition: Partition): void;
};

const ITEM_HEIGHT = 50;

const rStyles = DynamicStyleSheet.create({
  container: {
    height: ITEM_HEIGHT
  },
  subtitle: {
    flexShrink: 0
  }
});

const PartitionListCell = Object.assign(
  memo(
    ({
      selected,
      partition,
      value,
      onPress,
      style,
      ...rest
    }: PartitionListCellProps): JSX.Element => {
      const styles = useDynamicStyleSheet(rStyles);

      const timeSettings = useSiteTime();

      return (
        <BasicCell
          iconLeft={NoIcon}
          iconRight={selected ? TickIcon : NoIcon}
          titlesOrientation="horizontal"
          title={formatPartition(timeSettings)(partition)}
          subtitle={formatMoney(value)}
          subtitleStyle={styles.subtitle}
          onPress={() => onPress(partition)}
          style={[styles.container, style as ViewStyle]}
          {...rest}
        />
      );
    },
    (prev, next) =>
      comparePartitions(prev.partition, next.partition) === 0 &&
      prev.value === next.value &&
      prev.selected === next.selected &&
      prev.onPress === next.onPress
  ),
  { itemHeight: ITEM_HEIGHT }
);

export default PartitionListCell;
