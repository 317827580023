import {
  ActionSheetOptions,
  useActionSheet
} from '@expo/react-native-action-sheet';
import { useCallback, useMemo, useRef } from 'react';
import { findNodeHandle, View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { IconButton, IconButtonProps } from './IconButton';

type Option =
  | { label: string; style: 'cancel' }
  | {
      label: string;
      style?: 'destructive';
      isDisabled?: boolean;
      onSelect: () => void;
    };

type DeclarativeActionSheetOptions = Omit<
  ActionSheetOptions,
  | 'anchor'
  | 'options'
  | 'cancelButtonIndex'
  | 'destructiveButtonIndex'
  | 'disabledButtonIndices'
> & {
  options: Option[];
};

export type IconButtonMenuProps = Omit<IconButtonProps, 'onPress'> & {
  actionSheetOptions: DeclarativeActionSheetOptions;
};

const rStyles = DynamicStyleSheet.create({ container: {} });

const IconButtonMenu = ({
  style,
  actionSheetOptions: { options: declarativeOptions, ...actionSheetOptions },
  ...rest
}: IconButtonMenuProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { showActionSheetWithOptions } = useActionSheet();

  const menuButtonRef = useRef<View>(null);

  const options: ActionSheetOptions = useMemo(() => {
    return {
      options: declarativeOptions.map(o => o.label),
      destructiveButtonIndex: declarativeOptions.compactMap((o, i) =>
        o.style === 'destructive' ? i : null
      ),
      cancelButtonIndex: declarativeOptions.findIndex(o => o.style === 'cancel')
    };
  }, [actionSheetOptions]);

  const onMenu = useCallback(() => {
    showActionSheetWithOptions(
      {
        anchor:
          (menuButtonRef.current
            ? findNodeHandle(menuButtonRef.current)
            : null) ?? undefined,
        ...actionSheetOptions,
        ...options
      },
      i => {
        if (typeof i === 'undefined') return;

        const op = declarativeOptions[i];

        if (op.style === 'cancel') return;

        op.onSelect();
      }
    );
  }, [
    showActionSheetWithOptions,
    menuButtonRef.current,
    options,
    actionSheetOptions,
    declarativeOptions
  ]);

  return (
    <IconButton
      ref={menuButtonRef}
      style={[style, styles.container]}
      onPress={onMenu}
      accessibilityLabel="open context menu"
      {...rest}
    />
  );
};

export default IconButtonMenu;
