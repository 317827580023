import { HeaderButtonProps } from '@react-navigation/elements';
import { StyleSheet } from 'react-native';
import { useContextOrThrow } from '../../lib/use-context-or-throw';
import { nameof } from '../../lib/name-of';
import IconButtonMenu from '../buttons/IconButtonMenu';
import { VerticalMenuIcon } from '../icons/VerticalMenuIcon';
import { HeaderMenuContext } from './HeaderMenuContext';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8
  }
});

export const HeaderMenuButton = ({
  tintColor,
  ...rest
}: HeaderButtonProps): JSX.Element => {
  const { allOptions } = useContextOrThrow(
    HeaderMenuContext,
    nameof({ HeaderMenuContext })
  );

  return (
    <>
      {allOptions.any() && (
        <IconButtonMenu
          iconColour={tintColor}
          iconComponent={VerticalMenuIcon}
          actionSheetOptions={{
            options: [...allOptions, { label: 'Cancel', style: 'cancel' }]
          }}
          style={styles.container}
          iconSize={20}
          {...rest}
        />
      )}
    </>
  );
};
