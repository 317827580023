import { useCallback, useMemo, useState } from 'react';
import { ViewProps } from 'react-native';
import { updateProductGroup } from '../../commands/updateProductGroup';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import BasicCell from '../../../../components/cells/BasicCell';
import GroupIcon from '../../../../components/icons/GroupIcon';
import SelectFromListModal from '../../../../components/select-from-list-modal/SelectFromListModal';
import { useProductDataContext } from '../../data/ProductDataContext';
import { useProductRelationshipsContext } from '../../data/ProductRelationshipsContext';
import SelectIcon from '../../../../components/icons/SelectIcon';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { Alert } from '../../../../components/platform-alert/Alert';

export type EditDepartmentCellProps = Omit<ViewProps, 'children'>;

const EditProductGroupCell = ({
  style,
  ...rest
}: EditDepartmentCellProps): JSX.Element => {
  const {
    productId,
    product,
    loading: productLoading
  } = useProductDataContext();

  const departments = useProductRelationshipsContext().data?.departments;

  const departmentsWithoutNullAndSorted = useMemo(() => {
    if (!departments) {
      return [];
    }

    return Object.entries(departments)
      .filter(([key]) => key !== null)
      .map(([id, name]) => ({
        id,
        name
      }));
  }, [departments]);

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const selectedDepartmentName = useMemo(() => {
    if (!departments) {
      return '';
    }

    if (!product?.department) {
      return 'None';
    }

    return departments[product.department] ?? undefined;
  }, [departments, product?.department]);

  const { invoke, inProgress } = useCommand(
    updateProductGroup,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        dismissModal();
        return;
      }

      const errorMessage =
        result instanceof Error
          ? 'An unexpected error occurred.'
          : 'The product no longer exists.';

      Alert.alert(
        'Error',
        `Could not update the product's group.\n\n${errorMessage}`,
        [
          { text: 'Cancel', onPress: () => dismissModal() },
          { text: 'Retry', onPress: () => retry() }
        ]
      );
    }
  );

  const onAccept = useCallback(
    (id: string | null) => {
      invoke({ productId, newDepartmentId: id });
    },
    [invoke]
  );

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <>
      <BasicCell
        style={style}
        disabled={productLoading || inProgress || !departments}
        {...rest}
        title="Group"
        subtitle={selectedDepartmentName}
        iconLeft={GroupIcon}
        {...(authorised
          ? { iconRight: SelectIcon, onPress: () => setModalVisible(true) }
          : {})}
      ></BasicCell>
      <SelectFromListModal
        extractItemId={d => d.id}
        extractItemTitle={d => d.name ?? 'No name'}
        items={departmentsWithoutNullAndSorted}
        onAccept={onAccept}
        onDismiss={dismissModal}
        selectedItem={product?.department ?? null}
        title="Select group"
        visible={modalVisible}
        loading={productLoading || !departments || inProgress}
        noneOption
      />
    </>
  );
};

export default EditProductGroupCell;
