import { CalendarYearPartition } from '../types/Partition';

export const isCalendarYearPartition = (
  value: unknown
): value is CalendarYearPartition => {
  if (!value || !(typeof value === 'object') || !Array.isArray(value)) {
    return false;
  }

  return value[0] === 'calendar' && value.length === 2;
};
