import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';

export type GrowSpacerProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flexGrow: 1
  }
});

const GrowSpacer = React.memo<GrowSpacerProps>(({ style, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  return <View style={[styles.container, style]} {...rest} />;
});

export default GrowSpacer;
