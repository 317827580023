import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import { ServiceTransaction } from '../../documents/ServiceTransaction';
import { CalendarDayPartition, IsoDayPartition } from '../Partitions';
import { AnyPaymentId } from '../PaymentIds';

export type DashboardDatesForPaymentKey = [
  paymentId: AnyPaymentId,
  ...ignore: CalendarDayPartition | IsoDayPartition
];

export type DashboardDatesForPaymentValue = { readonly value: number };

export class DashboardDatesForPaymentDesignDoc {
  constructor(private readonly database: Database) {}

  public readonly view = new DatabaseView<
    DashboardDatesForPaymentKey,
    DashboardDatesForPaymentValue,
    ServiceTransaction
  >(this.database, '_design/dashboard-dates-for-payment', 'calendar');
}
