import { View, ViewProps } from 'react-native';
import { Text } from '../../../../components/text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';

export type ProductGroupEmptyFooterProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingHorizontal: 40,
    paddingBottom: 20
  }
});

const ProductGroupEmptyFooter = ({
  style,
  ...rest
}: ProductGroupEmptyFooterProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Text.SectionFooter>This group has no products</Text.SectionFooter>
    </View>
  );
};

export default ProductGroupEmptyFooter;
