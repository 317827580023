import { Product } from '../data-model/schema/databases/site-reference/documents/product/Product';
import { VariantArray } from '../data-model/schema/databases/site-reference/documents/product/ProductV2';
import {
  isProductV3,
  ProductV3,
  VariantArrayV2
} from '../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { Document } from '../data-model/types/Document';
import { convertProductToV2 } from './convertProductToV2';

function convertVariantsToV2(variants: VariantArray): VariantArrayV2 {
  return variants.map(v => ({
    ...v,
    $schemaVersion: '2',
    priceCalculation: 'None',
    valueType: 'Fixed'
  })) as VariantArrayV2;
}

export function convertProductToV3(
  product: Document<Product>
): Document<ProductV3> | 'N/A';
export function convertProductToV3(product: Product): ProductV3 | 'N/A';
export function convertProductToV3(product: Product): ProductV3 | 'N/A' {
  if (isProductV3(product)) return product;

  const v2 = convertProductToV2(product);

  if (v2 === 'N/A') return v2;

  const { preparationZone, prices = {}, variants: variantsV1, ...rest } = v2;

  const variants = convertVariantsToV2(variantsV1);

  if (preparationZone) {
    return {
      ...rest,
      $schemaVersion: '3',
      requiresPreparation: true,
      preparationZone,
      course: '0',
      priceCalculation: 'None',
      valueType: 'Fixed',
      prices,
      variants
    };
  }

  return {
    ...rest,
    $schemaVersion: '3',
    requiresPreparation: false,
    priceCalculation: 'None',
    valueType: 'Fixed',
    prices,
    variants
  };
}
