import { useCallback, useMemo } from 'react';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import {
  HeaderMenuOptions,
  HeaderMenuOptionsProps
} from '../../../../components/header-menu/HeaderMenuOptions';
import { deleteVariant } from '../../commands/deleteVariant';
import { useProductDataContext } from '../../data/ProductDataContext';
import { assertNever } from '../../../../lib/assert-never';
import { Alert } from '../../../../components/platform-alert/Alert';

export type DeleteVariantMenuOptionProps = {
  onVariantDeleted: (productId: string, variantId: string) => void;
  variantId?: string;
};

const DeleteVariantMenuOption = ({
  onVariantDeleted,
  variantId
}: DeleteVariantMenuOptionProps): JSX.Element => {
  const { product, productId } = useProductDataContext();

  const variant = useMemo(() => {
    return product?.variants.find(v => v.id === variantId);
  }, [product, variantId]);

  const { invoke: invokeCommand, inProgress } = useCommand(
    deleteVariant,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        onVariantDeleted(productId, result.data.variantId);
        return;
      }

      const errorMessage = (() => {
        if (result instanceof Error) {
          return 'An unexpected error occurred.';
        }

        switch (result.type) {
          case 'INVALID_VARIANT_TYPE':
            return 'This type of variant can not be removed.';
          case 'NOT_FOUND':
            return 'The product no longer exists.';
          case 'UNAUTHORISED':
            return 'You are not authorised to make this change.';
          default:
            return assertNever(result);
        }
      })();

      Alert.alert('Error', `Could not remove the variant.\n\n${errorMessage}`, [
        { text: 'Cancel' },
        { text: 'Retry', onPress: () => retry() }
      ]);
    }
  );

  const onSelect = useCallback(() => {
    if (
      !variantId ||
      !productId ||
      !variant ||
      variant.$schema !== 'DeclaredVariant'
    )
      return;

    Alert.alert(
      `Confirm delete`,
      `Are you sure you want to delete the ${[
        product?.productName.trim(),
        variant?.name.trim(),
        'variant'
      ].join(' ')}?`,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: () =>
            invokeCommand({
              productId,
              variantId
            })
        }
      ]
    );
  }, [variantId, productId, variant]);

  const options: HeaderMenuOptionsProps['options'] = useMemo(() => {
    if (
      !variantId ||
      !productId ||
      !variant ||
      variant.$schema !== 'DeclaredVariant'
    ) {
      return [];
    }

    return [
      {
        label: `Delete ${[variant?.name.trim(), 'variant'].join(' ')}`,
        style: 'destructive' as const,
        isDisabled: inProgress,
        onSelect
      }
    ];
  }, [variant, onSelect, inProgress, variantId, productId]);

  return <HeaderMenuOptions options={options} />;
};

export default DeleteVariantMenuOption;
