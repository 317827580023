/* eslint-disable no-restricted-syntax */
import 'ts-array-extensions';
import { initCap } from '../lib/init-cap';

const patterns = [
  /\s*(.+)\s(\d+(\.\d+)?(ml|ML|cl|l|g|kg))\s*$/,
  /\s*(.+)\s([Bb]ottle)\s*$/,
  /\s*(.+)\s([Hh]alf)\s*$/,
  /\s*(.+)\s([Pp]int)\s*$/,
  /\s*(.+)\s(1\/2 [Pp]int)\s*$/,
  /\s*(.+)\s([Ll]arge)\s*$/,
  /\s*(.+)\s([Rr]egular)\s*$/,
  /\s*(.+)\s([Ss]mall)\s*$/,
  /\s*(.+)\s([Cc]an)\s*$/,
  /\s*(.+)\s([Ss]ingle)\s*$/,
  /\s*(.+)\s([Dd]ouble)\s*$/,
  /\s*(.+)\s([Cc]ombo)\s*$/,
  /\s*(.+)\s([Oo]riginal)\s*$/
];

export const inferImpliedProductVariant = (
  productNameRaw: string
): { masterProductName: string; impliedVariantName: string | null } => {
  const productName = productNameRaw.trim();

  const firstMatch = (() => {
    for (const pattern of patterns) {
      const result = pattern.exec(productName);

      if (result && result.length > 0) {
        return result;
      }
    }

    return undefined;
  })();

  if (!firstMatch || firstMatch.length === 0) {
    return {
      masterProductName: initCap(productName),
      impliedVariantName: null
    };
  }

  const impliedVariantName = initCap(firstMatch[2]);
  const masterProductName = initCap(firstMatch[1]);

  return { masterProductName, impliedVariantName };
};
