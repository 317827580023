import React from 'react';
import { View, ViewStyle } from 'react-native';
import PressableCell, {
  PressableCellProps
} from '../../../../../../components/cells/PressableCell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { Text } from '../../../../../../components/text/Text';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import { SemanticColours } from '../../../../../../theme/SemanticColours';

export type CellProps = Omit<PressableCellProps, 'children'> & {
  quantity: string;
  name: string;
  value: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingLeft: 12,
    paddingRight: 2
  },
  quantity: {
    fontSize: 14,
    minWidth: 31,
    flexShrink: 0,
    flexWrap: 'nowrap',
    color: SemanticColours.primary.foreground[40],
    textAlign: 'right'
  },
  nameAndValue: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  name: {
    color: SemanticColours.primary.foreground[60],
    fontSize: 17,
    flexWrap: 'wrap',
    flexShrink: 1
  },
  value: {
    textAlign: 'right',
    flexShrink: 0,
    fontSize: 16
  },
  element: {
    marginHorizontal: 6
  },
  icon: {
    flexGrow: 0,
    color: SemanticColours.primary.foreground[35]
  }
});

const Cell = React.memo<CellProps>(
  ({ style, quantity, name, value, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <PressableCell style={[styles.container, style as ViewStyle]} {...rest}>
        <Text.CellTitle style={[styles.element, styles.quantity]}>
          {quantity}
        </Text.CellTitle>

        <View style={styles.nameAndValue}>
          <Text.CellTitle style={[styles.element, styles.name]}>
            {name}
          </Text.CellTitle>

          <Text.CellSubtitle style={[styles.value, styles.element]}>
            {value}
          </Text.CellSubtitle>
        </View>

        <ForwardIcon
          fill={styles.icon.color}
          height={18}
          style={[styles.element, styles.icon]}
          width={18}
        />
      </PressableCell>
    );
  }
);

export default Cell;
