import { StyleSheet, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type DividerProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  line: {
    borderBottomColor: SemanticColours.primary.foreground[15],
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginVertical: 15
  }
});

const Divider = (props: DividerProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View {...props}>
      <View style={styles.line} />
    </View>
  );
};

export default Divider;
