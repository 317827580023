import React from 'react';
import {
  IconButton,
  IconButtonProps
} from '../../../../../components/buttons/IconButton';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../lib/dynamic-style-sheet';
import SoundIcon from '../../../../../components/icons/SoundIcon';
import NoSoundIcon from '../../../../../components/icons/NoSoundIcon';

export type AlertsButtonProps = Omit<
  IconButtonProps,
  'onPress' | 'iconComponent'
> & {
  isOn: boolean;
  onToggle: () => void;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    marginVertical: 0,
    paddingHorizontal: 16
  },
  off: {
    opacity: 0.5
  }
});

const AlertsButton = React.memo<AlertsButtonProps>(
  ({ style, isOn, onToggle, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <IconButton
        style={[styles.root, !isOn && styles.off, style]}
        onPress={onToggle}
        iconComponent={isOn ? SoundIcon : NoSoundIcon}
        {...rest}
      />
    );
  }
);

export default AlertsButton;
