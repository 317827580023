import { ActivePartitionContextProvider } from '../../components/active-partition-context/ActivePartitionContext';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { DefaultPartitionNavigation } from '../../navigation/DefaultPartitionNavigation';
import { PartitionNavigationBar } from '../../components/partition-navigation-bar/PartitionNavigationBar';
import Header from '../../components/header/Header';
import UsersList from './components/users-list/UsersList';

const UsersScreen = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <Header />
      <DefaultPartitionNavigation />

      <ActivePartitionContextProvider>
        <PartitionNavigationBar />
        <UsersList />
      </ActivePartitionContextProvider>
    </ErrorBoundary>
  );
};

export default UsersScreen;
