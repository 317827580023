import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { isProductV1CompatVariant } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV1CompatVariant';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import {
  ProductV3,
  VariantArrayV2
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

const commandType = 'updateVariantPrice' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      priceBandId: string;
      variantId: string;
      newValue: { inherit: true } | { inherit: false; price: string };
      oldValue?: { inherit: true } | { inherit: false; price: string };
      variantName?: string;
    };
  }
}

const withPriceChangeApplied = (
  prices: Record<string, string> | undefined,
  priceBandId: string,
  change: { inherit: true } | { inherit: false; price: string }
): Record<string, string> => {
  const newPrices = { ...prices };

  if (change.inherit) {
    delete newPrices[priceBandId];
  } else {
    newPrices[priceBandId] = change.price;
  }

  return newPrices;
};

export const updateVariantPrice = async (
  args: Omit<SiteCommands[typeof commandType], 'type' | 'oldValue'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'INCOMPATIBLE_VALUE_TYPE' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const log = createLogger(nameof({ updateVariantPrice }), { args });

  const { repositories } = ctx.databases.reference.local;
  const { productId, priceBandId, variantId, newValue } = args;
  const { userId, createdAt } = ctx.meta;

  let name: string | null = null;
  let oldValue: SiteCommands[typeof commandType]['oldValue'] | null = null;

  const result = await repositories.products.updateDocument<
    'NOT_FOUND' | 'INCOMPATIBLE_VALUE_TYPE',
    ProductV3
  >(productId, (old, update, abort) => {
    const asV3 = convertProductToV3(old);

    if (asV3 === 'N/A') {
      return abort('NOT_FOUND');
    }

    const newVariants = [...asV3.variants] as VariantArrayV2;

    const variant = newVariants.find(v => v.id === variantId);

    if (!variant) {
      return abort('NOT_FOUND');
    }

    if (variant.valueType === 'Discretionary') {
      return abort('INCOMPATIBLE_VALUE_TYPE');
    }

    const oldPrice = variant.prices[priceBandId];
    oldValue = oldPrice
      ? { inherit: false, price: oldPrice }
      : { inherit: true };

    name = variant.name;

    const newPrices = withPriceChangeApplied(
      variant.prices,
      priceBandId,
      newValue
    );

    variant.prices = newPrices;

    return update({
      ...asV3,
      ...(variant.$schema === 'DefaultImpliedVariant' ||
      variant.$schema === 'DefaultUnnamedVariant'
        ? { prices: newPrices }
        : {}),
      revisionUserID: userId,
      revisionTime: createdAt,
      variants: newVariants
    });
  });

  if (result.type === 'ABORTED') {
    return { type: result.reason };
  }

  if (result.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  const updatedVariant = result.data.variants.find(v => v.id === variantId);

  if (!updatedVariant || updatedVariant.valueType !== 'Fixed') {
    throw new Error(
      `Atomicity fail. Product ${productId} variant ${variantId}`
    );
  }

  if (updatedVariant.$schema === 'DeclaredVariant') {
    const compatResult =
      await repositories.products.updateDocument<'NOT_COMPAT'>(
        updatedVariant.compatProductId,
        (old, update, abort) => {
          if (!isProductV1CompatVariant(old)) {
            return abort('NOT_COMPAT');
          }

          return update({
            ...old,
            revisionUserID: userId,
            revisionTime: createdAt,
            prices: updatedVariant.prices
          });
        }
      );

    if (compatResult.type !== 'SUCCESS') {
      if (compatResult.type === 'NOT_FOUND') {
        log.warn(`Variant's compatProductId not found`);
      } else if (compatResult.type === 'ABORTED') {
        log.warn(`Variant's compatProductId was not of the required type`);
      }
    }
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      priceBandId,
      variantId,
      newValue,
      oldValue: oldValue ?? undefined,
      name
    },
    [
      productId,
      variantId,
      ...(updatedVariant.$schema === 'DeclaredVariant'
        ? [updatedVariant.compatProductId]
        : [])
    ]
  );

  return { type: 'SUCCESS', data: command };
};
