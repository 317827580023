import { useEffect, useState } from 'react';
import { Pressable, PressableProps, ViewStyle } from 'react-native';
import Reanimated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type PressableCellProps = PressableProps & {
  highlighted?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[100],
    flexDirection: 'row',
    alignItems: 'center'
  },
  pressed: {
    backgroundColor: SemanticColours.primary.background[70]
  }
});

const ReanimatedPressable = Reanimated.createAnimatedComponent(Pressable);

const PressableCell = ({
  style,
  highlighted,
  onLongPress,
  onPress,
  onPressIn,
  onPressOut,
  ...rest
}: PressableCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [pressed, setPressed] = useState(false);

  const backgroundColour = useSharedValue(styles.container.backgroundColor);

  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: backgroundColour.value
  }));

  useEffect(() => {
    backgroundColour.value = styles.container.backgroundColor;
  }, [styles]);

  useEffect(() => {
    if (highlighted || pressed) {
      backgroundColour.value = withTiming(styles.pressed.backgroundColor, {
        duration: 50
      });
    } else {
      backgroundColour.value = withTiming(styles.container.backgroundColor, {
        duration: 50
      });
    }
  }, [highlighted, pressed]);

  return (
    <ReanimatedPressable
      style={[styles.container, animatedStyle, style as ViewStyle]}
      onLongPress={onLongPress}
      onPress={onPress}
      onPressIn={e => {
        if (onPress || onLongPress) {
          setPressed(true);
        }
        onPressIn?.(e);
      }}
      onPressOut={e => {
        if (onPress || onLongPress) {
          setPressed(false);
        }
        onPressOut?.(e);
      }}
      {...rest}
    />
  );
};

export default PressableCell;
