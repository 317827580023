import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { InitialProps } from 'expo/build/launch/withExpoRoot.types';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Linking from 'expo-linking';
import * as SplashScreen from 'expo-splash-screen';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from './lib/dynamic-style-sheet';
import { ThemeProvider } from './lib/theme-context';
import { DocumentCache } from './data-model/components/document-cache/DocumentCache';
import { RetainSplashContextProvider } from './lib/retain-splash/RetainSplashContextProvider';
import { RetainSplash } from './lib/retain-splash/RetainSplash';
import { LaunchArgumentsContextProvider } from './lib/launch-arguments';
import AppNavigator from './AppNavigator';
import { SecuritySchemeProviderSwitch } from './security/components/scheme/SecuritySchemeProviderSwitch';
import { AlertSoundContextProvider } from './components/alert-sound-context/AlertSoundContext';
import { ServerContextProvider } from './data-model/components/server-context/ServerContext';

void SplashScreen.preventAutoHideAsync();

const prefix = Linking.createURL('/', { scheme: 'usop' });

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1
  }
});

const App = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    prefixes: [prefix]
  };

  return (
    <RetainSplashContextProvider>
      <LaunchArgumentsContextProvider>
        <GestureHandlerRootView style={styles.container}>
          <AlertSoundContextProvider>
            <ThemeProvider theme="automatic">
              <ActionSheetProvider>
                <DocumentCache>
                  <ServerContextProvider>
                    <SafeAreaProvider>
                      <NavigationContainer
                        linking={linking}
                        fallback={<RetainSplash />}
                        documentTitle={{
                          formatter: options =>
                            [options?.title, 'Serveplus'].compact().join(' | ')
                        }}
                      >
                        <SecuritySchemeProviderSwitch>
                          <AppNavigator />
                        </SecuritySchemeProviderSwitch>
                      </NavigationContainer>
                    </SafeAreaProvider>
                  </ServerContextProvider>
                </DocumentCache>
              </ActionSheetProvider>
            </ThemeProvider>
          </AlertSoundContextProvider>
        </GestureHandlerRootView>
      </LaunchArgumentsContextProvider>
    </RetainSplashContextProvider>
  );
};

export default App;
