import { useMemo } from 'react';
import { createLogger } from '../lib/logging';
import { nameof } from '../lib/name-of';
import { UsopApiClient } from './generated/UsopApiClient.generated';
import { useContextOrThrow } from '../lib/use-context-or-throw';
import { ServerContext } from '../data-model/components/server-context/ServerContext';

export const useUsopApiClient = (): UsopApiClient => {
  const { apiBaseUrl: serverUrl } = useContextOrThrow(
    ServerContext,
    nameof({ ServerContext })
  );

  return useMemo(() => {
    const log = createLogger(nameof({ useUsopApiClient }));
    return new UsopApiClient(serverUrl, log.info);
  }, [serverUrl]);
};
