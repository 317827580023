import React from 'react';
import { ViewProps } from 'react-native';
import EmptyContent from '../../../../../components/empty-list-content/EmptyContent';
import PauseIcon from '../../../../../components/icons/PauseIcon';

export type NoInProgressContentProps = Omit<ViewProps, 'children'>;

const NoInProgressContent = React.memo<NoInProgressContentProps>(props => {
  return (
    <EmptyContent
      title="Nothing started"
      body="Check for pending or released tasks that can be started."
      iconComponent={PauseIcon}
      {...props}
    />
  );
});

export default NoInProgressContent;
