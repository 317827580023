import React from 'react';
import { FlatList, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useSiteContext } from '../../../../data-model/components/site-context/useSiteId';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import EmptyContent from '../../../../components/empty-list-content/EmptyContent';
import { SearchIcon } from '../../../../components/icons/SearchIcon';
import ChangeHistoryListItem from '../cells/ChangeHistoryListItem';
import { useQueryStream } from '../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../data-model/hooks/useTransform';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

export type ChangeHistoryModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
  productId: string;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  }
});

const ChangeHistoryModal = React.memo<ChangeHistoryModalProps>(
  ({ style, dismiss, visible, productId, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const db = useSiteContext().databases.commands;

    const { loading, data } = useTransform(
      useQueryStream(db.remote.design.changesByObjectTime.views.main, {
        startKey: [productId, []],
        endKey: [productId],
        descending: true,
        includeDocs: true
      }),
      rows =>
        rows.map(r => ({
          time: r.key[1],
          document: r.document
        })),
      () => []
    );

    return (
      <PlatformModal visible={visible} onRequestClose={dismiss} {...rest}>
        <View style={[styles.root, style]}>
          <ModalConfirmHeader onCross={dismiss} title="Change history" />
          <FlatList
            renderScrollComponent={scrollProps => (
              <PlatformScrollView {...scrollProps} />
            )}
            data={data}
            ItemSeparatorComponent={ListItemSeparator}
            keyExtractor={row => row.time}
            ListEmptyComponent={
              !loading ? (
                <EmptyContent
                  title="No changes yet"
                  iconComponent={SearchIcon}
                  body="Come back here once some changes have been made"
                />
              ) : (
                <></>
              )
            }
            renderItem={item => (
              <ChangeHistoryListItem document={item.item.document} />
            )}
          />
        </View>
      </PlatformModal>
    );
  }
);

export default ChangeHistoryModal;
