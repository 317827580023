import { SyncDisposable } from '../disposable/SyncDisposable';
import { Mapped } from './Mapped';
import { Publishable } from './Publishable';
import { Readable } from './Readable';
import { Subscribable } from './Subscribable';
import { Subscriber } from './Subscriber';

export class Subject<T>
  implements Publishable<T>, Subscribable<T>, Readable<T>
{
  constructor(
    private readonly options?: {
      readonly initialValue?: T;
      readonly onDidUnsubscribe?: () => void;
      readonly onDidSubscribe?: () => void;
    }
  ) {}

  private readonly subscribers: Map<number, Subscriber<T>> = new Map();

  private _current: T | undefined = this.options?.initialValue;

  private static lastId = 0;

  private static nextId() {
    this.lastId += 1;
    return this.lastId;
  }

  public get current() {
    return this._current;
  }

  public get subscriberCount() {
    return this.subscribers.size;
  }

  public next(value: T) {
    this._current = value;
    this.subscribers.forEach(subscriber => {
      subscriber.next(value);
    });
  }

  public subscribe(
    subscriber: Subscriber<T>,
    options?: {
      readonly sendCurrentValue?: boolean;
    }
  ): SyncDisposable {
    const id = Subject.nextId();

    this.subscribers.set(id, subscriber);

    this.options?.onDidSubscribe?.();

    if (options?.sendCurrentValue && typeof this.current !== 'undefined') {
      subscriber.next(this.current);
    }

    return {
      dispose: () => {
        this.subscribers.delete(id);

        this.options?.onDidUnsubscribe?.();
      }
    };
  }

  public map<U>(fn: (v: T) => U): Subscribable<U> {
    return new Mapped(this, fn);
  }
}
