import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useCallback } from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import { useProductDataContext } from '../../data/ProductDataContext';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { ProblemContent } from '../../../../components/unhappy-path-content/problem/ProblemContent';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ProductTab from './ProductTab';
import VariantTabs from './VariantTabs';
import { LazyMount } from '../../../../components/tabs/LazyMount';
import DeleteVariantMenuOption from '../../components/menu-options/DeleteVariantMenuOption';
import { VariantDataContextProvider } from '../../data/VariantDataContext';
import ProductTabContent from './ProductTabContent';
import VariantTabContent from './VariantTabContent';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { ScrollTabBar } from '../../../../components/tabs/ScrollTabBar';

type DetailPaneProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column'
  },
  tabBar: {
    backgroundColor: SemanticColours.secondary.background[100],
    flexGrow: 0
  }
});

const DetailPane = ({ style, ...rest }: DetailPaneProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<NavigationProp<ProductNavigatorParamList, 'show'>>();

  const route = useRoute<RouteProp<ProductNavigatorParamList, 'show'>>();

  const { product } = useProductDataContext();

  const onVariantSelect = useCallback(
    (variantId: string) => {
      navigation.setParams({ variantId });
    },
    [navigation]
  );

  const onVariantUnselect = useCallback(() => {
    navigation.setParams({ variantId: undefined });
  }, [navigation]);

  return (
    <ErrorBoundary FallbackComponent={ProblemContent}>
      <View style={[styles.container, style]} {...rest}>
        <ScrollTabBar style={styles.tabBar}>
          <ProductTab
            isActive={!route.params?.variantId}
            onPress={onVariantUnselect}
          />
          <VariantTabs
            selectedVariantId={route.params?.variantId}
            onSelect={onVariantSelect}
          />
        </ScrollTabBar>

        <LazyMount
          component={ProductTabContent}
          componentProps={{}}
          visible={!route.params?.variantId}
        />

        <AuthorisedContent minRole={RoleId.superUser}>
          <DeleteVariantMenuOption
            variantId={route.params?.variantId}
            onVariantDeleted={onVariantUnselect}
          />
        </AuthorisedContent>

        {product?.variants.map(v => (
          <VariantDataContextProvider variantId={v.id} key={v.id}>
            <LazyMount
              component={VariantTabContent}
              componentProps={{}}
              visible={route.params?.variantId === v.id}
            />
          </VariantDataContextProvider>
        ))}
      </View>
    </ErrorBoundary>
  );
};

export default DetailPane;
