import { View, ViewProps } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import LegacyCellGroup from '../../../../../../components/cells/LegacyCellGroup';
import Cell from '../../../../../../components/cells/Cell';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { Text } from '../../../../../../components/text/Text';
import { assertRouteParam } from '../../../../../../lib/assert-route-param';
import { useData } from './useData';
import PaymentTypes from './PaymentTypes';
import Accuracy from './Accuracy';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';

export type UserSummaryProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {},
  cell: {
    marginHorizontal: 24,
    marginVertical: 12
  },
  totalSales: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 16
  },
  separator: {
    alignSelf: 'stretch',
    marginVertical: 10
  },
  accuracy: {
    marginVertical: 2,
    minHeight: 22
  },
  paymentTypes: {
    marginTop: 6,
    minHeight: 22
  }
});

const UserSummary = ({ style, ...rest }: UserSummaryProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'user'>>();

  const userId = assertRouteParam(route.params, 'userId');

  const partition = useActivePartition();

  const { data } = useData(partition, userId);

  return (
    <View style={[styles.container, style]} {...rest}>
      <LegacyCellGroup headingText="Total sales">
        <Cell style={[styles.cell, styles.totalSales]}>
          <Text.BigNumber>
            {data.value !== null ? formatMoney(data.value) : ' '}
          </Text.BigNumber>

          <ListItemSeparator style={styles.separator} inset={false} />

          <PaymentTypes style={styles.paymentTypes} {...data} />
        </Cell>
      </LegacyCellGroup>

      <LegacyCellGroup headingText="Accuracy">
        <Cell style={styles.cell}>
          <Accuracy style={styles.accuracy} {...data} />
        </Cell>
      </LegacyCellGroup>
    </View>
  );
};

export default UserSummary;
