import React from 'react';
import { ViewProps } from 'react-native';
import { useSecurityDb } from '../../../../data-model/components/client-context/useSecurityDb';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { formatUserFullName } from '../../../../humanisers/formatUserFullName';
import UserCell from './UserCell';

export type BoundUserCellProps = Omit<ViewProps, 'children'> & {
  userId: string;
};

const BoundUserCell = React.memo<BoundUserCellProps>(({ userId, ...rest }) => {
  const usersDb = useSecurityDb();

  const { document: user } = useDocument(
    usersDb.local.repositories.users,
    userId
  );

  return <UserCell user={user ? formatUserFullName(user) : ''} {...rest} />;
});

export default BoundUserCell;
