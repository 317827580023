import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import {
  PaymentType,
  ServiceTransaction
} from '../../../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { QueryRow } from '../../../../../../data-model/schema/support/DatabaseView';
import { DashboardUsersForDateValue } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-users-for-date/DashboardUsersForDateDesignDoc';
import { useServingZoneNameLookup } from '../../../../../../data-model/components/site-context/useServingZoneNameLookup';

type Line = {
  key: string;
  time: string;
  lines: number;
  voids: number;
  value: number;
  paymentTypes: PaymentType[];
  transactionId: string;
};

type Section = {
  key: string;
  name: string;
  data: Line[];
  servingZoneId: string;
};

// massive time sink
//
// SectionList will remount it's header component if data is an empty array
// see:
//  https://github.com/facebook/react-native/issues/14249
//  https://github.com/facebook/react-native/issues/23400
//  https://github.com/facebook/react-native/issues/16823
//
const NO_DATA: Section[] = [
  { key: 'no-data', data: [], servingZoneId: '', name: '' }
];

export const useUserTransactionListData = (
  userId: string,
  partition: CalendarDayPartition
) => {
  const db = useTransactionsDb();

  const { data: servingZones } = useServingZoneNameLookup();

  const intervalDate = useMemo(() => {
    return partitionToIntervalDate(partition);
  }, [partition]);

  const { timeZone } = useSiteTime();

  return useTransform(
    useQueryStream(db.remote.design.dashboardUsersForDate.view, {
      startKey: [...intervalDate, userId],
      endKey: [...intervalDate, userId, []]
    }),
    rows => {
      const sections: Section[] = [];
      let currentSection: Section | undefined;

      rows.forEach(rawRow => {
        // we know we're inputting a day so our result WILL look like this
        const row = rawRow as QueryRow<
          [
            interval: 'day',
            date: string,
            userId: string,
            time: string,
            servingZoneId: string
          ],
          DashboardUsersForDateValue,
          object,
          ServiceTransaction
        >;

        const { documentID, key, value } = row;
        const servingZoneId = key[4];

        if (!currentSection || currentSection.servingZoneId !== servingZoneId) {
          currentSection = {
            key: `${servingZoneId}-${row.key[3]}`,
            servingZoneId,
            name: servingZones[servingZoneId],
            data: []
          };

          sections.push(currentSection);
        }

        const line: Line = {
          key: `${documentID}-${key[3]}`,
          transactionId: documentID,
          time: DateTime.fromISO(key[3], { zone: timeZone }).toLocaleString(
            DateTime.TIME_24_SIMPLE
          ),
          lines: (value.Sale?.units ?? 0) - (value.Void?.units ?? 0),
          voids: value.Void?.units ?? 0,
          value: [value.Sale?.value, value.Void?.value, value.Promotion?.value]
            .compact()
            .sum(),
          paymentTypes: [
            value.Card?.units ? ('Card' as const) : null,
            value.Cash?.units ? ('Cash' as const) : null,
            value.Voucher?.units ? ('Voucher' as const) : null,
            value.NoSale?.units ? ('NoSale' as const) : null
          ].compact()
        };

        currentSection.data.push(line);
      });

      return sections;
    },
    () => NO_DATA,
    [timeZone, servingZones]
  );
};
