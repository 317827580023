import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import {
  PreparationOrder,
  PreparationOrderStatus
} from '../../documents/PreparationOrder';

type Timestamp = string;
type PreparationZoneId = string;

// eslint-disable-next-line func-names
const map = function (doc: Document<PreparationOrder>) {
  'public source';

  if (
    doc._id.indexOf('preparationorder-') !== 0 ||
    doc.deleted ||
    !doc.status
  ) {
    return;
  }

  const { preparationZone, status } = doc;

  const value = 1;

  emit([preparationZone, status], value);
};

export type CountByStatusViewKey = readonly [
  PreparationZoneId,
  PreparationOrderStatus,
  Timestamp
];

export type CountByStatusViewValue = number;

export class CountByStatusView extends DatabaseView<
  CountByStatusViewKey,
  CountByStatusViewValue,
  PreparationOrder
> {
  static readonly viewName = 'main';

  static definition = {
    map: map.toString(),
    reduce: '_sum'
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, CountByStatusView.viewName);
  }
}
