import React, { Children, ComponentType, useMemo } from 'react';
import { View, ViewProps } from 'react-native';

export type SeparatedChildrenProps = ViewProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  separatorComponent: ComponentType<any>;
};

const SeparatedChildren = React.memo<SeparatedChildrenProps>(
  ({ separatorComponent: Component, children, ...rest }) => {
    const arrayChildren = useMemo(() => Children.toArray(children), [children]);

    return (
      <View {...rest}>
        {Children.map(arrayChildren, (child, index) => (
          <>
            {child}
            {index !== arrayChildren.length - 1 && <Component />}
          </>
        ))}
      </View>
    );
  }
);

export default SeparatedChildren;
