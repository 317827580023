import React from 'react';
import { Site } from '../../schema/databases/client-sites/documents/Site';
import { SiteCommandsDatabaseSchema } from '../../schema/databases/site-commands/SiteCommandsDatabaseSchema';
import { PreparationOrdersDatabaseSchema } from '../../schema/databases/site-preparation-orders/PreparationOrdersDatabaseSchema';
import { ReferenceDatabaseSchema } from '../../schema/databases/site-reference/ReferenceDatabaseSchema';
import { TransactionsArchiveDatabaseSchema } from '../../schema/databases/site-transactions-archive/TransactionsArchiveDatabaseSchema';
import { DatabasePair } from '../../schema/support/DatabasePair';
import { StockTransactionsDatabaseSchema } from '../../schema/databases/site-stock-transactions/StockTransactionsDatabaseSchema';

export type SiteContextType = {
  siteId: string;
  site: Site;
  databases: {
    commands: DatabasePair<SiteCommandsDatabaseSchema>;
    preparationOrders: DatabasePair<PreparationOrdersDatabaseSchema>;
    reference: DatabasePair<ReferenceDatabaseSchema>;
    transactionsArchive: DatabasePair<TransactionsArchiveDatabaseSchema>;
    stockTransactions: DatabasePair<StockTransactionsDatabaseSchema>;
  };
};

export const SiteContext = React.createContext<SiteContextType | null>(null);
