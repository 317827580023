import React from 'react';
import BasicCell, {
  BasicCellProps
} from '../../../../components/cells/BasicCell';
import TimerIcon from '../../../../components/icons/TimerIcon';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';

export type PreparationTimeCellProps = Omit<BasicCellProps, 'children'> & {
  time: string;
};

const rStyles = DynamicStyleSheet.create({
  subtitle: {
    fontVariant: ['tabular-nums']
  }
});

const PreparationTimeCell = React.memo<PreparationTimeCellProps>(
  ({ time, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <BasicCell
        titlesOrientation="horizontal"
        iconLeft={TimerIcon}
        title="Preparation time"
        subtitle={time}
        subtitleStyle={styles.subtitle}
        {...rest}
      />
    );
  }
);

export default PreparationTimeCell;
