import { createContext, useMemo, ReactNode } from 'react';
import { useQueryStream } from '../../../data-model/hooks/useQueryStream';
import { useReferenceDb } from '../../../data-model/components/site-context/useReferenceDb';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { nameof } from '../../../lib/name-of';
import { useSecurityDb } from '../../../data-model/components/client-context/useSecurityDb';

type ProductRelationshipsContextValue =
  | {
      loading: true;
      data: undefined;
    }
  | {
      loading: false;
      data:
        | {
            departments: Record<string, string | null>;
            optionSets: Record<string, string | null>;
            preparationZones: Record<string, string | null>;
            priceBands: Record<string, string | null>;
            users: Record<string, string | null>;
            defaultPriceBandId: string;
          }
        | undefined;
    };

export const ProductRelationshipsContext =
  createContext<ProductRelationshipsContextValue | null>(null);

type ProductRelationshipsContextProviderProps = {
  children?: ReactNode | undefined;
};

export const ProductRelationshipsContextProvider = ({
  children
}: ProductRelationshipsContextProviderProps): JSX.Element => {
  const { views } = useReferenceDb().local.design;

  const departments = useQueryStream(views.allDepartments, {
    startKey: [false],
    endKey: [false, []]
  });
  const optionSets = useQueryStream(views.productOptionSetsByName);
  const preparationZones = useQueryStream(views.preparationZonesByName);
  const priceBands = useQueryStream(views.allPriceBands, {
    includeDocs: true
  });

  const users = useQueryStream(useSecurityDb().local.design.views.allUsers, {
    includeDocs: true
  });

  const value: ProductRelationshipsContextValue = useMemo(() => {
    if (
      departments.loading ||
      optionSets.loading ||
      priceBands.loading ||
      preparationZones.loading ||
      users.loading
    ) {
      return { loading: true, data: undefined };
    }

    return {
      loading: false,
      data: {
        departments: Object.fromEntries(
          departments.data.map(row => [row.documentID, row.key[1]])
        ),
        optionSets: Object.fromEntries(
          optionSets.data.map(row => [row.documentID, row.key])
        ),
        priceBands: Object.fromEntries(
          priceBands.data.map(row => [row.documentID, row.document.name])
        ),
        preparationZones: Object.fromEntries(
          preparationZones.data.map(row => [row.documentID, row.key])
        ),
        users: Object.fromEntries(
          users.data.map(r => [r.documentID, r.key[1]])
        ),
        defaultPriceBandId: priceBands.data?.sortBy(row => row.documentID)[0]
          ?.documentID
      }
    };
  }, [departments, optionSets, priceBands, preparationZones]);

  return (
    <ProductRelationshipsContext.Provider value={value}>
      {children}
    </ProductRelationshipsContext.Provider>
  );
};

export const useProductRelationshipsContext = () =>
  useContextOrThrow(
    ProductRelationshipsContext,
    nameof({ ProductRelationshipsContext })
  );
