import {
  ClientResponsePayload,
  UsopApiClient
} from '../../api/generated/UsopApiClient.generated';
import { Logger } from '../../lib/logging';

export const verifyClient = async (
  clientId: string,
  serverUrl: string,
  log: Logger
): Promise<ClientResponsePayload | null> => {
  const api = new UsopApiClient(serverUrl);

  const clientResult = await api.getClientById({
    clientId
  });

  if (clientResult.status !== 200) {
    log.debug('Client not verified', {
      clientId,
      result: clientResult
    });
    return null;
  }

  return clientResult.body;
};
