import React from 'react';
import { PressableProps, ViewStyle } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import CollapseIcon from '../icons/CollapseIcon';
import ExpandIcon from '../icons/ExpandIcon';
import PlatformPressable from '../platform-pressable/PlatformPressable';
import { SemanticColours } from '../../theme/SemanticColours';

export type ExpandableCardHeaderProps = PressableProps & {
  expanded?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  icon: {
    color: SemanticColours.primary.foreground[40],
    height: 20,
    width: 20,
    alignSelf: 'center',
    marginTop: -10
  },
  collapseIcon: {
    marginBottom: 10
  }
});

const ExpandableCardHeader = React.memo<ExpandableCardHeaderProps>(
  ({ style, expanded, children, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <PlatformPressable style={[styles.root, style as ViewStyle]} {...rest}>
        {children}
        {expanded ? (
          <CollapseIcon
            style={[styles.icon, styles.collapseIcon]}
            fill={styles.icon.color}
            width={styles.icon.width}
            height={styles.icon.height}
          />
        ) : (
          <ExpandIcon
            style={styles.icon}
            fill={styles.icon.color}
            width={styles.icon.width}
            height={styles.icon.height}
          />
        )}
      </PlatformPressable>
    );
  }
);

export default ExpandableCardHeader;
