import NetInfo from '@react-native-community/netinfo';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';

// eslint-disable-next-line @typescript-eslint/ban-types
type IgnoreContent = {};

export class DatabaseReplicator {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static async replicate(
    from: PouchDB.Database,
    to: PouchDB.Database,
    options?: PouchDB.Replication.ReplicateOptions,
    abort?: AbortController
  ): Promise<{ type: 'SUCCESS' } | { type: 'OFFLINE' } | { type: 'ABORTED' }> {
    const log = createLogger(nameof({ DatabaseReplicator }), {
      from: from.name,
      to: to.name
    });

    const { isInternetReachable } = await NetInfo.fetch();

    if (!isInternetReachable) {
      log.info('Fail offline');
      return { type: 'OFFLINE' };
    }

    return new Promise((resolve, reject) => {
      let job: PouchDB.Replication.Replication<IgnoreContent> | undefined;

      const onAbort = () => {
        log.info('Fail aborted');

        job?.cancel();
        resolve({ type: 'ABORTED' });
      };

      job = from.replicate
        .to(to, options)
        .on('complete', result => {
          log.info('Complete', { result });

          abort?.signal.removeEventListener('abort', onAbort);
          resolve({ type: 'SUCCESS' });
        })
        .on('error', error => {
          log.info('Error', { error });

          abort?.signal.removeEventListener('abort', onAbort);
          reject(error);
        });

      abort?.signal.addEventListener('abort', onAbort);
    });
  }
}
