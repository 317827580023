import { ViewProps } from 'react-device-detect';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { disableStockTracking } from '../../commands/disableStockTracking';
import { useVariantDataContext } from '../../data/VariantDataContext';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import BasicCell from '../../../../components/cells/BasicCell';
import TickIcon from '../../../../components/icons/TickIcon';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import Warning from '../../../../components/warning/Warning';
import { useQueryStream } from '../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../data-model/hooks/useTransform';
import { useSiteContext } from '../../../../data-model/components/site-context/useSiteId';

export type DisableStockTrackingModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  warning: {
    margin: 20
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

type FormValues = {
  mode: 'UNTRACKED' | 'TRACKED';
};

const DisableStockTrackingModal = ({
  style,
  dismiss,
  visible,
  ...rest
}: DisableStockTrackingModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [commandError, setCommandError] = useState<string | null>(null);

  const { variantId, productId, variant } = useVariantDataContext();

  const variantProductId = useMemo(() => {
    if (variant && 'compatProductId' in variant) {
      return variant.compatProductId;
    }

    return productId;
  }, [variant, productId]);

  const { stockTransactions } = useSiteContext().databases;

  const writeOffBalance = useTransform(
    useQueryStream(stockTransactions.remote.design.runningBalance.views.main, {
      startKey: variantProductId,
      endKey: `${variantProductId}\ufff0`,
      groupLevel: 1,
      reduce: true
    }),
    rows => {
      const currentStock = rows.first()?.value;

      if (!currentStock) return 0;

      return (
        (currentStock.POSITIVE?.wholes ?? 0) -
        (currentStock.NEGATIVE?.wholes ?? 0)
      );
    },
    () => null
  );

  const { invoke, inProgress, reset } = useCommand(
    disableStockTracking,
    result => {
      if (!(result instanceof Error)) {
        if (result.type === 'UNAUTHORISED') {
          setCommandError('You are not authorised to make this change.');
          return;
        }

        if (result.type === 'SUCCESS') {
          dismiss();
          return;
        }
      }

      setCommandError('An unexpected error occurred.');
    }
  );

  useEffect(() => {
    if (!visible) {
      reset();
      setCommandError(null);
    }
  }, [visible, reset]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (values.mode === 'TRACKED') return;

      setCommandError(null);
      invoke({
        variantId,
        productId
      });
    },
    [invoke, variantId, productId]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView
        style={[styles.container, style as ViewStyle]}
        {...rest}
      >
        <Formik
          initialValues={{ mode: 'TRACKED' }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title="Edit stock tracking mode"
                onCross={dismiss}
                onTick={handleSubmit}
                tickEnabled={
                  !inProgress &&
                  formProps.values.mode === 'UNTRACKED' &&
                  !writeOffBalance.loading
                }
              />

              <BasicCell
                title="Untracked"
                iconRight={
                  formProps.values.mode === 'UNTRACKED' ? TickIcon : undefined
                }
                onPress={
                  formProps.values.mode === 'TRACKED'
                    ? () => formProps.setFieldValue('mode', 'UNTRACKED')
                    : undefined
                }
              />

              <ListItemSeparator />

              <BasicCell
                title="Tracked"
                iconRight={
                  formProps.values.mode === 'TRACKED' ? TickIcon : undefined
                }
                onPress={
                  formProps.values.mode === 'UNTRACKED'
                    ? () => formProps.setFieldValue('mode', 'TRACKED')
                    : undefined
                }
              />

              {formProps.values.mode === 'UNTRACKED' &&
                writeOffBalance.data !== null &&
                writeOffBalance.data > 0 && (
                  <SafeAreaView mode="padding" edges={['left', 'right']}>
                    <Warning
                      title="Stock will be written off"
                      body={`Changing the mode from tracked will cause ${writeOffBalance.data} items of current stock to be written off.`}
                      style={styles.warning}
                    />
                  </SafeAreaView>
                )}

              {commandError && (
                <ErrorMessage style={styles.error}>{commandError}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default DisableStockTrackingModal;
