import { Database } from '../../../../support/Database';
import { ByTenMinuteView } from './ByTenMinuteView';

export class DashboardChartsDesignDoc {
  private static documentId = '_design/dashboard-charts';

  static views = {
    [ByTenMinuteView.viewName]: ByTenMinuteView
  } as const;

  constructor(private readonly database: Database) {}

  public readonly views = Object.fromEntries(
    Object.entries(DashboardChartsDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, DashboardChartsDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof DashboardChartsDesignDoc.views]: InstanceType<
      (typeof DashboardChartsDesignDoc)['views'][P]
    >;
  };
}
