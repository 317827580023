import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { ProductOptionSet } from '../../documents/ProductOptionSet';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('productoptionset-') === 0 && !doc.deleted) {
    emit(null, null);
  }
};

export class AllProductOptionSetsView extends DatabaseView<
  null,
  null,
  ProductOptionSet
> {
  static readonly viewName = 'allProductOptionSets';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllProductOptionSetsView.viewName);
  }
}
