import { ScrollView, ViewProps } from 'react-native';
import { Fragment, useMemo } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { partitionForComparison } from './partitionForComparison';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { comparePartitions } from '../../../../helpers/comparePartitions';
import TickIcon from '../../../../../../components/icons/TickIcon';
import NoIcon from '../../../../../../components/icons/NoIcon';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { formatPartition } from '../../../../../../humanisers/formatPartition';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { TimeInterval } from '../../../../types/TimeInterval';

export type ComparisonListProps = Omit<ViewProps, 'children'> & {
  partition: CalendarDayPartition;
  selection: TimeInterval | CalendarDayPartition;
  onSelect: (selection: TimeInterval | CalendarDayPartition) => void;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[90],
    flex: 1
  }
});

const ComparisonList = ({
  style,
  partition,
  selection,
  onSelect,
  ...rest
}: ComparisonListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const siteSettings = useSiteTime();

  const rows = useMemo(() => {
    return [
      {
        comparison: 'day' as const,
        description: 'Previous day',
        partition: partitionForComparison(partition, 'day')
      },
      {
        comparison: 'week' as const,
        description: 'Same day, previous week',
        partition: partitionForComparison(partition, 'week')
      },
      {
        comparison: 'month' as const,
        description: 'Same day, previous pay month',
        partition: partitionForComparison(partition, 'month')
      },
      {
        comparison: 'year' as const,
        description: 'Same day, previous year',
        partition: partitionForComparison(partition, 'year')
      }
    ];
  }, [partition]);

  return (
    <ScrollView style={[styles.container, style]} {...rest}>
      {rows.map((r, index) => (
        <Fragment key={r.comparison}>
          <BasicCell
            title={r.description}
            subtitle={formatPartition(siteSettings)(r.partition)}
            iconRight={(() => {
              if (typeof selection === 'string') {
                return r.comparison === selection ? TickIcon : NoIcon;
              }

              return comparePartitions(selection, r.partition) === 0
                ? TickIcon
                : NoIcon;
            })()}
            onPress={() => onSelect(r.comparison)}
          />

          {index !== rows.length - 1 && <ListItemSeparator />}
        </Fragment>
      ))}
    </ScrollView>
  );
};

export default ComparisonList;
