import { useCallback, useMemo, useState } from 'react';
import { Alert, ViewProps } from 'react-native';
import { updateProductPreparationCourse } from '../../commands/updateProductPreparationCourse';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import BasicCell from '../../../../components/cells/BasicCell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useProductDataContext } from '../../data/ProductDataContext';
import SelectIcon from '../../../../components/icons/SelectIcon';
import SelectFromListModal from '../../../../components/select-from-list-modal/SelectFromListModal';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';

const options = [
  { id: '0', name: 'None' },
  { id: '1', name: 'First' },
  { id: '2', name: 'Second' },
  { id: '3', name: 'Third' },
  { id: '4', name: 'Fourth' }
];

export type EditPreparationCourseCellProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {}
});

const EditPreparationCourseCell = ({
  style,
  ...rest
}: EditPreparationCourseCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const data = useProductDataContext();

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const selectedPreparationCourseName = useMemo(() => {
    if (data.loading) return '';

    const { product } = data;

    if (!product.requiresPreparation) return '';

    return options.find(o => o.id === product.course)?.name ?? 'Unknown';
  }, [data]);

  const { invoke, inProgress } = useCommand(
    updateProductPreparationCourse,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        dismissModal();
        return;
      }

      const errorMessage =
        result instanceof Error
          ? 'An unexpected error occurred.'
          : 'The product no longer exists.';

      Alert.alert(
        'Error',
        `Could not update the product's preparation course.\n\n${errorMessage}`,
        [
          { text: 'Cancel', onPress: () => dismissModal() },
          { text: 'Retry', onPress: () => retry() }
        ]
      );
    }
  );

  const onAccept = useCallback(
    (id: string) => {
      invoke({ productId: data.productId, newCourse: id });
    },
    [invoke, data]
  );

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <>
      <BasicCell
        style={[styles.container, style]}
        disabled={data.loading || inProgress}
        {...rest}
        title="Course"
        titlesOrientation="horizontal"
        subtitle={selectedPreparationCourseName}
        {...(authorised
          ? { iconRight: SelectIcon, onPress: () => setModalVisible(true) }
          : {})}
      />

      <SelectFromListModal
        extractItemId={d => d.id}
        extractItemTitle={d => d.name}
        items={options}
        onAccept={onAccept}
        onDismiss={dismissModal}
        selectedItem={
          data.product?.requiresPreparation ? data.product.course : null
        }
        title="Select course"
        visible={modalVisible}
        loading={data.loading || inProgress}
      />
    </>
  );
};

export default EditPreparationCourseCell;
