import { StyleSheet, View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import TotalSalesCellGroup from './components/total-sales-cell-group/TotalSalesCellGroup';
import { ActivePartitionContextProvider } from '../../components/active-partition-context/ActivePartitionContext';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import VerticalDivider from '../../../../components/vertical-divider/VerticalDivider';
import { DefaultPartitionNavigation } from '../../navigation/DefaultPartitionNavigation';
import { PartitionNavigationBar } from '../../components/partition-navigation-bar/PartitionNavigationBar';
import Header from '../../components/header/Header';
import SubPartitionsCellGroup from './components/sub-partitions-breakdown/SubPartitionsCellGroup';
import BreakdownsCellGroup from './components/breakdowns/BreakdownsCellGroup';
import BreakdownTabBar from './components/breakdown-tab-bar/BreakdownTabBar';
import { LazyMount } from '../../../../components/tabs/LazyMount';
import UsersList from '../users/components/users-list/UsersList';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import ProductsList from '../products/components/products-list/ProductsList';
import PaymentTypesList from '../payment-types/components/list/PaymentsTypesList';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

export type ShowScreenProps = StackScreenProps<
  DashboardNavigatorParamList,
  'show'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'row',
    backgroundColor: SemanticColours.primary.background[90]
  }
})
  .override(Selectors.small, {
    pane: {
      flex: 1
    }
  })
  .override(Selectors.mediumOrLarge, {
    pane: {
      width: '45%'
    },
    rightPane: {
      flexGrow: 1
    },
    tabBar: {
      // cover the vertical divider
      marginLeft: -StyleSheet.hairlineWidth
    }
  });

const NO_PROPS = {};

const ShowScreen = ({ route }: ShowScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  return (
    <ErrorBoundary>
      <Header />
      <DefaultPartitionNavigation />

      <ActivePartitionContextProvider>
        <View style={styles.container}>
          <View style={styles.pane}>
            <PartitionNavigationBar />

            <PlatformScrollView>
              <TotalSalesCellGroup />
              <SubPartitionsCellGroup />

              {size === 'small' && <BreakdownsCellGroup />}
            </PlatformScrollView>
          </View>

          {size !== 'small' && (
            <>
              <VerticalDivider />
              <View style={styles.rightPane}>
                <BreakdownTabBar style={styles.tabBar} />

                <LazyMount
                  component={UsersList}
                  componentProps={NO_PROPS}
                  visible={route.params?.breakdownTabType === 'users'}
                />

                <LazyMount
                  component={ProductsList}
                  componentProps={NO_PROPS}
                  visible={route.params?.breakdownTabType === 'products'}
                />

                <LazyMount
                  component={PaymentTypesList}
                  componentProps={NO_PROPS}
                  visible={route.params?.breakdownTabType === 'payments'}
                />
              </View>
            </>
          )}
        </View>
      </ActivePartitionContextProvider>
    </ErrorBoundary>
  );
};

export default ShowScreen;
