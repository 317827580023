import { Switch, TextProps, View, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Text } from '../text/Text';
import Cell, { CellProps } from './Cell';

export type SwitchCellProps = Omit<CellProps, 'children'> & {
  disabled?: boolean;
  isOn: boolean;
  isOnChanged?: (value: boolean) => void;
  loading?: boolean;
  title: string;
  titleStyle?: TextProps['style'];
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 12
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  row: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    marginHorizontal: 6,
    flexWrap: 'wrap',
    flexShrink: 1
  },
  switch: {
    marginHorizontal: 6
  },
  switchLoading: {
    opacity: 0
  }
});

const SwitchCell = ({
  disabled,
  isOn,
  isOnChanged,
  loading,
  style,
  title,
  titleStyle,
  ...rest
}: SwitchCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Cell style={[styles.container, style as ViewStyle]} {...rest}>
      <SafeAreaView edges={['left', 'right']} style={styles.content}>
        <View style={styles.row}>
          <Text.CellTitle style={[styles.title, titleStyle]}>
            {title}
          </Text.CellTitle>

          <Switch
            style={[styles.switch, loading && styles.switchLoading]}
            disabled={disabled}
            value={isOn}
            onValueChange={isOnChanged}
          />
        </View>
      </SafeAreaView>
    </Cell>
  );
};

export default SwitchCell;
