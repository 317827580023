import { Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { TextInput, ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { createProduct } from '../../commands/createProduct';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../../../../components/text-field';
import { Normaliser } from '../../../../components/text-field/Normaliser';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import { ModalContentProps } from '../../../../lib/use-modal/useModal';
import { assertNever } from '../../../../lib/assert-never';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';

type FormValues = {
  name: string;
  price: string;
};

const priceNormaliser: Normaliser[] = [price => price.replace(/[^0-9.]/, '')];

const validate = ({ name, price }: FormValues): FormikErrors<FormValues> => {
  if (!name.trim()) {
    return { name: 'Name is required' };
  }

  if (!price) {
    return {
      price: 'Enter a price'
    };
  }

  if (!/^\d+(\.\d{0,2})?$/.test(price)) {
    return {
      price: 'Enter a valid price'
    };
  }

  return {};
};

export type CreateProductModalProps = ModalContentProps &
  Omit<ViewProps, 'children'> & {
    onProductCreated?: (productId: string, departmentId: string | null) => void;
    departmentId: string | null;
    dismiss: () => void;
    visible: boolean;
  };

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

const CreateProductModal = ({
  style,
  visible,
  dismiss,
  onProductCreated,
  departmentId,
  ...rest
}: CreateProductModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { invoke, inProgress, result, reset } = useCommand(createProduct, r => {
    if (!(r instanceof Error) && r.type === 'SUCCESS') {
      onProductCreated?.(r.data.productId, departmentId);
      dismiss();
    }
  });

  useEffect(() => {
    if (!visible) reset();
  }, [visible, reset]);

  const commandError = useMemo(() => {
    if (!result) {
      return null;
    }

    if (result instanceof Error) {
      return 'An unexpected error occurred.';
    }

    switch (result.type) {
      case 'NAME_IN_USE': {
        return 'Product name is already in use.';
      }
      case 'UNAUTHORISED': {
        return 'You are not authorised to make this change.';
      }
      case 'SUCCESS': {
        return null;
      }
      default:
        return assertNever(result);
    }
  }, [result]);

  const onSubmit = useCallback(
    ({ name, price }: FormValues) => {
      invoke({ name: name.trim(), standardPrice: price, departmentId });
    },
    [invoke, departmentId]
  );

  const priceFieldRef = useRef<TextInput>(null);

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView style={[styles.container, style]} {...rest}>
        <Formik
          initialValues={{ name: '', price: '' }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title="New product"
                onCross={dismiss}
                onTick={handleSubmit}
                tickEnabled={!inProgress}
              />
              <TextField
                {...formProps}
                autoCapitalize="words"
                autoCorrect
                autoFocus
                clearButtonMode="while-editing"
                enablesReturnKeyAutomatically
                editable={!inProgress}
                inputStyle={styles.field}
                name={'name'}
                onSubmitEditing={() => priceFieldRef.current?.focus()}
                placeholder="Product name"
                returnKeyType="next"
              />
              <TextField
                {...formProps}
                ref={priceFieldRef}
                autoComplete="off"
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                editable={!inProgress}
                enablesReturnKeyAutomatically
                inputStyle={styles.field}
                keyboardType="decimal-pad"
                name={'price'}
                normaliser={priceNormaliser}
                onSubmitEditing={() => handleSubmit()}
                placeholder="Standard price"
                returnKeyType="done"
              />
              {commandError && (
                <ErrorMessage style={styles.error}>{commandError}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default CreateProductModal;
