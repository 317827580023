import { SafeAreaView } from 'react-native-safe-area-context';
import { View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { memo } from 'react';
import PressableCell, {
  PressableCellProps
} from '../../../../../../components/cells/PressableCell';
import { Text } from '../../../../../../components/text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import { DashboardSummarisedData } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/DashboardSummarisedData';

export type CellProps = Omit<PressableCellProps, 'children'> & {
  time: string;
  user: string;
  servingZone: string;
  value: DashboardSummarisedData;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 6
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  time: {
    width: 56
  },
  zoneAndUser: {
    flexDirection: 'column'
  },
  user: {
    color: SemanticColours.primary.foreground[40],
    fontSize: 15
  },
  zone: {
    color: SemanticColours.primary.foreground[60]
  },
  units: {
    fontSize: 15
  },
  iconProps: {
    color: SemanticColours.primary.foreground[50],
    width: 18,
    height: 18
  },
  icon: {
    marginTop: 4
  },
  element: {
    marginHorizontal: 6
  },
  spacer: {
    flexGrow: 1
  }
}).override(Selectors.mediumOrLarge, {
  zoneAndUser: {
    flexDirection: 'row'
  },
  user: {
    marginHorizontal: 12
  }
});

const Cell = memo(
  ({
    style,
    time,
    value,
    user,
    servingZone,
    ...rest
  }: CellProps): JSX.Element => {
    const styles = useDynamicStyleSheet(rStyles);

    const sharedIconProps: SvgProps = {
      fill: styles.iconProps.color,
      width: styles.iconProps.width,
      height: styles.iconProps.height,
      style: styles.icon
    };

    return (
      <PressableCell style={[styles.container, style as ViewStyle]} {...rest}>
        <SafeAreaView edges={['left', 'right']} style={styles.content}>
          <Text.CellTitle style={[styles.element, styles.time]}>
            {time}
          </Text.CellTitle>

          <View style={[styles.zoneAndUser, styles.element]}>
            <Text.CellTitle style={styles.zone}>{servingZone}</Text.CellTitle>
            <Text.CellTitle style={styles.user}>{user}</Text.CellTitle>
          </View>

          <View style={styles.spacer} />

          {value.units > 1 && (
            <Text.CellSubtitle style={[styles.element, styles.units]}>
              x {value.units}
            </Text.CellSubtitle>
          )}

          <Text.CellSubtitle style={styles.element}>
            {formatMoney(value.value)}
          </Text.CellSubtitle>
          <ForwardIcon {...sharedIconProps} />
        </SafeAreaView>
      </PressableCell>
    );
  }
);

export default Cell;
