import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { User } from '../../documents/User';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('user-') === 0) {
    emit(
      [
        !!doc.deleted ?? false,
        [doc.firstName, doc.lastName]
          .filter(n => !!n)
          .map(s => String(s).trim())
          .join(' ')
      ],
      null
    );
  }
};

export class AllUsersView extends DatabaseView<
  readonly [deleted: boolean, fullName: string],
  null,
  User
> {
  static readonly viewName = 'allUsers';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllUsersView.viewName);
  }
}
