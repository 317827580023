import React from 'react';
import { View, ViewProps, ScrollView } from 'react-native';
import { usePreparationOrdersDb } from '../../../../data-model/components/site-context/usePreparationOrdersDb';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { NotFoundContent } from '../../../../components/unhappy-path-content/not-found/NotFoundContent';
import StatusTimeline from './StatusTimeline';
import StatusCell from './StatusCell';
import DetailModalLines from './DetailModalLines';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import TableCell from './TableCell';
import CourseCell from './CourseCell';
import CellGroup from '../../../../components/cells/CellGroup';
import BoundUserCell from './BoundUserCell';
import BoundPreparationTimeCell from './BoundPreparationTimeCell';

export type DetailModalContentProps = Omit<ViewProps, 'children'> & {
  orderId: string;
  perspective: 'pass' | 'prep';
};

const rStyles = DynamicStyleSheet.create({
  root: {
    flex: 1
  },
  timelineContainer: {
    backgroundColor: SemanticColours.primary.background[100],
    width: '100%'
  },
  timelineContent: {
    flexDirection: 'column',
    padding: 32,
    alignItems: 'center'
  },
  linesHeading: {
    backgroundColor: 'transparent'
  },
  lines: {
    padding: 20
  }
});

const DetailModalContent = React.memo<DetailModalContentProps>(
  ({ style, orderId, perspective, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const prepDb = usePreparationOrdersDb();

    const { loading, document } = useDocument(prepDb.local.repository, orderId);

    return (
      <View style={[style, styles.root]} {...rest}>
        {!loading && !document && <NotFoundContent objectDescription="order" />}

        {document && (
          <>
            <CellGroup headingText="Details">
              {document.tableNumber && (
                <TableCell tableNumber={document.tableNumber} />
              )}

              {document.course !== '0' && (
                <CourseCell course={document.course} />
              )}

              <BoundUserCell userId={document.userID} />

              <BoundPreparationTimeCell document={document} />
            </CellGroup>

            <DetailModalLines document={document} />

            <CellGroup headingText="Status">
              <StatusCell document={document} perspective={perspective} />
            </CellGroup>

            <CellGroup headingText="Timeline">
              <ScrollView
                style={styles.timelineContainer}
                contentContainerStyle={styles.timelineContent}
                horizontal
                showsHorizontalScrollIndicator={false}
              >
                <StatusTimeline document={document} />
              </ScrollView>
            </CellGroup>
          </>
        )}
      </View>
    );
  }
);

export default DetailModalContent;
