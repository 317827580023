import { DateTime } from 'luxon';
import { View } from 'react-native';
import Cell, { CellProps } from '../../../../../../components/cells/Cell';
import { Text } from '../../../../../../components/text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { LineGroup } from './useTransactionLinesData';
import Line from './Line';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';

export type GroupCellProps = Omit<CellProps, 'children'> & {
  data: LineGroup;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  time: {
    fontSize: 16,
    lineHeight: 34
  },
  lines: {
    flexGrow: 1
  }
})
  .override(Selectors.mediumOrLarge, {
    container: {
      flexDirection: 'row'
    },
    time: {
      width: 90
    }
  })
  .override(Selectors.small, {
    lines: {
      marginLeft: 30
    }
  });

const GroupCell = ({ style, data, ...rest }: GroupCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Cell style={[styles.container, style]} {...rest}>
      <Text.CellSubtitle style={styles.time}>
        {data.dt.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
      </Text.CellSubtitle>
      <View style={styles.lines}>
        {data.lines.map(line => (
          <Line data={line} key={line.key} />
        ))}
      </View>
    </Cell>
  );
};

export default GroupCell;
