import { ComponentType, forwardRef } from 'react';
import { Text, TextProps, View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Colours } from '../../theme/Colours';
import { nameof } from '../../lib/name-of';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { PropsType } from '../../lib/react-props-type';
import PlatformPressable from '../platform-pressable/PlatformPressable';

const rStyles = DynamicStyleSheet.create({
  button: {
    backgroundColor: Colours.transparent.$,
    borderRadius: 10,
    padding: 4,
    alignItems: 'center',
    flexDirection: 'row'
  },
  disabled: {
    opacity: 0.5
  },
  title: {
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 24
  },
  element: {
    marginHorizontal: 4
  }
}).override(Selectors.mediumOrLarge, {
  button: {
    padding: 8,
    marginVertical: -4
  }
});

export type ButtonProps = Omit<
  PropsType<typeof PlatformPressable>,
  'children'
> & {
  iconColour?: string;
  iconComponent: ComponentType<SvgProps>;
  iconSize?: number;
  title: string;
  titleStyle?: TextProps['style'];
};

export const Button = forwardRef<View, ButtonProps>(
  (
    {
      style,
      iconComponent: Icon,
      disabled,
      iconColour,
      iconSize = 24,
      title,
      titleStyle,
      ...rest
    },
    ref
  ): JSX.Element => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <PlatformPressable
        ref={ref}
        testID={nameof({ Button })}
        accessibilityRole="button"
        style={[styles.button, style as ViewStyle]}
        disabled={disabled}
        {...rest}
      >
        <Icon
          style={[disabled && styles.disabled, styles.element]}
          fill={iconColour}
          width={iconSize}
          height={iconSize}
        />
        <Text style={[styles.title, styles.element, titleStyle]}>{title}</Text>
      </PlatformPressable>
    );
  }
);
