import PouchDBBuilder from 'pouchdb-core';
import HttpPouch from 'pouchdb-adapter-http';
import replication from 'pouchdb-replication';
import mapreduce from 'pouchdb-mapreduce';
import idb from 'pouchdb-adapter-idb';

const PouchDB = PouchDBBuilder.plugin(HttpPouch)
  .plugin(replication)
  .plugin(mapreduce)
  .plugin(idb);

export default PouchDB;
