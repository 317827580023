import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import { SiteCommand } from '../../documents/SiteCommand';

export type ChangesByObjectTimeViewKey = [string, string];

export type ChangesByObjectTimeViewValue = null;

export class ChangesByObjectTimeView extends DatabaseView<
  ChangesByObjectTimeViewKey,
  ChangesByObjectTimeViewValue,
  SiteCommand
> {
  static readonly viewName = 'main';

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, ChangesByObjectTimeView.viewName);
  }
}
