import { PropsWithChildren, createContext, useMemo } from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { nameof } from '../../../../lib/name-of';
import { Partition } from '../../types/Partition';
import EmptyContent from '../../../../components/empty-list-content/EmptyContent';
import DashboardIcon from '../../../../components/icons/DashboardIcon';
import { PartitionSerialiser } from '../../navigation/PartitionSerialiser';

export type ActivePartitionContextValue = Readonly<{
  partition: Partition;
}>;

export const ActivePartitionContext =
  createContext<ActivePartitionContextValue | null>(null);

export const ActivePartitionContextProvider = ({
  children
}: // eslint-disable-next-line @typescript-eslint/ban-types
PropsWithChildren<{}>): JSX.Element => {
  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'show'>>();

  const partition = useMemo(
    () =>
      route.params?.partition
        ? PartitionSerialiser.parse(route.params.partition)
        : null,
    [route]
  );

  if (route.params?.partition === 'none') {
    return (
      <EmptyContent
        title="No sales yet"
        iconComponent={DashboardIcon}
        body="Come back here once some sales have been made"
      />
    );
  }

  if (!partition) {
    return <></>;
  }

  return (
    <ActivePartitionContext.Provider
      value={{
        partition
      }}
    >
      {children}
    </ActivePartitionContext.Provider>
  );
};

export const useActivePartition = <T extends Partition = Partition>(
  guard?: (value: Partition) => value is T
): T => {
  const { partition } = useContextOrThrow(
    ActivePartitionContext,
    nameof({ ActivePartitionContext })
  );

  if (guard) {
    if (guard(partition)) {
      return partition;
    }

    throw new Error('Partition type guard failed');
  }

  return partition as T;
};
