import { View, ViewProps } from 'react-native';
import { ComponentType } from 'react';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../theme/SemanticColours';
import CellGroupHeading from './CellGroupHeading';
import CellGroupSeparator from './CellGroupSeparator';
import CellGroupFooter from './CellGroupFooter';
import { useCellGroupStyles } from './useCellGroupStyles';

export interface LegacyCellGroupProps extends ViewProps {
  headingIconComponent?: ComponentType<SvgProps>;
  headingText?: string;
  footerText?: string;
  loading?: boolean;
  insets?: Parameters<typeof useCellGroupStyles>['0'];
}

const rStyles = DynamicStyleSheet.create({
  cells: {
    alignItems: 'stretch',
    backgroundColor: SemanticColours.primary.background[100],
    flexDirection: 'column',
    overflow: 'hidden'
  }
}).override(Selectors.mediumOrLarge, {
  cells: {
    marginHorizontal: 24
  }
});

const LegacyCellGroup = ({
  children,
  footerText,
  headingIconComponent,
  headingText,
  loading,
  insets = 'both',
  ...rest
}: LegacyCellGroupProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { header, cell } = useCellGroupStyles(insets);

  return (
    <View {...rest}>
      <CellGroupSeparator style={header} />

      {!!headingText && (
        <CellGroupHeading
          style={header}
          title={headingText ?? ''}
          loading={loading}
          iconComponent={headingIconComponent}
        />
      )}

      <View style={[styles.cells, cell(true, true)]} children={children} />

      {!!footerText && <CellGroupFooter text={footerText} style={header} />}

      <CellGroupSeparator style={header} />
    </View>
  );
};

export default LegacyCellGroup;
