import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { NavigationContainer } from '@react-navigation/native';
import { ComponentType } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { HeaderMenuContextProvider } from '../components/header-menu/HeaderMenuContext';
import { DocumentCache } from '../data-model/components/document-cache/DocumentCache';
import { ThemeProvider } from '../lib/theme-context';
import { ServerContextProvider } from '../data-model/components/server-context/ServerContext';
import { LegacySecurityProvider } from '../security/legacy/LegacySecurityProvider';
import { RoleId } from '../data-model/schema/databases/client-security/documents/Role';
import { AlertSoundContextProvider } from '../components/alert-sound-context/AlertSoundContext';
import { LaunchArgumentsContext } from '../lib/launch-arguments';

export type SiteEntryPointProps = LaunchArguments & {
  deviceId: string;
  userId: string;
  username: string;
  fullName: string;
  role: RoleId;
  clientId: string;
  siteId: string;
  dbUsername: string;
  dbPassword: string;
  navigatorComponent: ComponentType;
};

const SiteEntryPoint = ({
  navigatorComponent: Navigator,
  ...launchArgs
}: SiteEntryPointProps): JSX.Element => {
  return (
    <LaunchArgumentsContext.Provider value={launchArgs}>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <AlertSoundContextProvider>
          <ThemeProvider theme="light">
            <ActionSheetProvider>
              <DocumentCache>
                <ServerContextProvider>
                  <LegacySecurityProvider {...launchArgs}>
                    <SafeAreaProvider>
                      <HeaderMenuContextProvider>
                        <NavigationContainer>
                          <Navigator />
                        </NavigationContainer>
                      </HeaderMenuContextProvider>
                    </SafeAreaProvider>
                  </LegacySecurityProvider>
                </ServerContextProvider>
              </DocumentCache>
            </ActionSheetProvider>
          </ThemeProvider>
        </AlertSoundContextProvider>
      </GestureHandlerRootView>
    </LaunchArgumentsContext.Provider>
  );
};

export default SiteEntryPoint;
