import React from 'react';
import BasicCell, {
  BasicCellProps
} from '../../../../components/cells/BasicCell';
import UserIcon from '../../../../components/icons/UserIcon';

export type UserCellProps = Omit<BasicCellProps, 'children'> & {
  user: string;
};

const UserCell = React.memo<UserCellProps>(({ user, ...rest }) => {
  return (
    <BasicCell
      // titlesOrientation="horizontal"
      iconLeft={UserIcon}
      title="Server"
      subtitle={user}
      {...rest}
    />
  );
});

export default UserCell;
