import { View, Text, ViewProps } from 'react-native';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import TimelineSpot from './TimelineSpot';
import TimelineLine from './TimelineLine';
import { useSiteTime } from '../../features/dashboard/hooks/useSiteTime';

export interface TimelineItemProps extends Omit<ViewProps, 'children'> {
  completed: boolean;
  nextCompleted?: boolean;
  label: string;
  time?: string;
  isFirst?: boolean;
  isLast?: boolean;
}

const rStyles = DynamicStyleSheet.create({
  root: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  lineAndSpot: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexDirection: 'row'
  },
  leftLine: {
    flexGrow: 1
  },
  rightLine: {
    flexGrow: 1
  },
  text: {
    color: SemanticColours.primary.foreground[60],
    textAlign: 'center',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.45,
    textTransform: 'uppercase',
    lineHeight: 24,
    marginHorizontal: 8
  },
  status: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingVertical: 0,
    paddingHorizontal: 8
  },
  time: {
    color: SemanticColours.primary.foreground[60],
    textAlign: 'center',
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: 0.25,
    marginHorizontal: 8
  },
  notCompleted: {
    color: SemanticColours.primary.foreground[20]
  }
});

const TimelineItem = ({
  completed,
  nextCompleted,
  label,
  isFirst,
  isLast,
  time,
  ...rest
}: TimelineItemProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { timeZone } = useSiteTime();

  const timeStr = useMemo(() => {
    return time
      ? DateTime.fromISO(time, { zone: timeZone }).toFormat('HH:mm')
      : '';
  }, [time, timeZone]);

  return (
    <View style={styles.root} {...rest}>
      <View style={styles.lineAndSpot}>
        <TimelineLine
          style={styles.leftLine}
          completed={completed}
          visible={!isFirst}
        />

        <TimelineSpot completed={completed} />

        <TimelineLine
          style={styles.rightLine}
          completed={nextCompleted}
          visible={!isLast}
        />
      </View>

      <View style={styles.status}>
        <Text style={[styles.text, !completed && styles.notCompleted]}>
          {label}
        </Text>
      </View>

      <Text style={[styles.time, !completed && styles.notCompleted]}>
        {timeStr}
      </Text>
    </View>
  );
};

export default TimelineItem;
