import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';

const commandType = 'deleteProductGroup' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      departmentId: string;
    };
  }
}

export const deleteProductGroup = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const { products: productsRepo, departments: departmentsRepo } =
    ctx.databases.reference.local.repositories;

  const { productsByDepartment } = ctx.databases.reference.local.design.views;

  const { departmentId } = args;
  const { userId, createdAt } = ctx.meta;

  const products = await productsByDepartment.getQuery({
    key: [departmentId, false]
  });

  const productIds = products.map(r => r.documentID);

  const auditFields = {
    revisionUserID: userId,
    revisionTime: createdAt
  };

  await Promise.all(
    productIds.map(productId =>
      productsRepo.updateDocument(productId, (p, update) =>
        update({ ...p, deleted: true, ...auditFields })
      )
    )
  );

  await departmentsRepo.updateDocument(departmentId, (p, update) =>
    update({ ...p, deleted: true, ...auditFields })
  );

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      departmentId
    },
    [departmentId, ...productIds]
  );

  return { type: 'SUCCESS', data: command };
};
