import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../../../components/cells/LegacyCellGroup';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import TeamIcon from '../../../../../../components/icons/TeamIcon';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';
import ProductsIcon from '../../../../../../components/icons/ProductsIcon';
import PaymentsIcon from '../../../../../../components/icons/PaymentsIcon';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';

export type BreakdownsCellGroupProps = LegacyCellGroupProps;

const rStyles = DynamicStyleSheet.create({ container: {} });

const BreakdownsCellGroup = ({
  style,
  ...rest
}: BreakdownsCellGroupProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const partition = useActivePartition();

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'show'>>();

  return (
    <LegacyCellGroup
      style={[styles.container, style]}
      headingText="Breakdowns"
      {...rest}
    >
      <BasicCell
        iconLeft={TeamIcon}
        title="Team"
        onPress={() =>
          navigation.push('users', {
            partition: PartitionSerialiser.stringify(partition)
          })
        }
        iconRight={ForwardIcon}
      />

      <ListItemSeparator />

      <BasicCell
        iconLeft={ProductsIcon}
        title="Products"
        onPress={() =>
          navigation.push('products', {
            partition: PartitionSerialiser.stringify(partition)
          })
        }
        iconRight={ForwardIcon}
      />

      <ListItemSeparator />

      <BasicCell
        iconLeft={PaymentsIcon}
        title="Payments"
        onPress={() =>
          navigation.push('payment-types', {
            partition: PartitionSerialiser.stringify(partition)
          })
        }
        iconRight={ForwardIcon}
      />
    </LegacyCellGroup>
  );
};

export default BreakdownsCellGroup;
