import { ReactNode, createContext } from 'react';
import { useSound } from '../../lib/use-sound';
import { Assets } from '../../assets/Assets';

export type AlertSoundContextType = {
  play: () => void;
};

export const AlertSoundContext = createContext<AlertSoundContextType | null>(
  null
);

export const AlertSoundContextProvider = ({
  children
}: {
  children?: ReactNode;
}): JSX.Element => {
  const sound = useSound(Assets.ring);

  return (
    <AlertSoundContext.Provider value={sound}>
      {children}
    </AlertSoundContext.Provider>
  );
};
