import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { Partition } from '../../../../types/Partition';
import { partitionToDate } from '../../../../helpers/partitionToDate';
import thenThrow from '../../../../../../lib/then-throw';
import { partitionToDateTimeRange } from '../../../../helpers/partitionToDateTimeRange';

const INTERVAL = 10 * 60 * 1000;

type Value = {
  loading: boolean;
  data:
    | undefined
    | {
        points: readonly { readonly x: number; readonly y: number }[];
        startTime: number;
        endTime: number;
      };
};

export const useSeriesData = (partition: Partition): Value => {
  const date = useMemo(
    () =>
      partitionToDate(partition).toISODate() ?? thenThrow('Invalid partition'),
    [partition]
  );

  const db = useTransactionsDb();

  const siteTime = useSiteTime();

  return useTransform(
    useQueryStream(db.remote.design.dashboardCharts.views['by-10-min'], {
      startKey: [date],
      endKey: [date, []],
      groupLevel: 2,
      reduce: true
    }),
    rows => {
      const [startTime, endTime] = partitionToDateTimeRange(siteTime)(
        partition
      ).map(d => d.toMillis());

      let runningTotal = 0;
      let previousTime = 0;

      const dataPoints: { x: number; y: number }[] = [];

      rows.forEach(r => {
        if (r.value.value === 0) return;

        const absoluteTime = Math.min(
          DateTime.fromISO(r.key[1], {
            zone: 'utc'
          }).toMillis(),
          endTime
        );

        const recordRelativeTime = absoluteTime - startTime;

        if (previousTime < recordRelativeTime - INTERVAL) {
          dataPoints.push({
            x: recordRelativeTime - INTERVAL,
            y: runningTotal
          });
        }

        runningTotal += r.value.value ?? 0;
        previousTime = recordRelativeTime;

        dataPoints.push({
          x: recordRelativeTime,
          y: runningTotal
        });
      });

      return { points: dataPoints, startTime, endTime };
    },
    () => undefined,
    [siteTime]
  );
};
