import { useEffect } from 'react';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { nameof } from '../../../lib/name-of';
import { usePreparationOrdersDb } from '../site-context/usePreparationOrdersDb';
import { useQueryStream } from '../../hooks/useQueryStream';
import { PullerContext } from './PullerContext';

export type ZoneMonitorProps = {
  preparationZoneId: string;
};

const ZoneMonitor = ({ preparationZoneId }: ZoneMonitorProps): JSX.Element => {
  const db = usePreparationOrdersDb();

  const { data: remoteData } = useQueryStream(
    db.remote.design.summaryByStatus.views.main,
    {
      startKey: [preparationZoneId, '01 Pending'],
      endKey: [preparationZoneId, '04 Ready', []]
    }
  );

  const { data: localData } = useQueryStream(
    db.local.design.summaryByStatus.views.main,
    {
      startKey: [preparationZoneId, '01 Pending'],
      endKey: [preparationZoneId, '04 Ready', []]
    }
  );

  const { registerIdsForKey, releaseKey } = useContextOrThrow(
    PullerContext,
    nameof({ PullerContext })
  );

  useEffect(() => {
    const newIds = [
      ...(remoteData?.map(d => d.documentID) ?? []),
      ...(localData?.map(d => d.documentID) ?? [])
    ]
      .distinct()
      .sort();

    registerIdsForKey(preparationZoneId, newIds);
  }, [preparationZoneId, registerIdsForKey, remoteData, localData]);

  useEffect(() => {
    return () => {
      releaseKey(preparationZoneId);
    };
  }, [releaseKey, preparationZoneId]);

  return <></>;
};

export default ZoneMonitor;
