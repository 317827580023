import React, { useMemo } from 'react';
import { ButtonProps } from '../button/ButtonProps';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { nameof } from '../../../../lib/name-of';
import ClientSwitchContent from './ClientSwitchContent';
import { PersonalSecurityContext } from '../../../../security/personal/PersonalSecurityContext';
import { Button } from '../button/Button';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import ClientSwitchModal from './ClientSwitchModal';

export type ClientSwitchButtonProps = Omit<ButtonProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  root: {
    borderRadius: 6
  }
});

const ClientSwitchButton = React.memo<ClientSwitchButtonProps>(
  ({ style, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const security = useContextOrThrow(
      PersonalSecurityContext,
      nameof({ PersonalSecurityContext })
    );

    const activeClient = useMemo(() => {
      const client = security.clients.find(
        c => c.id === security.activeClientId
      );

      return client ?? null;
    }, [security]);

    const { present, ...modal } = useModalControl();

    return (
      <>
        <Button
          style={[styles.root, style]}
          {...rest}
          disabled={!activeClient}
          onPress={() => present()}
        >
          <ClientSwitchContent
            userName={activeClient?.user.fullName ?? ''}
            clientName={activeClient?.name ?? ''}
          />
        </Button>

        <ClientSwitchModal {...modal} />
      </>
    );
  }
);

export default ClientSwitchButton;
