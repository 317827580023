import { Pressable, PressableProps, View, ViewStyle } from 'react-native';
import Reanimated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useEffect } from 'react';
import Pill from '../../../../components/pill/Pill';
import IconButtonMenu from '../../../../components/buttons/IconButtonMenu';
import HorizontalMenuIcon from '../../../../components/icons/HorizontalMenuIcon';
import CollapseIcon from '../../../../components/icons/CollapseIcon';
import ExpandIcon from '../../../../components/icons/ExpandIcon';
import { Text } from '../../../../components/text/Text';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useDeleteDepartment } from '../../data/useDeleteDepartment';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import RenameGroupModal from '../modals/RenameGroupModal';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import AuthorisedContent from '../../../../security/components/authorised-content/AuthorisedContent';

export type ProductGroupHeaderCellProps = Omit<PressableProps, 'children'> & {
  department: {
    id: string | null;
    name: string | null;
    count: number;
  };
  expanded: boolean;
  onRequestAdd: () => void;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 6,
    paddingBottom: 0,
    backgroundColor: SemanticColours.primary.background[90]
  },
  containerPressed: {
    backgroundColor: SemanticColours.primary.background['80']
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  expandCollapseIcon: {
    flexShrink: 0,
    flexGrow: 0
  },
  title: {
    marginVertical: 12,
    marginHorizontal: 8,
    flexShrink: 1
  },
  titleUngrouped: {
    fontStyle: 'italic'
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    width: 42,
    height: 42
  },
  icon: {
    color: SemanticColours.primary.foreground[50]
  },
  spacing: {
    marginHorizontal: 8
  }
});

const ReanimatedPressable = Reanimated.createAnimatedComponent(Pressable);

const ProductGroupHeaderCell = ({
  style,
  disabled,
  department,
  expanded,
  onPressIn,
  onPressOut,
  onRequestAdd,
  ...rest
}: ProductGroupHeaderCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const backgroundColor = useSharedValue(styles.container.backgroundColor);

  const pressedStyle = useAnimatedStyle(() => ({
    backgroundColor: backgroundColor.value
  }));

  useEffect(() => {
    backgroundColor.value = styles.container.backgroundColor;
  }, [styles]);

  const onDelete = useDeleteDepartment(department);

  const { present: presentRenameModal, ...renameModal } = useModalControl();

  return (
    <ReanimatedPressable
      style={[styles.container, style as ViewStyle, pressedStyle]}
      disabled={disabled}
      onPressIn={e => {
        backgroundColor.value = withTiming(
          styles.containerPressed.backgroundColor,
          {
            duration: 150
          }
        );
        onPressIn?.(e);
      }}
      onPressOut={e => {
        backgroundColor.value = withTiming(styles.container.backgroundColor, {
          duration: 150
        });
        onPressOut?.(e);
      }}
      {...rest}
    >
      <SafeAreaView edges={['left', 'right']} style={styles.content}>
        {expanded
          ? !disabled && (
              <CollapseIcon
                fill={styles.icon.color}
                style={styles.expandCollapseIcon}
              />
            )
          : !disabled && (
              <ExpandIcon
                fill={styles.icon.color}
                style={styles.expandCollapseIcon}
              />
            )}
        <Text.SectionTitle
          style={[
            styles.title,
            styles.spacing,
            !department.id && styles.titleUngrouped
          ]}
        >
          {!department.id ? 'ungrouped' : department.name ?? 'No name'}
        </Text.SectionTitle>

        {expanded && department.id && (
          <AuthorisedContent minRole={RoleId.superUser}>
            <IconButtonMenu
              iconComponent={HorizontalMenuIcon}
              style={[styles.button]}
              iconColour={styles.icon.color}
              iconSize={20}
              actionSheetOptions={{
                options: [
                  { label: 'Rename group', onSelect: presentRenameModal },
                  { label: 'Add product', onSelect: onRequestAdd },
                  {
                    label: 'Delete group',
                    style: 'destructive',
                    onSelect: onDelete
                  },
                  { label: 'Cancel', style: 'cancel' }
                ],
                title: !department.id
                  ? 'Ungrouped'
                  : department.name ?? 'No name'
              }}
            />
            <RenameGroupModal
              departmentId={department.id}
              existingName={department.name ?? ''}
              {...renameModal}
            />
          </AuthorisedContent>
        )}

        <View style={styles.spacer} />

        <Pill value={department.count} max={999} style={styles.spacing} />
      </SafeAreaView>
    </ReanimatedPressable>
  );
};

export default ProductGroupHeaderCell;
