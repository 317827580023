import { range } from 'ts-array-extensions';
import { Interval } from 'luxon';
import {
  CalendarMonthPartition,
  CalendarYearPartition,
  IsoWeekPartition
} from '../types/Partition';
import { CalendarDayPartition } from '../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { isCalendarYearPartition } from './isCalendarYearPartition';
import { isIsoWeekPartition } from './isIsoWeekPartition';
import { assertNever } from '../../../lib/assert-never';
import { partitionToDate } from './partitionToDate';
import { isCalendarMonthPartition } from './isCalendarMonthPartition';

function partitionToSubPartitions(
  partition: CalendarYearPartition
): CalendarMonthPartition[];

function partitionToSubPartitions(
  partition: CalendarMonthPartition
): IsoWeekPartition[];

function partitionToSubPartitions(
  partition: IsoWeekPartition
): CalendarDayPartition[];

function partitionToSubPartitions(
  partition: CalendarYearPartition | CalendarMonthPartition | IsoWeekPartition
): CalendarMonthPartition[] | IsoWeekPartition[] | CalendarDayPartition[];

function partitionToSubPartitions(
  partition: CalendarYearPartition | CalendarMonthPartition | IsoWeekPartition
): CalendarMonthPartition[] | CalendarDayPartition[] | IsoWeekPartition[] {
  if (isCalendarYearPartition(partition)) {
    return range(1, 12).map(month => [...partition, month]);
  }

  if (isIsoWeekPartition(partition)) {
    const start = partitionToDate(partition);

    return range(0, 6).map(days => {
      const date = start.plus({ days });

      return ['calendar', date.year, date.month, date.day];
    });
  }

  if (isCalendarMonthPartition(partition)) {
    const start = partitionToDate(partition).startOf('week');
    const end = partitionToDate(partition).endOf('month').startOf('week');

    const length = Interval.fromDateTimes(start, end).length('weeks');

    return range(0, length).map(weeks => {
      const date = start.plus({ weeks });

      return ['iso', date.weekYear, date.weekNumber];
    });
  }

  return assertNever(partition);
}

export { partitionToSubPartitions };
