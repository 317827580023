import React, { ReactNode } from 'react';
import { Text, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../theme/SemanticColours';

export type PreparationOrderLineProps = Omit<ViewProps, 'children'> & {
  text: string;
  note?: ReactNode;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  text: {
    color: SemanticColours.primary.foreground[80],
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 23.5
  }
});

const PreparationOrderLine = React.memo<PreparationOrderLineProps>(
  ({ style, text, note, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style]} {...rest}>
        <Text style={styles.text}>{text}</Text>
        {note}
      </View>
    );
  }
);

export default PreparationOrderLine;
