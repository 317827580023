import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useEffect } from 'react';
import { PrepNavigatorParamList } from '../navigation/PrepNavigatorParamList';
import { useReferenceDb } from '../../../data-model/components/site-context/useReferenceDb';

const DefaultZoneNavigation = (): JSX.Element => {
  const route = useRoute<RouteProp<PrepNavigatorParamList>>();
  const navigation = useNavigation<NavigationProp<PrepNavigatorParamList>>();

  const db = useReferenceDb();

  useEffect(() => {
    if (route.params?.prepZoneId) return () => {};

    let mounted = true;

    void (async () => {
      const zones = await db.local.design.views.allPreparationZones.getQuery();

      const firstZone = zones.first()?.documentID;

      if (!mounted || !firstZone) return;

      navigation.setParams({ prepZoneId: firstZone });
    })();

    return () => {
      mounted = false;
    };
  }, [route, navigation]);

  return <></>;
};

export default DefaultZoneNavigation;
