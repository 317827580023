import { createContext } from 'react';
import { NewClientInput, PersonalSecurity } from './types';

export type PersonalSecurityContextType = PersonalSecurity & {
  switchToNewClient(input: NewClientInput): void;
  switchToExistingClient(clientId: string): void;
  removeClient(clientId: string): void;
};

export const PersonalSecurityContext =
  createContext<PersonalSecurityContextType | null>(null);
