import { ActivityIndicator, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Text } from '../text/Text';
import { SemanticColours } from '../../theme/SemanticColours';

export type HoldingScreenProps = Omit<ViewProps, 'children'> & {
  title?: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[100],
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    margin: 16,
    color: SemanticColours.secondary.foreground[100]
  },
  indicator: {
    color: SemanticColours.secondary.foreground[70]
  }
});

const HoldingScreen = ({
  style,
  title = 'Syncing',
  ...rest
}: HoldingScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Text.DocTitle style={styles.text}>{title}</Text.DocTitle>
      <ActivityIndicator color={styles.indicator.color} />
    </View>
  );
};

export default HoldingScreen;
