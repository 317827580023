import { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalProps } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import BasicCell from '../cells/BasicCell';
import ListItemSeparator from '../cells/ListItemSeparator';
import TickIcon from '../icons/TickIcon';
import ModalConfirmHeader from '../modal-confirm-header/ModalConfirmHeader';
import { SemanticColours } from '../../theme/SemanticColours';
import PlatformModal from '../platform-modal/PlatformModal';
import PlatformScrollView from '../platform-scroll-view/PlatformScrollView';

type MultiSelectProps = Readonly<{
  multiSelect: true;
  onAccept: (ids: string[]) => void;
  selectedItem?: string[];
}>;

type SingleSelectProps = Readonly<
  {
    multiSelect?: false;
    selectedItem?: string | null;
    immediate?: boolean;
  } & (
    | { noneOption: true; onAccept: (id: string | null) => void }
    | { noneOption?: false; onAccept: (id: string) => void }
  )
>;

const NONE_SELECTED_ID = '____NONE____';

export type SelectFromListModalProps<TItem> = Omit<
  ModalProps,
  'children' | 'onRequestClose'
> &
  Readonly<
    {
      extractItemDisabled?: (item: TItem) => boolean;
      extractItemId: (item: TItem) => string;
      extractItemTitle: (item: TItem) => string;
      items: readonly TItem[];
      loading?: boolean;
      onDismiss: () => void;
      title: string;
    } & (MultiSelectProps | SingleSelectProps)
  >;

const rStyles = DynamicStyleSheet.create({
  list: {
    backgroundColor: SemanticColours.primary.background[80],
    flex: 1
  }
});

function SelectFromListModal<TItem>(
  props: SelectFromListModalProps<TItem>
): JSX.Element {
  const {
    extractItemDisabled,
    extractItemId,
    extractItemTitle,
    items,
    loading,
    multiSelect,
    onDismiss,
    title,
    ...rest
  } = props;

  const noneOption = !multiSelect ? props.noneOption ?? false : false;
  const immediate = !multiSelect ? props.immediate ?? false : false;

  const styles = useDynamicStyleSheet(rStyles);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedIds(() => {
      if (props.multiSelect) {
        return props.selectedItem ?? [];
      }

      if (!props.selectedItem && noneOption) {
        return [NONE_SELECTED_ID];
      }

      return [props.selectedItem].compact();
    });
  }, [props.selectedItem]);

  const acceptEnabled = useMemo(() => {
    if (loading || immediate) {
      return false;
    }

    if (props.multiSelect) {
      return true;
    }

    return selectedIds.any();
  }, [loading, selectedIds, props.multiSelect, noneOption, immediate]);

  const onAccept = useCallback(() => {
    if (props.multiSelect) {
      props.onAccept(selectedIds);
    } else if (props.noneOption && selectedIds[0] === NONE_SELECTED_ID) {
      props.onAccept(null);
    } else {
      props.onAccept(selectedIds[0]);
    }
  }, [props.multiSelect, selectedIds, props.onAccept]);

  const rows = useMemo(() => {
    const mappedItems = props.items.map(i => ({
      id: extractItemId(i),
      title: extractItemTitle(i),
      disabled: extractItemDisabled?.(i) ?? false
    }));

    if (noneOption) {
      return [
        { id: NONE_SELECTED_ID, title: 'None', disabled: false },
        ...mappedItems
      ];
    }

    return mappedItems;
  }, [items, noneOption, extractItemId, extractItemTitle, extractItemDisabled]);

  const toggleSelection = useCallback(
    (id: string) => {
      setSelectedIds(ids => {
        if (props.multiSelect) {
          if (ids.includes(id)) {
            return ids.filter(s => s !== id);
          }

          return [...ids, id];
        }

        return [id];
      });
    },
    [props.multiSelect]
  );

  return (
    <PlatformModal {...rest} onRequestClose={onDismiss} hugsContent>
      <ModalConfirmHeader
        title={title}
        onCross={onDismiss}
        onTick={immediate ? undefined : onAccept}
        tickEnabled={acceptEnabled}
      />
      <FlatList
        renderScrollComponent={scrollProps => (
          <PlatformScrollView {...scrollProps} />
        )}
        style={styles.list}
        data={rows}
        keyExtractor={d => d.id}
        ItemSeparatorComponent={ListItemSeparator}
        renderItem={({ item }) => (
          <BasicCell
            disabled={item.disabled || loading}
            onPress={() => {
              toggleSelection(item.id);

              if (immediate && !props.multiSelect) {
                props.onAccept(item.id);
              }
            }}
            title={item.title}
            iconRight={selectedIds.includes(item.id) ? TickIcon : undefined}
          />
        )}
      />
    </PlatformModal>
  );
}

export default SelectFromListModal;
