import React from 'react';
import {
  SafeAreaView,
  SafeAreaViewProps
} from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { Text } from '../../../components/text/Text';

export type HeadingProps = Omit<SafeAreaViewProps, 'children'> & {
  label: string;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 4,
    paddingHorizontal: 16
  }
});

const Heading = React.memo<HeadingProps>(({ style, label, ...rest }) => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <SafeAreaView
      style={[styles.root, style]}
      edges={['left', 'right']}
      {...rest}
    >
      <Text.SectionTitle>{label}</Text.SectionTitle>
    </SafeAreaView>
  );
});

export default Heading;
