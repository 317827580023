import { Formik } from 'formik';
import { useCallback } from 'react';
import { ModalProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { KeyboardDismissView } from '../../lib/keyboard-dismiss-view';
import ModalConfirmHeader from '../modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../text-field';
import { Normaliser } from '../text-field/Normaliser';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import PlatformModal from '../platform-modal/PlatformModal';

export type EditValueModalProps = Omit<
  ModalProps,
  'children' | 'onRequestClose'
> & {
  disabled?: boolean;
  normaliser?: Normaliser | Normaliser[];
  onDismiss: () => void;
  onEdited: (newValue: string) => void;
  placeholder: string;
  title: string;
  validator: (newValue: string) => string | null;
  value: string | null | undefined;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  }
});

const EditValueModal = ({
  disabled,
  normaliser,
  onDismiss,
  onEdited,
  placeholder,
  title,
  validator,
  value,
  ...rest
}: EditValueModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const validate = useCallback(
    ({ value: newValue }: { value: string }) => {
      const result = validator(newValue);

      if (result !== null) {
        return { value: result };
      }

      return {};
    },
    [validator]
  );

  const onSubmit = useCallback(({ value: newValue }: { value: string }) => {
    onEdited(newValue);
  }, []);

  return (
    <PlatformModal {...rest} onRequestClose={onDismiss} hugsContent>
      <KeyboardDismissView style={styles.container}>
        <Formik
          initialValues={{ value: value ?? '' }}
          onSubmit={onSubmit}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                onCross={onDismiss}
                onTick={handleSubmit}
                title={title}
              />
              <TextField
                {...formProps}
                autoCapitalize="words"
                autoCorrect
                autoFocus
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                enablesReturnKeyAutomatically
                editable={!disabled}
                inputStyle={styles.field}
                name={'value'}
                normaliser={normaliser}
                onSubmitEditing={() => handleSubmit()}
                placeholder={placeholder}
                returnKeyType="done"
              />
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default EditValueModal;
