import React, { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import CardFooter, {
  CardFooterProps
} from '../../../components/card/CardFooter';
import { SummaryByStatusViewKey } from '../../../../../data-model/schema/databases/site-preparation-orders/design/summary-by-status/SummaryByStatusView';
import { PrepNavigatorParamList } from '../../../navigation/PrepNavigatorParamList';
import UndoStartButton from '../../../components/buttons/UndoStartButton';
import UndoFinishButton from '../../../components/buttons/UndoFinishButton';
import StartButton from '../../../components/buttons/StartButton';
import FinishButton from '../../../components/buttons/FinishButton';
import CollectButton from '../../../components/buttons/CollectButton';

export type BoundCardFooterProps = Omit<
  CardFooterProps,
  'leftButton' | 'rightButton'
> & {
  queryRow: {
    key: SummaryByStatusViewKey;
    documentID: string;
  };
};

const BoundCardFooter = React.memo<BoundCardFooterProps>(
  ({ queryRow, ...rest }) => {
    const navigation =
      useNavigation<StackNavigationProp<PrepNavigatorParamList>>();

    const onMorePress = useCallback(() => {
      navigation.setParams({ taskId: queryRow.documentID });
    }, [queryRow.documentID, navigation]);

    const LeftButton = useMemo(() => {
      switch (queryRow.key[1]) {
        case '03 In Progress':
          return UndoStartButton;
        case '04 Ready':
          return UndoFinishButton;
        default:
          return undefined;
      }
    }, [queryRow]);

    const RightButton = useMemo(() => {
      switch (queryRow.key[1]) {
        case '01 Pending':
        case '02 Released':
          return StartButton;
        case '03 In Progress':
          return FinishButton;
        case '04 Ready':
          return CollectButton;
        default:
          return undefined;
      }
    }, [queryRow]);

    return (
      <CardFooter
        {...rest}
        onMorePress={onMorePress}
        leftButton={
          LeftButton ? (
            <LeftButton preparationOrderId={queryRow.documentID} />
          ) : undefined
        }
        rightButton={
          RightButton ? (
            <RightButton preparationOrderId={queryRow.documentID} />
          ) : undefined
        }
      />
    );
  }
);

export default BoundCardFooter;
