import { ProductV1 } from './ProductV1';

export type DefaultImpliedVariant = {
  $schema: 'DefaultImpliedVariant';
  id: 'DEFAULT';
  name: string;
  prices: Record<string, string>;
  optionSets?: string[];
};

export const DEFAULT_UNNAMED_VARIANT_NAME = 'Default';

export type DefaultUnnamedVariant = {
  $schema: 'DefaultUnnamedVariant';
  id: 'DEFAULT';
  name: typeof DEFAULT_UNNAMED_VARIANT_NAME;
  prices: Record<string, string>;
  optionSets?: string[];
};

export type DeclaredVariant = {
  $schema: 'DeclaredVariant';
  id: string;
  name: string;
  compatProductId: string;
  prices: Record<string, string>;
  optionSets?: string[];
};

export type AnyVariant =
  | DefaultImpliedVariant
  | DefaultUnnamedVariant
  | DeclaredVariant;

export const isDeclaredVariant = (
  variant: AnyVariant
): variant is DeclaredVariant => {
  return variant.$schema === 'DeclaredVariant';
};

export type VariantArray = [
  DefaultImpliedVariant | DefaultUnnamedVariant,
  ...DeclaredVariant[]
];

export type ProductV2 = ProductV1 & {
  $schema: 'Product';
  $schemaVersion: '2';
  productName: string;
  variants: VariantArray;

  /**
   * @deprecated use productName instead
   */
  name: string;
};

export const isProductV2Compatible = (value: unknown): value is ProductV2 => {
  if (
    !(typeof value === 'object') ||
    !value ||
    !('$schema' in value) ||
    !('$schemaVersion' in value)
  ) {
    return false;
  }

  if (value.$schema !== 'Product') {
    return false;
  }

  return typeof value.$schemaVersion === 'string' && +value.$schemaVersion >= 2;
};
