import React, { useCallback, useEffect, useState } from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import AlertsButton, { AlertsButtonProps } from './AlertsButton';
import { usePreparationOrdersDb } from '../../../../../data-model/components/site-context/usePreparationOrdersDb';
import { useQueryStream } from '../../../../../data-model/hooks/useQueryStream';
import { PrepNavigatorParamList } from '../../../navigation/PrepNavigatorParamList';
import { useContextOrThrow } from '../../../../../lib/use-context-or-throw';
import { AlertSoundContext } from '../../../../../components/alert-sound-context/AlertSoundContext';
import { nameof } from '../../../../../lib/name-of';

export type AlertsProps = Omit<AlertsButtonProps, 'isOn' | 'onToggle'>;

const Alerts = React.memo<AlertsProps>(props => {
  const { play } = useContextOrThrow(
    AlertSoundContext,
    nameof({ AlertSoundContext })
  );

  const [enabled, setEnabled] = useState(false);

  const onToggle = useCallback(() => setEnabled(e => !e), []);

  useEffect(() => {
    if (enabled) play();
  }, [enabled, play]);

  const db = usePreparationOrdersDb();

  const route = useRoute<RouteProp<PrepNavigatorParamList>>();

  const { data } = useQueryStream(
    db.local.design.newReleasedOrders.views.main,
    {
      startKey: route.params?.prepZoneId,
      endKey: route.params?.prepZoneId
    }
  );

  const [cache, setCache] = useState<string[]>();

  useEffect(() => {
    setCache(undefined);
  }, [route]);

  useEffect(() => {
    if (!data) return;

    const nextIds = data.map(d => d.documentID);

    if (!cache) {
      setCache(nextIds);
      return;
    }

    const newIds = nextIds.except(cache);

    if (newIds.any() && enabled) {
      play();
    }

    setCache(nextIds);
  }, [data]);

  return <AlertsButton isOn={enabled} onToggle={onToggle} {...props} />;
});

export default Alerts;
