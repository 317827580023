import { TextProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import { Text } from '../text/Text';

const rStyles = DynamicStyleSheet.create({
  text: {
    color: SemanticColours.primary.error[100]
  }
});

const ErrorMessage = ({ style, ...rest }: TextProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Text.Emphasis testID="Error" style={[styles.text, style]} {...rest} />
  );
};

export default ErrorMessage;
