import { Platform } from 'react-native';
import PouchDB from '../../pouchdb';
import { RemoteDatabaseConfiguration } from './RemoteDatabaseConfiguration';
import { SchemaConstructor } from './SchemaConstructor';
import { Database } from './Database';
import { DatabaseChangeTracker } from './DatabaseChangeTracker';

export class LocalDatabase<TSchema> implements Database {
  static serverUrlAsPrefix(url: string): string | null {
    if (!url.trim()) {
      return null;
    }

    return new URL(url).hostname.split('.').join('-');
  }

  private static sharedConfig:
    | PouchDB.Configuration.DatabaseConfiguration
    | undefined =
    Platform.OS !== 'web'
      ? {
          adapter: 'react-native-sqlite'
        }
      : {
          adapter: 'idb'
        };

  constructor(
    public readonly name: string,
    private readonly SchemaClass: SchemaConstructor<TSchema>,
    private readonly config: RemoteDatabaseConfiguration
  ) {}

  public readonly pouch: PouchDB.Database = (() => {
    const local = new PouchDB(
      [
        'local',
        LocalDatabase.serverUrlAsPrefix(this.config.dbBaseUrl),
        this.name
      ]
        .compact()
        .join('-'),
      LocalDatabase.sharedConfig
    );

    LocalDatabase.applyFlipperInDev(local);

    return local;
  })();

  public readonly schema = new this.SchemaClass(this);

  public readonly changes = new DatabaseChangeTracker(this.pouch);

  private static applyFlipperInDev(database: PouchDB.Database): void {
    if (__DEV__ && Platform.OS !== 'web') {
      // Import connectDatabases function and required DBDrivers
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const {
        connectDatabases,
        PouchDB: PouchDBDriver
        // eslint-disable-next-line global-require, import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
      } = require('react-native-flipper-databases');

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      connectDatabases([
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        new PouchDBDriver([database]) // Pass in database definitions
      ]);
    }
  }
}
