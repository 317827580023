import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { SitesDatabaseSchema } from './SitesDatabaseSchema';

export class SitesDatabase extends DatabasePair<SitesDatabaseSchema> {
  constructor(
    clientId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(`sp-${clientId}-sites`, SitesDatabaseSchema, queryConfig, syncConfig);
  }
}
