import { DateTime } from 'luxon';
import { SiteTimeSettings } from '../types/SiteTimeSettings';
import { dateTimeToReportDate } from './dateTimeToReportDate';
import { CalendarDayPartition } from '../../../data-model/schema/databases/site-transactions-archive/design/Partitions';

export const dateTimeToPartition =
  (siteSettings: SiteTimeSettings) =>
  (input?: DateTime): CalendarDayPartition => {
    const { year, month, day } = dateTimeToReportDate(siteSettings)(input);

    return ['calendar', year, month, day];
  };
