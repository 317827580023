import {
  ActivityIndicator,
  ActivityIndicatorProps,
  Pressable
} from 'react-native';
import Reanimated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import { useCallback, useEffect } from 'react';
import { GlobalActivity } from './GlobalActivity';
import { useLogger } from '../logging/useLogger';
import { nameof } from '../name-of';
import useModalControl from '../use-modal/useModalControl';
import ActivitiesModal from './ActivitiesModal';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../dynamic-style-sheet';
import { Selectors } from '../dynamic-style-sheet/Selectors';

const rStyles = DynamicStyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8
  },
  activity: {}
}).override(Selectors.mediumOrLarge, {
  container: {
    paddingHorizontal: 16
  }
});

const AnimatedActivityIndicator =
  Reanimated.createAnimatedComponent(ActivityIndicator);

export const GlobalActivityIndicator = ({
  style,
  ...rest
}: ActivityIndicatorProps): JSX.Element => {
  const log = useLogger(nameof({ GlobalActivityIndicator }));

  const styles = useDynamicStyleSheet(rStyles);

  const opacity = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value
  }));

  const show = useCallback(() => {
    log.debug('Showing activity');
    opacity.value = withTiming(1, { duration: 750 });
  }, [opacity]);

  const hide = useCallback(() => {
    log.debug('Hiding activity');
    opacity.value = withTiming(0, { duration: 50 });
  }, [opacity]);

  useEffect(() => {
    const activitySubscription = GlobalActivity.hasActivity.subscribe(
      {
        next: v => {
          if (v) {
            show();
          } else {
            hide();
          }
        }
      },
      { sendCurrentValue: true }
    );

    return () => {
      activitySubscription.dispose();
    };
  }, [show, hide]);

  const { present, ...modal } = useModalControl();

  return (
    <>
      <Pressable onLongPress={present} style={styles.container}>
        <AnimatedActivityIndicator
          style={[styles.activity, animatedStyle, style]}
          {...rest}
        />
      </Pressable>

      <ActivitiesModal {...modal} />
    </>
  );
};
