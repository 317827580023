import { ComponentType } from 'react';
import { ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../theme/SemanticColours';
import { Text } from '../text/Text';

export type CellGroupFooterProps = ViewProps & {
  iconComponent?: ComponentType<SvgProps>;
  text: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 8,
    paddingHorizontal: 16,
    backgroundColor: SemanticColours.primary.background[90]
  },
  icon: {
    marginRight: 10,
    color: SemanticColours.primary.foreground[50]
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    paddingTop: 10
  }
});

const CellGroupFooter = ({
  style,
  text,
  iconComponent: Icon,
  ...rest
}: CellGroupFooterProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <SafeAreaView
      style={[styles.container, style]}
      edges={['left', 'right']}
      {...rest}
    >
      {Icon && (
        <Icon
          height={16}
          width={16}
          style={styles.icon}
          fill={styles.icon.color}
        />
      )}
      <Text.SectionFooter>{text}</Text.SectionFooter>
    </SafeAreaView>
  );
};

export default CellGroupFooter;
