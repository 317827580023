import { useMemo } from 'react';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { Partition } from '../../../../types/Partition';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useValueFromPartitionNavigationCache } from '../../../../components/partition-navigation-context/useValueFromPartitionNavigationCache';

const DEFAULT_VALUE = {
  value: null as number | null,
  units: null as number | null
};

export const useData = (partition: Partition, productId: string) => {
  const intervalDate = useMemo(
    () => partitionToIntervalDate(partition),
    [partition]
  );

  const db = useTransactionsDb();

  const getValue = useValueFromPartitionNavigationCache();

  return useTransform(
    useQueryStream(db.remote.design.dashboardProductsForDate.view, {
      startKey: [...intervalDate, productId],
      endKey: [...intervalDate, productId, []],
      groupLevel: 3,
      reduce: true
    }),

    rows => rows.map(r => r.value).first() ?? DEFAULT_VALUE,

    () => ({
      ...DEFAULT_VALUE,
      value: getValue(partition)
    })
  );
};
