import { createStackNavigator } from '@react-navigation/stack';
import { OnboardingNavigator } from './features/onboarding/navigation/OnboardingNavigator';
import { nameof } from './lib/name-of';
import { useContextOrThrow } from './lib/use-context-or-throw';
import { SecuritySchemeContext } from './security/components/scheme/SecuritySchemeContext';
import { AppNavigatorParamList } from './AppNavigatorParamList';
import NavNavigator from './features/navigation/NavNavigator';

const Stack = createStackNavigator<AppNavigatorParamList>();

const AppNavigator = (): JSX.Element => {
  const { currentScheme: securityKind } = useContextOrThrow(
    SecuritySchemeContext,
    nameof({ SecurityKindContext: SecuritySchemeContext })
  );

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, cardStyle: { flex: 1 } }}
    >
      {securityKind === 'NONE' ? (
        <>
          <Stack.Screen name="onboarding" component={OnboardingNavigator} />
        </>
      ) : (
        <>
          <Stack.Screen name="nav" component={NavNavigator} />
        </>
      )}
    </Stack.Navigator>
  );
};

export default AppNavigator;
