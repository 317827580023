import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import { ServiceTransaction } from '../../documents/ServiceTransaction';
import { CalendarDayPartition, IsoDayPartition } from '../Partitions';

export type CalendarViewKey = CalendarDayPartition | IsoDayPartition;

export type CalendarViewValue = { readonly value: number };

export class CalendarView extends DatabaseView<
  CalendarViewKey,
  CalendarViewValue,
  ServiceTransaction
> {
  static readonly viewName = 'calendar';

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, CalendarView.viewName);
  }
}
