import { Repository } from '../../../support/Repository';
import { newRandomUuid } from '../../../../../lib/uuid';
import { Site } from '../documents/Site';
import { Database } from '../../../support/Database';

export class SitesRepository extends Repository<Site> {
  constructor(database: Database) {
    super(database, () => `site-${newRandomUuid()}`);
  }
}
