import { createStackNavigator } from '@react-navigation/stack';
import { DashboardNavigatorParamList } from './DashboardNavigatorParamList';
import { SemanticColours } from '../../../theme/SemanticColours';
import ShowScreen from '../screens/show/ShowScreen';
import UsersScreen from '../screens/users/UsersScreen';
import UserScreen from '../screens/user/UserScreen';
import { GlobalPartitionNavigationProvider } from '../components/partition-navigation-context/GlobalPartitionNavigationProvider';
import TransactionScreen from '../screens/transaction/TransactionScreen';
import ProductsScreen from '../screens/products/ProductsScreen';
import ProductScreen from '../screens/product/ProductScreen';
import PaymentTypesScreen from '../screens/payment-types/PaymentTypesScreen';
import { PaymentTypeScreen } from '../screens/payment-type/PaymentTypeScreen';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { HeaderMenuContextProvider } from '../../../components/header-menu/HeaderMenuContext';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import DrawerAuthorisationBoundary from '../../navigation/components/drawer-authorisation-boundary/DrawerAuthorisationBoundary';

const Stack = createStackNavigator<DashboardNavigatorParamList>();

const rStyles = DynamicStyleSheet.create({
  content: {
    backgroundColor: SemanticColours.primary.background[100],
    flex: 1
  },
  header: {
    backgroundColor: SemanticColours.secondary.background[100]
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const DashboardNavigator = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <DrawerAuthorisationBoundary minRole={RoleId.superUser}>
      <HeaderMenuContextProvider>
        <GlobalPartitionNavigationProvider>
          <Stack.Navigator
            screenOptions={{
              headerTintColor: styles.headerTint.color,
              headerStyle: styles.header,
              cardStyle: styles.content
            }}
          >
            <Stack.Screen
              name="show"
              options={{ title: 'Sales Dashboard' }}
              component={ShowScreen}
            />

            <Stack.Screen
              name="users"
              options={{ title: 'Team' }}
              component={UsersScreen}
            />

            <Stack.Screen name="user" component={UserScreen} />

            <Stack.Screen name="transaction" component={TransactionScreen} />

            <Stack.Screen
              name="products"
              options={{ title: 'Products' }}
              component={ProductsScreen}
            />

            <Stack.Screen name="product" component={ProductScreen} />

            <Stack.Screen
              name="payment-types"
              options={{ title: 'Payments' }}
              component={PaymentTypesScreen}
            />

            <Stack.Screen name="payment-type" component={PaymentTypeScreen} />
          </Stack.Navigator>
        </GlobalPartitionNavigationProvider>
      </HeaderMenuContextProvider>
    </DrawerAuthorisationBoundary>
  );
};

export default DashboardNavigator;
