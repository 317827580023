import { DependencyList, useEffect, useState } from 'react';

type Result<T> = { readonly loading: boolean; readonly data: T };
type DataType<T> = T extends readonly unknown[]
  ? {
      [P in keyof T]: T[P] extends Result<infer D> ? NonNullable<D> : never;
    }
  : T extends Result<infer D>
  ? NonNullable<D>
  : never;

export const useTransform = <
  const T extends Result<unknown> | readonly Result<unknown>[],
  U
>(
  input: T,
  transformer: (data: DataType<T>) => U,
  skeleton: () => U,
  deps?: DependencyList
): { loading: boolean; data: U } => {
  const [result, setResult] = useState<{ loading: boolean; data: U }>({
    loading: true,
    data: skeleton()
  });

  useEffect(() => {
    setResult(() => {
      const isLoading =
        'loading' in input ? input.loading : input.some(i => i.loading);

      if (isLoading) {
        return { loading: true, data: skeleton() };
      }

      const inputData = (
        'data' in input ? input.data : input.map(i => i.data)
      ) as DataType<T>;

      return {
        loading: false,
        data: transformer(inputData)
      };
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  }, [...(Array.isArray(input) ? input : [input]), ...(deps ?? [])]);

  return result;
};
