import CardLines, {
  CardLinesProps
} from './CardLines';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { nameof } from '../../../../lib/name-of';
import { usePreparationOrdersDb } from '../../../../data-model/components/site-context/usePreparationOrdersDb';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { ProductLookupCache } from '../ProductLookupCache';
import PreparationOrderLine from '../PreparationOrderLine';
import Note from '../Note';

export type BoundRemoteCardLinesProps = Omit<
  CardLinesProps,
  'children'
> & {
  documentId: string;
};

const BoundRemoteCardLines = ({
  documentId,
  ...rest
}: BoundRemoteCardLinesProps): JSX.Element => {
  const products = useContextOrThrow(
    ProductLookupCache,
    nameof({ ProductLookupCache })
  );

  const db = usePreparationOrdersDb();

  const { loading, document } = useDocument(db.remote.repository, documentId);

  return (
    <CardLines {...rest} loading={loading}>
      {document?.lines.map(line => (
        <PreparationOrderLine
          key={line.ID}
          text={products.data[line.productID]}
          note={line.notes ? <Note text={line.notes} /> : undefined}
        />
      ))}
    </CardLines>
  );
};

export default BoundRemoteCardLines;
