import { Database } from '../../../support/Database';
import { RunningBalanceView } from './views/RunningBalanceView';

export class RunningBalanceDesignDoc {
  private static documentId = '_design/running-balance';

  static views = {
    [RunningBalanceView.viewName]: RunningBalanceView
  } as const;

  constructor(private readonly database: Database) {}

  public readonly views = Object.fromEntries(
    Object.entries(RunningBalanceDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, RunningBalanceDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof RunningBalanceDesignDoc.views]: InstanceType<
      (typeof RunningBalanceDesignDoc)['views'][P]
    >;
  };
}
