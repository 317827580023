import { PropsWithChildren, useMemo } from 'react';
import { useTransactionsDb } from '../../../../data-model/components/site-context/useTransactionsDb';
import { usePaginatedQuery } from '../../../../data-model/hooks/usePaginatedQuery';
import {
  PartitionNavigationContext,
  PartitionNavigationContextValue
} from './PartitionNavigationContext';
import { QueryRow } from '../../../../data-model/schema/support/DatabaseView';
import {
  CalendarViewKey,
  CalendarViewValue
} from '../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-dates/CalendarView';
import { ServiceTransaction } from '../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import { CalendarDayPartition } from '../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import {
  CalendarMonthPartition,
  CalendarYearPartition,
  IsoWeekPartition
} from '../../types/Partition';

type CommonQueryOptions = {
  readonly descending: true;
  readonly limit: 30;
  readonly reduce: true;
};

function mapper<TPartition>(
  rows: readonly QueryRow<
    CalendarViewKey,
    CalendarViewValue,
    unknown,
    ServiceTransaction
  >[]
): Readonly<{ partition: TPartition; value: number }>[] {
  return rows.map(r => ({
    partition: r.key as TPartition,
    value: r.value.value
  }));
}

export const GlobalPartitionNavigationProvider = ({
  children
}: // eslint-disable-next-line @typescript-eslint/ban-types
PropsWithChildren<{}>): JSX.Element => {
  const db = useTransactionsDb();

  const view = db.remote.design.dashboardDates.views.calendar;

  const commonQueryOptions: CommonQueryOptions = {
    descending: true,
    limit: 30,
    reduce: true
  };

  const calendarQueryOptions = {
    startKey: ['calendar', []],
    endKey: ['calendar']
  } as const;

  const day = usePaginatedQuery(
    view,
    {
      groupLevel: 4,
      ...calendarQueryOptions,
      ...commonQueryOptions
    },
    mapper<CalendarDayPartition>
  );

  const month = usePaginatedQuery(
    view,
    {
      groupLevel: 3,
      ...calendarQueryOptions,
      ...commonQueryOptions
    },
    mapper<CalendarMonthPartition>
  );

  const year = usePaginatedQuery(
    view,
    {
      groupLevel: 2,
      ...calendarQueryOptions,
      ...commonQueryOptions
    },
    mapper<CalendarYearPartition>
  );

  const week = usePaginatedQuery(
    view,
    {
      startKey: ['iso', []],
      endKey: ['iso'],
      groupLevel: 3,
      ...commonQueryOptions
    },
    mapper<IsoWeekPartition>
  );

  const value: PartitionNavigationContextValue = useMemo(
    () => ({
      day,
      month,
      year,
      week
    }),
    [day, month, year, week]
  );

  return (
    <PartitionNavigationContext.Provider value={value}>
      {children}
    </PartitionNavigationContext.Provider>
  );
};
