import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import { PreparationOrder } from '../../documents/PreparationOrder';

// eslint-disable-next-line func-names
const map = function (doc: Document<PreparationOrder>) {
  'public source';

  if (
    doc._id.indexOf('preparationorder-') !== 0 ||
    doc.deleted ||
    doc.status !== '02 Released' ||
    !doc.statusHistory ||
    doc.statusHistory.filter(s => s.status === '02 Released').length > 1
  ) {
    return;
  }

  emit(doc.preparationZone, null);
};

export class NewReleasedOrdersView extends DatabaseView<
  string,
  null,
  PreparationOrder
> {
  static readonly viewName = 'main';

  static definition = {
    map: map.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, NewReleasedOrdersView.viewName);
  }
}
