import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import { ServiceTransaction } from '../../documents/ServiceTransaction';
import { Date, Timestamp } from '../../documents/SharedTypes';

export class ByTenMinuteView extends DatabaseView<
  readonly [Date, Timestamp],
  { readonly value: number },
  ServiceTransaction
> {
  static readonly viewName = 'by-10-min';

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, ByTenMinuteView.viewName);
  }
}
