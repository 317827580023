import NetInfo, { NetInfoSubscription } from '@react-native-community/netinfo';
import { Platform } from 'react-native';
import { createLogger } from '../../lib/logging';
import { nameof } from '../../lib/name-of';
import { Subject } from '../../lib/pub-sub/Subject';
import { Subscribable } from '../../lib/pub-sub/Subscribable';
import { RemoteDatabaseConfiguration } from '../schema/support/RemoteDatabaseConfiguration';
import PouchDB from '../pouchdb';
import { timeout } from '../../lib/timeout-promise';
import { AsyncDisposeBag } from '../../lib/disposable/AsyncDisposeBag';
import { AsyncDisposable } from '../../lib/disposable/AsyncDisposable';
import { Readable } from '../../lib/pub-sub/Readable';

export type SyncStatus = {
  state:
    | 'STARTING'
    | 'ACTIVE'
    | 'PAUSED'
    | 'DENIED'
    | 'ERROR'
    | 'SEND'
    | 'RECEIVE'
    | 'STOPPING'
    | 'STOPPED';
  error?: Error;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type SyncContentType = {};

export class DatabaseSync implements AsyncDisposable {
  private static readonly replicationOptions = {
    live: true,
    retry: true,
    selector: {
      _id: { $regex: '^(?!_design).*$' }
    },

    // webpack dev server is http 1.1, and chrome will only allow six
    // concurrent connections over this protocol. This means that changes
    // feeds can inadvertently block sync requests.
    //
    // this dirty hack kills change feeds every 5sec so sync requests
    // only have to wait that long (at maximum)
    ...(__DEV__ && Platform.OS === 'web'
      ? {
          heartbeat: false,
          timeout: 5000
        }
      : {})
  };

  constructor(
    public readonly database: {
      pouches: {
        local: PouchDB.Database;
      };
      name: string;
    },
    public readonly direction: 'pull' | 'push',
    private readonly config: RemoteDatabaseConfiguration,
    private readonly options?: PouchDB.Replication.ReplicateOptions
  ) {
    this.startOnceConnected();
  }

  private readonly remote = new PouchDB(
    `${this.config.dbBaseUrl}/${this.database.name}`,
    {
      fetch: this.config.authoriser.authorisedFetch,
      skip_setup: true
    }
  );

  private readonly log = createLogger(nameof({ DatabaseSync }), {
    destination: this.database.name,
    direction: this.direction
  });

  private readonly disposeBag = new AsyncDisposeBag();

  private startOnceConnected() {
    this.log.debug('Starting once connected');

    let unsubscribe: NetInfoSubscription | undefined;

    unsubscribe = NetInfo.addEventListener(state => {
      if (state.isInternetReachable === true) {
        this.log.info('Connected. Starting');
        this.start();
        unsubscribe?.();
        unsubscribe = undefined;
      }
    });

    this.disposeBag.add({
      dispose: () => {
        if (unsubscribe) {
          this.log.info('Cancelled waiting for connection');
          unsubscribe();
          unsubscribe = undefined;
        }
      }
    });
  }

  private activeSync: PouchDB.Replication.Replication<SyncContentType> | null =
    null;

  private readonly _status = new Subject<SyncStatus>();

  public get status(): Subscribable<SyncStatus> & Readable<SyncStatus> {
    return this._status;
  }

  private start() {
    if (this.activeSync) {
      return;
    }

    const { local } = this.database.pouches;

    this.log.debug('Starting');

    this._status.next({ state: 'STARTING' });

    const combinedOptions = {
      ...DatabaseSync.replicationOptions,
      ...this.options
    };

    this.activeSync =
      this.direction === 'pull'
        ? local.replicate.from(this.remote, combinedOptions)
        : local.replicate.to(this.remote, combinedOptions);

    this.activeSync
      .on('change', this.onChangeHandler.bind(this))
      .on('paused', this.onPausedHandler.bind(this))
      .on('active', this.onActiveHandler.bind(this))
      .on('denied', this.onDeniedHandler.bind(this))
      .on('error', this.onErrorHandler.bind(this))
      .catch(this.onErrorHandler.bind(this));

    let unsubscribe: NetInfoSubscription | undefined;

    unsubscribe = NetInfo.addEventListener(state => {
      if (state.isInternetReachable === false) {
        this.log.info('Connection lost. Stopping');
        unsubscribe?.();
        unsubscribe = undefined;

        this.stop()
          .then(() => this.startOnceConnected())
          .catch(this.onErrorHandler.bind(this));
      }
    });

    this.disposeBag.add({
      dispose: () => {
        if (unsubscribe) {
          unsubscribe();
          unsubscribe = undefined;
        }
      }
    });
  }

  private async stop() {
    if (this.activeSync) {
      this._status.next({ ...this._status.current, state: 'STOPPING' });

      this.log.debug('Stopping');

      await timeout(
        5000,
        new Promise<void>(resolve => {
          // complete is valid
          // see https://pouchdb.com/guides/replication.html#canceling—replication
          void this.activeSync?.on('complete', () => {
            this.log.debug('Stopped');
            resolve();
          });

          this.activeSync?.cancel();
        })
      );

      await this.activeSync?.removeAllListeners();
      this.activeSync = null;
    }

    this._status.next({ ...this._status.current, state: 'STOPPED' });
  }

  private onActiveHandler() {
    // replicate resumed (e.g. new changes replicating, user went back online)
    this.log.debug('Sync status active');

    this._status.next({ state: 'ACTIVE' });
  }

  private onChangeHandler(
    info:
      | PouchDB.Replication.SyncResult<SyncContentType>
      | PouchDB.Replication.ReplicationResult<SyncContentType>
  ) {
    const state = (() => {
      if ('direction' in info) {
        return info.direction === 'pull' ? 'RECEIVE' : 'SEND';
      }

      return this.direction === 'pull' ? 'RECEIVE' : 'SEND';
    })();

    if (this._status.current?.state !== state) {
      this.log.debug(`Sync status ${state.toLowerCase()}`);

      this._status.next({ state });
    }
  }

  private onPausedHandler(error: unknown) {
    // replication paused (e.g. replication up to date, user went offline)

    this.log.debug('Sync status paused', { error });

    this._status.next({
      state: 'PAUSED',
      error: error && error instanceof Error ? error : undefined
    });
  }

  private onDeniedHandler(error: unknown) {
    // a document failed to replicate (e.g. due to permissions)
    this.log.debug('Sync status denied', { error });

    this._status.next({
      state: 'DENIED',
      error: error && error instanceof Error ? error : undefined
    });

    void this.stop();
  }

  private onErrorHandler(error: unknown) {
    // handle error
    this.log.warn('Sync status error', { error });

    this._status.next({
      state: 'ERROR',
      error: error && error instanceof Error ? error : undefined
    });

    void this.stop();
  }

  async dispose(): Promise<void> {
    await this.disposeBag.dispose();
    await this.stop();
  }
}
