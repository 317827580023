import { ChartDataPoint, XYValue } from 'react-native-responsive-linechart';
import { Text, Rect } from 'react-native-svg';
import { useMemo } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { formatMoney } from '../../../../../../humanisers/formatMoney';

type Props = {
  value?: ChartDataPoint;
  position?: XYValue;
};

const rStyles = DynamicStyleSheet.create({
  rect: {
    backgroundColor: SemanticColours.secondary.background[100],
    opacity: 1
  },
  label: {
    fontSize: 12,
    fontWeight: '700',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    color: SemanticColours.secondary.foreground[100],
    opacity: 1
  }
});

const Tooltip = ({ value, position }: Props): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const text = useMemo(() => (value ? formatMoney(value.y) : ''), [value?.y]);

  if (!value || !position) {
    return <></>;
  }

  return (
    <>
      <Rect
        x={position.x - (text.length * 10) / 2 + 0}
        y={position.y - 30 / 2 - 20}
        rx={4}
        fill={styles.rect.backgroundColor}
        opacity={styles.rect.opacity}
        height={30}
        width={text.length * 10}
      />
      <Text
        x={position.x + 0}
        y={position.y - 16}
        fontSize={styles.label.fontSize}
        fontWeight={styles.label.fontWeight}
        fontFamily={styles.label.fontFamily}
        fill={styles.label.color}
        opacity={styles.label.opacity}
        textAnchor="middle"
      >
        {text}
      </Text>
    </>
  );
};

export { Tooltip };
