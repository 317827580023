import { Database } from '../../../../support/Database';
import { DesignDocumentDefinition } from '../../../../support/DesignDocument';
import { NewReleasedOrdersView } from './NewReleasedOrdersView';

export class NewReleasedOrdersViewDesignDoc {
  private static documentId = '_design/new-released-orders';

  static views = {
    [NewReleasedOrdersView.viewName]: NewReleasedOrdersView
  } as const;

  static definition = new DesignDocumentDefinition(
    NewReleasedOrdersViewDesignDoc.documentId,
    {
      views: Object.fromEntries(
        Object.entries(NewReleasedOrdersViewDesignDoc.views).map(
          ([name, View]) => [name, View.definition]
        )
      )
    }
  );

  constructor(private readonly database: Database) {}

  public async ensureDefined(): Promise<void> {
    await NewReleasedOrdersViewDesignDoc.definition.ensureDefined(
      this.database.pouch
    );
  }

  public readonly views = Object.fromEntries(
    Object.entries(NewReleasedOrdersViewDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, NewReleasedOrdersViewDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof NewReleasedOrdersViewDesignDoc.views]: InstanceType<
      (typeof NewReleasedOrdersViewDesignDoc)['views'][P]
    >;
  };
}
