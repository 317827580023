import { CalendarDayPartition } from '../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import {
  CalendarMonthPartition,
  CalendarYearPartition,
  IsoWeekPartition
} from '../types/Partition';
import { isCalendarDayPartition } from './isCalendarDayPartition';
import { isCalendarMonthPartition } from './isCalendarMonthPartition';
import { partitionToDate } from './partitionToDate';

function partitionToParent(
  partition: CalendarDayPartition,
  target: 'month'
): CalendarMonthPartition;
function partitionToParent(
  partition: CalendarDayPartition,
  target: 'week'
): IsoWeekPartition;
function partitionToParent(
  partition: CalendarMonthPartition
): CalendarYearPartition;
function partitionToParent(
  partition: CalendarDayPartition | CalendarMonthPartition,
  target?: 'month' | 'week'
): CalendarMonthPartition | IsoWeekPartition | CalendarYearPartition {
  if (isCalendarMonthPartition(partition)) {
    return [partition[0], partition[1]];
  }

  if (isCalendarDayPartition(partition)) {
    if (target === 'month') {
      return [partition[0], partition[1], partition[2]];
    }

    if (target === 'week') {
      const date = partitionToDate(partition);
      return ['iso', date.year, date.weekNumber];
    }

    throw new Error(`Unexpected target value ${String(target)}`);
  }

  throw new Error(`Unexpected partition value ${JSON.stringify(partition)}`);
}

export { partitionToParent };
