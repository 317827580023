import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useEffect } from 'react';
import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../navigation/DashboardNavigatorParamList';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { GlobalActivityIndicator } from '../../../../lib/global-activity/GlobalActivityIndicator';
import { HeaderMenuButton } from '../../../../components/header-menu/HeaderMenuButton';
import { HeaderNavigation } from '../../../../components/header-navigation/HeaderNavigation';

export type HeaderProps = {
  title?: string;
};

const rStyles = DynamicStyleSheet.create({
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  },
  rightButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12
  }
});

const Header = ({ title }: HeaderProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<
      StackNavigationProp<
        DashboardNavigatorParamList,
        keyof DashboardNavigatorParamList
      >
    >();

  useEffect(() => {
    navigation.setOptions({
      headerStyle: styles.header,

      headerRight: headerProps => (
        <View style={styles.rightButtons}>
          <GlobalActivityIndicator color={styles.headerTint.color} />
          <HeaderMenuButton {...headerProps} />
        </View>
      )
    });
  }, [navigation, styles]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: headerProps => <HeaderNavigation {...headerProps} />
    });
  }, [navigation]);

  useEffect(() => {
    if (title) {
      navigation.setOptions({
        title
      });
    }
  }, [navigation, title]);

  return <></>;
};

export default Header;
