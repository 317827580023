import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { Pressable, View } from 'react-native';
import { useLinkProps } from '@react-navigation/native';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import ServeplusLogoIcon from '../../../../components/serveplus-logo-icon/ServeplusLogoIcon';
import { CloseIcon } from '../../../../components/icons/CloseIcon';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformPressable from '../../../../components/platform-pressable/PlatformPressable';

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[100]
  },
  large: {
    paddingTop: 32,
    paddingBottom: 12,
    alignItems: 'center',
    justifyContent: 'center'
  },
  small: {
    padding: 12,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  button: {
    padding: 8
  },
  icon: {
    color: SemanticColours.primary.foreground[95]
  }
});

const Large = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const props = useLinkProps({
    to: { screen: 'nav', params: { screen: 'home' } }
  });

  return (
    <Pressable style={[styles.container, styles.large]} {...props}>
      <ServeplusLogoIcon />
    </Pressable>
  );
};

const Small = (props: DrawerContentComponentProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, styles.small]}>
      <PlatformPressable
        onPress={() => {
          props.navigation.closeDrawer();
        }}
        style={styles.button}
      >
        <CloseIcon fill={styles.icon.color} />
      </PlatformPressable>
    </View>
  );
};

const Header = (props: DrawerContentComponentProps): JSX.Element => {
  const size = useSizeClass();

  return size === 'large' ? <Large /> : <Small {...props} />;
};

export default Header;
