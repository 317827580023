import { useEffect } from 'react';
import { Pressable, ViewProps, ViewStyle } from 'react-native';
import Reanimated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming
} from 'react-native-reanimated';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type CellProps = ViewProps & {
  highlighted?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[100],
    flexDirection: 'row',
    alignItems: 'center'
  },
  highlighted: {
    backgroundColor: SemanticColours.primary.background['70']
  }
});

const ReanimatedPressable = Reanimated.createAnimatedComponent(Pressable);

const Cell = ({ style, highlighted, ...rest }: CellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const backgroundColour = useSharedValue(styles.container.backgroundColor);
  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: backgroundColour.value
  }));

  useEffect(() => {
    backgroundColour.value = styles.container.backgroundColor;
  }, [styles]);

  useEffect(() => {
    if (highlighted) {
      backgroundColour.value = withTiming(styles.highlighted.backgroundColor, {
        duration: 50
      });
    } else {
      backgroundColour.value = withTiming(styles.container.backgroundColor, {
        duration: 50
      });
    }
  }, [highlighted]);

  return (
    <ReanimatedPressable
      style={[styles.container, animatedStyle, style as ViewStyle]}
      {...rest}
    />
  );
};

export default Cell;
