import { createContext, ReactNode, useContext, useMemo } from 'react';
import { nameof } from '../name-of';
import { useLogger } from '../logging/useLogger';
import { getArguments } from './getArguments';

export const LaunchArgumentsContext = createContext<unknown>(null);

export function LaunchArgumentsContextProvider<T extends object>({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const logger = useLogger(nameof({ LaunchArgumentsContextProvider }));

  const value = useMemo(() => {
    const args = getArguments<T>();
    logger.debug('Launched with arguments', { args });
    return args;
  }, []);

  return (
    <LaunchArgumentsContext.Provider value={value}>
      {children}
    </LaunchArgumentsContext.Provider>
  );
}

export function useLaunchArguments<T>() {
  const hookValue = useContext(LaunchArgumentsContext);
  if (!hookValue) {
    throw new Error(
      `${nameof({ useLaunchArguments })}() hook requires a ${nameof({
        LaunchArgumentsContext
      })} within the component hierarchy.`
    );
  }
  return hookValue as T;
}
