import { DatabaseView, QueryRow } from '../../../../support/DatabaseView';
import { ServiceTransaction } from '../../documents/ServiceTransaction';
import { TimeInterval } from '../../../../../../features/dashboard/types/TimeInterval';
import { AnyPaymentId } from '../PaymentIds';
import { DashboardSummarisedData } from '../DashboardSummarisedData';
import { Database } from '../../../../support/Database';

export type DashboardPaymentsForDateKey<
  TPaymentId extends AnyPaymentId = AnyPaymentId
> =
  | [interval: TimeInterval, date: string, paymentId: TPaymentId]
  | [
      interval: 'day',
      date: string,
      paymentId: TPaymentId,
      time: string,
      lineId: string,
      userId: string
    ];

export type DashboardPaymentsForDateValue = Readonly<{
  received?: DashboardSummarisedData;
  refund?: DashboardSummarisedData;
}>;

export class DashboardPaymentsForDateDesignDoc {
  constructor(private readonly database: Database) {}

  public readonly view = new DatabaseView<
    DashboardPaymentsForDateKey,
    DashboardPaymentsForDateValue,
    ServiceTransaction
  >(this.database, '_design/dashboard-payments-for-date', 'main');
}

export type DashboardPaymentsForDateRow<
  TOptions = object,
  TPaymentId extends AnyPaymentId = AnyPaymentId
> = QueryRow<
  DashboardPaymentsForDateKey<TPaymentId>,
  DashboardPaymentsForDateValue,
  TOptions,
  ServiceTransaction
>;
