import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { ServingZone } from '../../documents/ServingZone';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('servingzone-') === 0) {
    emit([!!doc.deleted ?? false, String(doc.name)], null);
  }
};

export class AllServingZonesView extends DatabaseView<
  readonly [deleted: boolean, name: string],
  null,
  ServingZone
> {
  static readonly viewName = 'allServingZones';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllServingZonesView.viewName);
  }
}
