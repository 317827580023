import { useCallback } from 'react';
import { View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import LoginForm from '../../../../security/components/login-form/LoginForm';
import { SessionTokenResponsePayload } from '../../../../api/generated/UsopApiClient.generated';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { ReLoginNavigatorParamList } from '../../navigation/ReLoginNavigatorParamList';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { PersonalSecurityContext } from '../../../../security/personal/PersonalSecurityContext';
import { nameof } from '../../../../lib/name-of';
import { useUsopApiClient } from '../../../../api/useUsopApiClient';
import { usePromiseFactory } from '../../../../lib/use-promise/usePromiseFactory';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type ReLoginScreenProps = StackScreenProps<
  ReLoginNavigatorParamList,
  're-login'
>;

const rStyles = DynamicStyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[100]
  },
  container: {
    flex: 1,
    marginVertical: 70
  },
  form: {
    alignItems: 'stretch',
    paddingHorizontal: 24
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    alignSelf: 'center',
    width: 445
  }
});

const ReLoginScreen = ({
  navigation,
  route
}: ReLoginScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { clients, switchToNewClient } = useContextOrThrow(
    PersonalSecurityContext,
    nameof({ PersonalSecurityContext })
  );

  const apiClient = useUsopApiClient();

  const onSuccess = usePromiseFactory(
    useCallback(
      async (payload: SessionTokenResponsePayload) => {
        if (!route.params) return;

        const { clientId, clientName } = route.params;

        const authorisedClientResponse = await apiClient.getClientById({
          clientId: route.params.clientId,
          authorization: `Bearer ${payload.token}`
        });

        if (
          authorisedClientResponse.status !== 200 ||
          !('defaultSiteId' in authorisedClientResponse.body)
        ) {
          return;
        }

        const {
          userId,
          username,
          fullName,
          role,
          id: userSessionId,
          token,
          device: { id: deviceId }
        } = payload;

        switchToNewClient({
          id: clientId,
          name: clientName,
          defaultSiteId: authorisedClientResponse.body.defaultSiteId,
          user: {
            userId,
            username,
            fullName,
            role: role as RoleId,
            userSessionId,
            token,
            deviceId
          }
        });
      },
      [route, apiClient, switchToNewClient]
    )
  );

  const onRequestClientChange = useCallback(() => {
    navigation.push(
      'client-list',
      route.params ? { activeClientId: route.params.clientId } : undefined
    );
  }, [navigation, route]);

  if (!route.params) {
    return <></>;
  }

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <LoginForm
          style={styles.form}
          clientId={route.params.clientId}
          clientName={route.params.clientName}
          defaultUsername={
            clients.find(c => c.id === route.params?.clientId)?.user.username
          }
          onSuccess={onSuccess.invoke}
          disabled={onSuccess.inProgress}
          onRequestClientChange={onRequestClientChange}
        />
      </View>
    </View>
  );
};

export default ReLoginScreen;
