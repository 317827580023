import { useContext, useEffect, useState } from 'react';
import { DocumentCacheContext } from '../components/document-cache/DocumentCache';
import { nameof } from '../../lib/name-of';
import { Repository } from '../schema/support/Repository';
import { Document } from '../types/Document';

type DocumentType<TRepo> = TRepo extends Repository<infer TProperties>
  ? Document<TProperties>
  : never;

type DocumentHookValue<TRepo extends Repository> =
  | {
      loading: true;
      document: undefined;
    }
  | {
      loading: false;
      document: DocumentType<TRepo> | null;
    };

export const useDocument = <TRepo extends Repository>(
  repo: TRepo,
  documentId: string
): DocumentHookValue<TRepo> => {
  const ctx = useContext(DocumentCacheContext);

  if (!ctx) {
    throw new Error(`Requires ${nameof({ DocumentCacheContext })}`);
  }

  const { subscribe, getCurrentValue } = ctx;

  const [value, setValue] = useState<DocumentHookValue<TRepo>>(() => {
    const currentValue = getCurrentValue(repo, documentId);

    return typeof currentValue === 'undefined'
      ? { loading: true, document: undefined }
      : {
          loading: false,
          document: currentValue as DocumentType<TRepo> | null
        };
  });

  useEffect(() => {
    const currentValue = getCurrentValue(repo, documentId);

    setValue(
      typeof currentValue === 'undefined'
        ? { loading: true, document: undefined }
        : {
            loading: false,
            document: currentValue as DocumentType<TRepo> | null
          }
    );

    const subscription = subscribe(repo, documentId, {
      next: doc => {
        setValue({
          loading: false,
          document: doc as DocumentType<TRepo> | null
        });
      }
    });

    return () => {
      subscription.dispose();
    };
  }, [ctx, documentId, repo]);

  return value;
};
