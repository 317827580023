import { useEffect } from 'react';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { nameof } from '../../../lib/name-of';
import { PreparationOrdersPullContext } from '../../../data-model/components/preparation-orders-pull-context/PreparationOrdersPullContext';

export type PullIncompletePreparationOrdersProps = {
  preparationZoneId: string;
};

const PullIncompletePreparationOrders = ({
  preparationZoneId
}: PullIncompletePreparationOrdersProps): JSX.Element => {
  const { retainZone } = useContextOrThrow(
    PreparationOrdersPullContext,
    nameof({ PreparationOrdersPullContext })
  );

  useEffect(() => {
    const { release } = retainZone(preparationZoneId);

    return release;
  }, [preparationZoneId]);

  return <></>;
};

export default PullIncompletePreparationOrders;
