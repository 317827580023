import { useEffect } from 'react';
import { useContextOrThrow } from '../../lib/use-context-or-throw';
import { nameof } from '../../lib/name-of';
import { Option, HeaderMenuContext } from './HeaderMenuContext';

export type HeaderMenuOptionsProps = {
  options: Option[];
};

export const HeaderMenuOptions = ({
  options
}: HeaderMenuOptionsProps): JSX.Element => {
  const { registerOptions } = useContextOrThrow(
    HeaderMenuContext,
    nameof({ HeaderMenuContext })
  );

  useEffect(() => {
    return registerOptions(options);
  }, [options]);

  return <></>;
};
