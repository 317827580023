import { AppRegistry } from 'react-native';
import ProductNavigator from '../../features/product-management/navigation/ProductNavigator';
import { nameof } from '../../lib/name-of';
import SiteEntryPoint, { SiteEntryPointProps } from '../SiteEntryPoint';

type ProductsEntryPointProps = Omit<SiteEntryPointProps, 'navigatorComponent'>;

const ProductsEntryPoint = (props: ProductsEntryPointProps): JSX.Element => {
  return <SiteEntryPoint {...props} navigatorComponent={ProductNavigator} />;
};

AppRegistry.registerComponent(
  nameof({ ProductsEntryPoint }),
  () => ProductsEntryPoint
);
