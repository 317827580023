import React from 'react';
import ActionIconButton from '../card/ActionIconButton';
import VoidIcon from '../../../../components/icons/VoidIcon';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { undoReleasePreparationOrder } from '../../commands/undoReleasePreparationOrder';
import { Alert } from '../../../../components/platform-alert/Alert';
import ActionButton from '../../../../components/buttons/ActionButton';
import { PlatformPressableProps } from '../../../../components/platform-pressable/PlatformPressableProps';

export type UndoReleaseButtonProps = Omit<
  PlatformPressableProps,
  'children'
> & {
  preparationOrderId: string;
  size?: 'small' | 'large';
};

const UndoReleaseButton = React.memo<UndoReleaseButtonProps>(
  ({ preparationOrderId, size = 'small', ...rest }) => {
    const { invoke, inProgress } = useCommand(
      undoReleasePreparationOrder,
      (result, retry) => {
        if (!(result instanceof Error) && result.type === 'SUCCESS') {
          return;
        }

        const errorMessage =
          result instanceof Error
            ? 'An unexpected error occurred.'
            : 'The task is not in the expected state.';

        Alert.alert(
          'Error',
          `Could not undo releasing the task.\n\n${errorMessage}`,
          [
            { text: 'Cancel', onPress: () => {} },
            { text: 'Retry', onPress: () => retry() }
          ]
        );
      }
    );

    return size === 'small' ? (
      <ActionIconButton
        {...rest}
        iconComponent={VoidIcon}
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    ) : (
      <ActionButton
        {...rest}
        iconComponent={VoidIcon}
        label="Undo"
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    );
  }
);

export default UndoReleaseButton;
