import { Text, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type SectionTitleProps = Omit<ViewProps, 'children'> & { title: string };

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 30,
    paddingBottom: 10
  },
  text: {
    color: SemanticColours.primary.foreground[40],
    textTransform: 'uppercase',
    fontSize: 13,
    letterSpacing: 1.155
  }
});

const SectionTitle = ({
  title,
  style,
  ...rest
}: SectionTitleProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Text style={styles.text}>{title}</Text>
    </View>
  );
};

export default SectionTitle;
