import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../theme/SemanticColours';
import { Colours } from '../../../theme/Colours';

export type PreparationOrderLineSeparatorProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  root: {
    backgroundColor: Colours.transparent.$,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: SemanticColours.primary.foreground[25],
    marginHorizontal: 20
  }
});

const PreparationOrderLineSeparator =
  React.memo<PreparationOrderLineSeparatorProps>(({ style, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return <View style={[styles.root, style]} {...rest} />;
  });

export default PreparationOrderLineSeparator;
