import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import { ServiceTransaction } from '../../documents/ServiceTransaction';
import { CalendarDayPartition, IsoDayPartition } from '../Partitions';

export type DashboardDatesForProductKey = [
  productId: string,
  ...ignore: CalendarDayPartition | IsoDayPartition
];

export type DashboardDatesForProductValue = { readonly value: number };

export class DashboardDatesForProductDesignDoc {
  constructor(private readonly database: Database) {}

  public readonly view = new DatabaseView<
    DashboardDatesForProductKey,
    DashboardDatesForProductValue,
    ServiceTransaction
  >(this.database, '_design/dashboard-dates-for-product', 'calendar');
}
