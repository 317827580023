import { Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../../../../components/text-field';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import { assertNever } from '../../../../lib/assert-never';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { updateProductName } from '../../commands/updateProductName';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';

type FormValues = {
  name: string;
};

const validate = ({ name }: FormValues): FormikErrors<FormValues> => {
  if (!name.trim()) {
    return { name: 'Name is required' };
  }

  return {};
};

export type RenameProductModalProps = Omit<ViewProps, 'children'> & {
  initialName: string;
  productId: string;
  dismiss: () => void;
  visible: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

const RenameProductModal = ({
  style,
  initialName,
  productId,
  dismiss,
  visible,
  ...rest
}: RenameProductModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { invoke, inProgress, result, reset } = useCommand(
    updateProductName,
    r => {
      if (!(r instanceof Error) && r.type === 'SUCCESS') {
        dismiss();
      }
    }
  );

  const onSubmit = useCallback(
    ({ name }: FormValues) => {
      invoke({ newName: name.trim(), productId });
    },
    [invoke]
  );

  useEffect(() => {
    if (!visible) reset();
  }, [visible, reset]);

  const commandError = useMemo(() => {
    if (!result) {
      return null;
    }

    if (result instanceof Error) {
      return 'An unexpected error occurred.';
    }

    switch (result.type) {
      case 'NOT_FOUND':
        return 'Product no longer exists.';
      case 'NAME_IN_USE':
        return 'Product name is already in use.';
      case 'SUCCESS':
        return null;
      case 'UNAUTHORISED':
        return 'You are not authorised to make this change.';
      default:
        return assertNever(result);
    }
  }, [result]);

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView style={[styles.container, style]} {...rest}>
        <Formik
          initialValues={{ name: initialName }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title="Edit product name"
                onCross={dismiss}
                onTick={handleSubmit}
                tickEnabled={!inProgress}
              />
              <TextField
                {...formProps}
                autoCapitalize="words"
                autoCorrect
                autoFocus
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                enablesReturnKeyAutomatically
                editable={!inProgress}
                inputStyle={styles.field}
                name="name"
                onSubmitEditing={() => handleSubmit()}
                placeholder="Product name"
                returnKeyType="done"
              />
              {commandError && (
                <ErrorMessage style={styles.error}>{commandError}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default RenameProductModal;
