import { View, ViewProps } from 'react-native';
import { Children, ComponentType, useMemo } from 'react';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import CellGroupHeading from './CellGroupHeading';
import CellGroupSeparator from './CellGroupSeparator';
import CellGroupFooter from './CellGroupFooter';
import { useCellGroupStyles } from './useCellGroupStyles';

export interface CellGroupProps extends ViewProps {
  headingIconComponent?: ComponentType<SvgProps>;
  headingText?: string;
  footerText?: string;
  loading?: boolean;
  insets?: Parameters<typeof useCellGroupStyles>['0'];
}

const rStyles = DynamicStyleSheet.create({
  cells: {
    alignItems: 'stretch',
    flexDirection: 'column'
  },
  cell: {
    overflow: 'hidden'
  }
});

const CellGroup = ({
  children,
  footerText,
  headingIconComponent,
  headingText,
  loading,
  insets = 'both',
  ...rest
}: CellGroupProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { Separator, header, cell } = useCellGroupStyles(insets);

  const arrayChildren = useMemo(() => Children.toArray(children), [children]);

  return (
    <View {...rest}>
      <CellGroupSeparator style={header} />

      {!!headingText && (
        <CellGroupHeading
          style={header}
          title={headingText ?? ''}
          loading={loading}
          iconComponent={headingIconComponent}
        />
      )}

      <View style={styles.cells}>
        {Children.map(arrayChildren, (child, index) => (
          <>
            <View
              style={[
                styles.cell,
                cell(index === 0, index === arrayChildren.length - 1)
              ]}
            >
              {child}
            </View>
            {index !== arrayChildren.length - 1 && <Separator />}
          </>
        ))}
      </View>

      {!!footerText && <CellGroupFooter text={footerText} style={header} />}

      <CellGroupSeparator style={header} />
    </View>
  );
};

export default CellGroup;
