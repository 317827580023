import { useCallback } from 'react';
import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import LoginForm from '../../../../security/components/login-form/LoginForm';
import { SessionTokenResponsePayload } from '../../../../api/generated/UsopApiClient.generated';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { useProgress } from '../../components/progress-context/useProgress';
import { OnboardingProgress } from '../../components/progress-context/ProgressContext';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { nameof } from '../../../../lib/name-of';

const rStyles = DynamicStyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[100]
  },
  container: {
    flex: 1,
    marginVertical: 70
  },
  form: {
    alignItems: 'stretch',
    paddingHorizontal: 24
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    alignSelf: 'center',
    width: 445
  }
});

const LoginScreen = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { progress, commit } = useProgress();

  const onSuccess = useCallback(
    (payload: SessionTokenResponsePayload) => {
      const {
        userId,
        username,
        fullName,
        token,
        id,
        role,
        device: { id: deviceId }
      } = payload;

      const user: OnboardingProgress['user'] = {
        id: userId,
        username,
        fullName
      };

      const userSession: OnboardingProgress['userSession'] = {
        token,
        userSessionId: id,
        role: role as RoleId,
        deviceId
      };

      commit.invoke({ user, userSession });
    },
    [commit]
  );

  if (!progress.client) {
    return <></>;
  }

  return (
    <View style={styles.screen} testID={nameof({ LoginScreen })}>
      <View style={styles.container}>
        <LoginForm
          style={styles.form}
          clientId={progress.client.id}
          clientName={progress.client.name}
          defaultUsername={progress.user?.username}
          onSuccess={onSuccess}
          disabled={commit.inProgress}
        />
      </View>
    </View>
  );
};

export default LoginScreen;
