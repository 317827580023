import { LogLevel } from './LogLevel';
import { Logger } from './Logger';
import { LoggingConfig } from './LoggingConfig';

export const createLogger = (
  component: string,
  staticFields?: Record<string, unknown>
): Logger => {
  const { sink } = LoggingConfig;

  const log = (
    level: LogLevel,
    message: string,
    additionalFields?: Record<string, unknown>
  ) => {
    sink?.next(
      level,
      component,
      message,
      staticFields || additionalFields
        ? {
            ...(additionalFields || {}),
            ...(staticFields || {})
          }
        : undefined
    );
  };
  return {
    staticFields,
    debug: (message, additionalFields) =>
      log(LogLevel.debug, message, additionalFields),
    info: (message, additionalFields) =>
      log(LogLevel.info, message, additionalFields),
    warn: (message, additionalFields) =>
      log(LogLevel.warn, message, additionalFields),
    error: (messageOrError: string | Error, additionalFields) => {
      if (typeof messageOrError === 'object') {
        const {
          stack = 'No stack available',
          name: errorName,
          message: errorMessage = 'No message'
        } = messageOrError;
        return log(LogLevel.error, errorMessage, {
          ...additionalFields,
          errorName,
          stack
        });
      }
      return log(LogLevel.error, messageOrError, additionalFields);
    }
  };
};
