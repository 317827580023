import { ScrollViewProps, View } from 'react-native';
import Reanimated, { Easing, Layout } from 'react-native-reanimated';
import {
  ComponentType,
  JSXElementConstructor,
  ReactElement,
  cloneElement
} from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import useSet from '../../lib/use-set';
import PlatformScrollView from '../platform-scroll-view/PlatformScrollView';

const rStyles = DynamicStyleSheet.create({
  root: {},
  content: {
    padding: 8,
    paddingTop: 10
  },
  cardContainer: {
    padding: 8
  }
});

export type CardColumnData = {
  data: {
    key: string;
    element: ReactElement<{
      expanded: boolean;
      onToggleExpansion: () => void;
    }>;
  }[];
};

type ListComponent =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ReactElement<any, string | JSXElementConstructor<any>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ComponentType<any>
  | null;

export type CardColumnProps = Omit<ScrollViewProps, 'children'> & {
  data: CardColumnData;
  ListEmptyComponent?: ListComponent;
  ListHeaderComponent?: ListComponent;
};

const CardColumn = ({
  style,
  contentContainerStyle,
  data,
  ListHeaderComponent,
  ...rest
}: CardColumnProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [expandedKeys, { has, toggle }] = useSet<string>();

  return (
    <Reanimated.FlatList
      renderScrollComponent={props => <PlatformScrollView {...props} />}
      contentContainerStyle={[styles.content, contentContainerStyle]}
      extraData={expandedKeys}
      style={[styles.root, style]}
      data={data.data}
      initialNumToRender={15}
      itemLayoutAnimation={Layout.easing(Easing.ease)}
      stickyHeaderIndices={ListHeaderComponent ? [0] : undefined}
      ListHeaderComponent={ListHeaderComponent}
      renderItem={({ item: { key, element } }) => (
        <View style={styles.cardContainer}>
          {cloneElement(element, {
            expanded: has(key),
            onToggleExpansion: () => toggle(key)
          })}
        </View>
      )}
      {...rest}
    />
  );
};

export default CardColumn;
