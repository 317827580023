import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';

const commandType = 'renameProductGroup' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      newName: string;
      departmentId: string;
    };
  }
}

export const renameProductGroup = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'NAME_IN_USE' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const {
    repositories,
    design: { views }
  } = ctx.databases.reference.local;
  const { departmentId, newName } = args;
  const { userId, createdAt } = ctx.meta;

  const existingDepartments = await views.allDepartments.getQuery({
    startKey: [false],
    endKey: [false, []]
  });

  if (
    existingDepartments
      .filter(r => r.documentID !== departmentId)
      .map(r => r.key[1])
      .includes(newName)
  ) {
    return { type: 'NAME_IN_USE' };
  }

  const result = await repositories.departments.updateDocument(
    departmentId,
    (p, update) =>
      update({
        ...p,
        name: newName,
        revisionTime: createdAt,
        revisionUserID: userId
      })
  );

  if (result.type === 'NOT_FOUND') {
    return result;
  }

  if (result.type === 'SKIPPED') {
    throw new Error('Impossible skip');
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      newName,
      departmentId
    },
    [departmentId]
  );

  return { type: 'SUCCESS', data: command };
};
