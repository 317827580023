import { useCallback, useMemo, useState } from 'react';

interface UseModalControl {
  present: () => void;
  visible: boolean;
  dismiss: () => void;
}

type UseModalControlWithProps<TProps> = UseModalControl & {
  present: (props: TProps) => void;
} & TProps;

function useModalControl(): UseModalControl;
function useModalControl<TProps>(): UseModalControlWithProps<TProps>;
function useModalControl<TProps>() {
  const [visible, setVisible] = useState(false);

  const [modalProps, setModalProps] = useState<undefined | TProps>();

  const dismiss = useCallback(() => {
    setVisible(false);
  }, []);

  const present = useCallback((props?: TProps) => {
    setModalProps(props);
    setVisible(true);
  }, []);

  const value = useMemo(
    () => ({
      present,
      visible,
      dismiss,
      ...modalProps
    }),
    [present, visible, dismiss, modalProps]
  );

  return value;
}

export default useModalControl;
