import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { View } from 'react-native';
import { OnboardingNavigatorParamList } from '../navigation/OnboardingNavigatorParamList';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { GlobalActivityIndicator } from '../../../lib/global-activity/GlobalActivityIndicator';
import PlatformPressable from '../../../components/platform-pressable/PlatformPressable';
import { Text } from '../../../components/text/Text';
import { ReLoginNavigatorParamList } from '../../login/navigation/ReLoginNavigatorParamList';

export type ConfirmHeaderProps = {
  disabled?: boolean;
  onPress: () => void;
};

const rStyles = DynamicStyleSheet.create({
  rightButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12
  }
});

const ConfirmHeader = React.memo<ConfirmHeaderProps>(
  ({ disabled, onPress }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const navigation = useNavigation<
      | StackNavigationProp<
          OnboardingNavigatorParamList,
          keyof OnboardingNavigatorParamList
        >
      | StackNavigationProp<
          ReLoginNavigatorParamList,
          keyof ReLoginNavigatorParamList
        >
    >();

    useEffect(() => {
      navigation.setOptions({
        headerRight: headerProps => (
          <View style={styles.rightButtons}>
            <GlobalActivityIndicator color={headerProps.tintColor} />
            <PlatformPressable onPress={onPress} disabled={disabled}>
              <Text.DocBody style={{ color: headerProps.tintColor }}>
                Confirm
              </Text.DocBody>
            </PlatformPressable>
          </View>
        )
      });
    }, [navigation]);

    return <></>;
  }
);

export default ConfirmHeader;
