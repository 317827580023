import { Department } from '../../documents/Department';
import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('productoptionset-') === 0 && !doc.deleted) {
    const name =
      'name' in doc && typeof doc.name === 'string' ? doc.name : null;

    emit(name, null);
  }
};

export class ProductOptionSetsByNameView extends DatabaseView<
  string,
  null,
  Department
> {
  static readonly viewName = 'productOptionSetsByName';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, ProductOptionSetsByNameView.viewName);
  }
}
