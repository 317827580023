import { assertNever } from '../../../lib/assert-never';
import { IntervalDate } from '../types/IntervalDate';

const DAY_M_SEC = 24 * 60 * 60 * 1000;

export const intervalStartDate = ([
  interval,
  dateStr
]: IntervalDate): string => {
  if (interval === 'day') {
    return dateStr;
  }

  if (interval === 'week') {
    const date = new Date(dateStr);
    const timeInt = date.getTime() - ((date.getUTCDay() || 7) - 1) * DAY_M_SEC;
    const weekStart = new Date(timeInt);

    return weekStart.toISOString().split('T')[0];
  }

  if (interval === 'month') {
    const date = new Date(dateStr);
    const timeInt = date.getTime() - (date.getUTCDate() - 1) * DAY_M_SEC;
    const monthStart = new Date(timeInt);

    return monthStart.toISOString().split('T')[0];
  }

  if (interval === 'year') {
    return `${new Date(dateStr).toISOString().substring(0, 4)}-01-01`;
  }

  return assertNever(interval);
};
