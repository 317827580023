import React, { useMemo } from 'react';
import { ViewProps } from 'react-native';
import { DateTime, Duration } from 'luxon';
import { Document } from '../../../../data-model/types/Document';
import { PreparationOrder } from '../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import { useCurrentDateTime } from '../../../../lib/use-current-time';
import PreparationTimeCell from './PreparationTimeCell';

export type BoundPreparationTimeCellProps = Omit<ViewProps, 'children'> & {
  document: Document<PreparationOrder>;
};

const BoundPreparationTimeCell = React.memo<BoundPreparationTimeCellProps>(
  ({ document, ...rest }) => {
    const startTime = useMemo(() => {
      return (
        document.statuses?.['02 Released'] ??
        document.statuses?.['03 In Progress']
      );
    }, [document]);

    const currentTime = useCurrentDateTime(Duration.fromObject({ seconds: 1 }));

    const endTime = useMemo(() => {
      const collectedTime = document.statuses?.['05 Collected'];

      return collectedTime
        ? DateTime.fromISO(collectedTime, { zone: 'utc' })
        : currentTime;
    }, [document, currentTime]);

    const value = useMemo(() => {
      if (!startTime) return null;

      const timeSince = endTime.diff(
        DateTime.fromISO(startTime, { zone: 'utc' }),
        ['hours', 'minutes', 'seconds']
      );

      const { hours, minutes } = timeSince;
      const seconds = Math.floor(timeSince.seconds);

      return [
        hours > 0 ? `${hours}h` : null,
        minutes > 0 ? `${minutes}m` : null,
        seconds > 0 ? `${seconds}s` : null
      ]
        .flat()
        .join(' ');
    }, [startTime, endTime]);

    return <PreparationTimeCell time={value ?? ''} {...rest} />;
  }
);

export default BoundPreparationTimeCell;
