import { ScrollViewProps, SectionList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { usePaymentTypesListData } from './usePaymentTypesListData';
import CellGroupSeparator from '../../../../../../components/cells/CellGroupSeparator';
import EmptyList from '../../../../components/empty-list/EmptyList';
import Heading from './Heading';
import Cell from './Cell';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type PaymentTypesListProps = Omit<ScrollViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[90]
  }
});

const PaymentTypesList = ({
  style,
  ...rest
}: PaymentTypesListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const partition = useActivePartition();

  const { data, loading } = usePaymentTypesListData(partition);

  const { header, cell, Separator } = useCellGroupStyles('left');

  const navigation =
    useNavigation<
      StackNavigationProp<DashboardNavigatorParamList, 'payment-types'>
    >();

  return (
    <SectionList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      ListHeaderComponent={CellGroupSeparator}
      ListEmptyComponent={() => <EmptyList loading={loading} />}
      style={[styles.container, style]}
      sections={data}
      initialNumToRender={15}
      ItemSeparatorComponent={Separator}
      renderSectionHeader={({ section: { Icon, title, received, refund } }) => (
        <Heading
          iconComponent={Icon}
          paymentType={title}
          received={received}
          refund={refund}
          style={header}
        />
      )}
      renderSectionFooter={() => <CellGroupSeparator large style={header} />}
      renderItem={({ item, index, section }) => (
        <Cell
          received={item.received}
          refund={item.refund}
          name={item.title}
          style={cell(index === 0, index === section.data.length - 1)}
          onPress={
            loading
              ? undefined
              : () => {
                  navigation.push('payment-type', {
                    partition: PartitionSerialiser.stringify(partition),
                    paymentTypeId: item.navigationParams
                  });
                }
          }
        />
      )}
      {...rest}
    />
  );
};

export default PaymentTypesList;
