import React, { useEffect, useState } from 'react';
import { SyncStatus } from '../../tools/DatabaseSync';
import { nameof } from '../../../lib/name-of';
import HoldingScreen from '../../../components/holding-screen/HoldingScreen';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { ServerContext } from '../server-context/ServerContext';
import { SyncWaiter } from '../../tools/SyncWaiter';
import { useDocument } from '../../hooks/useDocument';
import { useSitesDb } from '../client-context/useSitesDb';
import { ReferenceDatabase } from '../../schema/databases/site-reference/ReferenceDatabase';
import { SiteCommandsDatabase } from '../../schema/databases/site-commands/SiteCommandsDatabase';
import { TransactionsArchiveDatabase } from '../../schema/databases/site-transactions-archive/TransactionsArchiveDatabase';
import { useClientId } from '../client-context/useClientId';
import { QueryAuthorisationContext } from '../../../security/context/QueryAuthorisationContext';
import { ReplicationAuthorisationContext } from '../../../security/context/ReplicationAuthorisationContext';
import { PreparationOrdersDatabase } from '../../schema/databases/site-preparation-orders/PreparationOrdersDatabase';
import { PreparationOrdersPullContextProvider } from '../preparation-orders-pull-context/PreparationOrdersPullContext';
import { SiteContext, SiteContextType } from './SiteContext';
import { StockTransactionsDatabase } from '../../schema/databases/site-stock-transactions/StockTransactionsDatabase';

export type SiteContextProviderProps = React.PropsWithChildren<{
  siteId: string;
}>;

export const SiteContextProvider = ({
  children,
  siteId
}: SiteContextProviderProps): JSX.Element => {
  const [value, setValue] = useState<Partial<SiteContextType>>({ siteId });

  const clientId = useClientId();

  const siteDocument = useDocument(
    useSitesDb().local.repositories.sites,
    siteId
  );

  useEffect(() => {
    if (siteDocument.loading) return;

    const { document: site } = siteDocument;

    if (!site) {
      throw new Error('Site not found');
    }

    setValue(v => ({
      ...v,
      site
    }));
  }, [siteDocument]);

  const { dbBaseUrl } = useContextOrThrow(
    ServerContext,
    nameof({ ServerContext })
  );

  const { authoriser: queryAuthoriser } = useContextOrThrow(
    QueryAuthorisationContext,
    nameof({ QueryAuthorisationContext })
  );

  const { authoriser: syncAuthoriser } = useContextOrThrow(
    ReplicationAuthorisationContext,
    nameof({ ReplicationAuthorisationContext })
  );

  useEffect(() => {
    const queryConfig = {
      dbBaseUrl,
      authoriser: queryAuthoriser
    };

    const syncConfig = {
      dbBaseUrl,
      authoriser: syncAuthoriser
    };

    const databases: SiteContextType['databases'] = {
      reference: new ReferenceDatabase(
        clientId,
        siteId,
        queryConfig,
        syncConfig
      ),

      commands: new SiteCommandsDatabase(
        clientId,
        siteId,
        queryConfig,
        syncConfig
      ),

      preparationOrders: new PreparationOrdersDatabase(
        clientId,
        siteId,
        queryConfig,
        syncConfig
      ),

      transactionsArchive: new TransactionsArchiveDatabase(
        clientId,
        siteId,
        queryConfig,
        syncConfig
      ),

      stockTransactions: new StockTransactionsDatabase(
        clientId,
        siteId,
        queryConfig,
        syncConfig
      )
    };

    const abort = new AbortController();

    void (async () => {
      await Promise.all([
        databases.reference.configurePullReplication(),
        databases.reference.configurePushReplication(),
        databases.commands.configurePushReplication(),
        databases.transactionsArchive.configurePushReplication(),
        databases.preparationOrders.configurePushReplication(),
        databases.stockTransactions.configurePushReplication()
      ]);

      await SyncWaiter.waitMany(
        [databases.reference.pullReplication].compact(),
        new Set<SyncStatus['state']>(['PAUSED', 'STOPPED', 'ERROR', 'DENIED']),
        abort
      );

      await Promise.all([
        databases.reference.local.ensureSchemaApplied(),
        databases.commands.local.ensureSchemaApplied(),
        databases.preparationOrders.local.ensureSchemaApplied()
      ]);

      if (abort.signal.aborted) return;

      setValue(v => ({
        ...v,
        databases
      }));
    })();

    return () => {
      abort.abort();
      void Promise.all([
        databases.reference.dispose(),
        databases.commands.dispose(),
        databases.transactionsArchive.dispose(),
        databases.preparationOrders.dispose(),
        databases.stockTransactions.dispose()
      ]);
    };
  }, [siteId, clientId, dbBaseUrl, queryAuthoriser, syncAuthoriser]);

  if (!value.siteId || !value.site || !value.databases) {
    return <HoldingScreen />;
  }

  return (
    <SiteContext.Provider value={value as SiteContextType}>
      <PreparationOrdersPullContextProvider>
        {children}
      </PreparationOrdersPullContextProvider>
    </SiteContext.Provider>
  );
};
