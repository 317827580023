import { useCallback } from 'react';
import { deleteProduct } from '../../commands/deleteProduct';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { HeaderMenuOptions } from '../../../../components/header-menu/HeaderMenuOptions';
import { useProductDataContext } from '../../data/ProductDataContext';
import { Alert } from '../../../../components/platform-alert/Alert';

export type DeleteProductMenuOptionProps = {
  onProductDeleted: (productId: string) => void;
};

const DeleteProductMenuOption = ({
  onProductDeleted
}: DeleteProductMenuOptionProps): JSX.Element => {
  const { product, productId } = useProductDataContext();

  const { invoke, inProgress } = useCommand(deleteProduct, (result, retry) => {
    if (!(result instanceof Error) && result.type === 'SUCCESS') {
      onProductDeleted(productId);
      return;
    }

    const errorMessage =
      result instanceof Error
        ? 'An unexpected error occurred.'
        : 'The product no longer exists.';

    Alert.alert('Error', `Could not delete the product.\n\n${errorMessage}`, [
      { text: 'Cancel' },
      { text: 'Retry', onPress: () => retry() }
    ]);
  });

  const onSelect = useCallback(() => {
    if (!product) return;

    Alert.alert(
      'Confirm delete',
      `Are you sure you want to delete ${
        product.productName ?? 'this product'
      }?`,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: () =>
            invoke({
              productId
            })
        }
      ]
    );
  }, [product]);

  return (
    <HeaderMenuOptions
      options={[
        {
          label: `Delete ${product?.productName ?? 'product'}`,
          style: 'destructive' as const,
          isDisabled: inProgress,
          onSelect
        }
      ]}
    />
  );
};

export default DeleteProductMenuOption;
