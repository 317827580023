import { createStackNavigator } from '@react-navigation/stack';
import { SemanticColours } from '../../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { PrepNavigatorParamList } from './PrepNavigatorParamList';
import PrepScreen from '../screens/prep/PrepScreen';

const rStyles = DynamicStyleSheet.create({
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const Stack = createStackNavigator<PrepNavigatorParamList>();

const PrepNavigator = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: styles.headerTint.color,
        headerStyle: styles.header,
        cardStyle: { flex: 1 }
      }}
    >
      <Stack.Screen name="tasks" component={PrepScreen} />
    </Stack.Navigator>
  );
};

export default PrepNavigator;
