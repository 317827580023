import { useCallback } from 'react';
import { deleteProductGroup } from '../commands/deleteProductGroup';
import { useCommand } from '../../../data-model/hooks/useCommand';
import { Alert } from '../../../components/platform-alert/Alert';

export const useDeleteDepartment = (department: {
  id: string | null;
  name: string | null;
  count: number;
}) => {
  const { invoke } = useCommand(deleteProductGroup);

  const onDelete = useCallback(() => {
    const { id, name, count } = department;

    if (!id) return;

    if (count === 0) {
      invoke({
        departmentId: id
      });
      return;
    }

    Alert.alert(
      'Delete product group',
      `Are you sure you want to delete ${name ?? 'this department'} and the ${
        count > 1 ? `${count} products` : 'product'
      } it contains?`,
      [
        { text: 'Cancel', style: 'cancel' },
        {
          text: 'Delete',
          style: 'destructive',
          onPress: () =>
            invoke({
              departmentId: id
            })
        }
      ]
    );
  }, [department]);

  return onDelete;
};
