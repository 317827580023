import { Product } from '../data-model/schema/databases/site-reference/documents/product/Product';
import {
  DEFAULT_UNNAMED_VARIANT_NAME,
  ProductV2,
  VariantArray
} from '../data-model/schema/databases/site-reference/documents/product/ProductV2';
import { Document } from '../data-model/types/Document';
import { inferImpliedProductVariant } from './inferImpliedProductVariant';

export function convertProductToV2(
  product: Document<Product>
): Document<ProductV2> | 'N/A';
export function convertProductToV2(product: Product): ProductV2 | 'N/A';
export function convertProductToV2(product: Product): ProductV2 | 'N/A' {
  if ('$schemaVersion' in product) {
    if (product.$schemaVersion === '2') {
      return product;
    }

    return 'N/A';
  }

  const { masterProductName, impliedVariantName } = inferImpliedProductVariant(
    product.name ?? ''
  );
  const { prices, optionSets } = product;

  if (!prices) {
    return 'N/A';
  }

  const variants: VariantArray = impliedVariantName
    ? [
        {
          $schema: 'DefaultImpliedVariant',
          id: 'DEFAULT',
          name: impliedVariantName,
          prices,
          optionSets
        }
      ]
    : [
        {
          $schema: 'DefaultUnnamedVariant',
          id: 'DEFAULT',
          name: DEFAULT_UNNAMED_VARIANT_NAME,
          prices,
          optionSets
        }
      ];

  return {
    ...product,
    name: product.name ?? '',
    $schema: 'Product',
    $schemaVersion: '2',
    productName: masterProductName,
    variants
  };
}
