import { Text, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type PillProps = ViewProps & {
  value: number;
  max?: number;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.foreground[40],
    paddingHorizontal: 8,
    borderRadius: 100
  },
  text: {
    color: SemanticColours.primary.background[100],
    fontWeight: '700'
  }
});

const PillContent = ({
  style,
  value,
  max = 99,
  ...rest
}: PillProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Text style={styles.text}>{value > max ? `${max}+` : value}</Text>
    </View>
  );
};

const Pill = (props: PillProps) => <PillContent {...props} />;

export default Pill;
