import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { PreparationZone } from '../../documents/PreparationZone';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('preparationzone-') === 0 && !doc.deleted) {
    emit(null, null);
  }
};

export class AllPreparationZonesView extends DatabaseView<
  null,
  null,
  PreparationZone
> {
  static readonly viewName = 'allPreparationZones';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllPreparationZonesView.viewName);
  }
}
