import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import CellGroup, {
  CellGroupProps
} from '../../../../components/cells/CellGroup';
import BasicCell from '../../../../components/cells/BasicCell';
import HistoryIcon from '../../../../components/icons/HistoryIcon';
import PopUpIcon from '../../../../components/icons/PopUpIcon';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import ChangeHistoryModal from '../modals/ChangeHistoryModal';
import { useProductDataContext } from '../../data/ProductDataContext';
import { useDocument } from '../../../../data-model/hooks/useDocument';
import { useSecurityDb } from '../../../../data-model/components/client-context/useSecurityDb';
import { formatUserFullName } from '../../../../humanisers/formatUserFullName';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';

export type ChangeHistoryCellGroupProps = Omit<CellGroupProps, 'children'>;

const rStyles = DynamicStyleSheet.create({}).override(Selectors.small, {
  subtitle: {
    fontSize: 14
  }
});

const ChangeHistoryCellGroup = React.memo<ChangeHistoryCellGroupProps>(
  props => {
    const styles = useDynamicStyleSheet(rStyles);

    const { productId, product } = useProductDataContext();

    const { present, ...modal } = useModalControl();

    const user = useDocument(
      useSecurityDb().local.repositories.users,
      product?.revisionUserID ?? ''
    );

    const who = useMemo(() => {
      return user.document ? `by ${formatUserFullName(user.document)}` : '';
    }, [user]);

    const when = useMemo(() => {
      const relative = product?.revisionTime
        ? DateTime.fromISO(product.revisionTime, {
            zone: 'utc'
          }).toRelativeCalendar()
        : null;

      return relative ? `Last updated ${relative}` : '';
    }, [product]);

    return (
      <>
        <CellGroup
          headingText="Change history"
          footerText="See how this product has changed over time"
          headingIconComponent={HistoryIcon}
          {...props}
        >
          <BasicCell
            title={when}
            subtitle={who}
            subtitleStyle={styles.subtitle}
            iconRight={PopUpIcon}
            onPress={() => present()}
          />
        </CellGroup>

        <ChangeHistoryModal productId={productId} {...modal} />
      </>
    );
  }
);

export default ChangeHistoryCellGroup;
