import { TimeInterval } from '../types/TimeInterval';
import { assertNever } from '../../../lib/assert-never';
import { Partition } from '../types/Partition';

export const partitionToTimeInterval = (key: Partition): TimeInterval => {
  switch (key[0]) {
    case 'calendar': {
      switch (key.length) {
        case 2:
          return 'year';
        case 3:
          return 'month';
        case 4:
          return 'day';
        default:
          return assertNever(key);
      }
    }

    case 'iso':
      return 'week';

    default:
      return assertNever(key);
  }
};
