import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import { useCallback, useEffect } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ErrorBoundary } from 'react-error-boundary';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import ProductDataView from '../../components/product-data-view/ProductDataView';
import {
  ProductDataContextProvider,
  useProductDataContext
} from '../../data/ProductDataContext';
import DeleteProductMenuOption from '../../components/menu-options/DeleteProductMenuOption';
import { HeaderMenuButton } from '../../../../components/header-menu/HeaderMenuButton';
import { ProblemContent } from '../../../../components/unhappy-path-content/problem/ProblemContent';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

export type ProductShowScreenProps = StackScreenProps<
  ProductNavigatorParamList,
  'show'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingVertical: 6
  }
});

const HeaderTitle = (): JSX.Element => {
  const { product, loading } = useProductDataContext();

  const navigation =
    useNavigation<
      StackNavigationProp<ProductNavigatorParamList, 'show' | 'list'>
    >();

  useEffect(() => {
    if (loading) return;

    navigation.setOptions({
      title: !product ? '' : product?.productName ?? 'No name'
    });
  }, [loading, product]);

  return <></>;
};

const ProductShowScreen = ({
  navigation,
  route
}: ProductShowScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const isFocussed = useIsFocused();

  useEffect(() => {
    navigation.setOptions({
      headerRight: headerProps => <HeaderMenuButton {...headerProps} />
    });
  }, [navigation]);

  const onProductDeleted = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  if (!route.params?.productId) {
    throw new Error('productId route param required');
  }

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <ErrorBoundary FallbackComponent={ProblemContent}>
      <ProductDataContextProvider productId={route.params?.productId}>
        <HeaderTitle />

        {isFocussed && authorised && (
          <DeleteProductMenuOption onProductDeleted={onProductDeleted} />
        )}

        <PlatformScrollView
          style={styles.container}
          contentContainerStyle={styles.content}
          keyboardShouldPersistTaps="always"
        >
          <ProductDataView includeVariants />
        </PlatformScrollView>
      </ProductDataContextProvider>
    </ErrorBoundary>
  );
};

export default ProductShowScreen;
