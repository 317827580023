import { ActivePartitionContextProvider } from '../../components/active-partition-context/ActivePartitionContext';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import { DefaultPartitionNavigation } from '../../navigation/DefaultPartitionNavigation';
import { PartitionNavigationBar } from '../../components/partition-navigation-bar/PartitionNavigationBar';
import Header from '../../components/header/Header';
import PaymentTypesList from './components/list/PaymentsTypesList';

const PaymentTypesScreen = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <DefaultPartitionNavigation />

      <ActivePartitionContextProvider>
        <Header />
        <PartitionNavigationBar />
        <PaymentTypesList />
      </ActivePartitionContextProvider>
    </ErrorBoundary>
  );
};

export default PaymentTypesScreen;
