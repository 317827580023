import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import {
  isProductV2Compatible,
  isDeclaredVariant
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV2';
import { isProductV1CompatVariant } from '../../../data-model/schema/databases/site-reference/documents/product/ProductV1CompatVariant';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';

const commandType = 'updateProductPreparationZone' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      newPreparationZoneId: string;
      oldPreparationZoneId?: string;
    };
  }
}

export const updateProductPreparationZone = async (
  args: Omit<SiteCommands[typeof commandType], 'type' | 'oldPreparationZoneId'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const log = createLogger(nameof({ updateProductPreparationZone }), { args });

  const { repositories } = ctx.databases.reference.local;
  const { productId, newPreparationZoneId } = args;
  const { userId, createdAt } = ctx.meta;

  let oldPreparationZoneId: string | null = null;

  const productResult = await repositories.products.updateDocument(
    productId,
    (p, update) => {
      oldPreparationZoneId =
        'preparationZone' in p ? p.preparationZone ?? null : null;

      update({
        ...p,
        requiresPreparation: true,
        preparationZone: newPreparationZoneId,
        revisionUserID: userId,
        revisionTime: createdAt
      });
    }
  );

  if (productResult.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  if (isProductV2Compatible(productResult.data)) {
    await Promise.all(
      productResult.data.variants.map(async v => {
        if (v.$schema !== 'DeclaredVariant') {
          return;
        }

        const compatResult =
          await repositories.products.updateDocument<'NOT_COMPAT'>(
            v.compatProductId,
            (old, update, abort) => {
              if (!isProductV1CompatVariant(old)) {
                return abort('NOT_COMPAT');
              }

              return update({
                ...old,
                revisionUserID: userId,
                revisionTime: createdAt,
                preparationZone: newPreparationZoneId
              });
            }
          );

        if (compatResult.type !== 'SUCCESS') {
          if (compatResult.type === 'NOT_FOUND') {
            log.warn(`Variant's compatProductId not found`, {
              productId,
              variant: v
            });
          } else if (compatResult.type === 'ABORTED') {
            log.warn(`Variant's compatProductId was not of the required type`, {
              productId,
              variant: v
            });
          }
        }
      })
    );
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      newPreparationZoneId,
      oldPreparationZoneId: oldPreparationZoneId ?? undefined
    },
    [
      productId,
      ...(isProductV2Compatible(productResult.data)
        ? productResult.data.variants.compactMap(v =>
            isDeclaredVariant(v) ? v.compatProductId : null
          )
        : [])
    ]
  );

  return { type: 'SUCCESS', data: command };
};
