import { IsoWeekPartition } from '../types/Partition';

export const isIsoWeekPartition = (
  value: unknown
): value is IsoWeekPartition => {
  if (!value || !(typeof value === 'object') || !Array.isArray(value)) {
    return false;
  }

  return value[0] === 'iso' && value.length === 3;
};
