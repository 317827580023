import { View, ViewProps } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { useMemo } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { useDocument } from '../../../../../../data-model/hooks/useDocument';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { useServingZoneNameLookup } from '../../../../../../data-model/components/site-context/useServingZoneNameLookup';
import LegacyCellGroup from '../../../../../../components/cells/LegacyCellGroup';
import KeyValueCell from '../../../../../../components/cells/KeyValueCell';
import ListItemSeparator from '../../../../../../components/cells/ListItemSeparator';

export type TransactionSummaryProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {},
  key: {
    width: 140
  },
  totalPrice: {
    fontSize: 28,
    lineHeight: 36
  }
});

const TransactionSummary = ({
  style,
  ...rest
}: TransactionSummaryProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route =
    useRoute<RouteProp<DashboardNavigatorParamList, 'transaction'>>();

  if (!route.params?.transactionId) {
    throw new Error('Transaction id is required');
  }

  const transactionsDb = useTransactionsDb();

  const { document } = useDocument(
    transactionsDb.remote.repository,
    route.params.transactionId
  );

  const { data: servingZones } = useServingZoneNameLookup();

  const data = useMemo(() => {
    if (!document) return null;

    return {
      status: document.state.toUpperCase(),

      totalPrice: formatMoney(+document.totalPrice * 100),

      locations:
        document.sessions
          ?.map(s => s.servingZoneID)
          .distinct()
          .map(s => servingZones[s]) ?? [],

      table: document.tableNumber,

      tabName: document.customerName
    };
  }, [document, servingZones]);

  return !data ? (
    <></>
  ) : (
    <View style={[styles.container, style]} {...rest}>
      <LegacyCellGroup headingText="Transaction summary">
        <KeyValueCell
          keyText="Location"
          valueText={data.locations.join(', ')}
          keyStyle={styles.key}
        />
        <ListItemSeparator />
        {data.table && (
          <>
            <KeyValueCell
              keyText="Table"
              valueText={data.table}
              keyStyle={styles.key}
            />
            <ListItemSeparator />
          </>
        )}
        {data.tabName && (
          <>
            <KeyValueCell
              keyText="Tab name"
              valueText={data.tabName}
              keyStyle={styles.key}
            />
            <ListItemSeparator />
          </>
        )}
        <KeyValueCell
          keyText="Status"
          valueText={data.status}
          keyStyle={styles.key}
        />
        <ListItemSeparator />
        <KeyValueCell
          keyText="Total price"
          valueText={data.totalPrice}
          keyStyle={styles.key}
          valueStyle={styles.totalPrice}
        />
      </LegacyCellGroup>
    </View>
  );
};

export default TransactionSummary;
