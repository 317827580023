import PouchDB from '../../pouchdb';
import { Database } from './Database';
import { DatabaseChangeTracker } from './DatabaseChangeTracker';
import { RemoteDatabaseConfiguration } from './RemoteDatabaseConfiguration';
import { SchemaConstructor } from './SchemaConstructor';

export class RemoteDatabase<TSchema> implements Database {
  constructor(
    public readonly name: string,
    private readonly SchemaClass: SchemaConstructor<TSchema>,
    private readonly config: RemoteDatabaseConfiguration
  ) {}

  public readonly pouch: PouchDB.Database = new PouchDB(
    `${this.config.dbBaseUrl}/${this.name}`,
    {
      fetch: this.config.authoriser.authorisedFetch,
      skip_setup: true,
      adapter: 'http'
    }
  );

  public readonly schema = new this.SchemaClass(this);

  public readonly changes = new DatabaseChangeTracker(this.pouch);
}
