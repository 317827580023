import { createStackNavigator } from '@react-navigation/stack';
import { SemanticColours } from '../../../theme/SemanticColours';
import { ReLoginNavigatorParamList } from './ReLoginNavigatorParamList';
import ClientListScreen from '../screens/client-list/ClientListScreen';
import ReLoginScreen from '../screens/re-login/ReLoginScreen';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import AddClientScreen from '../screens/add-client/AddClientScreen';

const Stack = createStackNavigator<ReLoginNavigatorParamList>();

const rStyles = DynamicStyleSheet.create({
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  }
});

type ReLoginNavigatorProps = {
  defaultClient: {
    id: string;
    name: string;
  };
};

export const ReLoginNavigator = ({
  defaultClient: { id, name }
}: ReLoginNavigatorProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: styles.headerTint.color,
        headerStyle: styles.header
      }}
    >
      <Stack.Screen
        name="re-login"
        component={ReLoginScreen}
        options={{ title: 'Login' }}
        initialParams={{ clientId: id, clientName: name }}
      />

      <Stack.Screen
        name="client-list"
        component={ClientListScreen}
        options={{ title: 'Change client' }}
      />

      <Stack.Screen
        name="add-client"
        component={AddClientScreen}
        options={{ title: 'Add client' }}
      />
    </Stack.Navigator>
  );
};
