import { ViewProps } from 'react-native';
import { useProductDataContext } from '../../data/ProductDataContext';
import BasicCell from '../../../../components/cells/BasicCell';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import EditIcon from '../../../../components/icons/EditIcon';
import RenameProductModal from '../modals/RenameProductModal';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';

export type ProductNameCellProps = ViewProps;

const ProductNameCell = (props: ProductNameCellProps): JSX.Element => {
  const { productId, product, loading } = useProductDataContext();

  const { authorised } = useAuthorisation(RoleId.superUser);

  const { present, ...modal } = useModalControl();

  return (
    <>
      <BasicCell
        {...props}
        title={product?.productName || (loading ? ' ' : 'No name')}
        {...(authorised
          ? {
              onPress: () => present(),
              iconRight: EditIcon
            }
          : {})}
      />
      <RenameProductModal
        productId={productId}
        initialName={product?.productName ?? 'No name'}
        {...modal}
      />
    </>
  );
};

export default ProductNameCell;
