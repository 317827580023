import { RoleId } from '../../data-model/schema/databases/client-security/documents/Role';

const roleOrder: Record<RoleId, number> = {
  [RoleId.admin]: 1,
  [RoleId.superUser]: 2,
  [RoleId.staff]: 3
};

export const roleIsAtLeast = (role: RoleId, minRole: RoleId): boolean => {
  return roleOrder[role] <= roleOrder[minRole];
};
