import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { SecurityDatabaseSchema } from './SecurityDatabaseSchema';

export class SecurityDatabase extends DatabasePair<SecurityDatabaseSchema> {
  constructor(
    clientId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(
      `sp-${clientId}-security`,
      SecurityDatabaseSchema,
      queryConfig,
      syncConfig
    );
  }
}
