import { ViewProps } from 'react-device-detect';
import { Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { enableStockTracking } from '../../commands/enableStockTracking';
import { useVariantDataContext } from '../../data/VariantDataContext';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import BasicCell from '../../../../components/cells/BasicCell';
import TickIcon from '../../../../components/icons/TickIcon';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import { TextField } from '../../../../components/text-field';

export type EnableStockTrackingModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  },
  fieldContainer: {
    marginVertical: 24
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

type FormValues =
  | {
      mode: 'UNTRACKED';
    }
  | {
      mode: 'TRACKED';
      openingBalance: string;
    };

const validate = (values: FormValues): FormikErrors<FormValues> => {
  if (values.mode === 'UNTRACKED') return {};

  const asNum = +values.openingBalance.trim();

  if (Number.isNaN(asNum)) {
    return { openingBalance: 'Must be a number' };
  }

  if (asNum < 0) {
    return { openingBalance: 'Must be a positive number, or zero' };
  }

  if (asNum % 1 !== 0) {
    return { openingBalance: 'Must be a whole number' };
  }

  return {};
};

const EnableStockTrackingModal = ({
  style,
  dismiss,
  visible,
  ...rest
}: EnableStockTrackingModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const [commandError, setCommandError] = useState<string | null>(null);

  const { variantId, productId } = useVariantDataContext();

  const { invoke, inProgress, reset } = useCommand(
    enableStockTracking,
    result => {
      if (!(result instanceof Error)) {
        if (result.type === 'UNAUTHORISED') {
          setCommandError('You are not authorised to make this change.');
          return;
        }

        if (result.type === 'SUCCESS') {
          dismiss();
          return;
        }
      }

      setCommandError('An unexpected error occurred.');
    }
  );

  useEffect(() => {
    if (!visible) {
      reset();
      setCommandError(null);
    }
  }, [visible, reset]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (values.mode === 'UNTRACKED') return;

      setCommandError(null);
      invoke({
        openingBalance: +values.openingBalance.trim(),
        variantId,
        productId
      });
    },
    [invoke, variantId, productId]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView
        style={[styles.container, style as ViewStyle]}
        {...rest}
      >
        <Formik
          initialValues={{ mode: 'UNTRACKED' }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title="Edit stock tracking mode"
                onCross={dismiss}
                onTick={handleSubmit}
                tickEnabled={!inProgress && formProps.values.mode === 'TRACKED'}
              />

              <BasicCell
                title="Untracked"
                iconRight={
                  formProps.values.mode === 'UNTRACKED' ? TickIcon : undefined
                }
                onPress={
                  formProps.values.mode === 'TRACKED'
                    ? () => formProps.setFieldValue('mode', 'UNTRACKED')
                    : undefined
                }
              />

              <ListItemSeparator />

              <BasicCell
                title="Tracked"
                iconRight={
                  formProps.values.mode === 'TRACKED' ? TickIcon : undefined
                }
                onPress={
                  formProps.values.mode === 'UNTRACKED'
                    ? () => formProps.setFieldValue('mode', 'TRACKED')
                    : undefined
                }
              />

              {formProps.values.mode === 'TRACKED' && (
                <TextField
                  {...formProps}
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoFocus
                  clearButtonMode="while-editing"
                  containerComponent={props => (
                    <SafeAreaView
                      mode="padding"
                      edges={['left', 'right']}
                      {...props}
                    />
                  )}
                  enablesReturnKeyAutomatically
                  editable={!inProgress}
                  inputStyle={styles.field}
                  keyboardType="number-pad"
                  name={'openingBalance'}
                  onSubmitEditing={() => handleSubmit()}
                  placeholder="Opening balance"
                  returnKeyType="go"
                  style={styles.fieldContainer}
                />
              )}

              {commandError && (
                <ErrorMessage style={styles.error}>{commandError}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default EnableStockTrackingModal;
