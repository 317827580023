import { FallbackProps } from 'react-error-boundary';
import { ProblemIcon } from './ProblemIcon';
import { UnhappyPathBaseContent } from '../UnhappyPathContentBase';

export type ProblemContentProps = FallbackProps;

export const ProblemContent = ({
  resetErrorBoundary
}: ProblemContentProps): JSX.Element => (
  <UnhappyPathBaseContent
    icon={ProblemIcon}
    title="We've encountered a problem"
    hint="Please try again. If the problem persists please contact support"
    onAction={resetErrorBoundary}
  />
);
