import { useMemo } from 'react';
import { ProductListSectionData } from '../../data/useProductListData';

export const useSearchResults = (
  listData: ProductListSectionData[] | undefined,
  criteria: string
): ProductListSectionData[] | null => {
  return useMemo(() => {
    const terms = criteria
      ? criteria?.toLocaleLowerCase().trim().split(' ')
      : undefined;

    if (!listData || !terms || terms.none()) {
      return null;
    }

    return listData.compactMap(({ department, data: products }) => {
      const searchableDepartmentContent = department.name?.toLocaleLowerCase();

      const includeWholeDept = terms.every(t =>
        searchableDepartmentContent?.includes(t)
      );

      if (includeWholeDept) {
        return {
          department,
          data: products
        };
      }

      const matchingProducts = products.filter(p => {
        const searchableProductContent = p.name?.toLocaleLowerCase();
        return terms.every(t => searchableProductContent.includes(t));
      });

      if (matchingProducts.none()) {
        return null;
      }

      return {
        department,
        data: matchingProducts
      };
    });
  }, [listData, criteria]);
};
