import { Platform } from 'react-native';
import { Subject } from '../../../lib/pub-sub/Subject';
import { Subscribable } from '../../../lib/pub-sub/Subscribable';
import { isRemoteDb } from '../../helpers/isRemoteDb';
import { RateLimiter } from '../../tools/RateLimiter';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { Mapped } from '../../../lib/pub-sub/Mapped';
import { SyncDisposable } from '../../../lib/disposable/SyncDisposable';

type DocumentId = string;

export const AllDocumentsValue = '*';

type AllDocuments = typeof AllDocumentsValue;

type Change = DocumentId[] | AllDocuments;

export class DatabaseChangeTracker implements Subscribable<Change> {
  constructor(private readonly pouch: PouchDB.Database) {
    this.subject = new Subject<Change>({
      onDidSubscribe: this.onDidSubscribe.bind(this),
      onDidUnsubscribe: this.onDidUnsubscribe.bind(this)
    });
  }
  subscribe(
    ...args: Parameters<Subscribable<Change>['subscribe']>
  ): SyncDisposable {
    return this.subject.subscribe(...args);
  }
  current: undefined;

  private readonly logger = createLogger(nameof({ DatabaseChangeTracker }), {
    db: this.pouch.name
  });

  private onDidSubscribe(): void {
    if (this.subject?.subscriberCount === 1) {
      this.cycleChangesFeed();
      this.logger.debug('Started');
    }
  }

  private onDidUnsubscribe(): void {
    if (this.subject?.subscriberCount === 0) {
      this.changesFeed?.cancel();
      this.limiter.flush();
      this.logger.debug('Stopped');
    }
  }

  private limiter = new RateLimiter<DocumentId | AllDocuments>(250, ids => {
    if (ids.includes('*')) {
      return this.subject.next('*');
    }

    return this.subject.next(ids);
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  private changesFeed: PouchDB.Core.Changes<{}> | undefined;

  private cycleChangesFeed() {
    this.changesFeed?.cancel();

    this.changesFeed = this.pouch
      .changes({
        since: 'now',
        live: true,

        // DEVELOPMENT OPTIMISATION
        //
        // webpack dev server is http 1.1, and chrome will only allow six
        // concurrent connections over this protocol. This means that changes
        // feeds can inadvertently block sync requests.
        //
        // this dirty hack kills change feeds every 5sec so sync requests
        // only have to wait that long (at maximum)
        ...(__DEV__ && Platform.OS === 'web' && isRemoteDb(this.pouch)
          ? {
              heartbeat: false,
              timeout: 5000
            }
          : {})
      })
      .on('change', changes => {
        this.limiter.next(changes.id);
      })
      .on('error', (error: unknown) => {
        this.logger.warn('Error in changes feed', { error });

        if (error instanceof Error) {
          if (error.message === 'Network request failed') {
            this.cycleChangesFeed();
            this.limiter.next(AllDocumentsValue);
            return;
          }

          setTimeout(() => {
            this.cycleChangesFeed();
            this.limiter.next(AllDocumentsValue);
          }, 1000);
        }
      });
  }

  private readonly subject: Subject<Change>;

  public map<U>(fn: (v: Change) => U): Subscribable<U> {
    return new Mapped(this, fn);
  }
}
