import { Text, View, ViewProps } from 'react-native';
import RestaurantTableIcon from '../../../../components/icons/RestaurantTableIcon';
import { SemanticColours } from '../../../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';

const rStyles = DynamicStyleSheet.create({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  icon: {
    width: 18,
    height: 18,
    color: SemanticColours.primary.foreground[40]
  },
  text: {
    color: SemanticColours.primary.foreground[80],
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: 26,
    marginLeft: 2
  }
});

export type TableNumberProps = Omit<ViewProps, 'children'> & {
  tableNumber: string;
};

const TableNumber = ({
  style,
  tableNumber,
  ...rest
}: TableNumberProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.root, style]} {...rest}>
      <RestaurantTableIcon
        width={styles.icon.width}
        height={styles.icon.height}
        fill={styles.icon.color}
      />
      <Text style={styles.text}>{tableNumber}</Text>
    </View>
  );
};

export default TableNumber;
