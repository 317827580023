import { ViewProps } from 'react-native';
import { useCallback, useEffect, useMemo } from 'react';
import BasicCell from '../../../../components/cells/BasicCell';
import { useVariantDataContext } from '../../data/VariantDataContext';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import SelectIcon from '../../../../components/icons/SelectIcon';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import EnableStockTrackingModal from '../modals/EnableStockTrackingModal';
import DisableStockTrackingModal from '../modals/DisableStockTrackingModal';

export type StockModeCellProps = Omit<ViewProps, 'children'>;

const StockModeCell = (props: StockModeCellProps): JSX.Element => {
  const data = useVariantDataContext();

  const { authorised } = useAuthorisation(RoleId.superUser);

  const mode = useMemo(() => {
    if (!data.variant) return undefined;

    if (data.variant.stock?.mode === 'TRACKED') {
      return 'Tracked';
    }

    return 'Untracked';
  }, [data.variant]);

  const { present: presentEnable, ...enableModal } = useModalControl();
  const { present: presentDisable, ...disableModal } = useModalControl();

  useEffect(() => {
    enableModal.dismiss();
    disableModal.dismiss();
  }, [mode]);

  const onPress = useCallback(() => {
    if (mode === 'Tracked') {
      presentDisable();
    } else {
      presentEnable();
    }
  }, [mode, presentEnable, presentDisable]);

  return (
    <>
      <BasicCell
        title="Mode"
        subtitle={mode}
        disabled={!mode}
        titlesOrientation="horizontal"
        {...(authorised ? { iconRight: SelectIcon, onPress } : {})}
        {...props}
      />

      {mode === 'Untracked' && <EnableStockTrackingModal {...enableModal} />}
      {mode === 'Tracked' && <DisableStockTrackingModal {...disableModal} />}
    </>
  );
};

export default StockModeCell;
