import { useCallback } from 'react';
import { View } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { ReLoginNavigatorParamList } from '../../navigation/ReLoginNavigatorParamList';
import EnterClientForm from '../../../../security/components/enter-client-form/EnterClientForm';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type AddClientScreenProps = StackScreenProps<
  ReLoginNavigatorParamList,
  'add-client'
>;

const rStyles = DynamicStyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[100]
  },
  container: {
    flex: 1,
    marginVertical: 70,
    marginHorizontal: 35
  }
}).override(Selectors.mediumOrLarge, {
  container: {
    alignSelf: 'center',
    width: 445
  }
});

const AddClientScreen = ({ navigation }: AddClientScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const onSuccess = useCallback(
    (client: { id: string; name: string }) => {
      navigation.push('re-login', {
        clientId: client.id,
        clientName: client.name
      });
    },
    [navigation]
  );

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <EnterClientForm onSuccess={onSuccess} />
      </View>
    </View>
  );
};

export default AddClientScreen;
