import React from 'react';
import { View, ViewProps, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../../../theme/SemanticColours';
import ActionButtonBar from '../../../../components/buttons/ActionButtonBar';
import ActionButton from '../../../../components/buttons/ActionButton';
import { IconButton } from '../../../../components/buttons/IconButton';
import { CloseIcon } from '../../../../components/icons/CloseIcon';
import LogInIcon from '../../../../components/icons/LogInIcon';

export type ExpiredSessionCellProps = Omit<ViewProps, 'children'> & {
  clientName: string;
  onLogInPress: () => void;
  onRemovePress: () => void;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    color: SemanticColours.secondary.foreground[60],
    marginRight: 8
  },
  clientName: {
    color: SemanticColours.secondary.foreground[90],
    flexGrow: 1,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 26
  }
}).override(Selectors.mediumOrLarge, {
  root: {
    paddingVertical: 16,
    paddingHorizontal: 28
  },
  clientName: {
    fontSize: 17
  }
});

const ExpiredSessionCell = React.memo<ExpiredSessionCellProps>(
  ({ style, clientName, onRemovePress, onLogInPress, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style]} {...rest}>
        <IconButton
          iconComponent={CloseIcon}
          onPress={onRemovePress}
          iconSize={16}
          iconColour={styles.icon.color}
          style={styles.icon}
        />

        <Text style={styles.clientName}>{clientName}</Text>

        <ActionButtonBar>
          <ActionButton
            colourScheme="secondary"
            label="Log in"
            iconComponent={LogInIcon}
            onPress={() => onLogInPress()}
          />
        </ActionButtonBar>
      </View>
    );
  }
);

export default ExpiredSessionCell;
