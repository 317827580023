import React, { useCallback, useMemo } from 'react';
import { ScrollView, View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { PersonalSecurityContext } from '../../../../security/personal/PersonalSecurityContext';
import { nameof } from '../../../../lib/name-of';
import ActiveClientCell from './ActiveClientCell';
import ModalHeader from '../../../../components/modal-header/ModalHeader';
import { IconButton } from '../../../../components/buttons/IconButton';
import { CloseIcon } from '../../../../components/icons/CloseIcon';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import LoginModal from './LoginModal';
import AddIcon from '../../../../components/icons/AddIcon';
import { Text } from '../../../../components/text/Text';
import SessionCell from './SessionCell';
import ExpiredSessionCell from './ExpiredSessionCell';
import SeparatedChildren from '../../../../components/separated-children/DividedChildren';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import { Button } from '../../../../components/buttons/Button';
import { useUsopApiClient } from '../../../../api/useUsopApiClient';
import { useLogger } from '../../../../lib/logging/useLogger';

export type ClientSwitchModalProps = Omit<ViewProps, 'children'> & {
  dismiss: () => void;
  visible: boolean;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: SemanticColours.secondary.background[70]
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  },
  sectionTitle: {
    color: SemanticColours.secondary.foreground[60],
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 24
  },
  addButton: {
    color: SemanticColours.secondary.foreground[100]
  }
});

const Separator = (): JSX.Element => <ListItemSeparator contrastColourScheme />;

const ClientSwitchModal = React.memo<ClientSwitchModalProps>(
  ({ style, dismiss, visible, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const security = useContextOrThrow(
      PersonalSecurityContext,
      nameof({ PersonalSecurityContext })
    );

    const activeClient = useMemo(() => {
      const client = security.clients.find(
        c => c.id === security.activeClientId
      );

      return client ?? null;
    }, [security]);

    const apiClient = useUsopApiClient();

    const log = useLogger(nameof({ ClientSwitchModal }));

    const onLogOut = useCallback(() => {
      if (!activeClient) return;

      security.removeClient(activeClient.id);

      if (!('token' in activeClient.user)) return;

      void apiClient
        .deleteSession({ authorization: `Bearer ${activeClient.user.token}` })
        .catch((error: unknown) => {
          log.error('Failed to delete session', { error });
        });
    }, [security, activeClient]);

    const { present, ...modal } = useModalControl();

    const otherSessions = useMemo(() => {
      return security.clients.filter(
        c => c.id !== security.activeClientId && 'userSessionId' in c.user
      );
    }, [security]);

    const expiredSessions = useMemo(() => {
      return security.clients.filter(c => !('userSessionId' in c.user));
    }, [security]);

    return (
      <PlatformModal visible={visible} onRequestClose={dismiss} {...rest}>
        <View style={[styles.root, style]}>
          <ModalHeader>
            <IconButton
              iconComponent={CloseIcon}
              iconSize={22}
              onPress={() => dismiss()}
              iconColour={styles.headerTint.color}
            />
            <Button
              iconComponent={AddIcon}
              iconSize={28}
              onPress={() => present()}
              iconColour={styles.headerTint.color}
              title="Add another client"
              titleStyle={styles.addButton}
            />

            <LoginModal {...modal} />
          </ModalHeader>
          <ScrollView>
            <ActiveClientCell
              clientName={activeClient?.name ?? ''}
              userFullName={activeClient?.user.fullName ?? ''}
              username={activeClient?.user.username ?? ''}
              onLogOutPress={onLogOut}
            />

            {otherSessions.any() && (
              <>
                <Text.SectionTitle style={styles.sectionTitle}>
                  Other clients
                </Text.SectionTitle>

                <SeparatedChildren separatorComponent={Separator}>
                  {otherSessions.map(o => (
                    <SessionCell
                      key={o.id}
                      clientName={o.name}
                      onSwitchPress={() =>
                        security.switchToExistingClient(o.id)
                      }
                    />
                  ))}
                </SeparatedChildren>
              </>
            )}

            {expiredSessions.any() && (
              <>
                <Text.SectionTitle style={styles.sectionTitle}>
                  Expired sessions
                </Text.SectionTitle>

                <SeparatedChildren separatorComponent={Separator}>
                  {expiredSessions.map(o => (
                    <ExpiredSessionCell
                      key={o.id}
                      clientName={o.name}
                      onLogInPress={() => security.switchToExistingClient(o.id)}
                      onRemovePress={() => security.removeClient(o.id)}
                    />
                  ))}
                </SeparatedChildren>
              </>
            )}
          </ScrollView>
        </View>
      </PlatformModal>
    );
  }
);

export default ClientSwitchModal;
