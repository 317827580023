/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import chalk, { Chalk } from 'chalk';
import { LogLevel } from './LogLevel';
import { LogSink } from './Sink';

const ctx = new chalk.Instance({
  level: __DEV__ ? 3 : 0
});

const colourForLogLevel: Record<LogLevel, Chalk> = {
  [LogLevel.debug]: ctx.blue,
  [LogLevel.info]: ctx.white,
  [LogLevel.warn]: ctx.yellow,
  [LogLevel.error]: ctx.red
};

export class WebSink implements LogSink {
  next(
    level: LogLevel,
    component: string,
    message: string,
    additionalFields?: Record<string, unknown>
  ): void {
    const mainOutput = `${component} : ${message}`;

    const colour = colourForLogLevel[level];

    console[level](colour(mainOutput), additionalFields);
  }
}
