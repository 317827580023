import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import {
  StockTransaction,
  StockTransactionDeltaSign
} from '../../documents/StockTransaction';

export type RunningBalanceViewValue = {
  [key in StockTransactionDeltaSign]?: { wholes: number };
};

export class RunningBalanceView extends DatabaseView<
  string,
  RunningBalanceViewValue,
  StockTransaction
> {
  static readonly viewName = 'main';

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, RunningBalanceView.viewName);
  }
}
