import { ProductV1 } from './ProductV1';

export type ProductV1CompatVariant = ProductV1 & {
  $schema: 'Product';
  $schemaVersion: '1';
  variantId: string;
  variantOfProductId: string;
};

export const isProductV1CompatVariant = (
  value: unknown
): value is ProductV1CompatVariant => {
  if (
    !(typeof value === 'object') ||
    !value ||
    !('$schema' in value) ||
    !('$schemaVersion' in value)
  ) {
    return false;
  }

  if (value.$schema !== 'Product' || value.$schemaVersion !== '1') {
    return false;
  }

  return true;
};
