import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Fragment, useEffect } from 'react';
import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../../theme/SemanticColours';
import { PrepNavigatorParamList } from '../../../navigation/PrepNavigatorParamList';
import { GlobalActivityIndicator } from '../../../../../lib/global-activity/GlobalActivityIndicator';
import { HeaderNavigation } from '../../../../../components/header-navigation/HeaderNavigation';
import ZoneSwitcher from '../../../components/ZoneSwitcher';
import { useSizeClass } from '../../../../../lib/responsive-size-class';
import CompletedVisibilityTab from './CompletedVisibilityTab';
import { Text } from '../../../../../components/text/Text';
import Alerts from './Alerts';
import { ErrorBoundary } from '../../../../../components/error-boundary/ErrorBoundary';

const rStyles = DynamicStyleSheet.create({
  header: {
    backgroundColor: SemanticColours.secondary.background[100],
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0
    // paddingRight: 0
  },
  headerTitle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerTint: {
    color: SemanticColours.secondary.foreground[100]
  },
  rightItems: {
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-end'
  }
});

const Header = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  const navigation =
    useNavigation<
      StackNavigationProp<PrepNavigatorParamList, keyof PrepNavigatorParamList>
    >();

  useEffect(() => {
    navigation.setOptions({
      headerStyle: styles.header,

      headerTitle: props => (
        <ErrorBoundary FallbackComponent={Fragment}>
          <View style={styles.headerTitle}>
            <Text.HeaderSubtitle style={{ color: props.tintColor }}>
              Prep
            </Text.HeaderSubtitle>
            <ZoneSwitcher {...props} />
          </View>
        </ErrorBoundary>
      ),

      headerRightContainerStyle: styles.rightItems,

      headerRight: () => (
        <ErrorBoundary FallbackComponent={Fragment}>
          <GlobalActivityIndicator color={styles.headerTint.color} />

          <Alerts iconColour={styles.headerTint.color} />

          {size !== 'small' && <CompletedVisibilityTab />}
        </ErrorBoundary>
      )
    });
  }, [navigation, styles, size]);

  useEffect(() => {
    navigation.setOptions({
      headerLeft: headerProps => <HeaderNavigation {...headerProps} />
    });
  }, [navigation]);

  return <></>;
};

export default Header;
