import React, { ReactNode } from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { IconButton } from '../../../../components/buttons/IconButton';
import HorizontalMenuIcon from '../../../../components/icons/HorizontalMenuIcon';
import { SemanticColours } from '../../../../theme/SemanticColours';

export type CardFooterProps = Omit<ViewProps, 'children'> & {
  leftButton?: ReactNode;
  rightButton?: ReactNode;
  onMorePress?: () => void;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: -2,
    marginTop: 6,
    marginBottom: 8
  },
  left: {
    position: 'absolute',
    left: 20
  },
  right: {
    position: 'absolute',
    right: 20
  },
  moreIcon: {
    width: 20,
    color: SemanticColours.primary.foreground[60]
  }
});

const CardFooter = React.memo<CardFooterProps>(
  ({ style, leftButton, rightButton, onMorePress, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.root, style]} {...rest}>
        {leftButton && <View style={[styles.left]}>{leftButton}</View>}

        {onMorePress && (
          <IconButton
            iconComponent={HorizontalMenuIcon}
            onPress={onMorePress}
            iconSize={styles.moreIcon.width}
            iconColour={styles.moreIcon.color}
          />
        )}

        {rightButton && <View style={[styles.right]}>{rightButton}</View>}
      </View>
    );
  }
);

export default CardFooter;
