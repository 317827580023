import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';

export type TimelineSpotProps = Omit<ViewProps, 'children'> & {
  completed?: boolean;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    borderRadius: 5,
    width: 10,
    height: 10
  },
  notCompleted: {
    borderWidth: 1,
    borderColor: SemanticColours.primary.foreground[30]
  },
  completed: {
    backgroundColor: SemanticColours.primary.foreground[80]
  }
});

const TimelineSpot = React.memo<TimelineSpotProps>(
  ({ style, completed, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View
        style={[
          styles.root,
          style,
          completed ? styles.completed : styles.notCompleted
        ]}
        {...rest}
      />
    );
  }
);

export default TimelineSpot;
