import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const ServeplusLogoIcon = (props: SvgProps) => (
  <Svg {...props} width={37} height={23} fill="none">
    <G clipPath="url(#a)">
      <Path fill="#FF7070" d="M0 0h10.895v10.399H0z" />
      <Path fill="#159500" d="M26.105 0H37v10.399H26.105z" />
      <Path fill="#7E7BFF" d="M13.053 0h10.895v10.399H13.053z" />
      <Path fill="#D2CB23" d="M0 12.601h10.895V23H0z" />
      <Path fill="#007676" d="M26.105 12.601H37V23H26.105z" />
      <Path fill="#D764FF" d="M13.053 12.601h10.895V23H13.053z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h37v23H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ServeplusLogoIcon;
