import { SyncDisposable } from '../../../lib/disposable/SyncDisposable';
import { createLogger } from '../../../lib/logging';
import { nameof } from '../../../lib/name-of';
import { Subject } from '../../../lib/pub-sub/Subject';
import thenThrow from '../../../lib/then-throw';
import { Document, DocumentProperties } from '../../types/Document';
import { Database } from './Database';
import { AllDocumentsValue } from './DatabaseChangeTracker';

type UpdateFunction<
  T extends DocumentProperties,
  R = undefined,
  U extends T = T
> = (
  oldProperties: T,
  update: (newProperties: U) => void,
  abort: (reason: R) => void
) => void;

export type UpdateResult<T> =
  | {
      type: 'SKIPPED';
    }
  | {
      type: 'SUCCESS';
      data: T;
    }
  | {
      type: 'NOT_FOUND';
    };

export type CreateResult<T> =
  | {
      type: 'SUCCESS';
      data: T;
    }
  | {
      type: 'CONFLICT';
    };

export class Repository<T extends DocumentProperties = DocumentProperties> {
  constructor(
    public readonly database: Database,
    protected readonly idFactory: () => string
  ) {}

  private readonly logger = createLogger(nameof({ Repository }), {
    database: this.database.pouch.name
  });

  nextId() {
    return this.idFactory();
  }

  async getDocument(id: string): Promise<Document<T> | null> {
    try {
      const doc = await this.database.pouch.get(id);

      this.logger.info('Got document', { id });

      return doc as Document<T>;
    } catch (e: unknown) {
      if (e instanceof Error && e.name === 'not_found') {
        this.logger.info('Document not found', { id });

        return null;
      }

      throw e;
    }
  }

  async getDocuments(
    ids: string[]
  ): Promise<Record<string, Document<T> | null>> {
    this.logger.debug('Get documents', { ids });

    const { results } = await this.database.pouch.bulkGet({
      docs: ids.map(id => ({ id }))
    });

    const asDict = Object.fromEntries(
      results.map(({ docs, id }) => {
        const docResult =
          docs.first() ??
          thenThrow('Unexpected empty docs array in bulk get response');

        if ('error' in docResult && docResult.error.error === 'not_found') {
          return [id, null] as const;
        }

        if ('ok' in docResult) {
          return [id, docResult.ok as Document<T>];
        }

        throw new Error(
          `Unexpected bulk get document result. ${JSON.stringify(docResult)}`
        );
      })
    );

    this.logger.info('Got documents', { docs: asDict });

    return asDict;
  }

  getDocumentStream(
    id: string,
    onNext: (value: Document<T> | null) => void,
    onError: (error: Error) => void = this.logger.error
  ): SyncDisposable {
    let ended = false;

    const subject = new Subject<Document<T> | null | Error | 'DO_FETCH'>();

    const changeSubscription = this.database.changes.subscribe({
      next: ids => {
        if (ended) return;

        if (ids === AllDocumentsValue || ids.includes(id)) {
          subject.next('DO_FETCH');
        }
      }
    });

    // fetch handler
    subject.subscribe({
      next: v => {
        if (v !== 'DO_FETCH' || ended) return;

        this.getDocument(id)
          .then(results => {
            if (!ended) subject.next(results);
          })
          .catch((error: unknown) => {
            if (error instanceof Error && !ended) {
              subject.next(error);
            }
          });
      }
    });

    // error handler
    subject.subscribe({
      next: v => {
        if (!(v instanceof Error) || ended) return;

        onError(v);

        setTimeout(() => {
          if (ended) return;

          subject.next('DO_FETCH');
        }, 1000);
      }
    });

    // new data handler
    subject.subscribe({
      next: v => {
        if (ended || typeof v !== 'object' || v instanceof Error) return;

        onNext(v);
      }
    });

    subject.next('DO_FETCH');

    return {
      dispose: () => {
        ended = true;
        changeSubscription.dispose();
      }
    };
  }

  async createDocument<U extends T = T>(properties: U): Promise<Document<U>>;
  async createDocument<U extends T = T>(
    id: string,
    properties: U
  ): Promise<CreateResult<Document<U>>>;
  async createDocument<U extends T = T>(
    idOrProps: string | U,
    maybeProps?: U
  ): Promise<Document<U> | CreateResult<Document<U>>> {
    const [id, properties] =
      typeof idOrProps === 'string'
        ? [idOrProps, maybeProps as U]
        : [this.idFactory(), idOrProps];

    this.logger.debug('Creating document', { id, properties });

    try {
      const { rev } = await this.database.pouch.put({
        ...properties,
        _id: id
      });

      const data = {
        ...properties,
        _id: id,
        _rev: rev
      };

      this.logger.info('Document created', { data });

      if (typeof idOrProps === 'string') {
        return {
          type: 'SUCCESS',
          data
        };
      }

      return data;
    } catch (error: unknown) {
      if (!(error instanceof Error) || error.name !== 'conflict') {
        this.logger.error('Error creating document', { id, properties, error });

        throw error;
      }

      if (typeof idOrProps === 'string') {
        return {
          type: 'CONFLICT'
        };
      }

      this.logger.error('Improbable conflict', { id });
      throw error;
    }
  }

  private static maxUpdateAttempts = 10;

  private static invokeUpdateFunction<
    T extends DocumentProperties,
    R = undefined,
    U extends T = T
  >(
    existing: Document<T>,
    fn: UpdateFunction<T, R, U>
  ):
    | { type: 'ABORT'; reason: R }
    | { type: 'SKIP' }
    | { type: 'UPDATE'; document: Document<U> } {
    let newProperties: U | undefined;
    const update = (p: U) => {
      newProperties = p;
    };

    let reason: R | undefined;
    const abort = (r: R) => {
      reason = r;
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id, _rev, ...rest } = existing;

    fn(rest as unknown as T, update, abort);

    if (reason) {
      return { type: 'ABORT', reason };
    }

    if (!newProperties) {
      return { type: 'SKIP' };
    }

    return { type: 'UPDATE', document: { ...newProperties, _id, _rev } };
  }

  async updateDocument<R = never, U extends T = T>(
    id: string,
    fn: (
      oldProperties: T,
      update: (newProperties: U) => unknown,
      abort: (reason: R) => unknown
    ) => unknown
  ): Promise<UpdateResult<U> | { type: 'ABORTED'; reason: R }>;
  async updateDocument<U extends T = T>(
    id: string,
    fn: (oldProperties: T, update: (newProperties: U) => unknown) => unknown
  ): Promise<UpdateResult<U>>;
  async updateDocument<R = never, U extends T = T>(
    id: string,
    fn: (
      oldProperties: T,
      update: (newProperties: U) => void,
      abort: (reason: R) => void
    ) => void
  ): Promise<UpdateResult<U> | { type: 'ABORTED'; reason: R }> {
    this.logger.debug('Update document', { id });

    let attempts = 0;

    while (attempts < Repository.maxUpdateAttempts) {
      const existing = await this.getDocument(id);

      if (!existing) {
        return { type: 'NOT_FOUND' };
      }

      const updateFnResult = Repository.invokeUpdateFunction(existing, fn);

      if (updateFnResult.type === 'ABORT') {
        return { type: 'ABORTED', reason: updateFnResult.reason };
      }

      if (updateFnResult.type === 'SKIP') {
        return { type: 'SKIPPED' };
      }

      try {
        const { rev } = await this.database.pouch.put(updateFnResult.document);

        return {
          type: 'SUCCESS',
          data: {
            ...updateFnResult.document,
            _rev: rev
          }
        };
      } catch (e: unknown) {
        if (!(e instanceof Error) || e.name !== 'conflict') {
          throw e;
        }

        attempts += 1;
      }
    }

    throw new Error(
      `Unsuccessful after ${Repository.maxUpdateAttempts} attempts`
    );
  }
}
