import { ReactNode, createContext } from 'react';
import { useTransform } from '../../../data-model/hooks/useTransform';
import { usePreparationOrdersDb } from '../../../data-model/components/site-context/usePreparationOrdersDb';
import { useQueryStream } from '../../../data-model/hooks/useQueryStream';
import { QueryRow } from '../../../data-model/schema/support/DatabaseView';
import {
  SummaryByStatusViewKey,
  SummaryByStatusViewValue
} from '../../../data-model/schema/databases/site-preparation-orders/design/summary-by-status/SummaryByStatusView';
import { PreparationOrder } from '../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import { useContextOrThrow } from '../../../lib/use-context-or-throw';
import { nameof } from '../../../lib/name-of';

export type TasksDataContextType = {
  loading: boolean;
  data: QueryRow<
    SummaryByStatusViewKey,
    SummaryByStatusViewValue,
    object,
    PreparationOrder
  >[];
};

export const TasksDataContext = createContext<TasksDataContextType | null>(
  null
);

export interface TasksDataContextProviderProps {
  children?: ReactNode;
  zoneId: string;
}

export const TasksDataContextProvider = ({
  children,
  zoneId
}: TasksDataContextProviderProps): JSX.Element => {
  const db = usePreparationOrdersDb();

  const value = useTransform(
    [
      useQueryStream(db.local.design.summaryByStatus.views.main, {
        startKey: [zoneId, '01 Pending'],
        endKey: [zoneId, '04 Ready', []]
      }),
      useQueryStream(db.local.design.summaryByStatus.views.main, {
        endKey: [zoneId, '05 Collected'],
        startKey: [zoneId, '05 Collected', []],
        descending: true,
        limit: 5
      }),
      useQueryStream(db.remote.design.summaryByStatus.views.main, {
        endKey: [zoneId, '05 Collected'],
        startKey: [zoneId, '05 Collected', []],
        descending: true,
        limit: 50
      })
    ],
    results => {
      /**
       * DRAGONS
       *
       * 1. Local collected items are only synced up, so any collected items
       *    that exist locally _might_ be really old. [USOP-104]
       *
       * 2. Sometimes the remote query updates first, in which case there will
       *    be duplication
       */

      const [localPendingToReady, localCollectedRaw, remoteCollected] = results;

      const remoteIds = new Set(remoteCollected.map(r => r.documentID));

      const oldestRemoteTime = remoteCollected.last()?.key[2];

      const pendingToReady = localPendingToReady.filter(
        // exclude tasks that are already synced
        l => !remoteIds.has(l.documentID)
      );

      const collected = (
        localCollectedRaw as readonly QueryRow<
          SummaryByStatusViewKey,
          SummaryByStatusViewValue,
          unknown,
          PreparationOrder
        >[]
      )
        .filter(
          // exclude tasks that are already synced or older than the oldest remote
          // task
          l =>
            !remoteIds.has(l.documentID) &&
            (!oldestRemoteTime || l.key[2] > oldestRemoteTime)
        )
        .concat(
          remoteCollected as readonly QueryRow<
            SummaryByStatusViewKey,
            SummaryByStatusViewValue,
            unknown,
            PreparationOrder
          >[]
        )
        .sortBy(r => r.key[2], 'desc');

      return [...pendingToReady, ...collected];
    },
    () => [],
    []
  );

  return (
    <TasksDataContext.Provider value={value}>
      {children}
    </TasksDataContext.Provider>
  );
};

export const useTasksData = () =>
  useContextOrThrow(TasksDataContext, nameof({ TasksDataContext }));
