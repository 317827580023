import { StackNavigationProp, StackScreenProps } from '@react-navigation/stack';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ProductNavigatorParamList } from '../../navigation/ProductNavigatorParamList';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import {
  VariantDataContextProvider,
  useVariantDataContext
} from '../../data/VariantDataContext';
import VariantDataView from '../../components/variant-data-view/VariantDataView';
import { ProductDataContextProvider } from '../../data/ProductDataContext';
import { HeaderMenuButton } from '../../../../components/header-menu/HeaderMenuButton';
import DeleteVariantMenuOption from '../../components/menu-options/DeleteVariantMenuOption';
import { ProblemContent } from '../../../../components/unhappy-path-content/problem/ProblemContent';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

export type ProductVariantScreenProps = StackScreenProps<
  ProductNavigatorParamList,
  'variant'
>;

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  content: {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingVertical: 6
  }
});

const HeaderTitle = (): JSX.Element => {
  const { variant, loading } = useVariantDataContext();

  const navigation =
    useNavigation<StackNavigationProp<ProductNavigatorParamList, 'variant'>>();

  useEffect(() => {
    if (loading) return;

    navigation.setOptions({
      title: !variant ? '' : variant.name ?? 'No name'
    });
  }, [loading, variant]);

  return <></>;
};

const ProductVariantScreen = ({
  navigation,
  route
}: ProductVariantScreenProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const isFocussed = useIsFocused();

  useEffect(() => {
    navigation.setOptions({
      headerRight: headerProps => <HeaderMenuButton {...headerProps} />
    });
  }, [navigation]);

  const onVariantDeleted = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  if (!route.params?.variantId) {
    throw new Error('variantId route param required');
  }

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <ErrorBoundary FallbackComponent={ProblemContent}>
      <ProductDataContextProvider productId={route.params?.productId}>
        {isFocussed && authorised && (
          <DeleteVariantMenuOption
            variantId={route.params?.variantId}
            onVariantDeleted={onVariantDeleted}
          />
        )}

        <VariantDataContextProvider variantId={route.params?.variantId}>
          <PlatformScrollView
            style={styles.container}
            contentContainerStyle={styles.content}
            keyboardShouldPersistTaps="always"
          >
            <HeaderTitle />
            <VariantDataView />
          </PlatformScrollView>
        </VariantDataContextProvider>
      </ProductDataContextProvider>
    </ErrorBoundary>
  );
};

export default ProductVariantScreen;
