import { ScrollViewProps, SectionList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useMemo } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import useSet from '../../../../../../lib/use-set';
import { useProductsListData } from './useProductsListData';
import EmptyList from '../../../../components/empty-list/EmptyList';
import BasicCell from '../../../../../../components/cells/BasicCell';
import CollapseIcon from '../../../../../../components/icons/CollapseIcon';
import ExpandIcon from '../../../../../../components/icons/ExpandIcon';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import CellGroupSeparator from '../../../../../../components/cells/CellGroupSeparator';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import Cell from './Cell';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type ProductsListProps = Omit<ScrollViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.primary.background[90]
  },
  content: {
    marginVertical: 14
  },
  header: {
    paddingRight: 32
  },
  subtitle: {
    flexShrink: 0
  },
  departmentTotal: {
    color: SemanticColours.primary.foreground[80]
  }
}).override(Selectors.mediumOrLarge, {
  content: {
    marginVertical: 24
  }
});

const ProductsList = ({ style, ...rest }: ProductsListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<
      StackNavigationProp<DashboardNavigatorParamList, 'products'>
    >();

  const partition = useActivePartition();

  const [expandedDepartmentIds, { has, toggle }] = useSet<string | null>();

  const { data, loading } = useProductsListData(partition);

  const dataWithSectionsExpanded = useMemo(() => {
    return data.map(d => ({
      ...d,
      data: has(d.id) ? d.data : []
    }));
  }, [data, expandedDepartmentIds]);

  const { cell, header, Separator } = useCellGroupStyles('left');

  return (
    <SectionList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      contentContainerStyle={styles.content}
      ListEmptyComponent={() => <EmptyList loading={loading} />}
      style={[styles.container, style]}
      sections={dataWithSectionsExpanded}
      keyExtractor={d => d.id}
      initialNumToRender={15}
      ItemSeparatorComponent={Separator}
      renderSectionFooter={() => <CellGroupSeparator style={header} />}
      stickySectionHeadersEnabled={false}
      renderSectionHeader={({ section: { id, name, value } }) => (
        <>
          <BasicCell
            iconLeft={has(id) ? CollapseIcon : ExpandIcon}
            title={name}
            subtitle={value}
            subtitleStyle={[styles.subtitle, styles.departmentTotal]}
            titlesOrientation="horizontal"
            onPress={() => {
              toggle(id);
            }}
            style={[cell(true, !has(id)), styles.header]}
          />
          {has(id) && <Separator />}
        </>
      )}
      renderItem={({ index, item: { id, name, units, value }, section }) => (
        <Cell
          name={name}
          value={value}
          quantity={units}
          style={cell(false, index === section.data.length - 1)}
          onPress={() => {
            navigation.push('product', {
              productId: id,
              partition: PartitionSerialiser.stringify(partition)
            });
          }}
        />
      )}
      {...rest}
    />
  );
};

export default ProductsList;
