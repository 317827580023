import { View, ViewProps } from 'react-native';
import { useCallback, useEffect, useMemo } from 'react';
import { Formik, FormikErrors } from 'formik';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../lib/dynamic-style-sheet';
import { useUsopApi } from '../../../api/useUsopApi';
import { TextField } from '../../../components/text-field';
import ConfirmHeader from '../../../features/onboarding/components/ConfirmHeader';

export type EnterClientFormProps = Omit<ViewProps, 'children'> & {
  onSuccess: (data: { id: string; name: string }) => void;
};

const rStyles = DynamicStyleSheet.create({ container: {} });

type FormValues = {
  clientId: string;
};

const EnterClientForm = ({
  style,
  onSuccess,
  ...rest
}: EnterClientFormProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { inProgress, result, call } = useUsopApi('getClientById', {
    cacheStrategy: 'NO_CACHE'
  });

  const validate = useCallback(
    ({ clientId }: FormValues): FormikErrors<FormValues> => {
      if (!clientId.trim()) {
        return { clientId: 'Required' };
      }

      // from OpenAPI
      if (!/^[a-z][a-z0-9_$()+-]{1,250}$/.test(clientId.trim().toLowerCase())) {
        return {
          clientId: 'Enter a valid clientId'
        };
      }

      return {};
    },
    []
  );

  const onSubmit = useCallback(
    ({ clientId }: FormValues) => {
      call({ clientId: clientId.trim().toLowerCase() });
    },
    [call]
  );

  const errorMessage = useMemo(() => {
    if (inProgress || !result) {
      return undefined;
    }

    if (result.success && result.data.response.status === 404) {
      return 'Not found. Check and try again';
    }

    if (!result.success) {
      if (typeof result.reason === 'string') {
        return 'Check your connection and try again';
      }

      return 'An unknown error occurred';
    }

    return undefined;
  }, [result, inProgress]);

  useEffect(() => {
    if (result?.success && result.data.response.status === 200) {
      onSuccess(result.data.response.body);
    }
  }, [result]);

  return (
    <View style={[styles.container, style]} {...rest}>
      <Formik
        initialValues={{ clientId: '' }}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, ...formProps }) => (
          <>
            <ConfirmHeader
              disabled={inProgress}
              onPress={() => handleSubmit()}
            />
            <TextField
              {...formProps}
              autoComplete="off"
              autoFocus
              autoCapitalize="none"
              clearButtonMode="while-editing"
              secondaryColourScheme
              editable={!inProgress}
              enablesReturnKeyAutomatically
              keyboardType="default"
              keyboardAppearance="dark"
              name={'clientId'}
              onSubmitEditing={() => handleSubmit()}
              placeholder="client id"
              returnKeyType="go"
              errorMessage={errorMessage}
            />
          </>
        )}
      </Formik>
    </View>
  );
};

export default EnterClientForm;
