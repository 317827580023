import { RoleId } from '../../data-model/schema/databases/client-security/documents/Role';
import { nameof } from '../../lib/name-of';
import { useContextOrThrow } from '../../lib/use-context-or-throw';
import UserContext from '../context/UserContext';
import { roleIsAtLeast } from '../helpers/roleIsAtLeast';

export const useAuthorisation = (minRole: RoleId): { authorised: boolean } => {
  const user = useContextOrThrow(UserContext, nameof({ UserContext }));

  return {
    authorised: roleIsAtLeast(user.role, minRole)
  };
};
