export class HslColour {
  constructor(
    private readonly hue: number,
    private readonly saturation: number,
    private readonly lightness: number,
    private readonly opacity: number = 1
  ) {}

  o(opacity: number): HslColour {
    return new HslColour(this.hue, this.saturation, this.lightness, opacity);
  }

  l(lightness: number): HslColour {
    return new HslColour(this.hue, this.saturation, lightness, this.opacity);
  }

  get $() {
    return `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.opacity})`;
  }
}
