import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { Subject } from '../../../lib/pub-sub/Subject';
import { Subscriber } from '../../../lib/pub-sub/Subscriber';
import { Repository } from '../../schema/support/Repository';
import { isRemoteDb } from '../../helpers/isRemoteDb';
import { GlobalActivity } from '../../../lib/global-activity/GlobalActivity';
import { SyncDisposable } from '../../../lib/disposable/SyncDisposable';

export interface DocumentCacheContextValue {
  subscribe<TRepo extends Repository, TDoc>(
    repo: TRepo,
    documentId: string,
    subscriber: Subscriber<TDoc>
  ): SyncDisposable;
  getCurrentValue<TRepo extends Repository, TDoc>(
    repo: TRepo,
    documentId: string
  ): TDoc | null | undefined;
}

export const DocumentCacheContext =
  createContext<DocumentCacheContextValue | null>(null);

export const DocumentCache = ({
  children
}: {
  children: ReactNode;
}): JSX.Element => {
  const [subjects] = useState<Map<string, Subject<unknown>>>(new Map());

  const getOrCreateSubject = useCallback(
    <TRepo extends Repository>(repo: TRepo, documentId: string) => {
      const key = documentId;

      let subject = subjects.get(key);
      if (!subject) {
        let stream: SyncDisposable;
        let activity: SyncDisposable | undefined;

        subject = new Subject({
          onDidSubscribe() {
            if (subject?.subscriberCount === 1) {
              activity = isRemoteDb(repo.database.pouch)
                ? GlobalActivity.enroll(`Downloading document ${documentId}`)
                : undefined;

              stream = repo.getDocumentStream(documentId, doc => {
                subject?.next(doc);
                activity?.dispose();
              });
            }
          },
          onDidUnsubscribe() {
            if (subject?.subscriberCount === 0) {
              stream.dispose();
              activity?.dispose();
              subjects.delete(key);
            }
          }
        });
        subjects.set(key, subject);
      }
      return subject;
    },
    [subjects]
  );

  const contextValue: DocumentCacheContextValue = useMemo(
    () => ({
      subscribe<TRepo extends Repository, TDoc>(
        repo: TRepo,
        documentId: string,
        subscriber: Subscriber<TDoc>
      ) {
        return getOrCreateSubject(repo, documentId).subscribe(
          subscriber as Subscriber<unknown>
        );
      },
      getCurrentValue<TRepo extends Repository, TDoc>(
        repo: TRepo,
        documentId: string
      ) {
        return getOrCreateSubject(repo, documentId).current as
          | TDoc
          | null
          | undefined;
      }
    }),
    [getOrCreateSubject]
  );

  return (
    <DocumentCacheContext.Provider value={contextValue}>
      {children}
    </DocumentCacheContext.Provider>
  );
};
