import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback, useEffect } from 'react';
import { TabBar, TabBarProps } from '../../../../../components/tabs/TabBar';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../../theme/SemanticColours';
import {
  PrepNavigatorParamList,
  PrepNavigatorTab
} from '../../../navigation/PrepNavigatorParamList';
import { useContrastTabTheme } from '../../../../../components/tabs/useContrastTabTheme';
import { Tab } from '../../../../../components/tabs/Tab';

export type StatusTabBarProps = TabBarProps;

const rStyles = DynamicStyleSheet.create({
  container: {
    backgroundColor: SemanticColours.secondary.background[100]
  },
  tab: {
    flexBasis: 1,
    flexGrow: 1
  }
});

const StatusTabBar = ({
  style,
  children,
  ...rest
}: StatusTabBarProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const navigation =
    useNavigation<StackNavigationProp<PrepNavigatorParamList, 'tasks'>>();

  const route = useRoute<RouteProp<PrepNavigatorParamList, 'tasks'>>();

  const navigateFor = useCallback(
    (tab: PrepNavigatorTab) => {
      navigation.setParams({ tab });
    },
    [navigation]
  );

  useEffect(() => {
    if (!route.params?.tab) {
      navigation.setParams({ tab: 'todo' });
    }
  }, [route]);

  const tabStyles = useContrastTabTheme();

  return (
    <TabBar style={[styles.container, style]} {...rest}>
      <Tab
        style={styles.tab}
        styles={tabStyles}
        title="Todo"
        isActive={route.params?.tab === 'todo'}
        onPress={() => navigateFor('todo')}
      />
      <Tab
        style={styles.tab}
        styles={tabStyles}
        title="In progress"
        isActive={route.params?.tab === 'in-progress'}
        onPress={() => navigateFor('in-progress')}
      />
      <Tab
        style={styles.tab}
        styles={tabStyles}
        title="Completed"
        isActive={route.params?.tab === 'completed'}
        onPress={() => navigateFor('completed')}
      />
      {children}
    </TabBar>
  );
};

export default StatusTabBar;
