// shameless copy paste from react native source
// https://github.com/facebook/react-native/blob/43636267011a97490ed7495b08e500c5d0d54872/Libraries/StyleSheet/flattenStyle.js

export const flattenStyle = <T extends object>(
  style: T[] | T | null
): T | undefined => {
  if (style === null || typeof style !== 'object') {
    return undefined;
  }

  if (!Array.isArray(style)) {
    return style;
  }

  const result = {} as T;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, styleLength = style.length; i < styleLength; ++i) {
    const computedStyle = flattenStyle(style[i]);
    if (computedStyle) {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const key in computedStyle) {
        result[key] = computedStyle[key];
      }
    }
  }
  return result;
};
