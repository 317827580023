import { Product } from '../../documents/product/Product';
import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('product-') === 0 && !doc.variantOfProductId) {
    const department =
      'department' in doc && typeof doc.department === 'string'
        ? doc.department
        : null;

    emit([department, !!doc.deleted], null);
  }
};

export class NonVariantProductsByDepartmentView extends DatabaseView<
  readonly [departmentId: string, deleted: boolean],
  null,
  Product
> {
  static readonly viewName = 'nonVariantProductsByDepartment';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(
      database,
      designDocumentId,
      NonVariantProductsByDepartmentView.viewName
    );
  }
}
