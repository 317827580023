import { SafeAreaView } from 'react-native-safe-area-context';
import { View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { memo } from 'react';
import PressableCell, {
  PressableCellProps
} from '../../../../../../components/cells/PressableCell';
import { Text } from '../../../../../../components/text/Text';
import { PaymentType } from '../../../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import RowsIcon from '../../../../../../components/icons/RowsIcon';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import VoidIcon from '../../../../../../components/icons/VoidIcon';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import PaymentTypeIcon from '../../../../../../components/icons/PaymentTypeIcon';
import SaveIcon from '../../../../../../components/icons/SaveIcon';

export type CellProps = Omit<PressableCellProps, 'children'> & {
  time: string;
  lines: number;
  voids: number;
  value: number;
  paymentTypes: PaymentType[];
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 6
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  time: {
    width: 64
  },
  icon: {
    color: SemanticColours.primary.foreground[50],
    width: 18,
    height: 18
  },
  element: {
    marginHorizontal: 6
  },
  nugget: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  nuggetChild: {
    marginHorizontal: 2
  },
  spacer: {
    flexGrow: 1
  },
  value: {
    textAlign: 'right'
  },
  paymentMethods: {
    flexDirection: 'row',
    alignItems: 'center'
  }
});

const Cell = memo(
  ({
    style,
    time,
    lines,
    voids,
    value,
    paymentTypes,
    ...rest
  }: CellProps): JSX.Element => {
    const styles = useDynamicStyleSheet(rStyles);

    const sharedIconProps: SvgProps = {
      style: styles.nuggetChild,
      fill: styles.icon.color,
      width: styles.icon.width,
      height: styles.icon.height
    };

    return (
      <PressableCell style={[styles.container, style as ViewStyle]} {...rest}>
        <SafeAreaView edges={['left', 'right']} style={styles.content}>
          <Text.CellTitle style={[styles.element, styles.time]}>
            {time}
          </Text.CellTitle>

          {lines > 0 ? (
            <View style={[styles.nugget, styles.element]}>
              <RowsIcon {...sharedIconProps} />
              <Text.CellSubtitle style={styles.nuggetChild}>
                {String(lines)}
              </Text.CellSubtitle>
            </View>
          ) : null}

          {voids > 0 ? (
            <View style={[styles.nugget, styles.element]}>
              <VoidIcon {...sharedIconProps} />
              <Text.CellSubtitle style={styles.nuggetChild}>
                {String(voids)}
              </Text.CellSubtitle>
            </View>
          ) : null}

          <View style={styles.spacer} />

          {value ? (
            <Text.CellSubtitle style={[styles.value, styles.element]}>
              {formatMoney(value)}
            </Text.CellSubtitle>
          ) : null}

          <View style={[styles.element, styles.paymentMethods]}>
            {paymentTypes.any() ? (
              paymentTypes.map(type => (
                <PaymentTypeIcon
                  key={type}
                  paymentType={type}
                  {...sharedIconProps}
                />
              ))
            ) : (
              <SaveIcon {...sharedIconProps} />
            )}
          </View>

          <ForwardIcon {...sharedIconProps} />
        </SafeAreaView>
      </PressableCell>
    );
  }
);

export default Cell;
