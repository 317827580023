import { LayoutChangeEvent, ScrollView, View } from 'react-native';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import DefaultZoneNavigation from '../../components/DefaultZoneNavigation';
import Header from './components/Header';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { PassNavigatorParamList } from '../../navigation/PassNavigatorParamList';
import ProductLookupCacheProvider from '../../components/ProductLookupCache';
import { useSizeClass } from '../../../../lib/responsive-size-class';
import { ErrorBoundary } from '../../../../components/error-boundary/ErrorBoundary';
import PullIncompletePreparationOrders from '../../components/PullIncompletePreparationOrders';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import DetailModalContent from '../../components/detail-modal/DetailModalContent';
import TasksWall from './components/TasksWall';
import TasksColumn from './components/TasksColumn';

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch'
  },
  modal: {
    backgroundColor: SemanticColours.primary.background[90]
  },
  column: {
    backgroundColor: SemanticColours.primary.background[70]
  }
});

const LargeContent = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  const isFocussed = useIsFocused();

  const [columns, setColumns] = useState(size === 'small' ? 1 : 3);

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!isFocussed) return;

      const { width } = e.nativeEvent.layout;

      if (width === 0) return;

      // desired card width 274
      const newColumns = Math.floor((width - 10) / 274);

      setColumns(newColumns <= 0 ? 1 : newColumns);
    },
    [isFocussed]
  );

  const insets = useSafeAreaInsets();

  return (
    <View style={styles.container} onLayout={onLayout}>
      {columns === 1 ? (
        <TasksColumn
          style={styles.column}
          contentContainerStyle={{
            marginLeft: insets.left,
            marginRight: insets.right
          }}
        />
      ) : (
        <TasksWall numColumns={columns} expandable={size === 'small'} />
      )}
    </View>
  );
};

const PassScreen = (): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const route = useRoute<RouteProp<PassNavigatorParamList>>();
  const navigation =
    useNavigation<StackNavigationProp<PassNavigatorParamList>>();

  const closeModal = useCallback(() => {
    navigation.setParams({ taskId: undefined });
  }, [route]);

  return (
    <ErrorBoundary>
      <DefaultZoneNavigation />
      <Header />
      <ProductLookupCacheProvider>
        {!!route.params?.prepZoneId && (
          <>
            <PullIncompletePreparationOrders
              preparationZoneId={route.params.prepZoneId}
            />
            <LargeContent />
          </>
        )}

        <PlatformModal
          visible={!!route.params?.taskId}
          onRequestClose={closeModal}
        >
          <ModalConfirmHeader title="Order detail" onCross={closeModal} />
          {route.params?.taskId && (
            <ScrollView style={styles.modal}>
              <DetailModalContent
                orderId={route.params.taskId}
                perspective="pass"
              />
            </ScrollView>
          )}
        </PlatformModal>
      </ProductLookupCacheProvider>
    </ErrorBoundary>
  );
};

export default PassScreen;
