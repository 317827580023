import { Product } from '../../documents/product/Product';
import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('product-') === 0) {
    emit([!!doc.deleted ?? false, String(doc.name)], null);
  }
};

export class AllProductsView extends DatabaseView<
  readonly [deleted: boolean, name: string],
  null,
  Product
> {
  static readonly viewName = 'allProducts';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllProductsView.viewName);
  }
}
