import React, { useMemo } from 'react';
import { View } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { Partition } from '../../../../types/Partition';
import { useChartData } from './useChartData';
import { partitionForComparison } from './partitionForComparison';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { formatPartition } from '../../../../../../humanisers/formatPartition';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import SelectIcon from '../../../../../../components/icons/SelectIcon';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import Chart from './Chart';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { Selectors } from '../../../../../../lib/dynamic-style-sheet/Selectors';
import { TimeInterval } from '../../../../types/TimeInterval';

export type SalesCumeChartProps = {
  comparison: TimeInterval | CalendarDayPartition;
  partition: CalendarDayPartition;
  requestChangeComparison: () => void;
};

const rStyles = DynamicStyleSheet.create({
  chartContainer: {
    flexDirection: 'row'
  },
  chart: {
    height: 200,
    width: '100%'
  },
  comparisonCell: {
    height: 50,
    marginHorizontal: 10,
    marginBottom: 10,
    borderRadius: 12
  }
}).override(Selectors.mediumOrLarge, {
  chart: {
    height: 260
  }
});

const SalesCumeChart = React.memo<SalesCumeChartProps>(
  ({ comparison, partition, requestChangeComparison }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const comparisonPartition: Partition = useMemo(() => {
      return partitionForComparison(partition, comparison);
    }, [partition, comparison]);

    const timeSettings = useSiteTime();

    const chartData = useChartData(partition, comparisonPartition);

    if (!chartData) {
      return (
        <>
          <View style={styles.chartContainer}>
            <View style={styles.chart} />
          </View>
          <View style={styles.comparisonCell} />
        </>
      );
    }

    return (
      <>
        <View style={styles.chartContainer}>
          <Chart {...chartData} style={styles.chart} />
        </View>

        <BasicCell
          style={styles.comparisonCell}
          title={`vs ${formatPartition(timeSettings)(comparisonPartition)}`}
          subtitle={formatMoney(chartData.secondarySeries.max(x => x.y) ?? 0)}
          iconRight={SelectIcon}
          titlesOrientation="horizontal"
          onPress={requestChangeComparison}
        />
      </>
    );
  }
);

export default SalesCumeChart;
