import { Database } from '../../../../support/Database';
import { CalendarView } from './CalendarView';

export class DashboardDatesDesignDoc {
  private static documentId = '_design/dashboard-dates';

  static views = {
    [CalendarView.viewName]: CalendarView
  } as const;

  constructor(private readonly database: Database) {}

  public readonly views = Object.fromEntries(
    Object.entries(DashboardDatesDesignDoc.views).map(([name, View]) => [
      name,
      new View(this.database, DashboardDatesDesignDoc.documentId)
    ])
  ) as {
    [P in keyof typeof DashboardDatesDesignDoc.views]: InstanceType<
      (typeof DashboardDatesDesignDoc)['views'][P]
    >;
  };
}
