import React, { useMemo } from 'react';
import ActionButtonBar, {
  ActionButtonBarProps
} from '../../../../components/buttons/ActionButtonBar';
import { Document } from '../../../../data-model/types/Document';
import { PreparationOrder } from '../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import FinishButton from '../buttons/FinishButton';
import UndoStartButton from '../buttons/UndoStartButton';
import StartButton from '../buttons/StartButton';
import UndoFinishButton from '../buttons/UndoFinishButton';
import Cell from '../../../../components/cells/Cell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import GrowSpacer from '../../../../components/grow-spacer/GrowSpacer';
import ReleaseButton from '../buttons/ReleaseButton';
import UndoReleaseButton from '../buttons/UndoReleaseButton';
import CollectButton from '../buttons/CollectButton';
import UndoCollectButton from '../buttons/UndoCollectButton';
import { Text } from '../../../../components/text/Text';
import { Selectors } from '../../../../lib/dynamic-style-sheet/Selectors';

export type StatusCellProps = Omit<ActionButtonBarProps, 'children'> & {
  document: Document<PreparationOrder>;
  perspective: 'pass' | 'prep';
};

const rStyles = DynamicStyleSheet.create({})
  .override(Selectors.mediumOrLarge, {
    root: {
      paddingLeft: 24,
      paddingVertical: 6,
      paddingRight: 6,
      flexWrap: 'wrap'
    }
  })
  .override(Selectors.small, {
    root: {
      paddingHorizontal: 20,
      paddingVertical: 12,
      flexDirection: 'column',
      alignItems: 'center'
    },
    buttonBar: {
      marginTop: 8
    }
  });

const StatusCell = React.memo<StatusCellProps>(
  ({ document, perspective, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const buttons = useMemo(() => {
      if (perspective === 'prep') {
        switch (document.status) {
          case '01 Pending':
          case '02 Released':
            return [StartButton];
          case '03 In Progress':
            return [UndoStartButton, FinishButton];
          case '04 Ready':
            return [UndoFinishButton];
          default:
            return undefined;
        }
      }

      switch (document.status) {
        case '01 Pending':
          return [ReleaseButton];
        case '02 Released':
          return [UndoReleaseButton];
        case '04 Ready':
          return [CollectButton];
        case '05 Collected':
          return [UndoCollectButton];
        default:
          return undefined;
      }
    }, [document]);

    return (
      <Cell style={styles.root}>
        <Text.CellTitle>{document.status?.substring(3)}</Text.CellTitle>

        <GrowSpacer />

        <ActionButtonBar style={styles.buttonBar} {...rest}>
          {buttons?.map((Button, index) => (
            <Button
              key={index}
              size="large"
              preparationOrderId={document._id}
            />
          ))}
        </ActionButtonBar>
      </Cell>
    );
  }
);

export default StatusCell;
