import { ComponentType, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import {
  SummaryByStatusViewKey,
  SummaryByStatusViewValue
} from '../../../../data-model/schema/databases/site-preparation-orders/design/summary-by-status/SummaryByStatusView';
import Card, { CardProps } from '../../../../components/card/Card';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import CardHeader from '../../../../components/card/CardHeader';
import BoundCardLines from './BoundCardLines';
import ExpandableCardHeader from '../../../../components/card/ExpandableCardHeader';
import CardHeaderLayout, { CardHeaderLayoutProps } from './CardHeaderLayout';
import { INACTIVE_STATUSES } from '../../screens/prep/constants';
import BoundRemoteCardLines from './BoundRemoteCardLines';
import PrepStatusBar from './StatusBar';

export type BoundCardProps = Omit<CardProps, 'header' | 'children'> & {
  queryRow: {
    key: SummaryByStatusViewKey;
    documentID: string;
    value: SummaryByStatusViewValue;
  };
  expanded?: boolean;
  footerComponent?: ComponentType<{
    queryRow: {
      key: SummaryByStatusViewKey;
      documentID: string;
      value: SummaryByStatusViewValue;
    };
  }>;
};

const rStyles = DynamicStyleSheet.create({
  card: {
    borderTopWidth: 0,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 4,
    borderBottomLeftRadius: 14,
    borderBottomRightRadius: 14
  },
  pending: {
    borderColor: SemanticColours.status.pending.foreground,
    backgroundColor: SemanticColours.status.pending.background
  },
  released: {
    borderColor: SemanticColours.status.released.foreground,
    backgroundColor: SemanticColours.status.released.background
  },
  inProgress: {
    borderColor: SemanticColours.status.inProgress.foreground,
    backgroundColor: SemanticColours.status.inProgress.background
  },
  ready: {
    borderColor: SemanticColours.status.ready.foreground,
    backgroundColor: SemanticColours.status.ready.background
  },
  collected: {
    borderColor: SemanticColours.status.collected.foreground,
    backgroundColor: SemanticColours.status.collected.background
  }
});

const BoundCard = ({
  queryRow,
  expandable,
  footerComponent: FooterComponent,
  style,
  ...rest
}: BoundCardProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const styleForStatus = useMemo(() => {
    switch (queryRow.key[1]) {
      case '01 Pending':
        return styles.pending;
      case '02 Released':
        return styles.released;
      case '03 In Progress':
        return styles.inProgress;
      case '04 Ready':
        return styles.ready;
      case '05 Collected':
        return styles.collected;
      default:
        return false;
    }
  }, [styles, queryRow]);

  const header = useMemo(() => {
    const Container = expandable ? ExpandableCardHeader : CardHeader;

    const {
      value: { course, tableNumber },
      key: [_, status, time]
    } = queryRow;

    const layout: CardHeaderLayoutProps['layout'] = INACTIVE_STATUSES.includes(
      status
    )
      ? 'inactive'
      : 'active';

    return (
      <Container>
        <PrepStatusBar status={queryRow.key[1]} />
        <CardHeaderLayout
          layout={layout}
          course={course}
          time={time}
          tableNumber={tableNumber}
        />
      </Container>
    );
  }, [queryRow, expandable]);

  return (
    <Card
      style={[styleForStatus, styles.card, style]}
      expandable={expandable}
      header={header}
      {...rest}
    >
      {queryRow.key[1] === '05 Collected' ? (
        <BoundRemoteCardLines documentId={queryRow.documentID} />
      ) : (
        <BoundCardLines documentId={queryRow.documentID} />
      )}

      {FooterComponent && <FooterComponent queryRow={queryRow} />}
    </Card>
  );
};

export default BoundCard;
