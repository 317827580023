import { ScrollViewProps } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export type KeyboardDismissViewProps = ScrollViewProps;

export const KeyboardDismissView = (
  props: KeyboardDismissViewProps
): JSX.Element => (
  <ScrollView
    keyboardShouldPersistTaps="handled"
    scrollEnabled={false}
    {...props}
  />
);
