import { useCallback } from 'react';
import * as CSV from 'csv-string';
import { useReferenceDb } from '../../../data-model/components/site-context/useReferenceDb';
import { useSecurityDb } from '../../../data-model/components/client-context/useSecurityDb';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';
import { useSiteContext } from '../../../data-model/components/site-context/useSiteId';

const isoDateToExcel = (isoDate: string): string => {
  const inDate = new Date(isoDate);

  const returnDateTime =
    25569.0 +
    (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
      (1000 * 60 * 60 * 24);
  return returnDateTime.toString().substr(0, 20);
};

export const useProductExport = () => {
  const { views } = useReferenceDb().local.design;
  const security = useSecurityDb();
  const { stockTransactions } = useSiteContext().databases;

  const doExport = useCallback(async () => {
    const [products, departments, prepZones, priceBands, users, stockBalances] =
      await Promise.all([
        views.nonVariantProductsByDepartment.getQuery({
          includeDocs: true
        }),
        views.allDepartments.getQuery({
          startKey: [false],
          endKey: [false, []]
        }),
        views.allPreparationZones.getQuery({
          includeDocs: true
        }),
        views.allPriceBands.getQuery({
          includeDocs: true
        }),
        security.local.design.views.allUsers.getQuery({
          includeDocs: true
        }),
        stockTransactions.remote.design.runningBalance.views.main.getQuery({
          reduce: true,
          groupLevel: 1
        })
      ]);

    const departmentLookup = Object.fromEntries(
      departments.map(d => [
        d.documentID,
        `${d.key[1]}${d.key[0] ? ' (deleted)' : ''}`
      ])
    );

    const prepZoneLookup = Object.fromEntries(
      prepZones.map(({ documentID, document: { name, deleted } }) => [
        documentID,
        `${name}${deleted ? ' (deleted)' : ''}`
      ])
    );

    const userLookup = Object.fromEntries(
      users.map(d => [
        d.documentID,
        `${d.key[1]}${d.key[0] ? ' (deleted)' : ''}`
      ])
    );

    const stockBalanceLookup = Object.fromEntries(
      stockBalances.map(r => [
        r.key,
        (r.value.POSITIVE?.wholes ?? 0) - (r.value.NEGATIVE?.wholes ?? 0)
      ])
    );

    const rows: string[] = [
      CSV.stringify([
        'Product ID',
        'Product name',
        'Group',
        'Preparation enabled',
        'Preparation zone',
        'Course',
        'Last modified by',
        'Last modified at',
        'Variant name',
        'Stock mode',
        'Stock balance',
        ...priceBands.map(p => `${p.document.name} price`)
      ])
    ];

    products.forEach(p => {
      const product = convertProductToV3(p.document);

      if (product === 'N/A' || product.deleted) return;

      const group = product.department
        ? departmentLookup[product.department] ?? 'Ungrouped'
        : 'Ungrouped';

      const zone =
        product.requiresPreparation && product.preparationZone
          ? prepZoneLookup[product.preparationZone] ?? ''
          : '';

      const user = product.revisionUserID
        ? userLookup[product.revisionUserID]
        : '';

      const modifiedAt = product.revisionTime
        ? isoDateToExcel(product.revisionTime)
        : '';

      const productFields = [
        p.documentID,
        product.productName,
        group,
        product.requiresPreparation ? 'Yes' : 'No',
        zone,
        product.requiresPreparation ? product.course : '',
        user,
        modifiedAt
      ];

      product.variants.forEach(v => {
        if (v.priceCalculation !== 'None' || v.valueType !== 'Fixed') return;

        const stockMode = v.stock?.mode ?? 'UNTRACKED';

        const variantCompatProductId =
          'compatProductId' in v ? v.compatProductId : product._id;

        const stockBalance =
          stockMode === 'UNTRACKED'
            ? ''
            : `${stockBalanceLookup[variantCompatProductId] ?? 0}`;

        rows.push(
          CSV.stringify([
            ...productFields,
            v.name !== 'Default' ? v.name : '',
            stockMode,
            stockBalance,
            ...priceBands.map(pb => v.prices[pb.documentID])
          ])
        );
      });
    });

    return rows;
  }, [views, security]);

  return doExport;
};
