import { useCallback, useMemo, useState } from 'react';
import { ViewProps } from 'react-native';
import { updateProductPreparationZone } from '../../commands/updateProductPreparationZone';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import BasicCell from '../../../../components/cells/BasicCell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useProductDataContext } from '../../data/ProductDataContext';
import { useProductRelationshipsContext } from '../../data/ProductRelationshipsContext';
import SelectIcon from '../../../../components/icons/SelectIcon';
import SelectFromListModal from '../../../../components/select-from-list-modal/SelectFromListModal';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { Alert } from '../../../../components/platform-alert/Alert';

export type EditPreparationZoneCellProps = Omit<ViewProps, 'children'>;

const rStyles = DynamicStyleSheet.create({
  container: {}
});

const EditPreparationZoneCell = ({
  style,
  ...rest
}: EditPreparationZoneCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const {
    productId,
    product,
    loading: productLoading
  } = useProductDataContext();

  const { data, loading } = useProductRelationshipsContext();

  const options = useMemo(() => {
    return (
      Object.entries(data?.preparationZones ?? {}).map(([id, name]) => ({
        id,
        name: name ?? 'No name'
      })) ?? []
    );
  }, [data?.preparationZones]);

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const selectedPreparationZoneName = useMemo(() => {
    if (!data?.preparationZones || !product || !product.requiresPreparation) {
      return 'None';
    }

    return data?.preparationZones[product?.preparationZone] ?? 'No name';
  }, [data?.preparationZones, product]);

  const { invoke, inProgress } = useCommand(
    updateProductPreparationZone,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        dismissModal();
        return;
      }

      const errorMessage =
        result instanceof Error
          ? 'An unexpected error occurred.'
          : 'The product no longer exists.';

      Alert.alert(
        'Error',
        `Could not update the product's preparation zone.\n\n${errorMessage}`,
        [
          { text: 'Cancel', onPress: () => dismissModal() },
          { text: 'Retry', onPress: () => retry() }
        ]
      );
    }
  );

  const onAccept = useCallback(
    (id: string) => {
      invoke({ productId, newPreparationZoneId: id });
    },
    [invoke]
  );

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <>
      <BasicCell
        style={[styles.container, style]}
        disabled={productLoading || loading || inProgress}
        {...rest}
        title="Zone"
        titlesOrientation="horizontal"
        subtitle={selectedPreparationZoneName ?? 'None'}
        {...(authorised
          ? { iconRight: SelectIcon, onPress: () => setModalVisible(true) }
          : {})}
      />

      <SelectFromListModal
        extractItemId={d => d.id}
        extractItemTitle={d => d.name}
        items={options}
        onAccept={onAccept}
        onDismiss={dismissModal}
        selectedItem={
          product?.requiresPreparation ? product.preparationZone : null
        }
        title="Select zone"
        visible={modalVisible}
        loading={productLoading || loading || inProgress}
      />
    </>
  );
};

export default EditPreparationZoneCell;
