import { ReactNode, createContext, useMemo } from 'react';
import { getServerUrl } from './getServerUrl';
import { useLaunchArguments } from '../../../lib/launch-arguments';

export type ServerContextType = {
  apiBaseUrl: string;
  dbBaseUrl: string;
};

export const ServerContext = createContext<ServerContextType | null>(null);

export type ServerContextProviderProps = {
  children?: ReactNode | undefined;
};

export const ServerContextProvider = (
  props: ServerContextProviderProps
): JSX.Element => {
  const launchArgs = useLaunchArguments<LaunchArguments>();

  const value: ServerContextType = useMemo(() => {
    const otherwise = getServerUrl();

    return {
      apiBaseUrl: launchArgs?.apiBaseUrl ?? otherwise,
      dbBaseUrl: launchArgs.dbBaseUrl ?? `${otherwise}/api/db`
    };
  }, [launchArgs]);

  return <ServerContext.Provider value={value} {...props} />;
};
