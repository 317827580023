import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useColorScheme } from 'react-native';

export type Theme = 'dark' | 'light';

type ThemeContextValue = {
  theme: Theme;
};

export const ThemeContext = createContext<ThemeContextValue | null>(null);

export type ThemeProviderProps = {
  theme?: 'automatic' | Theme;
  inverted?: boolean;
  children: ReactNode;
};

const invertTheme = (theme: Theme): Theme => {
  if (theme === 'dark') {
    return 'light';
  }

  return 'dark';
};

export const ThemeProvider = ({
  theme,
  inverted = false,
  children
}: ThemeProviderProps): JSX.Element => {
  const context = useContext(ThemeContext);

  const systemColourScheme = useColorScheme();

  const value = useMemo(() => {
    const currentTheme =
      (theme === 'automatic' ? systemColourScheme : theme) ??
      context?.theme ??
      'light';

    return inverted ? invertTheme(currentTheme) : currentTheme;
  }, [context, theme, systemColourScheme]);

  return (
    <ThemeContext.Provider value={{ theme: value }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (options?: { inverted?: boolean }): Theme => {
  const context = useContext(ThemeContext);

  const value = context?.theme ?? 'light';

  return options?.inverted ? invertTheme(value) : value;
};
