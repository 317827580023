import hash from 'object-hash';
import { ComponentType, useMemo } from 'react';
import { TextProps, View, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import { IconButton } from '../buttons/IconButton';
import PressableCell, { PressableCellProps } from './PressableCell';
import { ForwardIcon } from '../icons/ForwardIcon';
import { useSizeClass } from '../../lib/responsive-size-class';
import { Text } from '../text/Text';

export type BasicCellProps = Omit<PressableCellProps, 'children'> & {
  iconColour?: string;
  iconLeft?: ComponentType<SvgProps>;
  iconsLeft?: {
    component: ComponentType<SvgProps>;
    onPress?: () => void;
    onLongPress?: () => void;
  }[];
  iconRight?: ComponentType<SvgProps>;
  iconsRight?: {
    component: ComponentType<SvgProps>;
    onPress?: () => void;
    onLongPress?: () => void;
  }[];
  onIconLeftLongPress?: () => void;
  onIconLeftPressed?: () => void;
  onIconRightPressed?: () => void;
  title?: string;
  subtitle?: string;
  titlesOrientation?: 'horizontal' | 'vertical';
  subtitleStyle?: TextProps['style'];
  titleStyle?: TextProps['style'];
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 12
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  titles: {
    flex: 1,
    flexGrow: 1
  },
  titlesHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titlesVertical: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  icon: {
    flexGrow: 0,
    color: SemanticColours.primary.foreground[35],
    marginHorizontal: 6
  },
  text: {
    marginHorizontal: 6,
    flexWrap: 'wrap',
    flexShrink: 1
  },
  horizontalSubtitle: {
    textAlign: 'right'
  },
  subtitle: {
    marginHorizontal: 6
  },
  forwardIcon: {
    color: SemanticColours.primary.foreground[40],
    flexShrink: 0
  }
});

type CellIconsProps = {
  icons: {
    component: ComponentType<SvgProps>;
    onPress?: () => void;
    onLongPress?: () => void;
  }[];
  colour?: string;
};

const CellIcons = ({ icons, colour }: CellIconsProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <>
      {icons.map(props => {
        const { component: Icon, onPress, onLongPress } = props;
        const key = hash(props);

        return onPress || onLongPress ? (
          <IconButton
            key={key}
            iconColour={colour ?? styles.icon.color}
            iconComponent={Icon}
            iconSize={18}
            onPress={onPress}
            onLongPress={onLongPress}
            style={[styles.icon]}
          />
        ) : (
          <Icon
            key={key}
            fill={colour ?? styles.icon.color}
            height={18}
            style={[styles.icon]}
            width={18}
          />
        );
      })}
    </>
  );
};

const BasicCell = ({
  style,
  iconColour,
  iconLeft: IconLeft,
  iconsLeft,
  iconRight: IconRight,
  iconsRight,
  onIconLeftLongPress,
  onIconLeftPressed,
  onIconRightPressed,
  title,
  subtitle,
  titlesOrientation: maybeTitlesOrientation,
  subtitleStyle,
  titleStyle,
  ...rest
}: BasicCellProps): JSX.Element => {
  const size = useSizeClass();

  const styles = useDynamicStyleSheet(rStyles);

  const leftIcons = useMemo(() => {
    if (iconsLeft) {
      return iconsLeft;
    }

    if (IconLeft) {
      return [
        {
          component: IconLeft,
          onPress: onIconLeftPressed,
          onLongPress: onIconLeftLongPress
        }
      ];
    }

    return undefined;
  }, [iconsLeft, IconLeft]);

  const rightIcons = useMemo(() => {
    if (iconsRight) {
      return iconsRight;
    }

    if (IconRight) {
      return [
        {
          component: IconRight,
          onPress: onIconRightPressed
        }
      ];
    }

    return undefined;
  }, [iconsRight, IconRight]);

  const titlesOrientation = useMemo(() => {
    if (maybeTitlesOrientation) {
      return maybeTitlesOrientation;
    }

    if (size === 'small') {
      return 'vertical';
    }

    return 'horizontal';
  }, [maybeTitlesOrientation, size]);

  return (
    <PressableCell style={[styles.container, style as ViewStyle]} {...rest}>
      <SafeAreaView edges={['left', 'right']} style={styles.content}>
        {!!leftIcons && <CellIcons icons={leftIcons} colour={iconColour} />}
        <View
          style={[
            styles.titles,
            titlesOrientation === 'horizontal'
              ? styles.titlesHorizontal
              : styles.titlesVertical
          ]}
        >
          {/* Seems odd, but this forces the subtitle to the right if there is no title */}
          {(typeof title !== 'undefined' ||
            (titlesOrientation === 'horizontal' &&
              typeof subtitle !== 'undefined')) && (
            <Text.CellTitle style={[styles.text, titleStyle]}>
              {title}
            </Text.CellTitle>
          )}
          {typeof subtitle !== 'undefined' && (
            <Text.CellSubtitle
              style={[
                styles.text,
                styles.subtitle,
                titlesOrientation === 'horizontal' && styles.horizontalSubtitle,
                subtitleStyle
              ]}
            >
              {subtitle}
            </Text.CellSubtitle>
          )}
        </View>
        {!!rightIcons && <CellIcons icons={rightIcons} colour={iconColour} />}
      </SafeAreaView>
    </PressableCell>
  );
};

BasicCell.ForwardIcon = ({ fill, ...rest }: SvgProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);
  return (
    <ForwardIcon
      fill={fill ?? styles.forwardIcon.color}
      width={18}
      height={18}
      {...rest}
    />
  );
};

export default BasicCell;
