import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { StockTransactionsDatabaseSchema } from './StockTransactionsDatabaseSchema';

export class StockTransactionsDatabase extends DatabasePair<StockTransactionsDatabaseSchema> {
  constructor(
    clientId: string,
    siteId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(
      `sp-${clientId}-${siteId}-stock-transactions`,
      StockTransactionsDatabaseSchema,
      queryConfig,
      syncConfig
    );
  }
}
