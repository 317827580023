import { DateTime } from 'luxon';
import { CalendarDayPartition } from '../../../data-model/schema/databases/site-transactions-archive/design/Partitions';

export const dateToCalendarDayPartition = (
  input: DateTime
): CalendarDayPartition => {
  const { year, month, day } = input;

  return ['calendar', year, month, day];
};
