import React, { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import CardFooter, {
  CardFooterProps
} from '../../../components/card/CardFooter';
import { SummaryByStatusViewKey } from '../../../../../data-model/schema/databases/site-preparation-orders/design/summary-by-status/SummaryByStatusView';
import { PassNavigatorParamList } from '../../../navigation/PassNavigatorParamList';
import UndoReleaseButton from '../../../components/buttons/UndoReleaseButton';
import UndoCollectButton from '../../../components/buttons/UndoCollectButton';
import ReleaseButton from '../../../components/buttons/ReleaseButton';
import CollectButton from '../../../components/buttons/CollectButton';

export type BoundCardFooterProps = Omit<
  CardFooterProps,
  'leftButton' | 'rightButton'
> & {
  queryRow: {
    key: SummaryByStatusViewKey;
    documentID: string;
  };
};

const BoundCardFooter = React.memo<BoundCardFooterProps>(
  ({ queryRow, ...rest }) => {
    const navigation =
      useNavigation<StackNavigationProp<PassNavigatorParamList>>();

    const onMorePress = useCallback(() => {
      navigation.setParams({ taskId: queryRow.documentID });
    }, [queryRow.documentID, navigation]);

    const LeftButton = useMemo(() => {
      switch (queryRow.key[1]) {
        case '02 Released':
          return UndoReleaseButton;
        case '05 Collected':
          return UndoCollectButton;
        default:
          return undefined;
      }
    }, [queryRow]);

    const RightButton = useMemo(() => {
      switch (queryRow.key[1]) {
        case '01 Pending':
          return ReleaseButton;
        case '04 Ready':
          return CollectButton;
        default:
          return undefined;
      }
    }, [queryRow]);

    return (
      <CardFooter
        {...rest}
        onMorePress={onMorePress}
        leftButton={
          LeftButton ? (
            <LeftButton preparationOrderId={queryRow.documentID} />
          ) : undefined
        }
        rightButton={
          RightButton ? (
            <RightButton preparationOrderId={queryRow.documentID} />
          ) : undefined
        }
      />
    );
  }
);

export default BoundCardFooter;
