import { DatabaseView } from '../../../../support/DatabaseView';
import { ServiceTransaction } from '../../documents/ServiceTransaction';
import { TimeInterval } from '../../../../../../features/dashboard/types/TimeInterval';
import { DashboardSummarisedData } from '../DashboardSummarisedData';
import { Database } from '../../../../support/Database';

export type DashboardProductsForDateKey =
  | [interval: TimeInterval, date: string, productId: string]
  | [
      interval: 'day',
      date: string,
      productId: string,
      sessionStartTime: string,
      userId: string,
      servingZoneId: string
    ];

export type DashboardProductsForDateValue = DashboardSummarisedData;

export class DashboardProductsForDateDesignDoc {
  constructor(private readonly database: Database) {}

  public readonly view = new DatabaseView<
    DashboardProductsForDateKey,
    DashboardProductsForDateValue,
    ServiceTransaction
  >(this.database, '_design/dashboard-products-for-date', 'main');
}
