import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';
import { DatabaseView } from '../../../../support/DatabaseView';
import {
  PreparationOrder,
  PreparationOrderStatus
} from '../../documents/PreparationOrder';

type Timestamp = string;
type PreparationZoneId = string;

// eslint-disable-next-line func-names
const map = function (doc: Document<PreparationOrder>) {
  'public source';

  if (
    doc._id.indexOf('preparationorder-') !== 0 ||
    doc.deleted ||
    !doc.status ||
    !doc.statusHistory
  ) {
    return;
  }

  const {
    preparationZone,
    status,
    tableNumber,
    course,
    statuses,
    time: createTime
  } = doc;

  const pertinentTime = (() => {
    switch (status) {
      case '01 Pending':
        return statuses?.['01 Pending'];
      case '02 Released':
        return statuses?.['02 Released'];
      case '03 In Progress':
        return statuses?.['02 Released'] ?? statuses?.['03 In Progress'];
      case '04 Ready':
        return statuses?.['02 Released'] ?? statuses?.['03 In Progress'];
      case '05 Collected':
        return statuses?.['05 Collected'];
      default:
        return null;
    }
  })();

  const value = {
    tableNumber,
    course
  };

  emit([preparationZone, status, pertinentTime ?? createTime], value);
};

export type SummaryByStatusViewKey = readonly [
  PreparationZoneId,
  PreparationOrderStatus,
  Timestamp
];

export type SummaryByStatusViewValue = {
  readonly tableNumber?: string;
  readonly course?: string;
};

export class SummaryByStatusView extends DatabaseView<
  SummaryByStatusViewKey,
  SummaryByStatusViewValue,
  PreparationOrder
> {
  static readonly viewName = 'main';

  static definition = {
    map: map.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, SummaryByStatusView.viewName);
  }
}
