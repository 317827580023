import { Partition } from '../types/Partition';
import { isCalendarDayPartition } from './isCalendarDayPartition';
import { isCalendarMonthPartition } from './isCalendarMonthPartition';
import { isCalendarYearPartition } from './isCalendarYearPartition';
import { isIsoWeekPartition } from './isIsoWeekPartition';

export const isPartition = (value: unknown): value is Partition => {
  return (
    isCalendarDayPartition(value) ||
    isCalendarMonthPartition(value) ||
    isCalendarYearPartition(value) ||
    isIsoWeekPartition(value)
  );
};
