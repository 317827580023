import { AppRegistry } from 'react-native';
import SiteEntryPoint, { SiteEntryPointProps } from '../SiteEntryPoint';
import { nameof } from '../../lib/name-of';
import PrepNavigator from '../../features/prep-and-pass/navigation/PrepNavigator';

type PreparationEntryPointProps = Omit<
  SiteEntryPointProps,
  'navigatorComponent'
>;

const PreparationEntryPoint = (
  props: PreparationEntryPointProps
): JSX.Element => {
  return <SiteEntryPoint {...props} navigatorComponent={PrepNavigator} />;
};

AppRegistry.registerComponent(
  nameof({ PreparationEntryPoint }),
  () => PreparationEntryPoint
);
