/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable global-require */
import { AVPlaybackSource } from 'expo-av';

export class Assets {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static ring: AVPlaybackSource = require('./ring.mp3');
}
