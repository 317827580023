import { useEffect, useMemo } from 'react';
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { DashboardNavigatorParamList } from './DashboardNavigatorParamList';
import { usePartitionNavigation } from '../components/partition-navigation-context/usePartitionNavigation';
import { PartitionSerialiser } from './PartitionSerialiser';

export const DefaultPartitionNavigation = (): JSX.Element => {
  const route =
    useRoute<RouteProp<DashboardNavigatorParamList, 'show' | 'user'>>();

  const navigation =
    useNavigation<
      NavigationProp<DashboardNavigatorParamList, 'show' | 'user'>
    >();

  const { day } = usePartitionNavigation();

  const partition = useMemo(
    () =>
      route.params?.partition
        ? PartitionSerialiser.parse(route.params.partition)
        : null,
    [route]
  );

  // load the first day
  useEffect(() => {
    if (partition || day.state.state !== 'EMPTY') return;

    day.reload(2); // just to help out the seek buttons
  }, [partition, day]);

  // navigate to the first day
  useEffect(() => {
    if (partition || day.state.state !== 'SUCCESS') return;

    if (day.state.rows?.any()) {
      navigation.setParams({
        partition: PartitionSerialiser.stringify(day.state.rows[0].partition)
      });

      return;
    }

    navigation.setParams({
      partition: 'none'
    });
  }, [partition, day]);

  return <></>;
};
