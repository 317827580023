import React from 'react';
import ActionIconButton from '../card/ActionIconButton';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import { startPreparationOrder } from '../../commands/startPreparationOrder';
import { Alert } from '../../../../components/platform-alert/Alert';
import StartIcon from '../../../../components/icons/StartIcon';
import { PlatformPressableProps } from '../../../../components/platform-pressable/PlatformPressableProps';
import ActionButton from '../../../../components/buttons/ActionButton';

export type StartButtonProps = Omit<PlatformPressableProps, 'children'> & {
  preparationOrderId: string;
  size?: 'small' | 'large';
};

const StartButton = React.memo<StartButtonProps>(
  ({ preparationOrderId, size = 'small', ...rest }) => {
    const { invoke, inProgress } = useCommand(
      startPreparationOrder,
      (result, retry) => {
        if (!(result instanceof Error) && result.type === 'SUCCESS') {
          return;
        }

        const errorMessage =
          result instanceof Error
            ? 'An unexpected error occurred.'
            : 'The task is not in the expected state.';

        Alert.alert('Error', `Could not start the task.\n\n${errorMessage}`, [
          { text: 'Cancel', onPress: () => {} },
          { text: 'Retry', onPress: () => retry() }
        ]);
      }
    );

    return size === 'small' ? (
      <ActionIconButton
        {...rest}
        iconComponent={StartIcon}
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    ) : (
      <ActionButton
        {...rest}
        iconComponent={StartIcon}
        label="Start"
        onPress={() => invoke({ preparationOrderId })}
        disabled={inProgress}
      />
    );
  }
);

export default StartButton;
