import { ProductStock } from './ProductStock';
import {
  DeclaredVariant,
  DefaultImpliedVariant,
  DefaultUnnamedVariant,
  ProductV2
} from './ProductV2';

type ProductValueFixed = {
  valueType: 'Fixed';
  prices: Readonly<Record<string, string>>;
};

type ProductValueDiscretionary = {
  valueType: 'Discretionary';
};

type ProductValue = ProductValueFixed | ProductValueDiscretionary;

export type DefaultImpliedVariantV2 = Omit<DefaultImpliedVariant, 'prices'> & {
  $schemaVersion: '2';
  priceCalculation: 'None' | 'Percentage';
  stock?: ProductStock;
} & ProductValue;

export type DefaultUnnamedVariantV2 = Omit<DefaultUnnamedVariant, 'prices'> & {
  $schemaVersion: '2';
  priceCalculation: 'None' | 'Percentage';
  stock?: ProductStock;
} & ProductValue;

export type DeclaredVariantV2 = Omit<DeclaredVariant, 'prices'> & {
  $schemaVersion: '2';
  priceCalculation: 'None' | 'Percentage';
  stock?: ProductStock;
} & ProductValue;

export const isDeclaredVariantV2 = (
  variant: AnyVariantV2
): variant is DeclaredVariantV2 => {
  return variant.$schema === 'DeclaredVariant';
};

export type AnyVariantV2 =
  | DefaultImpliedVariantV2
  | DefaultUnnamedVariantV2
  | DeclaredVariantV2;

export type VariantArrayV2 = [
  DefaultImpliedVariantV2 | DefaultUnnamedVariantV2,
  ...DeclaredVariantV2[]
];

type ProductPreparationRequired = {
  requiresPreparation: true;
  preparationZone: string;
  course: string;
};

type ProductPreparationNotRequired = {
  requiresPreparation: false;
};

type ProductPreparation =
  | ProductPreparationRequired
  | ProductPreparationNotRequired;

export type ProductV3 = Omit<
  ProductV2,
  | 'preparationZone' // now linked to requiresPreparation
  | '$schemaVersion'
  | 'requiresPreparation' // now a discriminator for zone and course
  | 'course' // now linked to requiresPreparation
  | 'prices' // now only present if valueType === 'Fixed'
  | 'variants' // now v2 with valueType
  | 'priceCalculation' // now a stronger type
  | 'valueType' // now a stronger type
> &
  ProductPreparation &
  ProductValue & {
    $schemaVersion: '3';
    priceCalculation: 'None' | 'Percentage';
    variants: VariantArrayV2;
  };

export const isProductV3 = (value: unknown): value is ProductV3 => {
  if (
    !(typeof value === 'object') ||
    !value ||
    !('$schema' in value) ||
    !('$schemaVersion' in value)
  ) {
    return false;
  }

  if (value.$schema !== 'Product') {
    return false;
  }

  return value.$schemaVersion === '3';
};
