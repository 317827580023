import React from 'react';
import { View, ViewStyle } from 'react-native';
import PressableCell, {
  PressableCellProps
} from '../../../../../../components/cells/PressableCell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { Text } from '../../../../../../components/text/Text';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import { SemanticColours } from '../../../../../../theme/SemanticColours';
import { formatQuantity } from '../../../../../../humanisers/formatQuantity';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { DashboardSummarisedData } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/DashboardSummarisedData';

export type CellProps = Omit<PressableCellProps, 'children'> & {
  name: string;
  received: DashboardSummarisedData | null;
  refund: DashboardSummarisedData | null;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingLeft: 12,
    paddingRight: 2
  },
  lines: {
    flexDirection: 'column',
    flex: 1
  },
  lineOne: {
    flexDirection: 'row'
  },
  lineTwo: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  quantity: {
    fontSize: 14,
    minWidth: 31,
    flexShrink: 0,
    flexWrap: 'nowrap',
    color: SemanticColours.primary.foreground[40],
    textAlign: 'right'
  },
  nameAndValue: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  name: {
    color: SemanticColours.primary.foreground[60],
    fontSize: 17,
    flexWrap: 'wrap',
    flexShrink: 1
  },
  value: {
    textAlign: 'right',
    flexShrink: 0,
    fontSize: 16
  },
  element: {
    marginHorizontal: 6
  },
  icon: {
    flexGrow: 0,
    color: SemanticColours.primary.foreground[35]
  },
  iconHidden: {
    opacity: 0
  },
  refund: {
    fontSize: 14
  }
});

const Cell = React.memo<CellProps>(
  ({ style, name, received, refund, onPress, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <PressableCell
        style={[styles.container, style as ViewStyle]}
        onPress={onPress}
        {...rest}
      >
        <View style={styles.lines}>
          <View style={styles.lineOne}>
            <Text.CellTitle style={[styles.element, styles.quantity]}>
              {received?.units ? formatQuantity(received.units) : ''}
            </Text.CellTitle>

            <View style={styles.nameAndValue}>
              <Text.CellTitle style={[styles.element, styles.name]}>
                {name}
              </Text.CellTitle>

              {received && (
                <Text.CellSubtitle style={[styles.value, styles.element]}>
                  {formatMoney(received.value ?? 0)}
                </Text.CellSubtitle>
              )}
            </View>
          </View>

          {refund && (
            <View style={styles.lineTwo}>
              <Text.CellSubtitle
                style={[styles.value, styles.element, styles.refund]}
              >
                {formatQuantity(refund.units)} refunds{' '}
                {formatMoney(refund.value * -1)}
              </Text.CellSubtitle>
            </View>
          )}
        </View>

        <ForwardIcon
          fill={styles.icon.color}
          height={18}
          style={[styles.element, styles.icon, !onPress && styles.iconHidden]}
          width={18}
        />
      </PressableCell>
    );
  }
);

export default Cell;
