import { View, Text, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { ComponentType, useMemo } from 'react';
import { SvgProps } from 'react-native-svg';
import { nameof } from '../../lib/name-of';
import { SemanticColours } from '../../theme/SemanticColours';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { PropsType } from '../../lib/react-props-type';
import PlatformPressable from '../platform-pressable/PlatformPressable';
import Badge from '../badge/Badge';

export type TabProps = Omit<PropsType<typeof PlatformPressable>, 'children'> & {
  title?: string;
  hideIndicator?: boolean;
  isActive: boolean;
  badge?: string | number;
  icon?: ComponentType<SvgProps>;
  styles?: {
    activeIndicator?: StyleProp<ViewStyle>;
    activeLabel?: StyleProp<TextStyle>;
    indicator?: StyleProp<ViewStyle>;
    label?: StyleProp<TextStyle>;
  };
  disableActive?: boolean;
  indicatorLayout?: 'inline' | 'on-top';
};

const rStyles = DynamicStyleSheet.create({
  indicator: {
    height: 5,
    flexGrow: 0
  },
  indicatorOnTop: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0
  },
  activeIndicator: {
    backgroundColor: SemanticColours.primary.foreground['40']
  },
  labelContainer: {
    padding: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1
  },
  label: {
    paddingHorizontal: 5,
    fontSize: 17,
    lineHeight: 21,
    color: SemanticColours.primary.foreground[40]
  },
  activeLabel: {
    color: SemanticColours.primary.foreground[70]
  },
  badgeContainer: {
    position: 'absolute',
    right: -8,
    top: -8,
    left: -8,
    bottom: -8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  iconContainer: {
    padding: 0
  }
});

export const Tab = ({
  title,
  badge,
  isActive,
  disabled,
  style,
  styles: styleOverrides,
  hideIndicator = false,
  icon: Icon,
  disableActive,
  indicatorLayout = 'inline',
  ...rest
}: TabProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const iconColour = useMemo(() => {
    if (isActive) {
      return styleOverrides?.activeLabel &&
        'color' in styleOverrides.activeLabel
        ? styleOverrides.activeLabel.color
        : styles.activeLabel.color;
    }

    return styleOverrides?.label && 'color' in styleOverrides.label
      ? styleOverrides.label.color
      : styles.label.color;
  }, [isActive, styleOverrides]);

  return (
    <PlatformPressable
      disabled={(disableActive && isActive) || disabled}
      style={style}
      testID={nameof({ Tab })}
      {...rest}
    >
      <View style={styles.labelContainer}>
        {Icon && (
          <View style={styles.iconContainer}>
            <Icon width={18} height={18} fill={iconColour} />
            {badge && (
              <View style={styles.badgeContainer}>
                <Badge value={badge} />
              </View>
            )}
          </View>
        )}
        {title && (
          <Text
            style={[
              styles.label,
              styleOverrides?.label,
              isActive && styles.activeLabel,
              isActive && styleOverrides?.activeLabel
            ]}
          >
            {title}
          </Text>
        )}
      </View>
      {!hideIndicator && (
        <View
          style={[
            styles.indicator,
            styleOverrides?.indicator,
            isActive && styles.activeIndicator,
            isActive && styleOverrides?.activeIndicator,
            indicatorLayout === 'on-top' && styles.indicatorOnTop
          ]}
          testID="ActiveIndicator"
        />
      )}
    </PlatformPressable>
  );
};
