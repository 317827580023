import { useContext } from 'react';
import { nameof } from '../../../lib/name-of';
import { SiteContext } from './SiteContext';

export const useSiteContext = () => {
  const site = useContext(SiteContext);

  if (!site) {
    throw new Error(
      `${nameof({ useSiteContext })} requires a ${nameof({ SiteContext })}`
    );
  }

  return site;
};
