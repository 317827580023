import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { PriceBand } from '../../documents/PriceBand';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (doc._id.indexOf('priceband-') === 0 && !doc.deleted) {
    emit(null, null);
  }
};

export class AllPriceBandsView extends DatabaseView<null, null, PriceBand> {
  static readonly viewName = 'allPriceBands';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, AllPriceBandsView.viewName);
  }
}
