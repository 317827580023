import { Repository } from '../../../support/Repository';
import { newRandomUuid } from '../../../../../lib/uuid';
import { Voucher } from '../documents/Voucher';
import { Database } from '../../../support/Database';

export class VouchersRepository extends Repository<Voucher> {
  constructor(database: Database) {
    super(database, () => `voucher-${newRandomUuid()}`);
  }
}
