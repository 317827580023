import { ComponentType } from 'react';
import { View, ViewProps } from 'react-native';
import { SvgProps } from 'react-native-svg';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import { Text } from '../text/Text';

export type NuggetProps = ViewProps & {
  icon: ComponentType<SvgProps>;
  title: string;
  value: string;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: -4
  },
  element: {
    marginHorizontal: 4
  },
  icon: {
    color: SemanticColours.primary.foreground[50],
    width: 18,
    height: 18
  }
});

const Nugget = ({
  style,
  icon: Icon,
  title,
  value,
  ...rest
}: NuggetProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const sharedIconProps: SvgProps = {
    fill: styles.icon.color,
    width: styles.icon.width,
    height: styles.icon.height
  };

  return (
    <View style={[styles.container, style]} {...rest}>
      <View style={styles.element}>
        <Icon {...sharedIconProps} />
      </View>
      <Text.DocBody style={styles.element}>{title}</Text.DocBody>
      <Text.DocBody style={styles.element}>{value}</Text.DocBody>
    </View>
  );
};

export default Nugget;
