import { NotFoundIcon } from './NotFoundIcon';
import {
  UnhappyPathBaseContent,
  UnhappyPathBaseContentProps
} from '../UnhappyPathContentBase';

export type NotFoundContentProps = {
  objectDescription: string;
} & Partial<UnhappyPathBaseContentProps>;

export const NotFoundContent = ({
  objectDescription,
  ...rest
}: NotFoundContentProps): JSX.Element => (
  <UnhappyPathBaseContent
    icon={NotFoundIcon}
    title="Not found"
    hint={`This ${objectDescription} could not be found.`}
    {...rest}
  />
);
