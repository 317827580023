import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { AsyncResult, Result, fail, success } from '@laurence79/ts-results';
import {
  PromiseFactoryHook,
  usePromiseFactory
} from '../../../../lib/use-promise/usePromiseFactory';
import { Store as PersonalSecurityStore } from '../../../../security/personal/Store';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { useUsopApiClient } from '../../../../api/useUsopApiClient';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { SecuritySchemeContext } from '../../../../security/components/scheme/SecuritySchemeContext';
import { nameof } from '../../../../lib/name-of';
import { PersonalSecurityContext } from '../../../../security/personal/PersonalSecurityContext';
import { NewClientInput } from '../../../../security/personal/types';

export type OnboardingProgress = {
  readonly client?: { readonly id: string; readonly name: string };
  readonly user?: {
    readonly id: string;
    readonly username: string;
    readonly fullName: string;
  };
  readonly userSession?: {
    readonly token: string;
    readonly userSessionId: string;
    readonly role: RoleId;
    readonly deviceId: string;
  };
  readonly securityMode?: 'SITE_OWNED' | 'PERSONAL';
  readonly deviceName?: string;
};

type OnboardingProgressContextValue = {
  progress: OnboardingProgress;
  updateProgress: (progress: OnboardingProgress) => void;
  commit: PromiseFactoryHook<
    [OnboardingProgress],
    Result<void, 'INSUFFICIENT_PROGRESS' | 'UPDATE_CLIENT_FAIL'>
  >;
};

export const OnboardingProgressContext =
  createContext<OnboardingProgressContextValue | null>(null);

export type OnboardingProgressContextProviderProps = {
  children: ReactNode;
  existingProgress?: OnboardingProgress;
};

export const OnboardingProgressContextProvider = ({
  children,
  existingProgress = {}
}: OnboardingProgressContextProviderProps): JSX.Element => {
  const [progress, setProgress] =
    useState<OnboardingProgress>(existingProgress);

  const updateProgress = useCallback((newProgress: OnboardingProgress) => {
    setProgress(e => ({ ...e, ...newProgress }));
  }, []);

  const apiClient = useUsopApiClient();

  const { setCurrentScheme: setSecurityKind } = useContextOrThrow(
    SecuritySchemeContext,
    nameof({ SecurityKindContext: SecuritySchemeContext })
  );

  const personalSecurityContext = useContext(PersonalSecurityContext);

  const commit = useCallback(
    async (
      newProgress: OnboardingProgress
    ): AsyncResult<void, 'INSUFFICIENT_PROGRESS' | 'UPDATE_CLIENT_FAIL'> => {
      const effectiveProgress = { ...progress, ...newProgress };
      const { client, user, userSession, securityMode } = effectiveProgress;

      if (!client || !user || !userSession) {
        return fail('INSUFFICIENT_PROGRESS');
      }

      const authorisedClientResponse = await apiClient.getClientById({
        clientId: client.id,
        authorization: `Bearer ${userSession.token}`
      });

      if (
        authorisedClientResponse.status !== 200 ||
        !('defaultSiteId' in authorisedClientResponse.body)
      ) {
        return fail('UPDATE_CLIENT_FAIL');
      }

      const newClient: NewClientInput = {
        id: client.id,
        name: client.name,
        defaultSiteId: authorisedClientResponse.body.defaultSiteId,
        user: {
          userId: user.id,
          username: user.username,
          fullName: user.fullName,
          role: userSession.role,
          userSessionId: user.id,
          token: userSession.token,
          deviceId: userSession.deviceId
        }
      };

      if (personalSecurityContext) {
        personalSecurityContext.switchToNewClient(newClient);
        return success();
      }

      if (!securityMode || securityMode === 'PERSONAL') {
        await PersonalSecurityStore.addOrReplaceClient(newClient);
        setSecurityKind('PERSONAL');
      }

      return success();
    },
    [progress]
  );

  const commitHook = usePromiseFactory(commit);

  const value = useMemo(
    () => ({
      progress,
      updateProgress,
      commit: commitHook
    }),
    [progress, updateProgress, commitHook]
  );

  return (
    <OnboardingProgressContext.Provider value={value}>
      {children}
    </OnboardingProgressContext.Provider>
  );
};
