import { Formik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { ViewProps } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { renameProductGroup } from '../../commands/renameProductGroup';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import ModalConfirmHeader from '../../../../components/modal-confirm-header/ModalConfirmHeader';
import { TextField } from '../../../../components/text-field';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { KeyboardDismissView } from '../../../../lib/keyboard-dismiss-view';
import { ModalContentProps } from '../../../../lib/use-modal/useModal';
import { assertNever } from '../../../../lib/assert-never';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { SemanticColours } from '../../../../theme/SemanticColours';
import PlatformModal from '../../../../components/platform-modal/PlatformModal';

export type RenameGroupModalProps = ModalContentProps &
  Omit<ViewProps, 'children'> & {
    departmentId: string;
    existingName: string;
    visible: boolean;
  };

const rStyles = DynamicStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: SemanticColours.primary.background[90]
  },
  field: {
    borderWidth: 0,
    borderRadius: 0
  },
  error: {
    marginHorizontal: 24,
    marginVertical: 12
  }
});

const RenameGroupModal = ({
  style,
  visible,
  dismiss,
  departmentId,
  existingName,
  ...rest
}: RenameGroupModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const validate = useCallback(
    ({ value: newValue }: { value: string }) => {
      const trimmed = newValue.trim();

      if (!trimmed) {
        return { value: 'Name is required' };
      }

      return {};
    },
    [departmentId]
  );

  const { invoke, inProgress, result, reset } = useCommand(
    renameProductGroup,
    r => {
      if (!(r instanceof Error) && r.type === 'SUCCESS') {
        dismiss();
      }
    }
  );

  useEffect(() => {
    if (!visible) reset();
  }, [visible, reset]);

  const commandError = useMemo(() => {
    if (!result) {
      return null;
    }

    if (result instanceof Error) {
      return 'An unexpected error occurred.';
    }

    switch (result.type) {
      case 'NOT_FOUND': {
        return 'Product group no longer exists.';
      }
      case 'NAME_IN_USE': {
        return 'Product group name is already in use.';
      }
      case 'UNAUTHORISED': {
        return 'You are not authorised to make this change.';
      }
      case 'SUCCESS': {
        return null;
      }
      default:
        return assertNever(result);
    }
  }, [result]);

  const onSubmit = useCallback(
    ({ value: name }: { value: string }) => {
      invoke({ departmentId, newName: name.trim() });
    },
    [invoke, departmentId]
  );

  return (
    <PlatformModal visible={visible} onRequestClose={dismiss} hugsContent>
      <KeyboardDismissView style={[styles.container, style]} {...rest}>
        <Formik
          initialValues={{ value: existingName }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, ...formProps }) => (
            <>
              <ModalConfirmHeader
                title={`Rename ${existingName}`}
                onCross={dismiss}
                onTick={handleSubmit}
                tickEnabled={!inProgress}
              />
              <TextField
                {...formProps}
                autoCapitalize="words"
                autoCorrect
                autoFocus
                clearButtonMode="while-editing"
                containerComponent={props => (
                  <SafeAreaView
                    mode="padding"
                    edges={['left', 'right']}
                    {...props}
                  />
                )}
                enablesReturnKeyAutomatically
                editable={!inProgress}
                inputStyle={styles.field}
                name={'value'}
                onSubmitEditing={() => handleSubmit()}
                placeholder="Group name"
                returnKeyType="done"
              />
              {commandError && (
                <ErrorMessage style={styles.error}>{commandError}</ErrorMessage>
              )}
            </>
          )}
        </Formik>
      </KeyboardDismissView>
    </PlatformModal>
  );
};

export default RenameGroupModal;
