import { Modal, ModalProps, Platform, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { Colours } from '../../theme/Colours';
import { useSizeClass } from '../../lib/responsive-size-class';
import { webStyle } from './webStyle';

export type PlatformModalProps = Omit<
  ModalProps,
  'presentationStyle' | 'animationType' | 'transparent'
> & { hugsContent?: boolean };

const rStyles = DynamicStyleSheet.create({})
  .override(Selectors.all(Selectors.web, Selectors.mediumOrLarge), {
    screen: {
      backgroundColor: Colours.black.o(0.6).$,
      flex: 1
    },
    container: {
      borderRadius: 9,
      shadowColor: Colours.black.$,
      shadowOffset: {
        width: 0,
        height: 5
      },
      shadowOpacity: 0.34,
      shadowRadius: 6.27,
      overflow: 'hidden',
      minWidth: '50%',
      alignSelf: 'center',
      marginTop: '5%'
    },
    containerFills: {
      height: '80%'
    },
    containerHugsContent: {
      maxHeight: '80%'
    }
  })
  .override(Selectors.some(Selectors.ios, Selectors.android, Selectors.small), {
    screen: {
      flex: 1
    },
    container: {
      flex: 1
    }
  });

const PlatformModal = ({
  children,
  hugsContent,
  testID,
  ...rest
}: PlatformModalProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const size = useSizeClass();

  return (
    <Modal
      {...(Platform.OS === 'ios' ||
      Platform.OS === 'android' ||
      size === 'small'
        ? {
            animationType: 'slide',
            presentationStyle: 'formSheet'
          }
        : {
            animationType: 'fade',
            transparent: true
          })}
      {...rest}
    >
      <View style={[styles.screen, webStyle]} testID={testID}>
        <SafeAreaProvider>
          <View
            style={[
              styles.container,
              hugsContent ? styles.containerHugsContent : styles.containerFills
            ]}
          >
            {children}
          </View>
        </SafeAreaProvider>
      </View>
    </Modal>
  );
};

export default PlatformModal;
