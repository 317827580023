import React from 'react';
import { ViewProps } from 'react-native';
import EmptyContent from '../../../../../components/empty-list-content/EmptyContent';
import WaitIcon from '../../../../../components/icons/WaitIcon';

export type NoToDoContentProps = Omit<ViewProps, 'children'>;

const NoToDoContent = React.memo<NoToDoContentProps>(props => {
  return (
    <EmptyContent
      body="Waiting for new orders"
      iconComponent={WaitIcon}
      {...props}
    />
  );
});

export default NoToDoContent;
