import { Database } from '../../support/Database';
import { DashboardChartsDesignDoc } from './design/dashboard-charts/DashboardChartsDesignDoc';
import { DashboardDatesForPaymentDesignDoc } from './design/dashboard-dates-for-payment/DashboardDatesForPaymentDesignDoc';
import { DashboardDatesForProductDesignDoc } from './design/dashboard-dates-for-product/DashboardDatesForProductDesignDoc';
import { DashboardDatesForUserDesignDoc } from './design/dashboard-dates-for-user/DashboardDatesForUserDesignDoc';
import { DashboardDatesDesignDoc } from './design/dashboard-dates/DashboardDatesDesignDoc';
import { DashboardPaymentsForDateDesignDoc } from './design/dashboard-payments-for-date/DashboardPaymentsForDateDesignDoc';
import { DashboardProductsForDateDesignDoc } from './design/dashboard-products-for-date/DashboardProductsForDateDesignDoc';
import { DashboardUsersForDateDesignDoc } from './design/dashboard-users-for-date/DashboardUsersForDateDesignDoc';
import { ServiceTransactionsRepository } from './repositories/ServiceTransactionRepository';

export class TransactionsArchiveDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly design = {
    dashboardCharts: new DashboardChartsDesignDoc(this.database),
    dashboardDates: new DashboardDatesDesignDoc(this.database),
    dashboardDatesForPayment: new DashboardDatesForPaymentDesignDoc(
      this.database
    ),
    dashboardDatesForProduct: new DashboardDatesForProductDesignDoc(
      this.database
    ),
    dashboardDatesForUser: new DashboardDatesForUserDesignDoc(this.database),
    dashboardPaymentsForDate: new DashboardPaymentsForDateDesignDoc(
      this.database
    ),
    dashboardProductsForDate: new DashboardProductsForDateDesignDoc(
      this.database
    ),
    dashboardUsersForDate: new DashboardUsersForDateDesignDoc(this.database)
  };

  public readonly repository = new ServiceTransactionsRepository(this.database);
}
