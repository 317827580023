import { newRandomUuid } from '../../../../lib/uuid';
import { SiteCommand } from './documents/SiteCommand';
import { Repository } from '../../support/Repository';
import { Database } from '../../support/Database';
import { ChangesByObjectTimeDesignDoc } from './design/changes-by-object-time/ChangesByObjectTimeDesignDoc';

export class SiteCommandsDatabaseSchema {
  constructor(private readonly database: Database) {}

  public readonly design = {
    changesByObjectTime: new ChangesByObjectTimeDesignDoc(this.database)
  };

  public readonly repositories = {
    commands: new Repository<SiteCommand>(
      this.database,
      () => `command-${newRandomUuid()}`
    )
  };

  // eslint-disable-next-line class-methods-use-this
  public async ensureSchemaApplied(): Promise<void> {
    return Promise.resolve();
  }
}
