import { HeaderMenuOptions } from '../../../../components/header-menu/HeaderMenuOptions';
import useModalControl from '../../../../lib/use-modal/useModalControl';
import CreateGroupModal from '../modals/CreateGroupModal';

export type CreateGroupMenuOptionProps = {
  onGroupCreated: (groupId: string) => void;
};

const CreateGroupMenuOption = ({
  onGroupCreated
}: CreateGroupMenuOptionProps): JSX.Element => {
  const { present, ...modal } = useModalControl();

  return (
    <>
      <HeaderMenuOptions
        options={[{ label: 'Create new group', onSelect: present }]}
      />
      <CreateGroupModal onGroupCreated={onGroupCreated} {...modal} />
    </>
  );
};

export default CreateGroupMenuOption;
