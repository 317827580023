import { View, ViewProps, Text } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../theme/SemanticColours';
import WarningIcon from '../icons/WarningIcon';

export type WarningProps = Omit<ViewProps, 'children'> & {
  title: string;
  body?: string;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    paddingVertical: 12,
    paddingHorizontal: 19,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: SemanticColours.warnings.foreground[60],
    backgroundColor: SemanticColours.warnings.background[50]
  },
  icon: {
    color: SemanticColours.warnings.foreground[50],
    marginHorizontal: 7
  },
  title: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    color: SemanticColours.warnings.foreground[50],
    fontSize: 17,
    fontStyle: 'normal',
    fontWeight: '600',
    marginHorizontal: 7
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginVertical: 6
  },
  body: {
    alignSelf: 'stretch',
    color: SemanticColours.warnings.foreground[50],
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: '400',
    marginHorizontal: 7,
    marginVertical: 6
  }
});

const Warning = ({
  title,
  body,
  style,
  ...rest
}: WarningProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  return (
    <View style={[styles.root, style]} {...rest}>
      <View style={styles.header}>
        <WarningIcon style={styles.icon} fill={styles.icon.color} />
        <Text style={styles.title}>{title}</Text>
      </View>

      {body && <Text style={styles.body}>{body}</Text>}
    </View>
  );
};

export default Warning;
