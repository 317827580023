import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { CalendarDayPartition } from '../../../../../../data-model/schema/databases/site-transactions-archive/design/Partitions';
import { ServiceTransaction } from '../../../../../../data-model/schema/databases/site-transactions-archive/documents/ServiceTransaction';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { QueryRow } from '../../../../../../data-model/schema/support/DatabaseView';
import {
  DashboardProductsForDateKey,
  DashboardProductsForDateValue
} from '../../../../../../data-model/schema/databases/site-transactions-archive/design/dashboard-products-for-date/DashboardProductsForDateDesignDoc';
import { useServingZoneNameLookup } from '../../../../../../data-model/components/site-context/useServingZoneNameLookup';
import { useUsernameLookup } from '../../../../../../data-model/components/client-context/useUserNameLookup';
import { usePaginatedQuery } from '../../../../../../data-model/hooks/usePaginatedQuery';

const mapRow =
  (
    timeZone: string,
    servingZones: {
      [k: string]: string;
    },
    users: {
      [k: string]: string;
    }
  ) =>
  (
    rawRow: QueryRow<
      DashboardProductsForDateKey,
      DashboardProductsForDateValue,
      unknown,
      ServiceTransaction
    >
  ) => {
    // we know we're inputting a day so our result WILL look like this
    const row = rawRow as QueryRow<
      [
        interval: 'day',
        date: string,
        productId: string,
        sessionStartTime: string,
        userId: string,
        servingZoneId: string
      ],
      DashboardProductsForDateValue,
      object,
      ServiceTransaction
    >;

    return {
      key: `${row.documentID}-${row.key[2]}`,
      transactionId: row.documentID,
      time: DateTime.fromISO(row.key[3], { zone: timeZone }).toLocaleString(
        DateTime.TIME_24_SIMPLE
      ),
      user: users[row.key[4]] ?? 'Unknown user',
      servingZone: servingZones[row.key[5]] ?? 'Unknown location',
      value: row.value
    };
  };

export const useProductTransactionListData = (
  productId: string,
  partition: CalendarDayPartition
) => {
  const db = useTransactionsDb();

  const { data: servingZones } = useServingZoneNameLookup();
  const { data: users } = useUsernameLookup();

  const intervalDate = useMemo(() => {
    return partitionToIntervalDate(partition);
  }, [partition]);

  const { timeZone } = useSiteTime();

  const result = usePaginatedQuery(
    db.remote.design.dashboardProductsForDate.view,
    {
      startKey: [...intervalDate, productId],
      endKey: [...intervalDate, productId, []],
      limit: 20
    },
    rows => rows.map(mapRow(timeZone, servingZones, users)),
    [servingZones, users, timeZone]
  );

  useEffect(() => {
    if (result.state.state === 'EMPTY') {
      result.reload();
    }
  }, [result]);

  return result;
};
