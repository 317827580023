import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import {
  ComponentType,
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { RetainSplash } from '../../../lib/retain-splash/RetainSplash';
import { PersonalSecurityProvider } from '../../personal/PersonalSecurityProvider';
import { SiteOwnedSecurityProvider } from '../../site-owned/SiteOwnedSecurityProvider';
import { useLaunchArguments } from '../../../lib/launch-arguments';
import { useLogger } from '../../../lib/logging/useLogger';
import { nameof } from '../../../lib/name-of';
import { SecurityScheme, isSecurityScheme } from './SecurityScheme';
import {
  SecuritySchemeContext,
  SecuritySchemeContextType
} from './SecuritySchemeContext';

export type SecuritySchemeProviderSwitchProps = {
  children: ReactNode;
};

const ASYNC_STORAGE_KEY = 'SECURITY_KIND';

export const SecuritySchemeProviderSwitch = ({
  children
}: SecuritySchemeProviderSwitchProps): JSX.Element => {
  const log = useLogger(nameof({ SecuritySchemeProviderSwitch }));

  const launchArgs = useLaunchArguments<LaunchArguments | undefined>();

  const [currentSchemeInternal, setCurrentSchemeInternal] =
    useState<SecurityScheme>();

  const { getItem, setItem, removeItem } = useAsyncStorage(ASYNC_STORAGE_KEY);

  useEffect(() => {
    void (async () => {
      if (launchArgs?.reset) {
        await removeItem();
        log.info('Security reset');
      }

      const maybeScheme = await (async () => {
        if (launchArgs?.securityKind) {
          const { securityKind } = launchArgs;

          log.debug('Attempting to use security kind from launch arguments', {
            securityKind
          });

          await setItem(securityKind);
          return securityKind;
        }

        return getItem();
      })();

      if (!maybeScheme) {
        setCurrentSchemeInternal('NONE');
        return;
      }

      if (!isSecurityScheme(maybeScheme)) {
        log.warn('Invalid security scheme', { scheme: maybeScheme });
        setCurrentSchemeInternal('NONE');
        return;
      }

      log.info('Using scheme', { scheme: maybeScheme });
      setCurrentSchemeInternal(maybeScheme);
    })();
  }, []);

  const setCurrentScheme = useCallback(
    (newScheme: SecurityScheme) => {
      log.debug('Security scheme changing', { newScheme });

      void setItem(newScheme as string);
      setCurrentSchemeInternal(newScheme);
    },
    [setItem]
  );

  const InnerProvider = useMemo((): ComponentType<{ children: ReactNode }> => {
    if (currentSchemeInternal === 'PERSONAL') {
      return PersonalSecurityProvider;
    }

    if (currentSchemeInternal === 'SITE_OWNED') {
      return SiteOwnedSecurityProvider;
    }

    return Fragment;
  }, [currentSchemeInternal]);

  const value: SecuritySchemeContextType | null = useMemo(
    () =>
      currentSchemeInternal
        ? {
            currentScheme: currentSchemeInternal,
            setCurrentScheme
          }
        : null,
    [currentSchemeInternal, setCurrentScheme]
  );

  if (!value) {
    return <RetainSplash />;
  }

  return (
    <SecuritySchemeContext.Provider value={value}>
      <InnerProvider>{children}</InnerProvider>
    </SecuritySchemeContext.Provider>
  );
};
