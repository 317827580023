import { Product } from '../../documents/product/Product';
import { DatabaseView } from '../../../../support/DatabaseView';
import { Document } from '../../../../../types/Document';
import { Database } from '../../../../support/Database';

// eslint-disable-next-line func-names
const mapFn = function (doc: Document) {
  'public source';

  if (
    doc._id.indexOf('product-') === 0 &&
    !doc.deleted &&
    !doc.variantOfProductId
  ) {
    const productName =
      'productName' in doc && typeof doc.productName === 'string'
        ? doc.productName
        : null;

    const name =
      'name' in doc && typeof doc.name === 'string' ? doc.name : null;

    emit(productName || name, null);
  }
};

export class ProductsByNameView extends DatabaseView<string, null, Product> {
  static readonly viewName = 'productsByName';

  static definition = {
    map: mapFn.toString()
  };

  constructor(database: Database, designDocumentId: string) {
    super(database, designDocumentId, ProductsByNameView.viewName);
  }
}
