export class RgbColour {
  constructor(
    private readonly rgb: string,
    private readonly opacity: number = 1
  ) {}

  private opacityAsHex(): string {
    return Math.ceil(this.opacity * 255)
      .toString(16)
      .padStart(2, '0');
  }

  o(o: number): RgbColour {
    return new RgbColour(this.rgb, o);
  }

  get $() {
    return `#${this.rgb}${this.opacityAsHex()}`;
  }
}
