import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import {
  ProductV3,
  VariantArrayV2
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

const commandType = 'deleteVariant' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      variantId: string;
      name?: string;
    };
  }
}

export const deleteVariant = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'INVALID_VARIANT_TYPE' }
  | { type: 'UNAUTHORISED' }
> => {
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const { repositories } = ctx.databases.reference.local;

  const { productId, variantId } = args;
  const { userId, createdAt } = ctx.meta;

  const auditFields = {
    revisionUserID: userId,
    revisionTime: createdAt
  };

  let compatProductId: string | undefined;

  let name: string | null = null;

  const result = await repositories.products.updateDocument<
    'NOT_FOUND' | 'INVALID_VARIANT_TYPE',
    ProductV3
  >(productId, (p, update, abort) => {
    const asV3 = convertProductToV3(p);
    if (asV3 === 'N/A') {
      return abort('NOT_FOUND');
    }

    const variant = asV3.variants.find(v => v.id === variantId);
    if (!variant) {
      return abort('NOT_FOUND');
    }

    if (variant.$schema !== 'DeclaredVariant') {
      return abort('INVALID_VARIANT_TYPE');
    }

    name = variant.name;
    compatProductId = variant.compatProductId;

    return update({
      ...asV3,
      variants: asV3.variants.filter(v => v.id !== variantId) as VariantArrayV2,
      ...auditFields
    });
  });

  if (result.type === 'NOT_FOUND') {
    return result;
  }

  if (result.type === 'ABORTED') {
    return { type: result.reason };
  }

  if (result.type === 'SKIPPED') {
    throw new Error('Impossible');
  }

  if (compatProductId) {
    await repositories.products.updateDocument(compatProductId, (old, update) =>
      update({ ...old, ...auditFields, deleted: true })
    );
  }

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      variantId,
      name: name ?? undefined
    },
    [productId, variantId, ...(compatProductId ? [compatProductId] : [])]
  );

  return { type: 'SUCCESS', data: command };
};
