import { useContext } from 'react';
import { nameof } from '../../../lib/name-of';
import { SiteContext } from './SiteContext';

export const usePreparationOrdersDb = () => {
  const site = useContext(SiteContext);

  if (!site) {
    throw new Error(
      `${nameof({ usePreparationOrdersDb })} requires a ${nameof({
        SiteContext
      })}`
    );
  }

  return site.databases.preparationOrders;
};
