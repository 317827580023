import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useContextOrThrow } from '../../../../lib/use-context-or-throw';
import { nameof } from '../../../../lib/name-of';
import { ProductLookupCache } from '../ProductLookupCache';
import PreparationOrderLine from '../PreparationOrderLine';
import { Document } from '../../../../data-model/types/Document';
import { PreparationOrder } from '../../../../data-model/schema/databases/site-preparation-orders/documents/PreparationOrder';
import CellGroup, {
  CellGroupProps
} from '../../../../components/cells/CellGroup';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SemanticColours } from '../../../../theme/SemanticColours';
import Note from '../Note';

export type DetailModalLinesProps = Omit<CellGroupProps, 'children'> & {
  document: Document<PreparationOrder>;
};

const rStyles = DynamicStyleSheet.create({
  cell: {
    backgroundColor: SemanticColours.primary.background[100]
  },
  line: {
    paddingVertical: 12,
    paddingHorizontal: 20
  }
});

const DetailModalLines = React.memo<DetailModalLinesProps>(
  ({ document, ...rest }) => {
    const products = useContextOrThrow(
      ProductLookupCache,
      nameof({ ProductLookupCache })
    );

    const styles = useDynamicStyleSheet(rStyles);

    return (
      <CellGroup headingText="Lines" {...rest}>
        {document?.lines.map(line => (
          <SafeAreaView
            edges={['left', 'right']}
            key={line.ID}
            mode="padding"
            style={styles.cell}
          >
            <PreparationOrderLine
              text={products.data[line.productID]}
              note={line.notes ? <Note text={line.notes} /> : undefined}
              style={styles.line}
            />
          </SafeAreaView>
        ))}
      </CellGroup>
    );
  }
);

export default DetailModalLines;
