import { useCallback, useEffect, useMemo, useState } from 'react';

type Result<T> =
  | { type: 'SUCCESS'; data: T }
  | { type: 'ERROR'; error: unknown };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PromiseFactoryHook<TArgs extends any[], TResult> = {
  readonly args: TArgs | null;
  readonly inProgress: boolean;
  readonly result: Result<TResult> | null;
  readonly invoke: (...args: TArgs) => void;
  readonly reset: () => void;
};

type InvocationState = 'NEW' | 'EXECUTING' | 'SETTLED' | 'ABORTED';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Invocation<TArgs extends any[], TResult> = {
  state: InvocationState;
  args: TArgs;
  abortController: AbortController;
  result: Result<TResult> | null;
};

export const AbortReason = {
  replaced: 'REPLACED',
  unmounted: 'UNMOUNTED'
};

const NO_OP = () => {};

export const usePromiseFactory = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TFn extends (...args: any[]) => PromiseLike<any>
>(
  /** Must memoize */
  fn: TFn
): PromiseFactoryHook<Parameters<TFn>, Awaited<ReturnType<TFn>>> => {
  const [invocation, setInvocation] = useState<Invocation<
    Parameters<TFn>,
    Awaited<ReturnType<TFn>>
  > | null>(null);

  const invoke: PromiseFactoryHook<
    Parameters<TFn>,
    Awaited<ReturnType<TFn>>
  >['invoke'] = useCallback((...newArgs) => {
    setInvocation(existing => {
      if (existing) {
        if (!existing.result) {
          existing.abortController.abort(AbortReason.replaced);
        }
      }

      return {
        state: 'NEW',
        args: newArgs,
        abortController: new AbortController(),
        result: null
      };
    });
  }, []);

  useEffect(() => {
    if (invocation?.state !== 'NEW') {
      return NO_OP;
    }

    let mounted = true;

    const execute = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data: Awaited<ReturnType<TFn>> = await fn(...invocation.args);

        if (!mounted) return;

        setInvocation({
          ...invocation,
          state: 'SETTLED',
          result: { type: 'SUCCESS', data }
        });
      } catch (e: unknown) {
        if (!mounted) return;

        if (e instanceof Error && e.name === 'AbortError') {
          setInvocation({
            ...invocation,
            state: 'ABORTED'
          });
        } else {
          setInvocation({
            ...invocation,
            state: 'SETTLED',
            result: { type: 'ERROR', error: e }
          });
        }
      }
    };

    void execute();

    return () => {
      mounted = false;
      if (invocation) {
        if (!invocation.result) {
          invocation.abortController.abort(AbortReason.unmounted);
        }
      }
    };
  }, [invocation, fn]);

  return useMemo(
    () =>
      invocation
        ? {
            args: invocation.args,
            inProgress: invocation.state === 'EXECUTING',
            result: invocation.result,
            invoke,
            reset: () => setInvocation(null)
          }
        : {
            args: null,
            inProgress: false,
            result: null,
            invoke,
            reset: () => setInvocation(null)
          },
    [invocation, invoke]
  );
};
