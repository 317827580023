import { useEffect, useMemo, useState } from 'react';
import { SectionList } from 'react-native';
import {
  ProductListDepartmentData,
  ProductListRowData
} from '../../data/useProductListData';

type Ref = React.RefObject<
  SectionList<ProductListRowData, ProductListDepartmentData>
>;

type ScrollDestination = { departmentId: string | null; productId?: string };

export const useScrollTo = (
  ref: Ref,
  indexOfDepartment: (id: string | null) => number | undefined,
  indexOfProduct: (
    departmentId: string | null,
    productId: string
  ) => number | undefined
) => {
  const [location, setLocation] = useState<ScrollDestination | null>(null);

  useEffect(() => {
    if (!location) return;

    const { departmentId, productId } = location;

    const sectionIndex = indexOfDepartment(departmentId);

    const itemIndex = productId ? indexOfProduct(departmentId, productId) : 0;

    if (
      typeof sectionIndex === 'undefined' ||
      sectionIndex === -1 ||
      typeof itemIndex === 'undefined' ||
      itemIndex === -1
    ) {
      return;
    }

    ref.current?.scrollToLocation({
      sectionIndex,
      itemIndex,
      viewPosition: 0.5
    });

    setLocation(null);
  }, [location, indexOfDepartment, indexOfProduct]);

  return useMemo(
    () => ({
      scrollToDepartment: (departmentId: string | null) =>
        setLocation({ departmentId }),
      scrollToProduct: (departmentId: string | null, productId: string) =>
        setLocation({ departmentId, productId })
    }),
    [setLocation]
  );
};
