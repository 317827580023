import { Repository } from '../../../support/Repository';
import { newRandomUuid } from '../../../../../lib/uuid';
import { Department } from '../documents/Department';
import { Database } from '../../../support/Database';

export class DepartmentsRepository extends Repository<Department> {
  constructor(database: Database) {
    super(database, () => `department-${newRandomUuid()}`);
  }
}
