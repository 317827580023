import { AccessibilityRole, Text as Orig, TextProps } from 'react-native';
import { ArrayElement } from 'ts-array-extensions';
import { forwardRef } from 'react';
import Reanimated from 'react-native-reanimated';
import { initCap } from '../../lib/init-cap';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../lib/dynamic-style-sheet';
import { Selectors } from '../../lib/dynamic-style-sheet/Selectors';
import { SemanticColours } from '../../theme/SemanticColours';

const rStyles = DynamicStyleSheet.create({
  headerTitle: {
    fontSize: 17,
    fontWeight: '600',
    color: SemanticColours.primary.foreground[100]
  },
  headerSubtitle: {
    fontSize: 17,
    fontWeight: '400',
    color: SemanticColours.primary.foreground[80]
  },
  cellTitle: {
    fontSize: 17,
    fontWeight: '400',
    color: SemanticColours.primary.foreground[80],
    lineHeight: 26
  },
  cellSubtitle: {
    fontSize: 17,
    fontWeight: '400',
    color: SemanticColours.primary.foreground[50],
    lineHeight: 26
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: '400',
    color: SemanticColours.primary.foreground[50],
    lineHeight: 26,
    textTransform: 'uppercase'
  },
  sectionFooter: {
    color: SemanticColours.primary.foreground[50],
    fontSize: 14,
    lineHeight: 21
  },
  emphasis: {
    fontSize: 16,
    fontWeight: '700',
    color: SemanticColours.primary.foreground[100],
    lineHeight: 24
  },
  docTitle: {
    fontSize: 18,
    fontWeight: '700',
    color: SemanticColours.primary.foreground[70],
    lineHeight: 29
  },
  docBody: {
    fontSize: 16,
    fontWeight: '400',
    color: SemanticColours.primary.foreground[70],
    lineHeight: 22
  },
  bigNumber: {
    fontSize: 36,
    color: SemanticColours.primary.foreground[100],
    height: 50
  },
  navigation1: {
    fontSize: 15,
    fontWeight: '500',
    lineHeight: 24
  }
}).override(Selectors.mediumOrLarge, {
  headerTitle: {
    fontSize: 17
  }
});

const makeComponent = (
  styleKey: keyof ArrayElement<(typeof rStyles)['styles']>['styles'],
  accessibilityRole: AccessibilityRole
) => {
  const id = `Text.${initCap(styleKey)}`;
  const Component = forwardRef<Orig, TextProps>(
    ({ style, ...props }, ref): JSX.Element => {
      const styles = useDynamicStyleSheet(rStyles);

      return (
        <Orig
          style={[styles[styleKey], style]}
          accessibilityRole={accessibilityRole}
          ref={ref}
          {...props}
        />
      );
    }
  );

  Component.displayName = id;

  return Component;
};

const components = {
  BigNumber: makeComponent('bigNumber', 'text'),
  CellSubtitle: makeComponent('cellSubtitle', 'text'),
  CellTitle: makeComponent('cellTitle', 'text'),
  DocBody: makeComponent('docBody', 'text'),
  DocTitle: makeComponent('docTitle', 'text'),
  Emphasis: makeComponent('emphasis', 'text'),
  HeaderSubtitle: makeComponent('headerSubtitle', 'header'),
  HeaderTitle: makeComponent('headerTitle', 'header'),
  SectionFooter: makeComponent('sectionFooter', 'text'),
  SectionTitle: makeComponent('sectionTitle', 'text'),
  Navigation1: makeComponent('navigation1', 'text')
};

const Animated = Object.fromEntries(
  Object.entries(components).map(([key, value]) => [
    key,
    Reanimated.createAnimatedComponent(value)
  ])
) as Record<
  keyof typeof components,
  React.ForwardRefExoticComponent<TextProps & React.RefAttributes<Orig>>
>;

export const Text = {
  ...components,
  Animated
};
