import React, { useCallback, useMemo, useState } from 'react';
import { updateVariantPrice } from '../../commands/updateVariantPrice';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import BasicCell from '../../../../components/cells/BasicCell';
import LegacyCellGroup, {
  LegacyCellGroupProps
} from '../../../../components/cells/LegacyCellGroup';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import { formatMoney } from '../../../../humanisers/formatMoney';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import EditPriceModal from '../modals/EditPriceModal';
import EditScheduledPriceCell, {
  EditScheduledPriceCellProps
} from './EditScheduledPriceCell';
import { useProductRelationshipsContext } from '../../data/ProductRelationshipsContext';
import { useVariantDataContext } from '../../data/VariantDataContext';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { Alert } from '../../../../components/platform-alert/Alert';

export type PricingCellGroupProps = Omit<LegacyCellGroupProps, 'children'>;

const rStyles = DynamicStyleSheet.create({ container: {} });

const DEFAULT_PRICE_BAND_NAME = 'Standard';

const PricingCellGroup = ({
  style,
  ...rest
}: PricingCellGroupProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const {
    productId,
    variant,
    variantId,
    loading: documentLoading
  } = useVariantDataContext();

  const priceBands = useProductRelationshipsContext().data?.priceBands;

  const defaultPriceBandId =
    useProductRelationshipsContext().data?.defaultPriceBandId;

  const defaultPrice = useMemo(() => {
    if (!defaultPriceBandId || !variant || !('prices' in variant)) {
      return undefined;
    }

    return variant?.prices?.[defaultPriceBandId] ?? '0.00';
  }, [variant, defaultPriceBandId]);

  const data = useMemo<EditScheduledPriceCellProps[] | null>(() => {
    if (!priceBands || !variant || !defaultPrice) {
      return null;
    }

    let currentPrice = defaultPrice;
    let lastPriceName = DEFAULT_PRICE_BAND_NAME;

    return Object.entries(priceBands)
      .filter(([key]) => key !== defaultPriceBandId)
      .sortBy(v => v[0])
      .map(([documentID, name]) => {
        const maybePrice = 'prices' in variant && variant.prices?.[documentID];

        const inherited = !maybePrice;

        if (!inherited) {
          currentPrice = maybePrice;
        }

        const priceBandName = name ?? 'No name';

        const r: EditScheduledPriceCellProps = {
          priceBandId: documentID,
          priceBandName,
          currentPrice,
          inherited,
          canInheritFromPriceBandName: lastPriceName
        };

        if (!inherited) {
          lastPriceName = priceBandName;
        }

        return r;
      });
  }, [defaultPriceBandId, priceBands, variant]);

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const { invoke, inProgress } = useCommand(
    updateVariantPrice,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        dismissModal();
        return;
      }

      const errorMessage =
        result instanceof Error
          ? 'An unexpected error occurred.'
          : 'The product no longer exists.';

      Alert.alert('Error', `Could not update the price.\n\n${errorMessage}`, [
        { text: 'Cancel', onPress: () => dismissModal() },
        { text: 'Retry', onPress: () => retry() }
      ]);
    }
  );

  const onSubmit = useCallback(
    (price: string) => {
      if (!defaultPriceBandId) {
        return;
      }

      invoke({
        productId,
        variantId,
        priceBandId: defaultPriceBandId,
        newValue: { inherit: false, price }
      });
    },
    [productId, defaultPriceBandId]
  );

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <LegacyCellGroup
      headingText="Pricing"
      loading={documentLoading}
      style={[styles.container, style]}
      {...rest}
    >
      <BasicCell
        title={`${DEFAULT_PRICE_BAND_NAME} price`}
        subtitle={defaultPrice ? formatMoney(+defaultPrice * 100) : ''}
        titlesOrientation="horizontal"
        onPress={authorised ? () => setModalVisible(true) : undefined}
      />

      {defaultPrice && (
        <EditPriceModal
          disabled={inProgress}
          initialPrice={defaultPrice}
          onDismiss={dismissModal}
          onSubmit={onSubmit}
          title={`Edit standard price`}
          visible={modalVisible}
        />
      )}

      {data?.map(props => (
        <React.Fragment key={props.priceBandId}>
          <ListItemSeparator />
          <EditScheduledPriceCell {...props} />
        </React.Fragment>
      ))}
    </LegacyCellGroup>
  );
};

export default PricingCellGroup;
