import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import Nugget from '../../../../../../components/nugget/Nugget';
import NoSaleIcon from '../../../../../../components/icons/NoSaleIcon';
import { formatPercent } from '../../../../../../humanisers/formatPercent';
import VoidIcon from '../../../../../../components/icons/VoidIcon';

export type AccuracyProps = Omit<ViewProps, 'children'> & {
  paymentLines: null | number;
  noSales: null | number;
  lines: null | number;
  voids: null | number;
};

const rStyles = DynamicStyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: -14,
    flexWrap: 'wrap'
  },
  nugget: {
    marginHorizontal: 10
  }
});

const Accuracy = React.memo<AccuracyProps>(
  ({ style, paymentLines, noSales, lines, voids, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    return (
      <View style={[styles.container, style]} {...rest}>
        <Nugget
          style={styles.nugget}
          icon={NoSaleIcon}
          title="No sales"
          value={
            paymentLines && noSales
              ? formatPercent(noSales, paymentLines)
              : '0%'
          }
        />

        <Nugget
          style={styles.nugget}
          icon={VoidIcon}
          title="Undos"
          value={lines && voids ? formatPercent(voids, lines) : '0%'}
        />
      </View>
    );
  }
);

export default Accuracy;
