/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useContext } from 'react';

type ContextType<TContext> = TContext extends React.Context<infer T>
  ? NonNullable<T>
  : never;

export const useContextOrThrow = <TContext extends React.Context<any>>(
  context: TContext,
  name: string
): ContextType<TContext> => {
  const ctx = useContext(context);

  if (!ctx) {
    throw new Error(`${name} is required`);
  }

  return ctx;
};
