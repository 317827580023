import React, { useMemo } from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { DateTime } from 'luxon';
import Cell, { CellProps } from '../../../../components/cells/Cell';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { SiteCommand } from '../../../../data-model/schema/databases/site-commands/documents/SiteCommand';
import { useProductRelationshipsContext } from '../../data/ProductRelationshipsContext';
import UserIcon from '../../../../components/icons/UserIcon';
import { Text } from '../../../../components/text/Text';
import { SemanticColours } from '../../../../theme/SemanticColours';
import { formatMoney } from '../../../../humanisers/formatMoney';
import { useProductDataContext } from '../../data/ProductDataContext';

export type ChangeHistoryListItemProps = Omit<CellProps, 'children'> & {
  document: SiteCommand;
};

const rStyles = DynamicStyleSheet.create({
  root: {
    paddingHorizontal: 14,
    paddingVertical: 12
  },
  container: {
    flexDirection: 'row',
    flexShrink: 1
  },
  data: {
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 1
  },
  item: {
    paddingHorizontal: 4
  },
  icon: {
    color: SemanticColours.primary.foreground[30],
    width: 18,
    height: 18,
    margin: 6
  },
  time: {
    color: SemanticColours.primary.foreground[40],
    fontSize: 14
  },
  description: {
    paddingVertical: 8,
    flexWrap: 'wrap'
  }
});

const ChangeHistoryListItem = React.memo<ChangeHistoryListItemProps>(
  ({ style, document, ...rest }) => {
    const styles = useDynamicStyleSheet(rStyles);

    const relationships = useProductRelationshipsContext();

    const product = useProductDataContext();

    const description = useMemo(() => {
      switch (document.type) {
        case 'createProduct': {
          const group = document.departmentId
            ? relationships.data?.departments[document.departmentId]
            : undefined;

          return `Product created with price ${formatMoney(
            +document.standardPrice * 100
          )} ${group ? `in ${group}` : ''}`;
        }

        case 'createVariant':
          return `${document.name} variant added with price ${formatMoney(
            +document.price * 100
          )}`;

        case 'deleteVariant':
          return `Variant ${document.name ?? document.variantId} deleted`;

        case 'disableProductPreparation':
          return `Preparation disabled`;

        case 'enableProductPreparation':
          return `Preparation enabled`;

        case 'updateProductDepartment': {
          const group = document.newDepartmentId
            ? relationships.data?.departments[document.newDepartmentId]
            : undefined;

          return `Group changed to ${group ?? 'ungrouped'}`;
        }

        case 'updateProductName':
          return `Name changed to ${document.newName}`;

        case 'updateProductPreparationCourse': {
          const { oldCourse, newCourse } = document;

          return `Preparation course changed from ${
            !oldCourse || oldCourse === '0' ? 'none' : oldCourse
          } to ${newCourse === '0' ? 'none' : newCourse}`;
        }

        case 'updateProductPreparationZone': {
          const newZone =
            relationships.data?.preparationZones[document.newPreparationZoneId];

          const oldZone = document.oldPreparationZoneId
            ? relationships.data?.preparationZones[
                document.oldPreparationZoneId
              ]
            : null;

          return `Preparation zone changed from ${oldZone || 'none'} to ${
            newZone ?? 'unknown'
          }`;
        }

        case 'updateVariantName': {
          return `Variant ${
            document.oldName ?? document.variantId
          } name changed to ${document.newName}`;
        }

        case 'updateVariantPrice': {
          const oldPriceDescription = (() => {
            if (!document.oldValue) return 'unknown';

            return document.oldValue?.inherit
              ? 'inherited'
              : formatMoney(+document.oldValue.price * 100);
          })();

          const newPriceDescription = document.newValue.inherit
            ? 'inherited'
            : formatMoney(+document.newValue.price * 100);

          return `Variant ${
            relationships.data?.priceBands[document.priceBandId] ?? 'standard'
          } price changed from ${oldPriceDescription} to ${newPriceDescription}`;
        }

        case 'enableStockTracking': {
          const { variantId } = document;

          const variantName =
            product.product?.variants.find(v => v.id === variantId)?.name ??
            variantId;

          return `Stock mode changed to tracked for variant ${variantName}`;
        }

        case 'disableStockTracking': {
          const { variantId } = document;

          const variantName =
            product.product?.variants.find(v => v.id === variantId)?.name ??
            variantId;

          return `Stock mode changed to untracked for variant ${variantName}`;
        }

        default:
          return document.type;
      }
    }, [document, relationships, product]);

    return (
      <Cell style={[styles.root, style]} {...rest}>
        <SafeAreaView edges={['left', 'right']} style={styles.container}>
          <View style={styles.icon}>
            <UserIcon
              fill={styles.icon.color}
              width={styles.icon.width}
              height={styles.icon.height}
            />
          </View>

          <View style={styles.data}>
            <Text.CellSubtitle style={styles.item}>
              {relationships.data?.users[document.userId] ?? ''}
            </Text.CellSubtitle>

            <Text.CellSubtitle style={[styles.item, styles.time]}>
              {DateTime.fromISO(document.createdAt, {
                zone: 'utc'
              }).toRelative()}
            </Text.CellSubtitle>

            <Text.CellTitle style={[styles.item, styles.description]}>
              {description}
            </Text.CellTitle>
          </View>
        </SafeAreaView>
      </Cell>
    );
  }
);

export default ChangeHistoryListItem;
