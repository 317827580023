import { ViewProps } from 'react-native';
import { useCallback, useState } from 'react';
import { updateVariantName } from '../../commands/updateVariantName';
import { useCommand } from '../../../../data-model/hooks/useCommand';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { useVariantDataContext } from '../../data/VariantDataContext';
import { useProductDataContext } from '../../data/ProductDataContext';
import EditIcon from '../../../../components/icons/EditIcon';
import BasicCell from '../../../../components/cells/BasicCell';
import EditValueModal from '../../../../components/edit-value-modal/EditValueModal';
import { useAuthorisation } from '../../../../security/hooks/useAuthorisation';
import { RoleId } from '../../../../data-model/schema/databases/client-security/documents/Role';
import { Alert } from '../../../../components/platform-alert/Alert';

export type EditVariantNameCellProps = ViewProps;

const rStyles = DynamicStyleSheet.create({ container: {} });

const EditVariantNameCell = ({
  style,
  ...rest
}: EditVariantNameCellProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const { product, productId } = useProductDataContext();

  const { variantId, variant, loading: docLoading } = useVariantDataContext();

  const [modalVisible, setModalVisible] = useState(false);

  const dismissModal = useCallback(
    () => setModalVisible(false),
    [setModalVisible]
  );

  const { invoke, inProgress } = useCommand(
    updateVariantName,
    (result, retry) => {
      if (!(result instanceof Error) && result.type === 'SUCCESS') {
        dismissModal();
        return;
      }

      const errorMessage =
        result instanceof Error
          ? 'An unexpected error occurred.'
          : 'The product no longer exists.';

      Alert.alert(
        'Error',
        `Could not update the product's group.\n\n${errorMessage}`,
        [
          { text: 'Cancel', onPress: () => dismissModal() },
          { text: 'Retry', onPress: () => retry() }
        ]
      );
    }
  );

  const validator = useCallback(
    (newName: string) => {
      if (!newName.trim()) {
        return 'Name is required';
      }

      const trimmed = newName.trim();

      const existing = product?.variants
        .filter(v => v.id !== variantId)
        .find(
          d =>
            trimmed.localeCompare(d.name, undefined, {
              sensitivity: 'base'
            }) === 0
        );

      if (existing) {
        return 'Name is already in use';
      }

      return null;
    },
    [product]
  );

  const { authorised } = useAuthorisation(RoleId.superUser);

  return (
    <>
      <BasicCell
        {...rest}
        style={[styles.container, style]}
        disabled={docLoading || inProgress}
        title={variant?.name || (docLoading ? ' ' : 'No name')}
        {...(authorised
          ? { iconRight: EditIcon, onPress: () => setModalVisible(true) }
          : {})}
      />
      <EditValueModal
        disabled={docLoading || inProgress}
        placeholder="Variant name"
        title="Edit name"
        value={variant?.name || (docLoading ? ' ' : 'No name')}
        validator={validator}
        onDismiss={dismissModal}
        onEdited={newName => {
          invoke({ productId, variantId, newName: newName.trim() });
        }}
        visible={modalVisible}
      />
    </>
  );
};

export default EditVariantNameCell;
