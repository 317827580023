import { useCallback, useMemo } from 'react';
import { useReferenceDb } from '../../../data-model/components/site-context/useReferenceDb';
import { useQueryStream } from '../../../data-model/hooks/useQueryStream';
import { Product } from '../../../data-model/schema/databases/site-reference/documents/product/Product';
import { Document } from '../../../data-model/types/Document';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';

export type ProductListRowData = {
  id: string;
  name: string;
  priceRange: [number, number];
};

export type ProductListDepartmentData = {
  department: {
    id: string | null;
    name: string | null;
    count: number;
  };
};

export type ProductListSectionData = ProductListDepartmentData & {
  data: ProductListRowData[];
};

const mapProductListRowData =
  (priceBandIds: string[]) =>
  (queryRow: {
    readonly documentID: string;
    readonly document: Document<Product>;
  }): ProductListRowData | null => {
    const product = convertProductToV3(queryRow.document);

    if (product === 'N/A') {
      return null;
    }

    const allPrices = product.variants
      .flatMap(v =>
        'prices' in v
          ? Object.entries(v.prices)
              .filter(e => priceBandIds.includes(e[0]))
              .map(e => +e[1] * 100)
          : []
      )
      .sortBy(p => p);

    const variantOfNote =
      product.variants.length === 1 &&
      product.variants[0].$schema === 'DefaultImpliedVariant'
        ? product.variants[0].name
        : null;

    return {
      id: queryRow.documentID,
      name: `${product.productName}${
        variantOfNote ? ` (${variantOfNote})` : ''
      }`,
      priceRange: [allPrices.min() ?? 0, allPrices.max() ?? 0]
    };
  };

export const NO_DEPARTMENT = '__NONE__';

export const useProductListData = () => {
  const { design } = useReferenceDb().local;

  const { data: productData, loading: productDataLoading } = useQueryStream(
    design.views.nonVariantProductsByDepartment,
    { includeDocs: true }
  );

  const { data: departmentData, loading: departmentDataLoading } =
    useQueryStream(design.views.allDepartments, {
      startKey: [false],
      endKey: [false, []],
      includeDocs: true
    });

  const { data: priceBandData, loading: priceBandsLoading } = useQueryStream(
    design.views.allPriceBands
  );

  const data: ProductListSectionData[] | undefined = useMemo(() => {
    if (!departmentData || !productData || !priceBandData) {
      return undefined;
    }

    const priceBandIds = priceBandData.map(d => d.documentID);

    const productsByDepartmentId = Object.fromEntries(
      productData
        .filter(p => !p.key[1]) // remove deleted
        .groupBy(p => p.key[0])
        .map(group => [
          group.key ?? NO_DEPARTMENT,
          group.values
            .compactMap(mapProductListRowData(priceBandIds))
            .sortBy(p => p.name)
        ])
    );

    const departments = departmentData
      .filter(dept => !dept.document.isInternal)
      .map(dept => ({
        id: dept.documentID as string | null,
        name: dept.key[1] as string | null
      }))
      .concat(
        Object.keys(productsByDepartmentId).includes(NO_DEPARTMENT)
          ? [{ id: null, name: null }]
          : []
      );

    return departments.map(dept => {
      return {
        department: {
          ...dept,
          count: productsByDepartmentId[dept.id ?? NO_DEPARTMENT]?.length ?? 0
        },
        data: productsByDepartmentId[dept.id ?? NO_DEPARTMENT] ?? []
      };
    });
  }, [productData, departmentData, priceBandData]);

  const indexOfDepartment = useCallback(
    (id: string | null) => {
      return data?.map(({ department }) => department.id).indexOf(id);
    },
    [data]
  );

  const indexOfProduct = useCallback(
    (departmentId: string | null, productId: string) => {
      return productData
        ?.filter(p => p.key[0] === departmentId && !p.key[1])
        .findIndex(p => p.documentID === productId);
    },
    [productData]
  );

  return {
    loading: productDataLoading || departmentDataLoading || priceBandsLoading,
    data,
    indexOfDepartment,
    indexOfProduct
  };
};
