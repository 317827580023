import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  DatabaseView,
  GroupLevelOf,
  GroupLevelsFor,
  KeyForGroupLevel,
  QueryOptions,
  QueryRow,
  RangeKey,
  RangeKeyForGroupLevel,
  ViewKey
} from '../schema/support/DatabaseView';
import { DocumentProperties } from '../types/Document';
import { QueryPaginator } from '../tools/query-paginator/QueryPaginator';
import { QueryPaginatorState } from '../tools/query-paginator/QueryPaginatorState';

export type PaginatedQueryHookValue<TRow, TKey> = {
  state: QueryPaginatorState<TRow, RangeKey<TKey>>;
  reload: (to?: number | RangeKey<TKey>) => void;
  loadMore: (to?: number | RangeKey<TKey>) => void;
  rangeContains(key: RangeKey<TKey>): boolean;
};

export const usePaginatedQuery = <
  TKey extends ViewKey,
  TValue,
  TDoc extends DocumentProperties,
  const TOptions extends QueryOptions<
    TKey,
    GroupLevelOf<TOptions, TKey> & GroupLevelsFor<TKey>
  >,
  const U
>(
  view: DatabaseView<TKey, TValue, TDoc>,
  options: TOptions,
  transformer: (
    rows: readonly QueryRow<TKey, TValue, TOptions, TDoc>[]
  ) => readonly U[] = v => v as U[],
  transformerDeps?: DependencyList
): PaginatedQueryHookValue<
  U,
  KeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
> => {
  const [paginator, setPaginator] = useState<
    QueryPaginator<TKey, TValue, TDoc, TOptions, U> | undefined
  >(undefined);

  const [state, setState] = useState<
    QueryPaginatorState<
      U,
      RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
    >
  >({ state: 'EMPTY' });

  // handle primary arguments changing
  useEffect(() => {
    const p = new QueryPaginator(view, options, transformer);

    setState(e => (e.state !== 'EMPTY' ? { state: 'EMPTY' } : e));

    const subscription = p.state.subscribe({
      next: v => {
        setState(v);
      }
    });

    setPaginator(p);

    return () => {
      subscription.dispose();
      p.dispose();
    };
  }, [
    view.constructor.name,
    view.database.pouch.name,
    view.designDocumentId,
    view.viewName,
    JSON.stringify(options)
  ]);

  useEffect(() => {
    if (transformer) {
      paginator?.reTransform(transformer);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  }, transformerDeps ?? []);

  const reload = useCallback(
    (
      to?: number | RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
    ) => {
      paginator?.reload(to ?? options.limit ?? 50);
    },
    [paginator]
  );

  const loadMore = useCallback(
    (
      to?: number | RangeKeyForGroupLevel<TKey, GroupLevelOf<TOptions, TKey>>
    ) => {
      paginator?.loadMore(to ?? options.limit ?? 50);
    },
    [paginator]
  );

  useEffect(() => {
    if (state.state === 'ERROR') {
      const { error } = state;
      throw error;
    }
  }, [state]);

  return useMemo(
    () => ({
      state,
      reload,
      loadMore,

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rangeContains: paginator
        ? paginator.rangeContains.bind(paginator)
        : () => false
    }),
    [paginator, state, reload, loadMore]
  );
};
