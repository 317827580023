import { DatabaseView } from '../../../../support/DatabaseView';
import {
  PaymentType,
  ReversalType,
  ServiceTransaction
} from '../../documents/ServiceTransaction';
import { TimeInterval } from '../../../../../../features/dashboard/types/TimeInterval';
import { DashboardSummarisedData } from '../DashboardSummarisedData';
import { Database } from '../../../../support/Database';

export type DashboardUsersForDateKey =
  | [interval: TimeInterval, date: string, userId: string]
  | [
      interval: 'day',
      date: string,
      userId: string,
      time: string,
      servingZoneId: string
    ];

export type DashboardUsersForDateValue = Readonly<
  Partial<
    Record<
      ReversalType | 'Sale' | 'Promotion' | PaymentType,
      DashboardSummarisedData
    >
  >
>;

export class DashboardUsersForDateDesignDoc {
  constructor(private readonly database: Database) {}

  public readonly view = new DatabaseView<
    DashboardUsersForDateKey,
    DashboardUsersForDateValue,
    ServiceTransaction
  >(this.database, '_design/dashboard-users-for-date', 'main');
}
