/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
import { useMemo } from 'react';
import { Platform, useColorScheme } from 'react-native';
import { AnyStyle } from './AnyStyle';
import { useTheme } from '../theme-context';
import {
  DynamicStyleProperty,
  StyleWithDynamics,
  StyleWithoutDynamics
} from './DynamicStyleProperty';
import { Selector, Selectors } from './Selectors';
import { EvaluationContext } from './EvaluationContext';
import { flattenStyle } from './flattenStyle';
import { useSizeClass } from '../responsive-size-class';

type NamedDynamicStyles<T> = { [P in keyof T]: StyleWithDynamics<AnyStyle> };

type DynamicStyleSheetEntry<T> = {
  selector: Selector;
  styles: T;
};

type DynamicStyleSheets<T> = {
  override<T2 extends NamedDynamicStyles<T> | NamedDynamicStyles<any>>(
    selector: Selector,
    styles: T2
  ): DynamicStyleSheets<T & Partial<T2>>;
  styles: DynamicStyleSheetEntry<T>[];
};

const deconstructDynamicValues = <T extends NamedDynamicStyles<T>>(
  entry: DynamicStyleSheetEntry<T>
): DynamicStyleSheetEntry<T>[] => {
  const result: DynamicStyleSheetEntry<T>[] = [];

  const emit = (selector: Selector, name: string, style: AnyStyle) => {
    result.push({
      selector: Selectors.all(entry.selector, selector),
      styles: { [name]: style } as T
    });
  };

  return [
    {
      selector: entry.selector,
      styles: Object.fromEntries(
        Object.entries(entry.styles).map(([styleName, style]) => [
          styleName,
          Object.fromEntries(
            Object.entries(style as StyleWithDynamics<AnyStyle>).compactMap(
              ([key, property]) => {
                if (typeof property === 'function') {
                  const results = (property as DynamicStyleProperty<unknown>)();
                  results.forEach(({ selector, value }) =>
                    emit(selector, styleName, { [key]: value })
                  );
                  return null;
                }

                return [key, property];
              }
            )
          )
        ])
      ) as T
    },
    ...result
  ];
};

export namespace DynamicStyleSheet {
  export function create<
    T extends NamedDynamicStyles<T> | NamedDynamicStyles<any>
  >(base: T): DynamicStyleSheets<T> {
    const allStyles: DynamicStyleSheetEntry<unknown>[] = [];

    const override = <T2>(
      selector: Selector,
      other: NamedDynamicStyles<T2>
    ) => {
      const asStatic = deconstructDynamicValues({
        selector,
        styles: other
      });

      allStyles.push(...asStatic);

      return {
        styles: allStyles,
        override
      };
    };

    return override(Selectors.default, base) as DynamicStyleSheets<T>;
  }
}

type DynamicStyleSheetResult<T> = {
  [P in keyof T]: StyleWithoutDynamics<T[P]>;
};

export const useDynamicStyleSheet = <T extends object>(
  { styles }: DynamicStyleSheets<T>,
  contextOverrides?: Partial<EvaluationContext>
): DynamicStyleSheetResult<T> => {
  const size = useSizeClass();

  const systemColourScheme = useColorScheme();

  const colourScheme = useTheme();

  return useMemo(() => {
    const context: EvaluationContext = {
      size,
      theme: colourScheme,
      platform: Platform.OS,
      ...contextOverrides
    };

    const others = styles.filter(style => style.selector(context));

    const keys = others
      .map(o => Object.keys(o.styles))
      .flat()
      .distinct() as (keyof T)[];

    const p = keys.toRecord(
      k => k,
      k => {
        const outputStyles = flattenStyle(others.map(o => o.styles[k]));

        return outputStyles;
      }
    );

    return p as DynamicStyleSheetResult<T>;
  }, [size, systemColourScheme, colourScheme, contextOverrides]);
};
