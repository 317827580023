import { FlatList, ScrollViewProps } from 'react-native';
import { ComponentType, useCallback, useMemo } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../../../lib/dynamic-style-sheet';
import { useTransactionsDb } from '../../../../../../data-model/components/site-context/useTransactionsDb';
import { partitionToSubPartitions } from '../../../../helpers/partitionToSubPartitions';
import { useActivePartition } from '../../../../components/active-partition-context/ActivePartitionContext';
import { isCalendarDayPartition } from '../../../../helpers/isCalendarDayPartition';
import thenThrow from '../../../../../../lib/then-throw';
import { useTransform } from '../../../../../../data-model/hooks/useTransform';
import { useQueryStream } from '../../../../../../data-model/hooks/useQueryStream';
import { DashboardNavigatorParamList } from '../../../../navigation/DashboardNavigatorParamList';
import { formatMoney } from '../../../../../../humanisers/formatMoney';
import { useSiteTime } from '../../../../hooks/useSiteTime';
import { dateTimeToReportDate } from '../../../../helpers/dateTimeToReportDate';
import BasicCell from '../../../../../../components/cells/BasicCell';
import { formatPartition } from '../../../../../../humanisers/formatPartition';
import { ForwardIcon } from '../../../../../../components/icons/ForwardIcon';
import CellGroupHeading from '../../../../../../components/cells/CellGroupHeading';
import CellGroupSeparator from '../../../../../../components/cells/CellGroupSeparator';
import { partitionToIntervalDate } from '../../../../helpers/partitionToIntervalDate';
import { comparePartitions } from '../../../../helpers/comparePartitions';
import { Partition } from '../../../../types/Partition';
import { partitionToComponentKey } from '../../../../helpers/partitionToComponentKey';
import { partitionToDate } from '../../../../helpers/partitionToDate';
import { useCellGroupStyles } from '../../../../../../components/cells/useCellGroupStyles';
import { PartitionSerialiser } from '../../../../navigation/PartitionSerialiser';
import PlatformScrollView from '../../../../../../components/platform-scroll-view/PlatformScrollView';

export type UserSubIntervalsListProps = Omit<ScrollViewProps, 'children'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListHeaderComponent?: ComponentType<any>;
};

const rStyles = DynamicStyleSheet.create({
  container: {},
  content: { marginVertical: 20 }
});

const UserSubIntervalsList = ({
  style,
  ListHeaderComponent,
  ...rest
}: UserSubIntervalsListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const db = useTransactionsDb();

  const partition = useActivePartition();

  if (isCalendarDayPartition(partition)) {
    throw new Error(
      'Transaction sub intervals list does not support calendar day partitions'
    );
  }

  const route = useRoute<RouteProp<DashboardNavigatorParamList, 'user'>>();

  const navigation =
    useNavigation<StackNavigationProp<DashboardNavigatorParamList, 'user'>>();

  if (!route.params?.userId) {
    throw new Error('Transaction list requires a user id');
  }

  const siteSettings = useSiteTime();

  const subPartitions = useMemo(() => {
    const parts = partitionToSubPartitions(partition).map(subPartition => ({
      subPartition,
      intervalDate: partitionToIntervalDate(subPartition)
    }));

    const today =
      dateTimeToReportDate(siteSettings)().toISODate() ??
      thenThrow("Can't get current date");

    return parts
      .filter(p => p.intervalDate[1] <= today)
      .map(p => p.subPartition);
  }, [partition, siteSettings]);

  const subPartitionsType = useMemo(() => {
    if (subPartitions[0][0] === 'iso') {
      return 'Weeks';
    }

    if (subPartitions[0].length === 3) {
      return 'Months';
    }

    if (subPartitions[0].length === 4) {
      return 'Days';
    }

    return null;
  }, [subPartitions]);

  const { data } = useTransform(
    useQueryStream(db.remote.design.dashboardDatesForUser.view, {
      startKey: [
        route.params.userId,
        ...(subPartitions.first() ?? thenThrow('Invalid partition'))
      ],
      endKey: [
        route.params.userId,
        ...(subPartitions.last() ?? thenThrow('Invalid partition')),
        []
      ],
      groupLevel: (subPartitions[0].length + 1) as 4 | 5,
      reduce: true,
      inclusiveEnd: true
    }),
    rows =>
      subPartitions
        .leftJoin(
          rows,
          (l, r) =>
            comparePartitions(l, r.key.slice(1) as unknown as Partition) === 0
        )
        .map(({ left: rowPartition, right: row }) => {
          return {
            key:
              partitionToDate(rowPartition).toISODate() ??
              thenThrow('Invalid partition'),
            partition: rowPartition,
            value: row?.value ? formatMoney(row.value.value) : null
          };
        })
        .sortBy(x => x.key),
    () =>
      subPartitions.map(p => ({
        key: partitionToComponentKey(p),
        partition: p,
        value: null
      })),
    [subPartitions]
  );

  const { cell, header, Separator } = useCellGroupStyles('left');

  const HeaderComponent: ComponentType<unknown> = useCallback(
    () => (
      <>
        {ListHeaderComponent && <ListHeaderComponent />}
        {subPartitionsType ? (
          <>
            {ListHeaderComponent && <CellGroupSeparator style={header} />}
            <CellGroupHeading title={subPartitionsType} style={header} />
          </>
        ) : null}
      </>
    ),
    [subPartitionsType, ListHeaderComponent]
  );

  return (
    <FlatList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      style={[styles.container, style]}
      contentContainerStyle={styles.content}
      data={data}
      keyExtractor={x => x.key}
      ItemSeparatorComponent={Separator}
      ListHeaderComponent={HeaderComponent}
      renderItem={({ item, index }) => (
        <BasicCell
          style={cell(index === 0, index === data.length - 1)}
          title={formatPartition(siteSettings)(item.partition)}
          subtitle={item.value ?? ''}
          iconRight={item.value ? ForwardIcon : undefined}
          titlesOrientation="horizontal"
          onPress={
            item.value
              ? () => {
                  navigation.push('user', {
                    userId: route.params?.userId,
                    partition: PartitionSerialiser.stringify(item.partition)
                  });
                }
              : undefined
          }
        />
      )}
      {...rest}
    />
  );
};

export default UserSubIntervalsList;
