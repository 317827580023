import { HslColour } from '../lib/colours/HslColour';
import { RgbColour } from '../lib/colours/RgbColour';

export class Colours {
  static readonly transparent = new RgbColour('000000', 0);
  static readonly magenta = new HslColour(300, 100, 30);
  static readonly white = new HslColour(0, 0, 100);
  static readonly black = new HslColour(0, 0, 0);
  static readonly red = new HslColour(360, 100, 50);
}
