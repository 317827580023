import { NotAuthorisedIcon } from './NotAuthorisedIcon';
import { UnhappyPathBaseContent } from '../UnhappyPathContentBase';

export type NotAuthorisedContentProps = {
  objectDescription: string;
};

export const NotAuthorisedContent = ({
  objectDescription
}: NotAuthorisedContentProps): JSX.Element => (
  <UnhappyPathBaseContent
    icon={NotAuthorisedIcon}
    title="Not authorised"
    hint={`You do not have permission to view ${objectDescription}.`}
  />
);
