import { CommandContext } from '../../../data-model/types/CommandContext';
import { CommandBase } from '../../../data-model/schema/databases/shared/documents/CommandBase';
import { createCommandDocument } from '../../../data-model/helpers/createCommandDocument';
import { roleIsAtLeast } from '../../../security/helpers/roleIsAtLeast';
import { RoleId } from '../../../data-model/schema/databases/client-security/documents/Role';
import { convertProductToV3 } from '../../../helpers/convertProductToV3';
import {
  ProductV3,
  isDeclaredVariantV2
} from '../../../data-model/schema/databases/site-reference/documents/product/ProductV3';

const commandType = 'updateProductName' as const;

declare global {
  interface SiteCommands {
    [commandType]: {
      type: typeof commandType;
      productId: string;
      newName: string;
    };
  }
}

export const updateProductName = async (
  args: Omit<SiteCommands[typeof commandType], 'type'>,
  ctx: CommandContext
): Promise<
  | {
      type: 'SUCCESS';
      data: CommandBase & SiteCommands[typeof commandType];
    }
  | { type: 'NOT_FOUND' }
  | { type: 'NAME_IN_USE' }
  | { type: 'UNAUTHORISED' }
> => {
  const {
    repositories,
    design: { views }
  } = ctx.databases.reference.local;
  if (!roleIsAtLeast(ctx.meta.role, RoleId.superUser)) {
    return { type: 'UNAUTHORISED' };
  }

  const { productId, newName } = args;
  const { userId, createdAt } = ctx.meta;

  const existingProducts = await views.allProducts.getQuery({
    includeDocs: true
  });

  const existingProductNames = existingProducts
    .map(p => convertProductToV3(p.document))
    .compactMap(p =>
      p !== 'N/A' && !p.deleted && p._id !== productId ? p.productName : null
    );

  if (existingProductNames.includes(newName)) {
    return { type: 'NAME_IN_USE' };
  }

  const result = await repositories.products.updateDocument<
    'NOT_FOUND',
    ProductV3
  >(productId, (old, update, abort) => {
    const asV3 = convertProductToV3(old);

    if (asV3 === 'N/A') {
      return abort('NOT_FOUND');
    }

    return update({
      ...asV3,
      name:
        asV3.variants[0].$schema === 'DefaultImpliedVariant'
          ? `${newName} ${asV3.variants[0].name}`
          : newName,
      productName: newName,
      revisionUserID: userId,
      revisionTime: createdAt
    });
  });

  if (result.type !== 'SUCCESS') {
    return { type: 'NOT_FOUND' };
  }

  await Promise.all(
    result.data.variants.compactMap(v => {
      if (v.$schema !== 'DeclaredVariant') {
        return null;
      }

      return repositories.products.updateDocument(
        v.compatProductId,
        (p, update) => {
          update({
            ...p,
            name: `${newName} ${v.name}`
          });
        }
      );
    })
  );

  const command = await createCommandDocument(
    ctx,
    {
      type: commandType,
      productId,
      newName
    },
    [
      productId,
      ...result.data.variants.compactMap(v =>
        isDeclaredVariantV2(v) ? v.compatProductId : null
      )
    ]
  );

  return { type: 'SUCCESS', data: command };
};
