import { DatabasePair } from '../../support/DatabasePair';
import { RemoteDatabaseConfiguration } from '../../support/RemoteDatabaseConfiguration';
import { PreparationOrdersDatabaseSchema } from './PreparationOrdersDatabaseSchema';

export class PreparationOrdersDatabase extends DatabasePair<PreparationOrdersDatabaseSchema> {
  constructor(
    clientId: string,
    siteId: string,
    queryConfig: RemoteDatabaseConfiguration,
    syncConfig: RemoteDatabaseConfiguration
  ) {
    super(
      `sp-${clientId}-${siteId}-preparation-orders`,
      PreparationOrdersDatabaseSchema,
      queryConfig,
      syncConfig
    );
  }
}
