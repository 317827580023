import React from 'react';
import { RoleId } from '../../data-model/schema/databases/client-security/documents/Role';

export type UserContextType = {
  readonly userId: string;
  readonly username: string;
  readonly fullName: string;
  readonly role: RoleId;
};

const UserContext = React.createContext<UserContextType | null>(null);

export default UserContext;
