import { FlatList, RefreshControl, ViewProps } from 'react-native';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DynamicStyleSheet,
  useDynamicStyleSheet
} from '../../../../lib/dynamic-style-sheet';
import { usePartitionNavigation } from '../partition-navigation-context/usePartitionNavigation';
import { TimeInterval } from '../../types/TimeInterval';
import { ProblemContent } from '../../../../components/unhappy-path-content/problem/ProblemContent';
import EmptyContent from '../../../../components/empty-list-content/EmptyContent';
import { SearchIcon } from '../../../../components/icons/SearchIcon';
import ListItemSeparator from '../../../../components/cells/ListItemSeparator';
import { partitionToComponentKey } from '../../helpers/partitionToComponentKey';
import { Partition } from '../../types/Partition';
import { partitionToIntervalDate } from '../../helpers/partitionToIntervalDate';
import PartitionListCell from './PartitionListCell';
import { PartitionNavigationSlice } from '../partition-navigation-context/PartitionNavigationContext';
import PlatformScrollView from '../../../../components/platform-scroll-view/PlatformScrollView';

export type PartitionListProps = Omit<ViewProps, 'children'> & {
  selectedInterval: TimeInterval;
  selectedDate?: string;
  onSelect: (date: string) => void;
};

const rStyles = DynamicStyleSheet.create({
  container: {}
});

const PartitionList = ({
  style,
  selectedInterval,
  selectedDate,
  onSelect,
  ...rest
}: PartitionListProps): JSX.Element => {
  const styles = useDynamicStyleSheet(rStyles);

  const calendar = usePartitionNavigation();

  const listState = useMemo(() => {
    // Flatlist doesn't seem to like the union
    return calendar[selectedInterval] as PartitionNavigationSlice<
      Partition,
      2 | 3 | 4
    >;
  }, [calendar, selectedInterval]);

  // initial load if required
  useEffect(() => {
    if (listState.state.state === 'EMPTY') {
      listState.reload();
    }
  }, [listState]);

  const onPress = useCallback((partition: Partition) => {
    onSelect(partitionToIntervalDate(partition)[1]);
  }, []);

  const onEndReached = useCallback(() => {
    if (listState.state.state === 'SUCCESS' && listState.state.hasMore)
      listState.loadMore();
  }, [listState]);

  const initialScrollIndex = useMemo(() => {
    if (!('rows' in listState.state)) return undefined;

    const index = listState.state.rows?.findIndex(
      i => partitionToIntervalDate(i.partition)[1] === selectedDate
    );

    return index === -1 ? undefined : index;
  }, [selectedDate, listState]);

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (listState.state.state !== 'RELOADING') {
      setRefreshing(false);
    }
  }, [listState]);

  if (listState.state.state === 'ERROR') {
    return (
      <ProblemContent
        error={listState.state.error}
        resetErrorBoundary={() => listState.reload()}
      />
    );
  }

  return (
    <FlatList
      renderScrollComponent={scrollProps => (
        <PlatformScrollView {...scrollProps} />
      )}
      getItemLayout={(_, index) => ({
        length: PartitionListCell.itemHeight + ListItemSeparator.itemHeight,
        index,
        offset:
          (PartitionListCell.itemHeight + ListItemSeparator.itemHeight) * index
      })}
      initialScrollIndex={initialScrollIndex}
      style={[styles.container, style]}
      data={'rows' in listState.state ? listState.state.rows : []}
      ItemSeparatorComponent={ListItemSeparator}
      extraData={selectedDate}
      keyExtractor={row => partitionToComponentKey(row.partition)}
      initialNumToRender={20}
      ListEmptyComponent={
        listState.state.state === 'SUCCESS' ? (
          <EmptyContent
            title="No sales yet"
            iconComponent={SearchIcon}
            body="Come back here once some sales have been made"
          />
        ) : (
          <></>
        )
      }
      renderItem={({ item: { partition, value } }) => (
        <PartitionListCell
          partition={partition}
          value={value}
          selected={selectedDate === partitionToIntervalDate(partition)[1]}
          onPress={onPress}
        />
      )}
      onEndReachedThreshold={1}
      onEndReached={onEndReached}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={() => {
            if (listState.state.state === 'SUCCESS') {
              setRefreshing(true);
              listState.reload();
            }
          }}
        />
      }
      {...rest}
    />
  );
};

export default PartitionList;
